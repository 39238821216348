import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import PolicyMovements from 'components/documentation/PolicyMovements';

import PolicyBasePage from '../base';

const Movements = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, policyNum: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('documentation');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 2 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <PolicyBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/policies', text: t('policies') },
        {
          path: `/policies/${clientState.clientId}`,
          text: clientData.policyNum,
        },
        {
          path: `/policies/${clientState.clientId}/movements`,
          text: t('movements'),
        },
      ]}
    >
      {clientData.id && clientData.policyNum ? <PolicyMovements /> : null}
    </PolicyBasePage>
  );
};

export default Movements;
