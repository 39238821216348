import { useTranslation } from 'react-i18next';
import ProjectConfigurationForm from '../ProjectConfigurationForm';
import { useElementCreate } from 'hooks/useElementCreate';
import createProject from 'services/projects/createProject';

const CreateProjectTab = () => {
  const [t] = useTranslation('projectsComponent');

  const { handleOnSubmit } = useElementCreate(
    createProject,
    t('dataCreatedSuccessfully'),
    t('error'),
    '/projects',
  );

  return (
    <ProjectConfigurationForm
      onSubmit={handleOnSubmit}
      defaultFormValues={null}
    />
  );
};

export default CreateProjectTab;
