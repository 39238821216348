import axios from 'axios';

export default async function deleteDocument(projectId, documentId) {
  try {
    const res = await axios.delete(
      '/projects/' + projectId + '/documentation/' + documentId,
    );

    return res;
  } catch (error) {
    return error.response;
  }
}
