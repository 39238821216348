import axios from 'axios';

export default async function createContactMessage(data) {
  const body = {
    notificationTypeLookupId: data.notificationType,
    Message: data.message,
  };

  try {
    const res = await axios.post('/auth/contact', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
