import axios from 'axios';

async function postLogin(body) {
  try {
    const response = await axios.post('/auth/login', body);
    return response;
  } catch (error) {
    return error.response;
  }
}

export default postLogin;
