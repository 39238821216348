import axios from 'axios';
import Manager from 'models/Manager';

export default async function getManagerList() {
  const { data } = await axios.get('/list/managers').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new Manager({
        id: item.id,
        name: `${item.name ?? ''} ${item.surname ?? ''}`,
        email: item.emailAddress,
      }),
  );
}
