import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';

const InsurerFilter = ({ methods }) => {
  const [t] = useTranslation('insurersComponent');

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={12}>
          <Form.Column xs={12} sm={12} md={4} lg={4} xl={4}>
            <Form.Switch name="onboarding" label={t('onboarding')} />
          </Form.Column>

          <Form.Column xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Slider
              name="availableCredits"
              step={1000}
              max={100000}
              label={t('availableCredits')}
              styleContainer={{ justifyContent: 'center' }}
            />
          </Form.Column>

          <Form.Column xs={12} sm={6} md={4} lg={4} xl={4}>
            <Form.Slider
              name="ainaPolicies"
              label={t('numInsurers')}
              max={20}
              styleContainer={{ justifyContent: 'center' }}
            />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

InsurerFilter.propTypes = {
  methods: PropTypes.any,
};

export default InsurerFilter;
