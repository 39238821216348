import { PropTypes } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import Dialog from 'UI/atoms/Dialog';
import Title from 'UI/atoms/Title';

import { useEffect, useState } from 'react';
import { useDecodedData } from 'hooks/useDecodedData';
import { useClientStore } from 'context/ClientProvider/hooks';
import validationSchema from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'UI/molecules/Form';

const AccessCodeDialog = ({
  open,
  onClose,
  loading,
  selectedCode,
  onCreateOrUpdate,
  ...props
}) => {
  const [t] = useTranslation('insurersComponent');
  const [clientState] = useClientStore();

  const { getDecodedData } = useDecodedData(clientState.clientId);

  const [parentId, setParentId] = useState();
  const schema = validationSchema(t);

  const formDefaultValues = {
    user: selectedCode?.user ?? '',
    accessName: selectedCode?.accessName ?? '',
    code: selectedCode?.code ?? '',
    userEmail: selectedCode?.userEmail ?? '',
    observations: selectedCode?.observations ?? '',
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!clientState.clientId) return;
    setParentId(getDecodedData().id);
  }, [clientState.clientId]);

  async function handleUpload() {
    await onCreateOrUpdate(parentId, {
      ...methods.getValues(),
      id: selectedCode?.id,
    });
  }

  return (
    <>
      <Dialog open={open} showCloseButton={true} onClose={onClose} {...props}>
        <Title size="medium">
          {selectedCode ? t('editAccessCode') : t('newAccess')}
        </Title>
        <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleUpload)}>
            <Form.Row columns={12} rowSpacing={2}>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="user" label={t('user.user')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="accessName" label={t('user.accessName')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="code" label={t('user.code')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="userEmail" label={t('user.userEmail')} />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12} rowSpacing={2}>
              <Form.Column xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Input name="observations" label={t('observations')} />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12}>
              <Form.Column xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.PrimaryButton
                  text={t('saveAction')}
                  type="submit"
                  loading={loading}
                />
              </Form.Column>
              <Form.Column xs={2} sm={2} md={2} lg={2} xl={2}>
                <Form.SecondaryButton
                  text={t('cancelAction')}
                  onClick={onClose}
                />
              </Form.Column>
            </Form.Row>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

AccessCodeDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateOrUpdate: PropTypes.func,
  selectedCode: PropTypes.object,
};

export default AccessCodeDialog;
