import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SELECT_CLIENT, SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import InsurerDataTab from 'components/insurer/InsurerDataTab';
import CompanyDataTab from 'components/profile/CompanyDataTab';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

import ProfileBasePage from './base';

const ProfileGeneralDataPage = () => {
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();
  const [relatedEntityId, setRelatedEntityId] = useState(null);
  const [t] = useTranslation('profile');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 1 });

    if ([CLIENT_USER].includes(userState.role)) {
      clientDispatch({
        type: SELECT_CLIENT,
        payload: window.btoa(JSON.stringify({ id: userState.companyId })),
      });
      setRelatedEntityId(userState.companyId);
      return;
    }

    if (
      [INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(userState.role)
    ) {
      clientDispatch({
        type: SELECT_CLIENT,
        payload: window.btoa(JSON.stringify({ id: userState.insurerId })),
      });
      setRelatedEntityId(userState.insurerId);
    }
  }, []);

  return (
    <ProfileBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/profile', text: t('profile') },
        { path: '/profile/general-data', text: t('generalData') },
      ]}
    >
      {relatedEntityId && (
        <>
          {[CLIENT_USER].includes(userState.role) && (
            <>
              <CompanyDataTab />
            </>
          )}
          {[INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(
            userState.role,
          ) && (
            <>
              <InsurerDataTab />
            </>
          )}
        </>
      )}
    </ProfileBasePage>
  );
};

export default ProfileGeneralDataPage;
