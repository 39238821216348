class ProjectCalendar {
  constructor(params) {
    this.id = params.id;
    this.code = params.code;
    this.name = params.name;
    this.averageAge = params.averageAge;
    this.agreement = params.agreement;
    this.companyName = params.companyName;
    this.companyId = params.companyId;
    this.status = params.status;
    this.statusId = params.statusId;
    this.branch = params.branch;
    this.branchId = params.branchId;
    this.numWorkers = params.numWorkers;
    this.expirationDate = params.expirationDate;
    this.currentPremium = params.currentPremium;
    this.cnae = params.cnae;
    this.start = params.start;
    this.end = params.end;
  }
}

export default ProjectCalendar;
