import { CircularProgress as Progress } from '@mui/material';
import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const CircularProgress = ({ customColor, ...props }) => {
  const className = `${styles.root} ${styles[`root--${customColor}`]}`;
  return (
    <Progress
      classes={{
        root: className,
      }}
      {...props}
    />
  );
};

CircularProgress.propTypes = {
  customColor: PropTypes.string,
  props: PropTypes.object,
};

export default CircularProgress;
