import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import CircularProgress from 'UI/atoms/CircularProgress';

import styles from './styles.module.scss';

const PrimaryBtn = ({ text, red, onClick, loading, disabled, ...props }) => {
  return (
    <Button
      classes={{
        disabled: styles.disabled,
        root: `${styles.root} ${red ? styles[`root--red`] : ''}`,
      }}
      onClick={onClick}
      disabled={loading || disabled}
      {...props}
    >
      {loading ? <CircularProgress size={24} customColor="white" /> : text}
    </Button>
  );
};

PrimaryBtn.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  red: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default PrimaryBtn;
