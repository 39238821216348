import { FormProvider, useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';
import ProjectFilter from '../ProjectFilter';
import { useAppStore } from 'context/AppProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const formDefaultValues = {
  companyId: null,
  branchId: null,
  participantsId: null,
  currentInsurerId: null,
  currentPolicy: '',
  statusId: '',
};

const ProjectSearch = ({ onSearch }) => {
  const [t] = useTranslation('projectsComponent');
  const [searchValue, setSearchValue] = useState('');
  const [ownProjects, setOwnProjects] = useState(false);
  const [appState] = useAppStore();

  const [userState] = useUserStore();

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      companyId: methods.getValues('companyId')?.id,
      branchId: methods.getValues('branchId')?.id,
      accessGranted: ownProjects,
      participantsId: methods.getValues('participantsId')?.id,
      currentInsurerId: methods.getValues('currentInsurerId')?.id,
      statusId: methods.getValues('statusId'),
      currentPolicy: methods.getValues('currentPolicy'),
    };
    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    setOwnProjects(false);
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search')}
          />
          <FormProvider {...methods}>
            {[INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(
              userState.role,
            ) ? (
              <Filter.CheckButton
                isChecked={ownProjects}
                onClick={() => setOwnProjects(!ownProjects)}
                name="onlyOwnProjects"
                text={t('myProjects')}
              />
            ) : null}

            <Filter.Select
              options={appState.statuses.map((status) => ({
                label: status.status,
                value: status.id,
              }))}
              label={t('status')}
              extraClassName="filter"
              name="statusId"
            />
          </FormProvider>
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => <ProjectFilter methods={methods} />}
    />
  );
};

ProjectSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default ProjectSearch;
