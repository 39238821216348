import { useTranslation } from 'react-i18next';
import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import CustomTabs from 'UI/molecules/Tabs';
import { useNavigate } from 'react-router-dom';
import ProjectTableAndSearch from 'components/project/ProjectTableAndSearch';

const Projects = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('projects');
  const tabOptions = [
    { label: t('list'), route: `/projects` },
    { label: t('calendar'), route: `/projects/calendar` },
  ];

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route);
  };

  return (
    <PageContainer>
      <Header selected={t('title')}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[
            { path: '/', text: t('breadcrumbsHome') },
            { path: '/projects', text: t('title') },
          ]}
        >
          <CustomTabs tabs={tabOptions} selected={0} onChange={changeTab} />
        </SubHeader>
      </Header>
      <ContentContainer>
        <ProjectTableAndSearch />
      </ContentContainer>
    </PageContainer>
  );
};

export default Projects;
