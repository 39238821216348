class Project {
  constructor(params) {
    this.id = params.id;
    this.code = params.code;
    this.credits = params.credits;
    this.companyName = params.companyName;
    this.companyId = params.companyId;
    this.cif = params.cif;
    this.cnae = params.cnae;
    this.manager = params.manager;
    this.managerId = params.managerId;
    this.status = params.status;
    this.statusId = params.statusId;
    this.branch = params.branch;
    this.branchId = params.branchId;
    this.currentPolicy = params.currentPolicy;
    this.currentPolicyId = params.currentPolicyId;
    this.creationDate = params.creationDate;
    this.startDate = params.startDate;
    this.roundOneDate = params.roundOneDate;
    this.roundTwoDate = params.roundTwoDate;
    this.newInsuranceDate = params.newInsuranceDate;
    this.accessGranted = params.accessGranted;
    this.participants = params.participants;
    this.participantsRoundOne = params.participantsRoundOne;
    this.participantsRoundTwo = params.participantsRoundTwo;
    this.numWorkers = params.numWorkers;
    this.averageAge = params.averageAge;
    this.procedureIT = params.procedureIT;
    this.agreement = params.agreement;
    this.preferencesPb = params.preferencesPb;
    this.preferencesPremium = params.preferencesPremium;
    this.preferencesAdhesion = params.preferencesAdhesion;
    this.preferencesSolvency = params.preferencesSolvency;
    this.currentPb = params.currentPb;
    this.currentInsurerId = params.currentInsurerId;
    this.currentInsurer = params.currentInsurer;
    this.currentPremium = params.currentPremium;
    this.expirationDate = params.expirationDate;
  }
}

export default Project;
