import axios from 'axios';
import SIC from 'models/SIC';

export default async function getSICList() {
  const { data } = await axios.get('/list/sic').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new SIC({
        id: item.id,
        name: item.title,
        code: item.coD_SIC,
      }),
  );
}
