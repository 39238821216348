export const columns = (t) => [
  { field: 'id' },
  {
    field: 'cif',
    headerName: t('cif'),
    width: 125,
  },
  {
    field: 'name',
    headerName: t('socialReason'),
    minWidth: 200,
    flex: 0.2,
  },
  {
    field: 'ccaa',
    headerName: t('ccaaHeader'),
    align: 'left',
    headerAlign: 'left',
    flex: 0.2,
    minWidth: 200,
  },
  {
    field: 'province',
    headerName: t('provinceHeader'),
    align: 'left',
    headerAlign: 'left',
    flex: 0.2,
    minWidth: 150,
  },
  {
    field: 'city',
    headerName: t('cityHeader'),
    align: 'left',
    headerAlign: 'left',
    flex: 0.2,
    minWidth: 150,
  },
  {
    field: 'cnae',
    headerName: t('cnaeHeader'),
    minWidth: 125,
    flex: 0.2,
  },
  {
    field: 'sic',
    headerName: t('sicHeader'),
    width: 125,
  },
  {
    field: 'numWorkers',
    headerName: t('numWorkersHeader'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 125,
  },
  {
    field: 'averageAge',
    headerName: t('avgAge'),
    width: 125,
  },
  {
    field: 'ainaPolicies',
    headerName: t('insurances'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 150,
  },
  {
    field: 'onboarding',
    headerName: t('onboardingHeader'),
    align: 'left',
    headerAlign: 'left',
    type: 'boolean',
    width: 150,
  },
  {
    field: 'activeProjects',
    headerName: t('activeProjectHeader'),
    align: 'left',
    headerAlign: 'left',
    type: 'boolean',
    width: 150,
  },
  {
    field: 'letterOfAppointment',
    headerName: t('letterOfAppointmentHeader'),
    align: 'left',
    headerAlign: 'left',
    type: 'boolean',
  },
];
