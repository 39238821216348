class ClientOnboarding {
  constructor(params) {
    this.id = params.id;
    this.contactName = params.contactName;
    this.contactSurname = params.contactSurname;
    this.phone = params.phone;
    this.email = params.email;
    this.representativeName = params.representativeName;
    this.representativeSurname = params.representativeSurname;
    this.representativeNid = params.representativeNid;
    this.representativeNidExpirationDate =
      params.representativeNidExpirationDate;
    this.letterOfAppointment = params.letterOfAppointment;
    this.onboarding = params.onboarding;
    this.attachment = params.attachment;
  }
}

export default ClientOnboarding;
