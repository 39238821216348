import { PropTypes } from 'prop-types';
import NavigationButton from 'UI/atoms/NavigationButton';
import { WhiteArrow } from 'assets/icons/IconsLibrary';
import AinaLogo from 'assets/aina-logo.png';
import { Drawer } from '@mui/material';
import styles from './styles.module.scss';

const ResponsiveNavigationMenu = ({ onClick, show, navigationButtons }) => {
  return (
    <Drawer
      open={show}
      className={styles.modal}
      classes={{ paper: styles.paper }}
      hideBackdrop
    >
      <div className={`${styles.background}`}>
        <div className={styles.container} onClick={onClick}>
          <img src={WhiteArrow} alt="Flecha blanca"></img>
        </div>
        <header className={styles.menu}>
          <div className={styles.logoContainer}>
            <img src={AinaLogo} alt="Logo"></img>
          </div>
          <div className={styles.buttons}>
            {navigationButtons.map((navBtn) => (
              <NavigationButton
                key={navBtn.text}
                text={navBtn.text}
                route={navBtn.route}
                icon={navBtn.icon}
                selected={navBtn.selected}
                isResponsive
              />
            ))}
          </div>
        </header>
      </div>
    </Drawer>
  );
};

ResponsiveNavigationMenu.propTypes = {
  onClick: PropTypes.func,
  show: PropTypes.bool,
  navigationButtons: PropTypes.array,
};

export default ResponsiveNavigationMenu;
