import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';

import FormBox from 'UI/atoms/FormBox';
import Title from 'UI/atoms/Title';
import Paragraph from 'UI/atoms/Paragraph';

import styles from './styles.module.scss';

const PublicForm = ({ title, text, methods, onSubmit, fields, actions }) => {
  const { handleSubmit } = methods;

  return (
    <>
      <FormBox>
        <Title>{title}</Title>
        <Paragraph>{text}</Paragraph>
        <FormProvider {...methods}>
          <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
            {fields && <div className={styles.fields}>{fields()}</div>}
            {actions && <div className={styles.actions}>{actions()}</div>}
          </form>
        </FormProvider>
      </FormBox>
    </>
  );
};

PublicForm.propTypes = {
  title: PropTypes.string,
  text: PropTypes.node,
  methods: PropTypes.any,
  onSubmit: PropTypes.func,
  fields: PropTypes.func,
  actions: PropTypes.func,
};

export default PublicForm;
