import { useTranslation } from 'react-i18next';
import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import PoliciesTableAndSearch from 'components/policies/PoliciesTableAndSearch';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const Policies = () => {
  const [t] = useTranslation('policies');
  const [userState] = useUserStore();

  function getFIlter() {
    switch (userState.role) {
      case INSURER_MANAGER:
      case INSURER_SUPPORT:
      case INSURER_USER:
        return { insurerId: userState.insurerId };

      case CLIENT_USER:
        return { companyId: userState.companyId };

      default:
        return undefined;
    }
  }

  return (
    <PageContainer>
      <Header selected={t('title')}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[
            { path: '/', text: t('breadcrumbsHome') },
            { path: '/policies', text: t('title') },
          ]}
        />
      </Header>
      <ContentContainer>
        <PoliciesTableAndSearch filters={getFIlter()} />
      </ContentContainer>
    </PageContainer>
  );
};

export default Policies;
