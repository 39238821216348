import axios from 'axios';
import CreditPurchase from 'models/CreditPurchase';

export default async function getCredits(filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    estadoLookupId: filter?.paymentStatusId ?? '',
    search: filter?.searchBarText ?? '',
    aseguradoraLookupId: filter?.insurerId ?? '',

    NodecreditosStart: filter?.creditsStart ?? '',
    NodecreditosEnd: filter?.creditsEnd ?? '',

    PagoFrom: filter?.paymentDateStart ?? '',
    PagoTo: filter?.paymentDateEnd ?? '',

    SolicitudFrom: filter?.createdAtStart ?? '',
    SolicitudTo: filter?.createdAtEnd ?? '',

    Start: filter?.start ?? '',
    Length: filter?.length ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/credits', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new CreditPurchase({
            id: item.id,
            insurer: item.aseguradora,
            insurerId: item.aseguradoraLookupId,
            paymentStatus: item.estado,
            paymentStatusId: item.estadoLookupId,
            price: item.importe,
            taxes: item.iva,
            credits: item.nodecreditos,
            paymentDate: item.pago,
            createdAt: item.solicitud,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
