import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SELECT_CLIENT } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import ProjectTable from 'components/project/ProjectTable';
import ProjectSearch from 'components/project/ProjectSearch';
import { useTable } from 'hooks/useTable';
import getProjects from 'services/projects/getProjects';
import { CLIENT_USER, INSURER_MANAGER, INSURER_USER } from 'utils/constants';
import { projectsMapper } from 'utils/columnsMapper';

const ProjectTableAndSearch = ({ filters }) => {
  const [t] = useTranslation('projects');
  const navigate = useNavigate();
  const [userState] = useUserStore();

  const [, clientDispatch] = useClientStore();
  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
    handleOnRowClickWithFields,
  } = useTable(
    getProjects,
    t('error'),
    false,
    undefined,
    filters,
    projectsMapper,
  );

  const handleOnRowClickWithFieldsToPhases = (row, navigateTo, fields) => {
    clientDispatch({ type: SELECT_CLIENT, payload: null });
    const defaultField = { id: row.id };
    const object = fields.reduce((acc, field) => {
      return { ...acc, [field]: row[field] };
    }, defaultField);
    const id = btoa(JSON.stringify(object));
    navigate(`/${navigateTo}/${id}/phases`, {
      state: { clientId: id },
    });
  };

  return (
    <>
      <ProjectSearch onSearch={handleOnSearch} />
      <ProjectTable
        projects={rows}
        rowCount={rowCount}
        loading={loading}
        onRowClick={
          [CLIENT_USER, INSURER_MANAGER, INSURER_USER].includes(userState.role)
            ? handleOnRowClickWithFieldsToPhases
            : handleOnRowClickWithFields
        }
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addButtonOnClick={() => navigate('/projects/create')}
      />
    </>
  );
};

ProjectTableAndSearch.propTypes = {
  filters: PropTypes.object,
};

export default ProjectTableAndSearch;
