import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      ccaaId: yup.object().required(t('mandatory', { field: t('ccaa') })),
      name: yup.string().required(t('mandatory', { field: t('socialReason') })),
      trademark: yup
        .string()
        .required(t('mandatory', { field: t('commercialBrand') })),
      solvency: yup
        .number()
        .typeError(t('invalidFormat', { field: t('solvency') }))
        .positive(t('positive', { field: t('solvency') }))
        .required(t('mandatory', { field: t('solvency') })),
      insurerURL: yup
        .string()
        .required(t('mandatory', { field: t('insurerURL') })),
      provinceId: yup
        .object()
        .required(t('mandatory', { field: t('province') })),
      cif: yup.string().required(t('mandatory', { field: t('cif') })),
      email: yup
        .string()
        .email(t('invalidFormat', { field: t('contactEmail') })),
      zipCode: yup
        .number()
        .typeError(t('invalidFormat', { field: t('zipCode') }))
        .positive(t('positive', { field: t('zipCode') }))
        .required(t('mandatory', { field: t('zipCode') })),
      address: yup.string().required(t('mandatory', { field: t('address') })),
      averageAge: yup
        .number()
        .typeError(t('invalidFormat', { field: t('averageAge') }))
        .positive(t('positive', { field: t('averageAge') }))
        .required(t('mandatory', { field: t('averageAge') })),
      life: yup
        .number()
        .typeError(t('invalidFormat', { field: t('life') }))
        .positive(t('positive', { field: t('life') }))
        .required(t('mandatory', { field: t('life') })),
      accidents: yup
        .number()
        .typeError(t('invalidFormat', { field: t('accidents') }))
        .positive(t('positive', { field: t('accidents') }))
        .required(t('mandatory', { field: t('accidents') })),
      health: yup
        .number()
        .typeError(t('invalidFormat', { field: t('health') }))
        .positive(t('positive', { field: t('health') }))
        .required(t('mandatory', { field: t('health') })),
      accessLife: yup
        .number()
        .typeError(t('invalidFormat', { field: t('life') }))
        .positive(t('positive', { field: t('life') }))
        .required(t('mandatory', { field: t('life') })),
      accessAccidents: yup
        .number()
        .typeError(t('invalidFormat', { field: t('accidents') }))
        .positive(t('positive', { field: t('accidents') }))
        .required(t('mandatory', { field: t('accidents') })),
      accessHealth: yup
        .number()
        .typeError(t('invalidFormat', { field: t('health') }))
        .positive(t('positive', { field: t('health') }))
        .required(t('mandatory', { field: t('health') })),
    })
    .required();

export default validationSchema;
