import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';

const UsersFilter = ({ methods }) => {
  const [t] = useTranslation('user');

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={12}>
          <Form.Column sm={12} md={6} lg={4} xl={4}>
            <Form.Input name="email" label={t('contactEmail')} />
          </Form.Column>
          <Form.Column sm={12} md={6} lg={4} xl={4}>
            <Form.Input name="phone" label={t('contactPhone')} />
          </Form.Column>
          <Form.Column sm={12} md={6} lg={4} xl={4}>
            <Form.Input name="position" label={t('user.position')} />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

UsersFilter.propTypes = {
  methods: PropTypes.any,
};

export default UsersFilter;
