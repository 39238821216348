class Policy {
  constructor(params) {
    this.id = params.id;
    this.policyNum = params.policyNum;
    this.insurerId = params.insurerId;
    this.insurer = params.insurer;
    this.cif = params.cif;
    this.companyName = params.companyName;
    this.companyId = params.companyId;
    this.managerId = params.managerId;
    this.projectId = params.projectId;
    this.projectName = params.projectName;
    this.branch = params.branch;
    this.branchId = params.branchId;
    this.numWorkersWithInsurance = params.numWorkersWithInsurance;
    this.numWorkersOffWork = params.numWorkersOffWork;
    this.policyReceipt = params.policyReceipt;
    this.agreement = params.agreement;
    this.totalPremium = params.totalPremium;
    this.netPremium = params.netPremium;
    this.expirationDate = params.expirationDate;
    this.pb = params.pb;
    this.traditionalBranchesCommission = params.traditionalBranchesCommission;
    this.periodicity = params.periodicity;
    this.periodicityId = params.periodicityId;
    this.receiptPolicyActivationMovement =
      params.receiptPolicyActivationMovement;
    this.receiptPolicyTerminationMovement =
      params.receiptPolicyTerminationMovement;
  }
}

export default Policy;
