import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import CustomBreadcrumbs from 'UI/atoms/CustomBreadcrumbs';
import CustomChip from 'UI/atoms/CustomChip';
import Title from 'UI/atoms/Title';

import styles from './styles.module.scss';

const SubHeader = ({ text, chips, breadcrumbs, children, onChipClick }) => {
  const [t] = useTranslation('subHeader');
  return (
    <div className={styles.subheader}>
      <div className={styles.container}>
        <div>
          <CustomBreadcrumbs
            breadcrumbs={breadcrumbs}
            initialText={`${t('breadcrumbsInitialText')}: `}
          />
          <Title size="subheader">{text}</Title>
        </div>
        <div className={styles.chipsContainer}>
          {chips
            ? chips.map((chip) => (
                <CustomChip
                  onClick={() => onChipClick(chip)}
                  key={chip.id + chip.entity}
                  label={`${chip.label}${chip.labelEntityName}`}
                  icon={chip.icon}
                />
              ))
            : null}
        </div>
      </div>
      {children}
    </div>
  );
};

SubHeader.propTypes = {
  chips: PropTypes.array,
  breadcrumbs: PropTypes.array,
  text: PropTypes.string,
  children: PropTypes.node,
  onChipClick: PropTypes.func,
};

export default SubHeader;
