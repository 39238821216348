import * as yup from 'yup';

const validationSchema = (t, adminsLength, supportsLength) => {
  const adminFields = Array.from({ length: adminsLength }, (_, index) => ({
    [`adminName_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('user.contactAdministrator') })),
    [`adminPhone_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('contactPhone') })),
    [`adminEmail_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('contactEmail') })),
    [`adminPosition_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('user.position') })),
  }));

  const supportFields = Array.from({ length: supportsLength }, (_, index) => ({
    [`supportName_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('user.contactSupport') })),
    [`supportPhone_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('contactPhone') })),
    [`supportEmail_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('email') })),
    [`supportPosition_${index}`]: yup
      .string()
      .required(t('mandatory', { field: t('user.position') })),
  }));

  return yup
    .object({
      ...Object.assign({}, ...adminFields),
      ...Object.assign({}, ...supportFields),
    })
    .required();
};

export default validationSchema;
