export const columnsMapper = new Map();

columnsMapper.set('documentTypeId', 'tipoLookupId');
columnsMapper.set('documentType', 'tipo');
columnsMapper.set('fileName', 'nombreDeArchivo');
columnsMapper.set('verified', 'verificado');
columnsMapper.set('lastModificationDate', 'lastModifiedDateTime');
columnsMapper.set('name', 'razonSocial');
columnsMapper.set('cif', 'cif');
columnsMapper.set('ccaa', 'provincia_x003a__x0020_CCAA');
columnsMapper.set('province', 'provincia');
columnsMapper.set('city', 'ciudad');
columnsMapper.set('cnae', 'cnae');
columnsMapper.set('sic', 'sic');
columnsMapper.set('companyName', 'aseguradora');
columnsMapper.set('numWorkers', 'numTrabajadores');
columnsMapper.set('averageAge', 'edadMedia');
columnsMapper.set('ainaPolicies', 'polizasAina');
columnsMapper.set('onboarding', 'onboarding');
columnsMapper.set('activeProjects', 'proyectosActivos');
columnsMapper.set('availableCredits', 'creditosDisponibles');
columnsMapper.set('letterOfAppointment', 'cartaNombramiento');
columnsMapper.set('letterOfAppointment', 'cartaNombramiento');

export const clientsUsersMapper = new Map();

clientsUsersMapper.set('name', 'name');
clientsUsersMapper.set('position', 'cargo');
clientsUsersMapper.set('email', 'emailAddress');
clientsUsersMapper.set('phone', 'telefono');

export const insurersUsersMapper = new Map();

insurersUsersMapper.set('name', 'name');
insurersUsersMapper.set('position', 'cargo');
insurersUsersMapper.set('email', 'emailAddress');
insurersUsersMapper.set('phone', 'telefono');

export const accessCodesMapper = new Map();

accessCodesMapper.set('accessName', 'nombreAcceso');
accessCodesMapper.set('user', 'usuario');
accessCodesMapper.set('userEmail', 'emailUsuario');
accessCodesMapper.set('code', 'codigo');
accessCodesMapper.set('observations', 'observaciones');

export const projectsMapper = new Map();

projectsMapper.set('cif', 'empresa_x003a__x0020_CIF');
projectsMapper.set('companyName', 'empresa');
projectsMapper.set('credits', 'costeCreditos');
projectsMapper.set('status', 'estado');
projectsMapper.set('code', 'codigodeproyecto');
projectsMapper.set('currentPremium', 'primaActual');
projectsMapper.set('currentPolicy', 'polizaActual');
projectsMapper.set('branch', 'ramo');
projectsMapper.set('currentInsurer', 'aseguradoraPolizaActual');
projectsMapper.set('expirationDate', 'fechaVencimientoActual');

export const policiesMapper = new Map();

policiesMapper.set('policyNum', 'numeroDePoliza');
policiesMapper.set('cif', 'empresaCliente_x003a__x0020_CIF');
policiesMapper.set('companyName', 'empresaCliente');
policiesMapper.set('branch', 'ramo');
policiesMapper.set('insurer', 'aseguradora');
policiesMapper.set('netPremium', 'primaNeta');
policiesMapper.set('totalPremium', 'primaTotal');
policiesMapper.set('periodicity', 'periodicidad');
policiesMapper.set('pb', 'pb');
policiesMapper.set('expirationDate', 'fechaVencimiento');

export const receiptsMapper = new Map();

receiptsMapper.set('id', 'id');
receiptsMapper.set('receipt', 'recibo');
receiptsMapper.set('policyNum', 'numeroDePoliza');
receiptsMapper.set('receiptInsurer', 'reciboAseguradora');
receiptsMapper.set('branch', 'ramo');
receiptsMapper.set('insurer', 'aseguradora');
receiptsMapper.set('issueDate', 'emision');
receiptsMapper.set('expirationDate', 'vencimiento');
receiptsMapper.set('totalAmount', 'importe');
receiptsMapper.set('periodicity', 'periodicidad');
receiptsMapper.set('status', 'estado');

export const creditsConsumptionMapper = new Map();

creditsConsumptionMapper.set('id', 'id');
creditsConsumptionMapper.set('project', 'proyecto');
creditsConsumptionMapper.set('company', 'empresa');
creditsConsumptionMapper.set('insurer', 'aseguradora');
creditsConsumptionMapper.set('branch', 'ramo');
creditsConsumptionMapper.set('date', 'consumo');
creditsConsumptionMapper.set('credits', 'nodecreditos');

export const creditsPurchaseMapper = new Map();

creditsConsumptionMapper.set('id', 'id');
creditsConsumptionMapper.set('code', 'id');
creditsConsumptionMapper.set('paymentStatus', 'estado');
creditsConsumptionMapper.set('credits', 'nodecreditos');
