import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';

import settings from './slickSettings';
import 'slick-carousel/slick/slick.css';

import styles from './styles.module.scss';

import Step1Image from 'assets/icons/step01.svg';
import Step2Image from 'assets/icons/step02.svg';
import Step3Image from 'assets/icons/step03.svg';

const Steps = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={`${styles.steps} wow fadeInUp`}>
        <div id="steps" className={styles.anchor}></div>
        <div className={styles.header}>
          <p className={`${styles.title} wow fadeInUp`}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('steps.title'),
              }}
            ></span>
          </p>
          <p className={`${styles.text} wow fadeInUp`}>{t('steps.subtitle')}</p>
        </div>

        <div className={`${styles.sliderWrapper} wow fadeInUp`}>
          <Slider {...settings}>
            <div className={styles.sliderItem}>
              <div className={styles.image}>
                <div className={styles.imageWrapper}>
                  <img src={Step1Image} width="150" height="150" />
                </div>
                <p className={styles.count}>1</p>
              </div>

              <div className={styles.info}>
                <p className={styles.slideTitle}>{t('steps.slide1Title')}</p>
                <p className={styles.slideText}>{t('steps.slide1Text')}</p>
              </div>
            </div>

            <div className={styles.sliderItem}>
              <div className={styles.image}>
                <div className={styles.imageWrapper}>
                  <img src={Step2Image} width="150" height="150" />
                </div>
                <p className={styles.count}>2</p>
              </div>

              <div className={styles.info}>
                <p className={styles.slideTitle}>{t('steps.slide2Title')}</p>
                <p className={styles.slideText}>{t('steps.slide2Text')}</p>
              </div>
            </div>

            <div className={styles.sliderItem}>
              <div className={styles.image}>
                <div className={styles.imageWrapper}>
                  <img src={Step3Image} width="150" height="150" />
                </div>
                <p className={styles.count}>3</p>
              </div>

              <div className={styles.info}>
                <p className={styles.slideTitle}>{t('steps.slide3Title')}</p>
                <p className={styles.slideText}>{t('steps.slide3Text')}</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Steps;
