import axios from 'axios';
import Policy from 'models/Policy';

export default async function createPolicy(data) {
  const policy = new Policy(data);

  const body = {
    numeroDePoliza: policy.policyNum,
    aseguradora: policy.insurer,
    aseguradoraLookupId: policy.insurerId,
    empresaCliente: policy.companyName,
    empresaClienteLookupId: policy.companyId,
    empresaCliente_x003a__x0020_CIF: policy.cif,
    gestorLookupId: policy.managerId,
    comisionEmisionRamosTradicionale: policy.traditionalBranchesCommission,
    pb: policy.pb,
    trabajadoresAsegurados: policy.numWorkersWithInsurance,
    trabajadoresBajaLaboral: policy.numWorkersOffWork,
    reciboPoliza: policy.policyReceipt,
    ramo: policy.branch,
    ramoLookupId: policy.branchId,
    convenio: policy.agreement,
    fechaVencimiento: policy.expirationDate,
    primaNeta: policy.netPremium,
    primaTotal: policy.totalPremium,
    periodicidadLookupId: policy.periodicityId,
    periodicidad: policy.periodicity,
    reciboMovimientoAltaPoliza: policy.receiptPolicyActivationMovement,
    reciboMovimientoBajaPoliza: policy.receiptPolicyTerminationMovement,
  };

  try {
    const res = await axios.post('/policies', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
