const initialState = {
  id: null,
  role: null,
  roleId: null,
  companyId: null,
  insurerId: null,
  credits: null,
};

export default initialState;
