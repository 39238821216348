import { useTable } from 'hooks/useTable';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getPolicies from 'services/policies/getPolicies';
import PoliciesTable from '../PoliciesTable';
import PoliciesSearch from '../PoliciesSearch';
import { policiesMapper } from 'utils/columnsMapper';

const PoliciesTableAndSearch = ({ filters }) => {
  const [t] = useTranslation('policies');
  const navigate = useNavigate();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnRowClickWithFields,
    handleOnSortModelChange,
  } = useTable(
    getPolicies,
    t('error'),
    false,
    undefined,
    filters,
    policiesMapper,
  );

  return (
    <>
      <PoliciesSearch onSearch={handleOnSearch} />
      <PoliciesTable
        policies={rows}
        rowCount={rowCount}
        loading={loading}
        onRowClick={handleOnRowClickWithFields}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addButtonOnClick={() => navigate('/policies/create')}
      />
    </>
  );
};

PoliciesTableAndSearch.propTypes = {
  filters: PropTypes.object,
};
export default PoliciesTableAndSearch;
