import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      branch: yup
        .number()
        .required(t('mandatory', { field: t('dialog.branch') })),
      preference: yup
        .string()
        .required(t('mandatory', { field: t('preference') })),
      commission: yup
        .number()
        .typeError(t('invalidFormat', { field: t('commission') }))
        .moreThan(-1, t('positive', { field: t('commission') }))
        .required(t('mandatory', { field: t('commission') })),
    })
    .required();

export default validationSchema;
