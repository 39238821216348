class ProjectWinner {
  constructor(params) {
    this.companyName = params.companyName;
    this.currentInsurerId = params.currentInsurerId;
    this.currentInsurer = params.currentInsurer;
    this.isWinner = params.isWinner;
    this.offer = params.offer;
  }
}

export default ProjectWinner;
