import { useEffect, useState } from 'react';
import { SELECT_CLIENT } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';

import SubHeader from 'components/blocks/SubHeader';
import Header from 'components/blocks/Header';
import PageContainer from 'UI/molecules/PageContainer';
import ContentContainer from 'UI/atoms/ContentContainer';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ReceiptDataTab from 'components/receipts/ReceiptDataTab';

const GeneralData = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    receipt: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [clientId, setClientId] = useState();
  const [t] = useTranslation('receipts');
  const { pathname } = useLocation();
  const path = pathname.split('/');

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!clientId) return;
    setClientData(getDecodedData());
  }, [clientId]);

  useEffect(() => {
    const { clientId } = params;
    if (!clientId) {
      navigate(path);
      return;
    }
    setClientId(clientId);
    clientDispatch({ type: SELECT_CLIENT, payload: clientId });
  }, [params]);

  return (
    <PageContainer>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={`${t('receipt')} ${clientData.receipt}`}
            breadcrumbs={[
              { path: '/', text: t('breadcrumbsHome') },
              { path: `/${path[1]}/${path[2]}`, text: t('title') },
              {
                path: '',
                text: clientData.receipt,
              },
            ]}
            currentPagePath={pathname}
          ></SubHeader>
        </Header>

        <ContentContainer>
          {clientData.id ? <ReceiptDataTab /> : null}
        </ContentContainer>
      </>
    </PageContainer>
  );
};

export default GeneralData;
