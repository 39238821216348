import { useTranslation } from 'react-i18next';
import { useTable } from 'hooks/useTable';

import { useClientStore } from 'context/ClientProvider/hooks';
import Title from 'UI/atoms/Title';
import getInsurerDocumentation from 'services/insurers/getInsurerDocumentation';
import DocumentationSearch from 'components/documentation/DocumentationSearch';
import DocumentationTable from 'components/documentation/DocumentationTable';
import DocumentationDialog from 'components/documentation/DocumentationDialog';
import updateDocumentation from 'services/insurers/updateDocumentation';
import createDocumentation from 'services/insurers/createDocumentation';
import downloadDocumentation from 'services/insurers/downloadDocumentation';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import deleteDocument from 'services/insurers/deleteDocument';
import { useDialog } from 'hooks/useDialog';

const InsurerDocumentationTab = () => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    refreshRows,
    handleOnSortModelChange,
  } = useTable(getInsurerDocumentation, t('error'), true, clientState.clientId);

  const {
    openConfirmationDialog,
    loadingDialog,
    openDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleOnAdd,
    handleOnClose,
    handleOnDelete,
    handleDeleteElement,
    handleOnEdit,
  } = useDialog(
    updateDocumentation,
    createDocumentation,
    refreshRows,
    deleteDocument,
  );

  return (
    <>
      <Title size="medium">{t('title')}</Title>
      <DocumentationSearch onSearch={handleOnSearch} />
      <DocumentationTable
        documents={rows}
        rowCount={rowCount}
        loading={loading}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addDocumentHandler={() => handleOnAdd()}
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        downloadDocumentation={downloadDocumentation}
      />
      {openDialog ? (
        <DocumentationDialog
          selectedDocument={selectedElement}
          documentType="insurer"
          loading={loadingDialog}
          open={openDialog}
          onClose={handleOnClose}
          onCreateOrUpdate={handleCreateOrUpdateElement}
          downloadDocumentation={downloadDocumentation}
        />
      ) : null}
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement()}
        onClose={handleOnClose}
      />
    </>
  );
};

export default InsurerDocumentationTab;
