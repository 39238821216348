import { useEffect } from 'react';
import WOW from 'wowjs';

import Features from 'components/home/Features';
import FeaturesBackground from 'components/home/FeaturesBackground';
import Footer from 'components/home/Footer';
import Form from 'components/home/Form';
import Header from 'components/home/Header';
import Hero from 'components/home/Hero';
import ImageTextBlock from 'components/home/ImageTextBlock';
import Steps from 'components/home/Steps';
import Tabs from 'components/home/Tabs';

const Home = () => {
  useEffect(() => {
    new WOW.WOW({
      live: true,
    }).init();
  }, []);

  return (
    <div>
      <Header fullDisplay={true} />
      <Hero />
      <Features />
      <FeaturesBackground />
      <ImageTextBlock blockNumber={1} />
      <Tabs />
      <ImageTextBlock blockNumber={2} />
      <ImageTextBlock blockNumber={3} />
      <Steps />
      <Form />
      <Footer />
    </div>
  );
};

export default Home;
