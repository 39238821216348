import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';
import PoliciesFilter from '../PoliciesFilter';
import getInsurers from 'services/insurers/getInsurers';
import dayjs from 'dayjs';
import { useDebouncer } from 'hooks/useDebouncer';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';

const formDefaultValues = {
  cif: '',
  insurerId: null,
  netPremium: [0, 1000000],
  totalPremium: [0, 1000000],
  pb: [0, 100],
  periodicityId: null,
  expirationDateFrom: null,
  expirationDateTo: null,
};

const PoliciesSearch = ({ onSearch }) => {
  const [t] = useTranslation('policiesComponent');
  const [searchValue, setSearchValue] = useState('');
  const [insurers, setInsurers] = useState([]);
  const [userState] = useUserStore();

  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
  } = useDebouncer(
    [AINA_MANAGER].includes(userState.role) ? getInsurers : () => [],
    setInsurers,
    false,
  );

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  function getRangeValue(field, index) {
    return formDefaultValues[field][index] === methods.getValues(field)[index]
      ? ''
      : methods.getValues(field)[index];
  }

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      insurerId: methods.getValues('insurerId')?.id,
      cif: methods.getValues('cif'),
      periodicityId: methods.getValues('periodicityId'),
      expirationDateFrom: methods.getValues('expirationDateFrom')
        ? dayjs(methods.getValues('expirationDateFrom')).format('YYYY-MM-DD')
        : '',
      expirationDateTo: methods.getValues('expirationDateTo')
        ? dayjs(methods.getValues('expirationDateTo')).format('YYYY-MM-DD')
        : '',
      netPremiumStart: getRangeValue('netPremium', 0),
      netPremiumEnd: getRangeValue('netPremium', 1),
      totalPremiumStart: getRangeValue('totalPremium', 0),
      totalPremiumEnd: getRangeValue('totalPremium', 1),
      pbStart: getRangeValue('pb', 0),
      pbEnd: getRangeValue('pb', 1),
    };
    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => (
        <PoliciesFilter
          onInputChange={(value) => handleOnInputChangeInsurer(value)}
          methods={methods}
          insurers={insurers}
          loading={loadingInsurers}
        />
      )}
    />
  );
};

PoliciesSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default PoliciesSearch;
