import axios from 'axios';
import { getCookieValue } from 'utils/getCookieValue';

export default async function createDocumentation(
  receiptId,
  selectedFile,
  document,
) {
  const requestBody = {
    File: selectedFile,
    TipoLookupId: document.documentTypeId,
    Verificado: document.verified,
  };

  try {
    const instanceAxios = axios.create();
    instanceAxios.interceptors.request.clear();

    instanceAxios.interceptors.request.use(function (config) {
      const token = getCookieValue('token');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      config.headers['Content-Type'] = 'multipart/form-data';
      return config;
    });

    const { data } = await instanceAxios.post(
      `/receipts/${receiptId}/documentation`,
      requestBody,
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
