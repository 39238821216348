import { useTranslation } from 'react-i18next';
import Loader from 'UI/atoms/Loader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useElementData } from 'hooks/useElementData';
import { useElementSubmit } from 'hooks/useElementSubmit';
import InsurerOnboardingDataForm from '../InsurerOnboardingDataForm';
import getInsurerOnboarding from 'services/insurers/getInsurerOnboarding';
import updateOnboardingData from 'services/insurers/updateOnboardingData';

const InsurerOnboardingDataTab = () => {
  const [t] = useTranslation('onboarding');
  const [clientState] = useClientStore();
  const { loading, data } = useElementData(
    clientState.clientId,
    getInsurerOnboarding,
  );

  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateOnboardingData,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return (
    <InsurerOnboardingDataForm
      defaultFormValues={data}
      onSubmit={handleOnSubmit}
    />
  );
};

export default InsurerOnboardingDataTab;
