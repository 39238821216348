import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { DataGrid, GridPagination } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import { PropTypes } from 'prop-types';

import AddButton from 'UI/atoms/AddButton';

import styles from './styles.module.scss';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import ContactManagerButton from 'UI/molecules/ContactManagerButton';

const CustomPagination = () => {
  const [t] = useTranslation('customTable');

  function labelDisplayedRows({ from, to, count }) {
    return `${from}–${to} ${t('of')} ${
      count !== -1 ? count : `${t('moreThan')} ${to}`
    }`;
  }

  return (
    <GridPagination
      className={styles.tablePagination}
      classes={{
        toolbar: styles.toolbar,
        displayedRows: styles.displayedRows,
        selectLabel: styles.selectLabel,
        actions: styles.tablePaginationActions,
      }}
      slotProps={{
        actions: {
          previousButton: { className: styles.actionButton },
          nextButton: { className: styles.actionButton },
        },
        select: {
          classes: {
            select: styles.tablePaginationSelect,
            root: styles.tablePaginationInput,
          },
        },
      }}
      labelDisplayedRows={labelDisplayedRows}
      labelRowsPerPage={`${t('rowsPerPage')}:`}
    />
  );
};

const CustomTable = ({
  onRowClick,
  rows,
  addButtonText,
  addButtonOnClick,
  customPagination,
  columns,
  loading,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  pageSizeOptions,
  onSortModelChange,
  styleContainer,
  hideColumns,
  ...props
}) => {
  const [rowCountState, setRowCountState] = useState(rowCount);
  const [t] = useTranslation('customTable');
  const [userState] = useUserStore();

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      rowCount !== undefined ? rowCount : prevRowCountState,
    );
  }, [rowCount, setRowCountState]);

  function getHiddenColumns() {
    const hiddenColumns = hideColumns?.reduce((acc, column) => {
      acc[column] = false;
      return acc;
    }, {});
    return { id: false, ...hiddenColumns };
  }

  return (
    <Box
      style={styleContainer}
      sx={{ width: '100%', marginTop: 2, position: 'relative' }}
    >
      <DataGrid
        className={styles.table}
        loading={loading}
        slotProps={{
          row: { className: styles.row },
          cell: { className: styles.cell },
        }}
        localeText={{
          noRowsLabel: t('noResults'),
          noResultsOverlayLabel: t('noResults'),
        }}
        classes={{
          columnHeaders: styles.columnHeaders,
          columnHeader: styles.columnHeader,
          'columnHeader--sorted': styles['columnHeader--sorted'],
          iconButtonContainer: styles.iconButtonContainer,
          sortIcon: styles.sortIcon,
          columnHeaderTitleContainer: styles.columnHeaderTitleContainer,
          footerContainer: styles.footerContainer,
          virtualScroller: styles.virtualScroller,
        }}
        slots={{
          footer: () => (
            <>
              {addButtonText && addButtonOnClick ? (
                <div className={styles.addButtonContainer}>
                  {![AINA_MANAGER].includes(userState.role) ? (
                    <ContactManagerButton />
                  ) : (
                    <AddButton
                      text={addButtonText}
                      onClick={addButtonOnClick}
                    />
                  )}
                </div>
              ) : null}
              {customPagination || <CustomPagination />}
            </>
          ),
        }}
        rows={rows}
        columns={columns}
        initialState={{
          columns: {
            columnVisibilityModel: getHiddenColumns(),
          },
        }}
        hideFooter={loading}
        autoHeight
        sortingMode="server"
        paginationMode="server"
        rowCount={rowCountState}
        sortModel={sortModel}
        paginationModel={paginationModel}
        onPaginationModelChange={(model, details) =>
          onPaginationModelChange(model)
        }
        onSortModelChange={(model, details) => onSortModelChange(model)}
        pageSizeOptions={pageSizeOptions}
        disableColumnMenu
        disableColumnSelector
        disableRowSelectionOnClick
        onRowClick={(params) => onRowClick(params.row)}
        {...props}
      />
    </Box>
  );
};

export default CustomTable;

CustomTable.propTypes = {
  rows: PropTypes.array,
  columns: PropTypes.array,
  onRowClick: PropTypes.func,
  loading: PropTypes.bool,
  rowCount: PropTypes.number,
  paginationModel: PropTypes.object,
  hideColumns: PropTypes.array,
  styleContainer: PropTypes.object,
  sortModel: PropTypes.array,
  addButtonText: PropTypes.string,
  addButtonIcon: PropTypes.node,
  customPagination: PropTypes.node,
  addButtonOnClick: PropTypes.func,
  pageSizeOptions: PropTypes.array,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
};
