import dayjs from 'dayjs';

export const columns = (t) => [
  {
    field: 'code',
    headerName: t('idHeader'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
    flex: 0.5,
  },
  {
    field: 'insurer',
    headerName: t('insurerHeader'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
    flex: 0.5,
  },
  {
    field: 'credits',
    headerName: t('creditsHeader'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 100,
    flex: 1,
  },
  {
    field: 'price',
    headerName: t('priceHeader'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return params.value + ' €';
    },
  },
  {
    field: 'taxes',
    headerName: t('taxesHeader'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return params.value + ' €';
    },
  },
  {
    field: 'paymentStatus',
    headerName: t('paymentStatusHeader'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 150,
    flex: 1,
  },
  {
    field: 'createdAt',
    headerName: t('createdAtHeader'),
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
    },
  },
  {
    field: 'paymentDate',
    headerName: t('paymentDateHeader'),
    minWidth: 100,
    flex: 1,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return dayjs(params.value).format('DD/MM/YYYY HH:mm:ss');
    },
  },
];
