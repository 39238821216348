import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      phone: yup
        .number()
        .typeError(t('mandatory', { field: t('contactPhone') }))
        .positive(t('invalidFormat', { field: t('contactPhone') })),
      name: yup.string().required(t('mandatory', { field: t('name') })),
      surname: yup.string().required(t('mandatory', { field: t('surname') })),

      password: yup.string().required(t('mandatory', { field: t('password') })),
      confirmPassword: yup
        .string()
        .required(t('mandatory', { field: t('confirmPassword') }))
        .oneOf([yup.ref('password')], t('passwordsMustBeEqual')),
    })
    .required();

export default validationSchema;
