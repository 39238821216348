import axios from 'axios';
import Policy from 'models/Policy';

export default async function getPolicies(filter) {
  const params = new URLSearchParams();

  const pagination = {
    Start: filter.start,
    Length: filter.length,
  };

  const mappedFilters = {
    NumeroDePoliza: filter?.searchBarText ?? '',
    CIF: filter?.cif ?? '',
    // EmpresaCliente: filter?.searchBarText ?? '',
    EmpresaLookupId: filter?.companyId ?? '',
    Aseguradora: filter?.insurer ?? '',
    AseguradoraLookupId: filter?.insurerId ?? '',
    PeriodicidadLookupId: filter?.periodicityId ?? '',
    PrimaNetaStart: filter?.netPremiumStart ?? '',
    PrimaNetaEnd: filter?.netPremiumEnd ?? '',
    PbStart: filter?.pbStart ?? '',
    PbEnd: filter?.pbEnd ?? '',
    PrimaTotalStart: filter?.totalPremiumStart ?? '',
    PrimaTotalEnd: filter?.totalPremiumEnd ?? '',
    ParticipantsLookupId: filter?.participantsId ?? '',
    AseguradoraPolizaActualLookupId: filter?.currentInsurerId ?? '',
    fechaVencimientoFrom: filter?.expirationDateFrom ?? '',
    fechaVencimientoTo: filter?.expirationDateTo ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(
    fuseFilters(pagination, mappedFilters),
  )) {
    if (value !== '') {
      params.append(key, value);
    }
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/policies', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new Policy({
            id: item.id,
            policyNum: item.numeroDePoliza,
            insurer: item.aseguradora,
            insurerId: item.aseguradoraLookupId,
            companyName: item.empresaCliente,
            companyId: item.empresaClienteLookupId,
            projectId: item.proyectoLookupId,
            projectName: item.proyecto,
            cif: item.empresaCliente_x003a__x0020_CIF,
            managerId: item.gestorLookupId,
            traditionalBranchesCommission:
              item.comisionEmisionRamosTradicionale,
            pb: item.pb,
            policyReceipt: item.reciboPoliza,
            branch: item.ramo,
            branchId: item.ramoLookupId,
            numWorkersWithInsurance: item.trabajadoresAsegurados,
            numWorkersOffWork: item.trabajadoresBajaLaboral,
            agreement: item.convenio,
            expirationDate: item.fechaVencimiento,
            netPremium: item.primaNeta,
            totalPremium: item.primaTotal,
            periodicityId: item.periodicidadLookupId,
            periodicity: item.periodicidad,
            receiptPolicyActivationMovement: item.reciboMovimientoAltaPoliza,
            receiptPolicyTerminationMovement: item.reciboMovimientoBajaPoliza,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

function fuseFilters(pagination, filters) {
  if (pagination.Start !== undefined && pagination.Length !== undefined) {
    return { ...pagination, ...filters };
  } else {
    return { ...filters };
  }
}
