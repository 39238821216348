import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import CreditsInput from 'components/credits/CreditsInput';
import CreditsConsumptionsTableAndSearch from 'components/creditConsumptions/CreditConsumptionsTableAndSearch';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import Title from 'UI/atoms/Title';

import ProfileBasePage from './base';
import SectionTitle from 'UI/atoms/SectionTitle';

const ProfileCreditsPage = () => {
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();

  const [t] = useTranslation('profile');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 4 });
  }, []);

  return (
    <ProfileBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/profile', text: t('profile') },
        { path: '/profile/credits', text: t('credits') },
      ]}
    >
      <Title size="medium">{t('credits')}</Title>
      <CreditsInput />
      <SectionTitle title={t('creditsPurchaseHistory')} />
      <CreditsConsumptionsTableAndSearch
        filters={{ insurerId: userState.insurerId }}
      />
    </ProfileBasePage>
  );
};

export default ProfileCreditsPage;
