import { useLocation, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_USER,
  INSURER_SUPPORT,
  INSURER_MANAGER,
} from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import { useClientStore } from 'context/ClientProvider/hooks';

const ProfileBasePage = ({ breadcrumbs, extraClass, children, title }) => {
  const [t] = useTranslation('profile');
  const [userState] = useUserStore();
  const [clientState] = useClientStore();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const tabs = {
    me: { label: t('myData'), route: `/profile` },
    generalData: {
      label: t('generalData'),
      route: `/profile/general-data`,
    },
    users: {
      label: t('users'),
      route: `/profile/users`,
    },
    documentation: {
      label: t('documentation'),
      route: '/profile/documentation',
    },
    credits: {
      label: t('credits'),
      route: '/profile/credits',
    },
  };

  let tabOptions = [];

  switch (userState.role) {
    case AINA_MANAGER:
      tabOptions = [tabs.me];
      break;
    case CLIENT_USER:
      tabOptions = [tabs.me, tabs.generalData, tabs.users, tabs.documentation];
      break;
    case INSURER_USER:
    case INSURER_SUPPORT:
    case INSURER_MANAGER:
      tabOptions = [
        tabs.me,
        tabs.generalData,
        tabs.users,
        tabs.documentation,
        tabs.credits,
      ];
      break;
  }

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route);
  };

  return (
    <PageContainer extraClass={extraClass}>
      <>
        <Header selected={t('profile')}>
          <SubHeader
            text={title}
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            <CustomTabs
              tabs={tabOptions}
              selected={clientState.tab}
              onChange={changeTab}
            />
          </SubHeader>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </>
    </PageContainer>
  );
};

ProfileBasePage.propTypes = {
  extraClass: PropTypes.string,
  title: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default ProfileBasePage;
