import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const FormContainer = ({ children, ...props }) => (
  <div className={styles.container} {...props}>
    {children}
  </div>
);

FormContainer.propTypes = {
  children: PropTypes.node,
};

export default FormContainer;
