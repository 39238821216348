import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';
import UserFilter from '../UserFilter';

const formDefaultValues = {
  city: '',
  numWorkers: [0, 100],
  averageAge: [18, 65],
  ainaPolicies: [0, 100],
  billing: [0, 1000000],
  activeProjects: false,
  onboarding: false,
  letterOfAppointment: false,
  cnaeId: null,
  sicId: null,
  ccaa: null,
  provinceId: null,
};

const UserSearch = ({ onSearch }) => {
  const [t] = useTranslation('user');
  const [searchValue, setSearchValue] = useState('');

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  function getRangeValue(field, index) {
    return formDefaultValues[field][index] === methods.getValues(field)[index]
      ? ''
      : methods.getValues(field)[index];
  }

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      city: methods.getValues('city'),
      activeProjects: methods.getValues('activeProjects')
        ? methods.getValues('activeProjects')
        : '',
      onboarding: methods.getValues('onboarding')
        ? methods.getValues('onboarding')
        : '',
      letterOfAppointment: methods.getValues('letterOfAppointment')
        ? methods.getValues('letterOfAppointment')
        : '',
      numWorkersStart: getRangeValue('numWorkers', 0),
      numWorkersEnd: getRangeValue('numWorkers', 1),
      averageAgeStart: getRangeValue('averageAge', 0),
      averageAgeEnd: getRangeValue('averageAge', 1),
      ainaPoliciesStart: getRangeValue('ainaPolicies', 0),
      ainaPoliciesEnd: getRangeValue('ainaPolicies', 1),
      billingStart: getRangeValue('billing', 0),
      billingEnd: getRangeValue('billing', 1),
      cnaeId: methods.getValues('cnaeId') ? methods.getValues('cnaeId').id : '',
      sicId: methods.getValues('sicId') ? methods.getValues('sicId').id : '',
      ccaaId: methods.getValues('ccaa') ? methods.getValues('ccaa').id : '',
      provinceId: methods.getValues('provinceId')
        ? methods.getValues('provinceId').code
        : '',
    };
    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search.client')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => <UserFilter methods={methods} />}
    />
  );
};

UserSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default UserSearch;
