import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';

const FormRow = ({ children, ...props }) => (
  <Grid container columns={24} rowSpacing={0} columnSpacing={4} {...props}>
    {children}
  </Grid>
);

FormRow.propTypes = {
  children: PropTypes.node,
};

export default FormRow;
