import axios from 'axios';
import User from 'models/User';

export default async function createUser(clientId, data) {
  const user = new User(data);

  const body = {
    name: user.name,
    surname: user.surname,
    emailAddress: user.email,
    telefono: user.phone,
    cargo: user.position,
  };

  try {
    const res = await axios.post('/clients/' + clientId + '/users', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
