import { useForm } from 'react-hook-form';
import { useState } from 'react';
import dayjs from 'dayjs';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';

import DocumentationFilter from '../DocumentationFilter';
import { useTranslation } from 'react-i18next';

const DocumentationSearch = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const [t] = useTranslation('documentation');

  const formDefaultValues = {
    fromCreatedDate: null,
    toCreatedDate: null,
    documentTypeId: null,
    verified: false,
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      documentTypeId: methods.getValues('documentTypeId')
        ? methods.getValues('documentTypeId').id
        : '',
      fromCreatedDate: methods.getValues('fromCreatedDate')
        ? dayjs(methods.getValues('fromCreatedDate')).format('YYYY-MM-DD')
        : '',
      toCreatedDate: methods.getValues('toCreatedDate')
        ? dayjs(methods.getValues('toCreatedDate')).format('YYYY-MM-DD')
        : '',
      verified: methods.getValues('verified')
        ? methods.getValues('verified')
        : '',
    };

    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('searchBar')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('clearButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => <DocumentationFilter methods={methods} />}
    />
  );
};

DocumentationSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default DocumentationSearch;
