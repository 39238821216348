import { useLocation, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';
import { useUserStore } from 'context/UserProvider/hooks';
import { CLIENT_USER } from 'utils/constants';

const ReceiptBasePage = (props) => {
  const { breadcrumbs, children } = props;
  const { pathname } = useLocation();
  const [clientState] = useClientStore();
  const [userState] = useUserStore();
  const [t] = useTranslation('receipts');
  const navigate = useNavigate();

  const tabOptions = [
    { label: t('policyReceipts'), route: `/receipts/policy` },
    {
      label: t('commissionReceipts'),
      route: `/receipts/commission`,
    },
  ];

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route);
  };

  return (
    <PageContainer extraClass={props.extraClass}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={t('title')}
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            <CustomTabs
              tabs={
                [CLIENT_USER].includes(userState.role)
                  ? tabOptions.filter((tab, index) => index !== 1)
                  : tabOptions
              }
              selected={clientState.tab}
              onChange={changeTab}
            />
          </SubHeader>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </>
    </PageContainer>
  );
};

ReceiptBasePage.propTypes = {
  extraClass: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default ReceiptBasePage;
