import { useEffect, useState } from 'react';
import { useDecodedData } from './useDecodedData';

export const useElementData = (encodedData, retrieveFunction) => {
  const [loading, setLoading] = useState(true);
  const [elementData, setElementData] = useState();
  const { getDecodedData } = useDecodedData(encodedData);

  useEffect(() => {
    if (encodedData) {
      retrieveData();
    }
  }, [encodedData]);

  async function retrieveData() {
    setLoading(true);
    const decodedData = getDecodedData();
    if (decodedData) {
      const res = await retrieveFunction(decodedData.id);
      setElementData(res);
      setLoading(false);
    }
  }

  return { loading, data: elementData };
};
