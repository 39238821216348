import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';
import ProjectDocumentation from 'components/documentation/ProjectDocumentation';
import ProjectBasePage from '../base';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';

const Documentation = () => {
  const [userState] = useUserStore();
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    code: '',
    companyName: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('projects');

  useEffect(() => {
    if ([AINA_MANAGER].includes(userState.role)) {
      clientDispatch({ type: SELECT_TAB, payload: 2 });
      return;
    }
    clientDispatch({ type: SELECT_TAB, payload: 1 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <ProjectBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/projects', text: t('title') },
        {
          path: `/projects/${clientState.clientId}`,
          text: clientData.code,
        },
        {
          path: `/projects/${clientState.clientId}/documentation`,
          text: t('documentation'),
        },
      ]}
    >
      <ProjectDocumentation />
    </ProjectBasePage>
  );
};

export default Documentation;
