import PropTypes from 'prop-types';
import { IconButton } from '@mui/material';

import { BlueFilterIcon, FilterIcon } from 'assets/icons/IconsLibrary';

import styles from './styles.module.scss';

const FilterSwitchIcon = ({ open, setOpenFilter }) => (
  <IconButton
    onClick={() => setOpenFilter(!open)}
    disableTouchRipple
    className={`${styles.icon} ${open ? styles['icon--show-triangle'] : ''}`}
  >
    {open ? <BlueFilterIcon /> : <FilterIcon />}
  </IconButton>
);

FilterSwitchIcon.propTypes = {
  open: PropTypes.bool,
  setOpenFilter: PropTypes.func,
};

export default FilterSwitchIcon;
