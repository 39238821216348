import React from 'react';
import {
  Routes,
  Route,
  Navigate,
  createBrowserRouter,
  RouterProvider,
  ScrollRestoration,
} from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';
import { StyledEngineProvider } from '@mui/material';
import { PropTypes } from 'prop-types';

import AppProvider from 'context/AppProvider';
import ClientProvider from 'context/ClientProvider';
import UserProvider from 'context/UserProvider';

import Login from 'pages/login';
import LegalNoticePage from 'pages/legalNotice';
import PrivacyPolicyPage from 'pages/privacyPolicy';
import CookiesPage from 'pages/cookies';
import Clients from 'pages/clients';
import Dashboard from 'pages/dashboard';
import ClientsReceiptsPolicy from 'pages/client/{id}/policy';
import ClientsReceiptsCommission from 'pages/client/{id}/commission';
import Policies from 'pages/policies';
import PolicyGeneralData from 'pages/policies/{id}/general-data';
import PoliciesReceiptsPolicy from 'pages/policies/{id}/policy';
import PoliciesReceiptsCommission from 'pages/policies/{id}/commission';
import PoliciesDocumentation from 'pages/policies/{id}/documentation';
import PoliciesMovements from 'pages/policies/{id}/movements';
import CreatePolicy from 'pages/policies/create';
import Projects from 'pages/projects';
import CreateProject from 'pages/project/create';
import ProjectsCalendar from 'pages/projects-calendar';
import Error from 'pages/error';
import RecoverPassword from 'pages/rename-password';
import ModifyPassword from 'pages/modify-password';
import GeneralDataPage from 'pages/client/{id}/general-data';
import ReceiptsPolicy from 'pages/receipts/type/policy';
import ReceiptsCommission from 'pages/receipts/type/commission';
import ReceiptGeneralData from 'pages/receipts/general-data';
import CreateReceipt from 'pages/receipts/create';
import Credits from 'pages/credits/type/credits';
import Historic from 'pages/credits/type/historic';

import ClientProjects from 'pages/client/{id}/projects';
import ClientPolicies from 'pages/client/{id}/policies';
import CreateClientPage from 'pages/client/create';
import InsurerGeneralDataPage from 'pages/insurer/{id}/general-data';
import OnboardingPage from 'pages/client/{id}/onboarding';
import InsurerOnboardingPage from 'pages/insurer/{id}/onboarding';
import InsurerPolicies from 'pages/insurer/{id}/policies';
import InsurerReceiptsPolicy from 'pages/insurer/{id}/policy';
import InsurerReceiptsCommission from 'pages/insurer/{id}/commission';
import Phases from 'pages/project/{id}/phases';
import ProjectDocumentation from 'pages/project/{id}/documentation';
import ProjectDocumentationClient from 'pages/project/{id}/documentation-client';
import ProjectConfiguration from 'pages/project/{id}/configuration';
import InsurerUsersAndCodesPage from 'pages/insurer/{id}/users';
import InsurerProjects from 'pages/insurer/{id}/projects';
import Insurers from 'pages/insurers';

import ClientsUsersAndCodesPage from 'pages/client/{id}/users';
import DocumentationPage from 'pages/client/{id}/documentation';
import InsurerDocumentationPage from 'pages/insurer/{id}/documentation';

import ProfileCreditsPage from 'pages/profile/credits';
import ProfileUserDataPage from 'pages/profile/me';
import ProfileGeneralDataPage from 'pages/profile/general-data';
import ProfileUsersAndCodesPage from 'pages/profile/users-and-codes';
import ProfileDocumentationPage from 'pages/profile/documentation';
import { CookiesProvider } from 'react-cookie';

import App from './App';
import reportWebVitals from './reportWebVitals';
import './utils/axiosHeaders';

import './index.scss';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import { getCookieValue } from 'utils/getCookieValue';
import CreditsChecker from 'components/credits/CreditsChecker';

const isAuthenticated = () => {
  return getCookieValue('token');
};

const hasAccess = (requiredRoles, userRole) => {
  if (requiredRoles && userRole) {
    return requiredRoles.includes(userRole);
  }
  return true;
};

const PrivateRoute = ({ element, requiredRoles, ...props }) => {
  const [userState] = useUserStore();
  return isAuthenticated() && hasAccess(requiredRoles, userState.role) ? (
    element
  ) : (
    <Navigate to="/" replace state={{ from: props.location }} />
  );
};

PrivateRoute.propTypes = {
  element: PropTypes.node,
  requiredRoles: PropTypes.array,
  location: PropTypes.any,
};

const PublicRoute = ({ element, requiredRoles, ...props }) => {
  const [userState] = useUserStore();
  return isAuthenticated() && hasAccess(requiredRoles, userState.role) ? (
    <Navigate to="/dashboard" replace state={{ from: props.location }} />
  ) : (
    element
  );
};

PublicRoute.propTypes = {
  element: PropTypes.node,
  requiredRoles: PropTypes.array,
  location: PropTypes.any,
};

const routes = [
  {
    path: '/',
    element: <PublicRoute element={<App />} />,
  },
  {
    path: '/legal-notice',
    element: <PublicRoute element={<LegalNoticePage />} />,
  },
  {
    path: '/privacy-policy',
    element: <PublicRoute element={<PrivacyPolicyPage />} />,
  },
  {
    path: '/cookies',
    element: <PublicRoute element={<CookiesPage />} />,
  },
  {
    path: '/login',
    element: <PublicRoute element={<Login />} />,
  },
  {
    path: '/recover-password',
    element: <PublicRoute element={<RecoverPassword />} />,
  },
  {
    path: '/modify-password',
    element: <PublicRoute element={<ModifyPassword />} />,
  },
  {
    path: '/dashboard',
    element: <PrivateRoute element={<Dashboard />} />,
  },
  {
    path: '/clients',
    element: (
      <PrivateRoute element={<Clients />} requiredRoles={[AINA_MANAGER]} />
    ),
  },
  {
    path: '/clients/:clientId/receipts/policy',
    element: (
      <PrivateRoute
        element={<ClientsReceiptsPolicy />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/receipts/commission',
    element: (
      <PrivateRoute
        element={<ClientsReceiptsCommission />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/policies',
    element: <PrivateRoute element={<Policies />} />,
  },
  {
    path: '/policies/:clientId',
    element: <PrivateRoute element={<PolicyGeneralData />} />,
  },
  {
    path: '/policies/:clientId/documentation',
    element: <PrivateRoute element={<PoliciesDocumentation />} />,
  },
  {
    path: '/policies/:clientId/movements',
    element: <PrivateRoute element={<PoliciesMovements />} />,
  },
  {
    path: '/policies/:clientId/receipts/policy',
    element: <PrivateRoute element={<PoliciesReceiptsPolicy />} />,
  },
  {
    path: '/policies/:clientId/receipts/commission',
    element: (
      <PrivateRoute
        element={<PoliciesReceiptsCommission />}
        requiredRoles={[
          AINA_MANAGER,
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
        ]}
      />
    ),
  },
  {
    path: '/policies/create',
    element: (
      <PrivateRoute element={<CreatePolicy />} requiredRoles={[AINA_MANAGER]} />
    ),
  },
  {
    path: '/projects',
    element: <PrivateRoute element={<Projects />} />,
  },
  {
    path: '/projects/calendar',
    element: <PrivateRoute element={<ProjectsCalendar />} />,
  },
  {
    path: '/projects/create',
    element: (
      <PrivateRoute
        element={<CreateProject />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/projects/:clientId',
    element: (
      <PrivateRoute
        element={<ProjectConfiguration />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/projects/:clientId/phases',
    element: <PrivateRoute element={<Phases />} />,
  },
  {
    path: '/projects/:clientId/documentation',
    element: (
      <PrivateRoute
        element={<ProjectDocumentation />}
        requiredRoles={[AINA_MANAGER, INSURER_MANAGER]}
      />
    ),
  },
  {
    path: '/projects/:clientId/documentation-client',
    element: (
      <PrivateRoute
        element={<ProjectDocumentationClient />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },

  {
    path: '/insurers',
    element: (
      <PrivateRoute element={<Insurers />} requiredRoles={[AINA_MANAGER]} />
    ),
  },
  {
    path: '/clients/:clientId',
    element: (
      <PrivateRoute
        element={<GeneralDataPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/projects',
    element: (
      <PrivateRoute
        element={<ClientProjects />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/policies',
    element: (
      <PrivateRoute
        element={<ClientPolicies />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/create',
    element: (
      <PrivateRoute
        element={<CreateClientPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId',
    element: (
      <PrivateRoute
        element={<InsurerGeneralDataPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/onboarding',
    element: (
      <PrivateRoute
        element={<InsurerOnboardingPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/policies',
    element: (
      <PrivateRoute
        element={<InsurerPolicies />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/receipts/policy',
    element: (
      <PrivateRoute
        element={<InsurerReceiptsPolicy />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/receipts/commission',
    element: (
      <PrivateRoute
        element={<InsurerReceiptsCommission />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/users',
    element: (
      <PrivateRoute
        element={<InsurerUsersAndCodesPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/documentation',
    element: (
      <PrivateRoute
        element={<InsurerDocumentationPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/insurers/:clientId/projects',
    element: (
      <PrivateRoute
        element={<InsurerProjects />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/onboarding',
    element: (
      <PrivateRoute
        element={<OnboardingPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/users',
    element: (
      <PrivateRoute
        element={<ClientsUsersAndCodesPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/clients/:clientId/documentation',
    element: (
      <PrivateRoute
        element={<DocumentationPage />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/receipts/policy',
    element: (
      <PrivateRoute
        element={<ReceiptsPolicy />}
        requiredRoles={[
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          CLIENT_USER,
          AINA_MANAGER,
        ]}
      />
    ),
  },
  {
    path: '/receipts/commission',
    element: (
      <PrivateRoute
        element={<ReceiptsCommission />}
        requiredRoles={[
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          AINA_MANAGER,
        ]}
      />
    ),
  },
  {
    path: '/receipts/policy/:clientId',
    element: (
      <PrivateRoute
        element={<ReceiptGeneralData />}
        requiredRoles={[
          AINA_MANAGER,
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          CLIENT_USER,
        ]}
      />
    ),
  },
  {
    path: '/receipts/commission/:clientId',
    element: (
      <PrivateRoute
        element={<ReceiptGeneralData />}
        requiredRoles={[
          AINA_MANAGER,
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
        ]}
      />
    ),
  },
  {
    path: '/receipts/policy/create',
    element: (
      <PrivateRoute
        element={<CreateReceipt />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/receipts/commission/create',
    element: (
      <PrivateRoute
        element={<CreateReceipt />}
        requiredRoles={[AINA_MANAGER]}
      />
    ),
  },
  {
    path: '/credits',
    element: (
      <PrivateRoute element={<Credits />} requiredRoles={[AINA_MANAGER]} />
    ),
  },
  {
    path: '/credits/historic',
    element: (
      <PrivateRoute element={<Historic />} requiredRoles={[AINA_MANAGER]} />
    ),
  },
  {
    path: '/profile',
    element: <PrivateRoute element={<ProfileUserDataPage />} />,
  },
  {
    path: '/profile/general-data',
    element: (
      <PrivateRoute
        element={<ProfileGeneralDataPage />}
        requiredRoles={[
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          CLIENT_USER,
        ]}
      />
    ),
  },
  {
    path: '/profile/users',
    element: (
      <PrivateRoute
        element={<ProfileUsersAndCodesPage />}
        requiredRoles={[
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          CLIENT_USER,
        ]}
      />
    ),
  },
  {
    path: '/profile/documentation',
    element: (
      <PrivateRoute
        element={<ProfileDocumentationPage />}
        requiredRoles={[
          INSURER_MANAGER,
          INSURER_SUPPORT,
          INSURER_USER,
          CLIENT_USER,
        ]}
      />
    ),
  },
  {
    path: '/profile/credits',
    element: (
      <PrivateRoute
        element={<ProfileCreditsPage />}
        requiredRoles={[INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER]}
      />
    ),
  },
  {
    path: '*',
    element: <Error />,
  },
];

const AppRoutes = () => {
  return (
    <>
      <Routes>
        {routes.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
      <ScrollRestoration />
    </>
  );
};

const router = createBrowserRouter(
  [
    {
      path: '*',
      element: <AppRoutes />,
    },
  ],
  { basename: '/' },
);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <StyledEngineProvider injectFirst>
      <CookiesProvider>
        <AppProvider>
          <UserProvider>
            <ClientProvider>
              <RouterProvider router={router} />
              <ToastContainer />
              <CreditsChecker />
            </ClientProvider>
          </UserProvider>
        </AppProvider>
      </CookiesProvider>
    </StyledEngineProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
