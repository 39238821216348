import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Title from 'UI/atoms/Title';
import styles from './styles.module.scss';
import DOMPurify from 'dompurify';

const ConfirmationDialog = ({
  open,
  onClose,
  onPrimaryBtnClick,
  isDeleting,
  loading,
  message,
  title,
  ...props
}) => {
  const [t] = useTranslation('documentation');

  function handleOnClose() {
    if (!loading) {
      onClose();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        red={isDeleting ?? true}
        onClose={handleOnClose}
        {...props}
      >
        <Title size="medium">{title}</Title>
        <p
          className={styles.message}
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(message) }}
        ></p>
        <div className={styles.flexContainer}>
          <PrimaryBtn
            red={isDeleting ?? true}
            loading={loading}
            style={{ width: '45%' }}
            text={t('confirm')}
            onClick={onPrimaryBtnClick}
          />
          <SecondaryBtn
            style={{ margin: 0 }}
            text={t('cancelAction')}
            onClick={onClose}
          />
        </div>
      </Dialog>
    </>
  );
};

ConfirmationDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  message: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onPrimaryBtnClick: PropTypes.func,
  isDeleting: PropTypes.bool,
};

export default ConfirmationDialog;
