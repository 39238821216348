import { Grid } from '@mui/material';
import DocumentTypeFilter from 'components/filters/DocumentTypeFilter';
import { useAppStore } from 'context/AppProvider/hooks';
import { PropTypes } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Select from 'UI/atoms/Select';
import Title from 'UI/atoms/Title';
import { findElementByField } from 'utils/lookupIdMapper';
import { useEffect, useState } from 'react';
import { useDecodedData } from 'hooks/useDecodedData';
import { useClientStore } from 'context/ClientProvider/hooks';
import InputFile from 'UI/atoms/InputFile';
import Form from 'UI/molecules/Form';
import { useDownloadFile } from 'hooks/useDownloadFile';
import validationSchema from './validationSchema';

const DocumentationDialog = ({
  open,
  loading,
  onClose,
  selectedDocument,
  onCreateOrUpdate,
  downloadDocumentation,
  overwriteParentId,
  documentType,
  disableDocumentTypeFilter,
  ...props
}) => {
  const [t] = useTranslation('documentation');
  const [appState] = useAppStore();
  const [clientState] = useClientStore();
  const [parentEntityId, setParentEntityId] = useState(overwriteParentId);
  const schema = validationSchema(t);
  const { getDecodedData } = useDecodedData(clientState.clientId);

  // TODO: Rename .companyId to parentEntityId
  const { downloadFile } = useDownloadFile(
    () =>
      downloadDocumentation(selectedDocument.companyId, selectedDocument.id),
    selectedDocument?.fileName,
    t('errorDownloadFile'),
  );

  const formDefaultValues = {
    file: selectedDocument?.fileRef ?? null,
    documentType: selectedDocument
      ? findElementByField(
          appState.documentTypes,
          'name',
          selectedDocument?.documentType,
        )
      : null,
    verified: selectedDocument?.verified ?? false,
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    if (!clientState.clientId || overwriteParentId) return;
    setParentEntityId(getDecodedData().id);
  }, [clientState.clientId]);

  async function handleUpload() {
    await onCreateOrUpdate(parentEntityId, methods.getValues('file')[0], {
      id: selectedDocument?.id,
      verified: methods.getValues('verified'),
      documentTypeId: methods.getValues('documentType')?.id,
    });
  }

  function handleOnClose() {
    if (!loading) {
      onClose();
    }
  }

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <Title size="medium">
          {selectedDocument?.fileName ? t('editDocument') : t('createDocument')}
        </Title>
        <Form.ErrorBox errors={errors} text={t('errorBoxText')} />

        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleUpload)}>
            <Grid container rowGap={2} justifyContent="space-between">
              <Grid item xs={12}>
                <DocumentTypeFilter
                  disabled={disableDocumentTypeFilter}
                  documentType={documentType}
                  fieldName="documentType"
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputFile
                  name="file"
                  fileName={selectedDocument?.fileName ?? t('selectFile')}
                  register={methods.register}
                  id="fileId"
                  onDownloadIconClick={
                    selectedDocument?.fileName ? downloadFile : undefined
                  }
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Select
                  options={[
                    { label: t('yes'), value: true },
                    { label: t('no'), value: false },
                  ]}
                  style={{ marginBottom: 0 }}
                  extraClassName="no-margin-label"
                  color="grey"
                  name="verified"
                  label={t('verified')}
                />
              </Grid>
              <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                <PrimaryBtn
                  type="submit"
                  disabled={!isDirty}
                  loading={loading}
                  text={selectedDocument ? t('saveAction') : t('addAction')}
                />
              </Grid>
              <Grid item xs={12} md={7} marginTop={{ md: 3, lg: 6 }}>
                <SecondaryBtn
                  extraClassName="modal"
                  onClick={handleOnClose}
                  text={t('cancelAction')}
                />
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

DocumentationDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  disableDocumentTypeFilter: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateOrUpdate: PropTypes.func,
  downloadDocumentation: PropTypes.func,
  documentType: PropTypes.string,
  overwriteParentId: PropTypes.string,
  selectedDocument: PropTypes.object,
};

export default DocumentationDialog;
