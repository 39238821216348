import { useState } from 'react';
import { PropTypes } from 'prop-types';

import Filter from 'UI/molecules/Filter';

const SearchAndFilterContainer = ({ filters, topFilters }) => {
  const [openFilter, setOpenFilter] = useState(false);

  return (
    <Filter.Container>
      <Filter.TopContainer>
        {topFilters(openFilter, setOpenFilter)}
      </Filter.TopContainer>
      {openFilter ? filters() : null}
    </Filter.Container>
  );
};

SearchAndFilterContainer.propTypes = {
  filters: PropTypes.func,
  topFilters: PropTypes.func,
};

export default SearchAndFilterContainer;
