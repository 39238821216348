import axios from 'axios';

export default async function purchaseCredits(credits) {
  try {
    const res = await axios.post(`/auth/purchase-credit`, { credits });

    return res;
  } catch (error) {
    return error.response;
  }
}
