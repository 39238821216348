import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import { useForm } from 'react-hook-form';

import PublicForm from 'components/blocks/PublicForm';
import Form from 'UI/molecules/Form';
import PublicPage from 'UI/molecules/PublicPage';
import postUpdatePassword from 'services/auth/postUpdatePassword';
import {
  showSuccessNotification,
  showErrorNotification,
} from 'utils/showToastNotifications';

const validationSchema = (t) =>
  yup
    .object({
      password: yup.string().required(t('mandatoryPassword')),
      confirmPassword: yup
        .string()
        .required(t('mandatoryConfirmPassword'))
        .oneOf([yup.ref('password')], t('passwordsMustBeEqual')),
    })
    .required();

const ModifyPassword = () => {
  const [t] = useTranslation('modifyPassword');
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const schema = validationSchema(t);

  const methods = useForm({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  async function onSubmit() {
    setLoading(true);
    const res = await postUpdatePassword(methods.getValues());
    setLoading(false);
    if (res) {
      showSuccessNotification(t('passwordUpdatedSuccessfully'));
      navigate('/login');
    } else {
      showErrorNotification(t('error'));
    }
  }

  return (
    <PublicPage>
      <PublicForm
        title={t('title')}
        onSubmit={onSubmit}
        methods={methods}
        text={t('text')}
        fields={() => (
          <>
            <Form.Input
              label={t('newPassword')}
              withValidation
              type={showPassword ? 'text' : 'password'}
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Form.Input
              label={t('repeatPassword')}
              withValidation
              type={showPassword2 ? 'text' : 'password'}
              name="confirmPassword"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword2(!showPassword2)}
                      edge="end"
                    >
                      {showPassword2 ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        actions={() => (
          <>
            <Form.PrimaryButton
              text={t('savePassword')}
              type="submit"
              loading={loading}
            />
          </>
        )}
      ></PublicForm>
    </PublicPage>
  );
};

export default ModifyPassword;
