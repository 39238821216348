import { useDecodedData } from './useDecodedData';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';
export const useElementSubmit = (
  encodedData,
  updateFunction,
  successNotificationMessage,
  errorNotificationMessage,
) => {
  const { getDecodedData } = useDecodedData(encodedData);

  async function handleOnSubmit(data) {
    const decodedData = getDecodedData();
    if (decodedData) {
      const res = await updateFunction(decodedData.id, data);
      if (res.status === 200) {
        showSuccessNotification(successNotificationMessage);
      } else {
        showErrorNotification(errorNotificationMessage);
      }
    }
  }

  return { handleOnSubmit };
};
