import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';

import PolicyBasePage from '../base';
import PolicyDataTab from 'components/policies/PolicyDataTab';

const GeneralData = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    policyNum: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('policies');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <PolicyBasePage
      extraClass="form"
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/policies', text: t('title') },
        {
          path: `/policies/${clientState.clientId}`,
          text: clientData.policyNum,
        },
      ]}
    >
      <PolicyDataTab />
    </PolicyBasePage>
  );
};

export default GeneralData;
