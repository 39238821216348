import { SELECT_CLIENT, SELECT_TAB } from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SELECT_CLIENT: {
      return {
        ...state,
        clientId: action.payload,
      };
    }

    case SELECT_TAB: {
      return {
        ...state,
        tab: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
