import * as yup from 'yup';

const validationSchema = (t) =>
  yup.object().shape({
    notificationType: yup
      .string()
      .required(t('mandatory', { field: t('contact.notificationType') })),
    message: yup
      .string()
      .required(t('mandatory', { field: t('contact.message') })),
  });

export default validationSchema;
