import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const BoxFile = ({ children, onClick }) => (
  <div className={styles.box} onClick={onClick}>
    {children}
  </div>
);

BoxFile.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.node,
};

export default BoxFile;
