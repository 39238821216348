import { useTranslation } from 'react-i18next';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

import { DeleteIcon, DownloadIcon, EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import { columns as columsConfiguration } from './tableConfiguration';
import { useDownloadFile } from 'hooks/useDownloadFile';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';

const DocumentationTable = ({
  loading,
  documents,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  addDocumentHandler,
  downloadDocumentation,
  onEdit,
  onDelete,
  hideColumns,
}) => {
  const { t } = useTranslation('documentation');
  const [userState] = useUserStore();
  const columns = columsConfiguration(t);

  const { downloadFile } = useDownloadFile(
    (...params) => downloadDocumentation(...params),
    undefined,
    t('errorDownloadFile'),
  );

  const getActions = (params) => {
    if ([AINA_MANAGER].includes(userState.role)) {
      return [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label={t('delete')}
          onClick={() => onDelete(params.row)}
        />,
        <GridActionsCellItem
          key="download"
          icon={<DownloadIcon />}
          label={t('download')}
          onClick={() =>
            downloadFile(
              params.row.companyId,
              params.row.id,
              params.row.fileName,
            )
          }
        />,
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label={t('edit')}
          onClick={() => onEdit(params.row)}
        />,
      ];
    }
    return [
      <GridActionsCellItem
        key="download"
        icon={<DownloadIcon />}
        label={t('download')}
        onClick={() =>
          downloadFile(params.row.companyId, params.row.id, params.row.fileName)
        }
      />,
      <GridActionsCellItem
        key="edit"
        icon={<EditIcon />}
        label={t('edit')}
        onClick={() => onEdit(params.row)}
      />,
    ];
  };

  return (
    <CustomTable
      loading={loading}
      rows={documents}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actions'),
          flex: 0.15,
          minWidth: 150,
          getActions,
        },
      ]}
      hideColumns={hideColumns}
      addButtonText={t('createDocument')}
      addButtonOnClick={addDocumentHandler}
      onPaginationModelChange={(model) => onPaginationModelChange(model)}
      onSortModelChange={(model) => onSortModelChange(model)}
      onRowClick={(row) => onEdit(row)}
    />
  );
};

DocumentationTable.propTypes = {
  documents: PropTypes.array,
  rowCount: PropTypes.number,
  loading: PropTypes.bool,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  downloadDocumentation: PropTypes.func,
  paginationModel: PropTypes.object,
  hideColumns: PropTypes.array,
  sortModel: PropTypes.array,
  addDocumentHandler: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

export default DocumentationTable;
