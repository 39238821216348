import { useEffect, useState } from 'react';
import { useAppStore } from 'context/AppProvider/hooks';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import Autocomplete from 'UI/atoms/Autocomplete';

const CCAAFilter = ({ fieldName }) => {
  const [t] = useTranslation('filters');
  const [appState] = useAppStore();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(appState.geographicData.ccaa);
  }, [appState]);

  return (
    <Autocomplete
      label={t('ccaa')}
      options={options ?? []}
      getOptionLabel={(option) => `${option.name}`}
      name={fieldName}
    />
  );
};

CCAAFilter.propTypes = {
  fieldName: PropTypes.string,
};

export default CCAAFilter;
