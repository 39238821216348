import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTable } from 'hooks/useTable';
import { creditsConsumptionMapper } from 'utils/columnsMapper';
import getCreditConsumptions from 'services/credits/getCreditConsumptions';
import CreditConsumptionsTable from '../CreditConsumptionsTable';

const CreditsConsumptionsTableAndSearch = ({ filters }) => {
  const [t] = useTranslation('creditConsumptionsComponent');

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSortModelChange,
  } = useTable(
    getCreditConsumptions,
    t('error'),
    false,
    undefined,
    filters,
    creditsConsumptionMapper,
  );

  return (
    <>
      <CreditConsumptionsTable
        creditConsumptions={rows}
        rowCount={rowCount}
        loading={loading}
        onRowClick={() => {
          return undefined;
        }}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
      />
    </>
  );
};

CreditsConsumptionsTableAndSearch.propTypes = {
  filters: PropTypes.object,
};

export default CreditsConsumptionsTableAndSearch;
