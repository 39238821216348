import { SET_USER_DATA, UPDATE_CREDITS } from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case UPDATE_CREDITS:
      return {
        ...state,
        credits: action.payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export default reducer;
