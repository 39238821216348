export const columns = (t) => [
  { field: 'id' },

  {
    field: 'name',
    headerName: t('branch'),
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    sortable: false,
  },
  {
    field: 'commission',
    headerName: t('commission'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 175,
    sortable: false,
  },
  {
    field: 'preference',
    headerName: t('preference'),
    align: 'left',
    headerAlign: 'left',
    width: 175,
    sortable: false,
  },
];
