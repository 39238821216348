import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      contactName: yup
        .string()
        .required(t('mandatory', { field: t('contactName') })),
      contactSurname: yup
        .string()
        .required(t('mandatory', { field: t('contactSurname') })),
      phone: yup
        .number()
        .typeError(t('invalidFormat', { field: t('contactPhone') }))
        .positive(t('invalidFormat', { field: t('contactPhone') }))
        .required(t('mandatory', { field: t('contactPhone') })),
      onboarding: yup.boolean(),
      attachment: yup.boolean(),
    })
    .required();

export default validationSchema;
