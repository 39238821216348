import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const ProfileDataForm = ({ defaultFormValues, onSubmit }) => {
  const [errorsText, setErrorsText] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation('profileComponent');
  const schema = validationSchema(t);

  const [userState] = useUserStore();
  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      confirmPassword: defaultFormValues.password,
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  const handleOnSubmit = async (data) => {
    setSubmitting(true);
    await onSubmit({
      ...methods.getValues(),
    });
    setSubmitting(false);
  };

  return (
    <Form.Container>
      <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('myData')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }}>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                disabled={[
                  INSURER_MANAGER,
                  INSURER_SUPPORT,
                  INSURER_USER,
                  CLIENT_USER,
                ].includes(userState.role)}
                name="name"
                label={t('name')}
              />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                disabled={[
                  INSURER_MANAGER,
                  INSURER_SUPPORT,
                  INSURER_USER,
                  CLIENT_USER,
                ].includes(userState.role)}
                name="surname"
                label={t('surname')}
              />
            </Form.Column>

            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                disabled={[
                  INSURER_MANAGER,
                  INSURER_SUPPORT,
                  INSURER_USER,
                  CLIENT_USER,
                ].includes(userState.role)}
                name="phone"
                label={t('contactPhone')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }}>
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('accessData')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input disabled name="email" label={t('contactEmail')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                name="password"
                type="password"
                label={t('password')}
              />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                name="confirmPassword"
                type="password"
                label={t('confirmPassword')}
              />
            </Form.Column>
          </Form.Row>

          <Form.Footer
            onClickSecondary={() => navigate('/')}
            loading={submitting}
          />
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

ProfileDataForm.propTypes = {
  defaultFormValues: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default ProfileDataForm;
