import { useTranslation } from 'react-i18next';

import Title from 'UI/atoms/Title';
import HeroActions from 'UI/atoms/HeroActions';
import Hero from 'UI/atoms/Hero';
import { INSURER_MANAGER, INSURER_USER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import imageLoser from 'assets/images/aina-loser.png';
import ContactManagerButton from 'UI/molecules/ContactManagerButton';

const LoserHero = () => {
  const [t] = useTranslation('phasesHero');

  const [userState] = useUserStore();

  return (
    <>
      <Hero type="blue" image={imageLoser}>
        <Title size="hero">{t('loserHero.title')}</Title>

        <p>{t('loserHero.noSelected')}</p>
        <p>{t('loserHero.contactManager')}</p>

        <HeroActions>
          {[INSURER_MANAGER, INSURER_USER].includes(userState.role) && (
            <ContactManagerButton extraClassName="static" />
          )}
        </HeroActions>
      </Hero>
    </>
  );
};

export default LoserHero;
