import { PropTypes } from 'prop-types';
import CircularProgress from 'UI/atoms/CircularProgress';

import styles from './styles.module.scss';

const Loader = ({ text }) => {
  return (
    <div className={styles.container}>
      <div className={styles.loader}>
        <p className={styles.text}>{text}</p>
        <CircularProgress size={58} customColor="blue" />
      </div>
    </div>
  );
};

Loader.propTypes = {
  text: PropTypes.string,
};

export default Loader;
