import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { columns as columsConfiguration } from './tableConfiguration';
import { EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import VisibilityIcon from '@mui/icons-material/Visibility';

const PoliciesTable = ({
  loading,
  policies,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  addButtonOnClick,
  onRowClick,
}) => {
  const { t } = useTranslation('policiesComponent');
  const [userState] = useUserStore();
  const columns = columsConfiguration(t, userState.role);

  const getActions = (params) => {
    if ([AINA_MANAGER].includes(userState.role)) {
      return [
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label={t('edit')}
          onClick={() => {
            onRowClick(params.row, 'policies', [
              'policyNum',
              'companyName',
              'companyId',
              'projectId',
              'projectName',
            ]);
          }}
        />,
      ];
    }

    return [
      <GridActionsCellItem
        key="see"
        icon={<VisibilityIcon color="info" />}
        label={t('see')}
        onClick={() => {
          onRowClick(params.row, 'policies', [
            'policyNum',
            'companyName',
            'companyId',
            'projectId',
            'projectName',
          ]);
        }}
      />,
    ];
  };

  return (
    <CustomTable
      loading={loading}
      rows={policies}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          minWidth: 180,
          headerName: t('actionsHeader'),
          getActions,
        },
      ]}
      addButtonText={t('newPolicy')}
      addButtonOnClick={addButtonOnClick}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      onRowClick={(row) => {
        onRowClick(row, 'policies', [
          'policyNum',
          'companyName',
          'companyId',
          'projectId',
          'projectName',
        ]);
      }}
    />
  );
};

PoliciesTable.propTypes = {
  loading: PropTypes.bool,
  policies: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default PoliciesTable;
