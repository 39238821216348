import ClearFilterButton from 'UI/atoms/ClearFilterButton';
import FilterButton from 'UI/atoms/FilterButton';
import FilterContainer from 'UI/atoms/FilterContainer';
import FilterSwitchIcon from 'UI/atoms/FilterSwitchIcon';
import FilterTopContainer from 'UI/atoms/FilterTopContainer';
import CheckButton from 'UI/atoms/CheckButton';
import Select from 'UI/atoms/Select';
import SearchBar from 'UI/atoms/SearchBar';

const Filter = () => <></>;

Filter.Container = FilterContainer;

Filter.TopContainer = FilterTopContainer;

Filter.SearchButton = FilterButton;

Filter.Switch = FilterSwitchIcon;

Filter.CheckButton = CheckButton;

Filter.Select = Select;

Filter.ClearButton = ClearFilterButton;

Filter.SearchBar = SearchBar;

export default Filter;
