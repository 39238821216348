import React from 'react';
import { toast } from 'react-toastify';
import { NotificationIcon } from 'assets/icons/IconsLibrary';

import { PropTypes } from 'prop-types';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

const ToastNotification = ({
  type,
  message,
  action,
  actionMessage = 'First Action',
  autoClose,
  toastId,
  position = 'bottom-center',
}) => {
  if (toast.isActive(toastId)) return;

  toast[type](
    <div className={styles.container}>
      <div className={styles.nestedContainer}>
        <img src={NotificationIcon} alt="Notificación ícono"></img>
        <div className={styles.message}>{message}</div>
      </div>
      {action && (
        <button type="button" className={styles.action} onClick={action}>
          {actionMessage}
        </button>
      )}
    </div>,
    {
      icon: false,
      hideProgressBar: true,
      autoClose,
      toastId,
      position,
    },
  );
};

export default ToastNotification;

ToastNotification.propTypes = {
  type: PropTypes.string,
  message: PropTypes.string,
  action: PropTypes.func,
  actionMessage: PropTypes.string,
  autoClose: PropTypes.number,
  toastId: PropTypes.string,
  position: PropTypes.string,
};
