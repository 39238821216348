import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { useUserStore } from 'context/UserProvider/hooks';
import { SELECT_CLIENT, SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import {
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

import ProfileBasePage from './base';
import InsurerUsersAndCodesTab from 'components/insurer/InsurerUsersAndCodesTab';
import UsersTableTab from 'components/user/UsersTableTab';

const ProfileUsersAndCodesPage = () => {
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();

  const [t] = useTranslation('profile');

  useEffect(() => {
    clientDispatch({
      type: SELECT_CLIENT,
      payload: window.btoa(
        JSON.stringify({
          id: [INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(
            userState.role,
          )
            ? userState.insurerId
            : userState.companyId,
        }),
      ),
    });

    clientDispatch({ type: SELECT_TAB, payload: 2 });
  }, []);

  return (
    <ProfileBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/profile', text: t('profile') },
        {
          path: '/profile/users',
          text: t('users'),
        },
      ]}
    >
      {[INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(
        userState.role,
      ) ? (
        <InsurerUsersAndCodesTab />
      ) : (
        <UsersTableTab />
      )}
    </ProfileBasePage>
  );
};

export default ProfileUsersAndCodesPage;
