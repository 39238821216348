import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useAppStore } from 'context/AppProvider/hooks';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import getClients from 'services/clients/getClients';
import { findElementByLookupId } from 'utils/lookupIdMapper';
import getPolicies from 'services/policies/getPolicies';
import { useDebouncer } from 'hooks/useDebouncer';
import { usePrevious } from 'hooks/usePreviousFormValue';
import { useDownloadFile } from 'hooks/useDownloadFile';
import downloadDocumentation from 'services/receipts/downloadDocumentation';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';

const ReceiptDataForm = ({ onSubmit, defaultFormValues }) => {
  const [appState] = useAppStore();
  const [userState] = useUserStore();
  const [submitting, setSubmitting] = useState(false);
  const [shrinkLabel, setShrinkLabel] = useState(
    !!defaultFormValues?.companyId ?? true,
  );
  const [shrinkLabelPolicies, setShrinkLabelPolicies] = useState(
    !!defaultFormValues?.companyId ?? true,
  );
  const [clients, setClients] = useState([]);
  const [policies, setPolicies] = useState([]);

  const { t } = useTranslation('receiptsComponent');
  const schema = validationSchema(t);
  const navigate = useNavigate();

  const { downloadFile } = useDownloadFile(
    () =>
      downloadDocumentation(defaultFormValues?.id, defaultFormValues?.fileId),
    defaultFormValues?.fileName,
    t('errorDownloadFile'),
  );

  const { loading: loadingClients, handleOnInputChange } = useDebouncer(
    getClients,
    setClients,
    false,
  );

  const [loadingList, setLoadingList] = useState(false);

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      branchId: findElementByLookupId(
        appState.branches,
        defaultFormValues?.branchId,
      ),
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const watchClient = watch('companyId');

  const prevClient = usePrevious(watchClient);
  const watchPolicy = watch('policyId');

  useEffect(() => {
    if (prevClient && prevClient?.id !== watchClient?.id) {
      setValue('policyId', null);
      setValue('cif', '');
      setShrinkLabel(false);
      setShrinkLabelPolicies(false);
    }
    if (watchClient) {
      setShrinkLabel(true);
      setValue('cif', watchClient.cif);
      retrievePolicies('');
    }
  }, [watchClient, prevClient, setValue]);

  useEffect(() => {
    if (watchPolicy) {
      setValue('branch', watchPolicy.branch);
      setValue('branchId', watchPolicy.branchId);
      setValue('insurer', watchPolicy.insurer);
      setValue('insurerId', watchPolicy.insurerId);
      setValue('periodicity', watchPolicy.periodicity);
      setValue('periodicityId', watchPolicy.periodicityId);
      setShrinkLabelPolicies(true);
    } else {
      setValue('insurer', '');
      setValue('insurerId', null);
      setValue('branch', '');
      setValue('branchId', null);
      setValue('periodicity', '');
      setValue('periodicityId', null);
      setShrinkLabelPolicies(false);
    }
  }, [watchPolicy, prevClient, setValue]);

  async function retrievePolicies(value) {
    setLoadingList(true);
    const data = await getPolicies({
      policyNumStart: value,
      policyNumEnd: value,
      companyId: watchClient.id,
    });
    setPolicies(data.resultList);
    setLoadingList(false);
  }

  const handleOnSubmit = async (data) => {
    setSubmitting(true);
    await onSubmit({
      ...methods.getValues(),
      typeId: defaultFormValues.typeId,
      policyId: methods.getValues('policyId').id,
      companyId: methods.getValues('companyId').id,
      file: methods.getValues('file')[0],
    });

    setSubmitting(false);
  };

  function getInlineStylesInput() {
    return defaultFormValues?.fileName
      ? { position: 'relative', top: '6px' }
      : { position: 'static' };
  }

  return (
    <Form.Container style={{ marginBottom: 130 }}>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input name="receipt" label={t('form.receipt')} />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                options={clients}
                filterOptions={(x) => x}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                onInputChange={(e, newValue) => handleOnInputChange(newValue)}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingClients}
                name="companyId"
                label={t('clientName')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="cif"
                disabled
                label={t('cif')}
                shrinkLabel={shrinkLabel}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Select
                options={appState.receiptStatuses.map((status) => ({
                  label: status.status,
                  value: status.id,
                }))}
                style={{ marginBottom: 0 }}
                label={t('status')}
                name="statusId"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                options={policies}
                disabled={!watchClient || loadingList}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.policyNum}
                    </li>
                  );
                }}
                inputValue={watchPolicy?.policyNum ?? ''}
                getOptionLabel={(option) => `${option.policyNum}` ?? ''}
                loading={loadingList}
                name="policyId"
                label={t('policy')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                shrinkLabel={shrinkLabelPolicies}
                disabled
                name="insurer"
                label={t('insurer')}
              />
            </Form.Column>

            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                shrinkLabel={shrinkLabelPolicies}
                disabled
                name="branch"
                label={t('branch')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                shrinkLabel={shrinkLabelPolicies}
                disabled
                name="periodicity"
                label={t('periodicity')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="totalAmount"
                label={t('totalAmount')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Date label={t('issueDate')} name="issueDate" />
            </Form.Column>

            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.InputFile
                disabled={![AINA_MANAGER].includes(userState.role)}
                name="file"
                styleFlexContainer={getInlineStylesInput()}
                fileName={defaultFormValues?.fileName || t('noFile')}
                register={methods.register}
                onDownloadIconClick={
                  defaultFormValues?.fileName ? downloadFile : undefined
                }
                id="fileId"
              />
            </Form.Column>
          </Form.Row>

          {[AINA_MANAGER].includes(userState.role) ? (
            <Form.Footer
              onClickSecondary={() => navigate('/policies')}
              loading={submitting}
            />
          ) : null}
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

ReceiptDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ReceiptDataForm;
