import { PropTypes } from 'prop-types';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';

import { CheckedIcon, UncheckedIcon } from 'assets/icons/IconsLibrary';

import styles from './styles.module.scss';

const RadioButton = ({ buttonsText, title, name, sx }) => {
  const { control } = useFormContext();
  return (
    <div className={styles.container}>
      <p className={styles.title}>{title}</p>

      <Controller
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup {...field} className={styles.buttons} sx={sx}>
            {buttonsText.map((option) => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                control={
                  <Radio
                    classes={{
                      root: styles.radio,
                    }}
                    icon={<UncheckedIcon />}
                    checkedIcon={<CheckedIcon />}
                  />
                }
                classes={{
                  root: styles.label,
                }}
                componentsProps={{
                  typography: {
                    classes: {
                      root: styles.text,
                    },
                  },
                }}
                label={option.text}
              />
            ))}
          </RadioGroup>
        )}
      />
    </div>
  );
};

RadioButton.propTypes = {
  buttonsText: PropTypes.array,
  name: PropTypes.string,
  title: PropTypes.string,
  sx: PropTypes.object,
};

export default RadioButton;
