import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { Grid } from '@mui/material';

import { useUserStore } from 'context/UserProvider/hooks';
import Form from 'UI/molecules/Form';

import {
  INSURER_USER,
  INSURER_SUPPORT,
  INSURER_MANAGER,
} from 'utils/constants';
import AddCreditsDialog from '../AddCreditsDialog';
import AddButton from 'UI/atoms/AddButton';

const CreditsInput = () => {
  const [t] = useTranslation('creditsComponent');
  const [userState] = useUserStore();
  const [openAddCreditsModal, setOpenAddCreditsModal] = useState(false);

  const onCloseCreditsModal = () => {
    setOpenAddCreditsModal(false);
  };

  const methods = useForm({
    defaultValues: {
      credits: userState.credits ?? 0,
    },
  });

  if (
    ![INSURER_USER, INSURER_SUPPORT, INSURER_MANAGER].includes(userState.role)
  ) {
    return <></>;
  }

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid item xs={12} md={3} lg={2} marginBottom={{ xs: 3 }}>
          <FormProvider {...methods}>
            <Form.Input
              name="credits"
              disabled={true}
              label={t('currentCreditsInput')}
            />
          </FormProvider>
        </Grid>
        <Grid item xs={12} md={3} lg={2} marginBottom={{ xs: 3 }}>
          <AddButton
            extraClassName="form"
            text={t('addCredits')}
            onClick={() => setOpenAddCreditsModal(true)}
          />
        </Grid>
      </Grid>
      <AddCreditsDialog
        open={openAddCreditsModal}
        onClose={onCloseCreditsModal}
      />
    </>
  );
};

export default CreditsInput;
