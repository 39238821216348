import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const HeroActions = ({ children }) => (
  <div className={styles.wrapper}>{children}</div>
);

HeroActions.propTypes = {
  children: PropTypes.node,
};

export default HeroActions;
