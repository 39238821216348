import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const FormBox = ({ children }) => <div className={styles.box}>{children}</div>;

FormBox.propTypes = {
  children: PropTypes.node,
};

export default FormBox;
