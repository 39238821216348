import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import CircularProgress from 'UI/atoms/CircularProgress';

import styles from './styles.module.scss';

const AddButton = ({ text, onClick, extraClassName, loading, disabled }) => {
  return (
    <Button
      disabled={disabled}
      classes={{
        disabled: styles.disabled,
        root: `${styles.button} ${extraClassName ? styles[`button--${extraClassName}`] : ''}`,
      }}
      onClick={onClick}
    >
      {loading ? <CircularProgress size={24} customColor="white" /> : text}
    </Button>
  );
};

AddButton.propTypes = {
  text: PropTypes.string,
  extraClassName: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,

  onClick: PropTypes.func,
};

export default AddButton;
