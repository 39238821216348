import axios from 'axios';
import ClientDocumentation from 'models/ClientDocumentation';

export default async function getClientDocumentation(clientId, filter) {
  const params = new URLSearchParams();
  const mappedFilters = {
    search: filter.searchBarText ?? '',
    TipoLookupId: filter?.documentTypeId ?? '',
    ProyectoLookupId: filter?.projectId ?? '',
    verificado: filter.verified ?? '',
    FromCreatedDate: filter?.fromCreatedDate ?? '',
    ToCreatedDate: filter?.toCreatedDate ?? '',
    Start: filter?.start ?? '',
    Length: filter?.length ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    if (value !== '') params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get(
      '/clients/' + clientId + '/documentation',
      config,
    );

    return {
      resultList: data.resultList.map(
        (item) =>
          new ClientDocumentation({
            id: item.id,
            fileRef: item.fileLeafRef,
            companyName: item.empresa,
            companyId: item.empresaLookupId,
            documentType: item.tipo,
            documentTypeId: item.tipoLookupId,
            verified: item.verificado,
            fileName: item.nombreDeArchivo,
            creationDate: item.createdDateTime,
            lastModificationDate: item.lastModifiedDateTime,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
