import { useState, useRef } from 'react';
import Slider from 'react-slick';
import { useTranslation } from 'react-i18next';
import 'slick-carousel/slick/slick.css';

import QuoteBlock from 'components/home/QuoteBlock';

import styles from './styles.module.scss';
import settings from './slickSettings';

import TabImage1 from 'assets/images/tab01.png';
import TabImage2 from 'assets/images/tab02.png';
import TabImage3 from 'assets/images/tab03.png';

const Tabs = () => {
  const [t] = useTranslation('home');
  const [active, setActive] = useState(0);
  let sliderRef = useRef(null);
  let sliderRef2 = useRef(null);

  return (
    <>
      <div className={`${styles.tabs} wow fadeInUp`}>
        <Slider
          className={styles.topSlider}
          ref={(slider) => {
            sliderRef2 = slider;
          }}
          {...settings}
        >
          <div className={styles.topSliderImage}>
            <img
              src={TabImage2}
              className="wow fadeInUp"
              data-wow-delay="0.5s"
            />
          </div>
          <div className={styles.topSliderImage}>
            <img
              src={TabImage1}
              className="wow fadeInUp"
              data-wow-delay="0.5s"
            />
          </div>
          <div className={styles.topSliderImage}>
            <img
              src={TabImage3}
              className="wow fadeInUp"
              data-wow-delay="0.5s"
            />
          </div>
        </Slider>

        <div className={styles.container}>
          <ul className={`${styles.tabsList}`}>
            <li
              className={`${styles.tabLink} ${active === 0 ? styles.active : ''}`}
              onClick={() => {
                sliderRef.slickGoTo(0);
                sliderRef2.slickGoTo(0);
                setActive(0);
              }}
            >
              {t('tabs.title1')}
            </li>
            <li
              className={`${styles.tabLink} ${active === 1 ? styles.active : ''}`}
              onClick={() => {
                sliderRef.slickGoTo(1);
                sliderRef2.slickGoTo(1);
                setActive(1);
              }}
            >
              {t('tabs.title2')}
            </li>
            <li
              className={`${styles.tabLink} ${active === 2 ? styles.active : ''}`}
              onClick={() => {
                sliderRef.slickGoTo(2);
                sliderRef2.slickGoTo(2);
                setActive(2);
              }}
            >
              {t('tabs.title3')}
            </li>
          </ul>
        </div>

        <div className={`${styles.tabsContentWrapper} wow fadeInUp`}>
          <Slider
            ref={(slider) => {
              sliderRef = slider;
            }}
            {...settings}
          >
            <div className={styles.slide}>
              <div className={styles.tabsContent}>
                <div className={`${styles.tabInfo} wow fadeInUp`}>
                  <div className={styles.tabColumn}>
                    <p className={styles.tabTitle}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('tabs.tab1Title'),
                        }}
                      ></span>
                    </p>
                    <div
                      className={styles.tabText}
                      dangerouslySetInnerHTML={{
                        __html: t('tabs.tab1Text'),
                      }}
                    ></div>
                    <QuoteBlock blockNumber={1} />
                  </div>
                </div>
                <div className={styles.tabImage}>
                  <img
                    src={TabImage2}
                    width="825"
                    height="740"
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                  />
                </div>
              </div>
            </div>

            <div className={styles.slide}>
              <div className={styles.tabsContent}>
                <div className={`${styles.tabInfo} wow fadeInUp`}>
                  <div className={styles.tabColumn}>
                    <p className={styles.tabTitle}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('tabs.tab2Title'),
                        }}
                      ></span>
                    </p>
                    <div
                      className={styles.tabText}
                      dangerouslySetInnerHTML={{
                        __html: t('tabs.tab2Text'),
                      }}
                    ></div>
                    <QuoteBlock blockNumber={1} />
                  </div>
                </div>

                <div className={styles.tabImage}>
                  <img
                    src={TabImage1}
                    width="836"
                    height="741"
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                  />
                </div>
              </div>
            </div>

            <div className={styles.slide}>
              <div className={styles.tabsContent}>
                <div className={`${styles.tabInfo} wow fadeInRight`}>
                  <div className={styles.tabColumn}>
                    <p className={styles.tabTitle}>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('tabs.tab3Title'),
                        }}
                      ></span>
                    </p>
                    <div
                      className={styles.tabText}
                      dangerouslySetInnerHTML={{
                        __html: t('tabs.tab3Text'),
                      }}
                    ></div>
                    <QuoteBlock blockNumber={1} />
                  </div>
                </div>
                <div className={styles.tabImage}>
                  <img
                    src={TabImage3}
                    width="841"
                    height="744"
                    className="wow fadeInUp"
                    data-wow-delay="0.5s"
                  />
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    </>
  );
};

export default Tabs;
