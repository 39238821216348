import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import Form from 'UI/molecules/Form';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';
import downloadBenchmark from 'services/projects/downloadBenchmark';
import OfferTable from '../OfferTable';
import SelectBtn from 'UI/atoms/SelectBtn';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import OfferDialog from 'components/blocks/OfferDialog';
import createOffer from 'services/projects/createOffer';
import downloadOfferFile from 'services/projects/downloadOfferFile';
import { useClientStore } from 'context/ClientProvider/hooks';
import validationSchema from './validationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_USER,
  PROJECT_STATUS_FINALIZED_ID,
} from 'utils/constants';
import BoxFile from 'UI/atoms/BoxFile';
import AddButton from 'UI/atoms/AddButton';
import { useDebouncer } from 'hooks/useDebouncer';
import getInsurers from 'services/insurers/getInsurers';
import updateProjectWinner from 'services/projects/updateProjectWinner';

import LoserHero from 'components/blocks/LoserHero';
import WinnerHero from 'components/blocks/WinnerHero';
import ClientWinnerHero from 'components/blocks/ClientWinnerHero';
import { useDownloadFile } from 'hooks/useDownloadFile';
import getDocumentationList from 'services/projects/getDocumentationList';
import downloadDocumentation from 'services/projects/downloadDocumentation';
import DocumentationTableTab from 'components/documentation/DocumentationTableTab';

const DOCUMENT_QUOTE_ID = 25;

const ProjectOffersForm = ({
  currentRound,
  updateProject,
  onDeleteOffer,
  onSortModelChange,
  onPaginationModelChange,
  refreshOffers,
  onStatusChange,
  onSubmit,
  paginationModel,
  loadingTable,
  sortModel,
  rowCount,
  benchmark,
  projectWinner,
  title,
  date,
  rows,
  currentProject,
}) => {
  const [openDocumentDialog, setOpenDocumentDialog] = useState(false);
  const [addDocumentLoading, setAddDocumentLoading] = useState(false);
  const [disabledDocumentBtn, setDisabledDocumentBtn] = useState(true);
  const [disableInputFile, setDisableInputFile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [onlyRead] = useState(currentRound < currentProject.statusId);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const { t } = useTranslation('projectsComponent');
  const [clientState] = useClientStore();
  const [userState] = useUserStore();
  const schema = validationSchema(
    t,
    PROJECT_STATUS_FINALIZED_ID === currentRound,
  );
  const [insurers, setInsurers] = useState([]);
  const [projectFile, setProjectFile] = useState(null);

  const methods = useForm({
    defaultValues: {
      benchmarkFile: benchmark?.fileName ?? null,
      insurerId: projectWinner?.insurerId,
      statusId: benchmark?.statusId ?? currentProject.statusId,
      verified: benchmark?.verified ?? false,
    },
    resolver: yupResolver(schema),
  });

  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
  } = useDebouncer(getInsurers, setInsurers, projectWinner?.insurerId);
  const { downloadFile } = useDownloadFile(
    () => downloadBenchmark(currentProject?.id, benchmark?.statusId),
    benchmark?.fileName,
    t('errorDownloadFile'),
  );
  const { downloadFile: downloadProjectFile } = useDownloadFile(
    () => downloadDocumentation(currentProject?.id, projectFile?.id),
    projectFile?.fileName,
    t('errorDownloadFile'),
  );
  const { downloadFile: downloadOffer } = useDownloadFile(
    () => downloadOfferFile(currentProject?.id, projectWinner?.offer?.id),
    projectWinner?.offer?.fileName,
    t('errorDownloadFile'),
  );

  const navigate = useNavigate();

  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
  } = methods;

  const watchOfferFIle = watch('offerFile');

  useEffect(() => {
    loadProjectFile();
  }, []);

  useEffect(() => {
    if (watchOfferFIle?.length !== 0 && watchOfferFIle !== undefined) {
      setDisabledDocumentBtn(false);
    } else {
      // setDisableInputFile(true);
      setDisabledDocumentBtn(true);
    }
  }, [watchOfferFIle]);

  const handleOnSubmit = async (data) => {
    if (PROJECT_STATUS_FINALIZED_ID === currentRound) {
      if (
        methods.getValues('insurerId').id !== projectWinner?.currentInsurerId &&
        dirtyFields.insurerId
      ) {
        setOpenConfirmationDialog(true);
      }
    } else {
      if (
        methods.getValues('statusId').toString() !== currentRound &&
        dirtyFields.statusId
      ) {
        setOpenConfirmationDialog(true);
      } else {
        updateFields();
      }
    }
  };

  async function updateFields() {
    setLoading(true);
    const d = await onSubmit(currentProject.id, {
      file: methods.getValues('benchmarkFile')[0],
      statusId: methods.getValues('statusId'),
      verified: methods.getValues('verified'),
    });
    setLoading(false);

    if (d === null) {
      showErrorNotification(t('error'));
      return;
    }
    showNotification(t('success'));
  }

  async function onDialogConfirm() {
    if (PROJECT_STATUS_FINALIZED_ID === currentRound) {
      setLoading(true);
      const response = await updateProjectWinner(
        currentProject.id,
        methods.getValues('insurerId').id,
      );
      if (response) {
        showNotification(t('success'));
      } else {
        showErrorNotification(t('error'));
      }

      setLoading(false);
      setOpenConfirmationDialog(false);
      return;
    }
    await updateFields();
    await updateProject(currentProject.id, {
      statusId: methods.getValues('statusId').toString(),
    });
    navigateTo();
    setOpenConfirmationDialog(false);
  }

  const loadProjectFile = async () => {
    const projectFiles = await getDocumentationList(currentProject.id, {
      documentTypeId: DOCUMENT_QUOTE_ID,
    });

    let firstProjectFile = null;

    if (projectFiles?.resultList?.length > 0) {
      firstProjectFile = projectFiles.resultList[0];
    }

    if (!firstProjectFile) {
      return false;
    }

    setProjectFile(firstProjectFile);
  };

  function navigateTo() {
    switch (methods.getValues('statusId')) {
      case 2:
      case 3:
      case 4:
        onStatusChange(methods.getValues('statusId').toString());
        break;

      case 5:
        navigate(`/projects/${clientState.clientId}/configuration`);
        break;

      default:
        navigate('/projects');
        break;
    }
  }

  async function handleCreateOffer(projectId, selectedFile, document) {
    setAddDocumentLoading(true);
    setDisabledDocumentBtn(true);
    const data = await createOffer(projectId, selectedFile, document);
    setAddDocumentLoading(false);
    if ([AINA_MANAGER].includes(userState.role)) {
      if (data !== null) {
        setOpenDocumentDialog(false);
        refreshOffers();
      }
      setDisabledDocumentBtn(false);
      return data;
    } else {
      if (data !== null) {
        setDisableInputFile(true);
        showNotification(t('success'));
        return;
      }
      showErrorNotification(t('error'));
    }
  }

  function getTopRow() {
    switch (userState.role) {
      case AINA_MANAGER:
        return (
          <>
            {currentRound === PROJECT_STATUS_FINALIZED_ID ? (
              <>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={'Aseguradora vencedora'} />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={10} lg={10} xl={8}>
                  <Form.Autocomplete
                    options={insurers}
                    filterOptions={(x) => x}
                    onInputChange={(e, newValue) =>
                      handleOnInputChangeInsurer(newValue)
                    }
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id}>
                          {option.name}
                        </li>
                      );
                    }}
                    getOptionLabel={(option) => option.name ?? ''}
                    loading={loadingInsurers}
                    name="insurerId"
                    label={t('insurer')}
                  />
                </Form.Column>
              </>
            ) : (
              <>
                {projectFile ? (
                  <>
                    <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                      <Form.SectionTitle title={t('documentQuote')} />
                    </Form.Column>
                    <Form.Column sm={24} md={12} lg={12} xl={8}>
                      <BoxFile onClick={downloadProjectFile}>
                        <p style={{ margin: 0 }}>{t('downloadInfo')}</p>
                        <b>{projectFile?.fileName}</b>
                      </BoxFile>
                    </Form.Column>
                  </>
                ) : null}

                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={'Benchmark ' + title} />
                </Form.Column>
                <Form.Column sm={24} md={12} lg={12} xl={4}>
                  <Form.InputFile
                    name="benchmarkFile"
                    disabled={onlyRead}
                    fileName={benchmark?.fileName}
                    register={methods.register}
                    id="benchmarkFileId"
                    onDownloadIconClick={benchmark ? downloadFile : undefined}
                  />
                </Form.Column>
                <Form.Column sm={24} md={8} lg={4} xl={4}>
                  <Form.Select
                    disabled={onlyRead}
                    options={[
                      { label: t('yes'), value: true },
                      { label: t('no'), value: false },
                    ]}
                    extraClassName="no-margin-label"
                    style={{ marginBottom: 0 }}
                    color="grey"
                    name="verified"
                    label={t('verified')}
                  />
                </Form.Column>
              </>
            )}
          </>
        );
      case CLIENT_USER:
        return (
          <>
            {benchmark && currentRound !== PROJECT_STATUS_FINALIZED_ID ? (
              <>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={'Benchmark ' + title} />
                </Form.Column>
                <Form.Column sm={24} md={12} lg={12} xl={8}>
                  <BoxFile onClick={downloadFile}>
                    <p style={{ margin: 0 }}>{t('downloadBenchmark')}</p>
                    <b>{benchmark?.fileName}</b>
                  </BoxFile>
                </Form.Column>
              </>
            ) : null}
          </>
        );
      case INSURER_MANAGER:
      case INSURER_USER:
        if (currentRound === PROJECT_STATUS_FINALIZED_ID) {
          return null;
        }
        return (
          <>
            {projectFile ? (
              <>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={t('documentQuote')} />
                </Form.Column>
                <Form.Column sm={24} md={12} lg={12} xl={8}>
                  <BoxFile onClick={downloadProjectFile}>
                    <p style={{ margin: 0 }}>{t('downloadInfo')}</p>
                    <b>{projectFile?.fileName}</b>
                  </BoxFile>
                </Form.Column>
              </>
            ) : null}

            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={'Oferta ' + title} />
            </Form.Column>
            {loadingTable ? null : (
              <>
                <Form.Column sm={24} md={12} lg={12} xl={4}>
                  <Form.InputFile
                    disabled={
                      disableInputFile ||
                      !!rows.find(
                        (row) => row.companyId === userState.insurerId,
                      )?.fileName
                    }
                    name="offerFile"
                    fileName={
                      rows.find((row) => row.companyId === userState.insurerId)
                        ?.fileName
                    }
                    register={methods.register}
                    id="offerFileId"
                  />
                </Form.Column>

                <Form.Column sm={24} md={12} lg={12} xl={4}>
                  <AddButton
                    disabled={disabledDocumentBtn}
                    loading={addDocumentLoading}
                    extraClassName="form"
                    text={t('addDocument')}
                    onClick={() =>
                      handleCreateOffer(
                        currentProject.id,
                        methods.getValues('offerFile')[0],
                        { insurerId: userState.insurerId },
                      )
                    }
                  />
                </Form.Column>
              </>
            )}
          </>
        );

      default:
        return null;
    }
  }
  function getBottomRow() {
    switch (userState.role) {
      case INSURER_MANAGER:
      case INSURER_USER:
        if (currentRound === PROJECT_STATUS_FINALIZED_ID) {
          if (projectWinner?.isWinner) {
            return (
              <>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <WinnerHero />
                </Form.Column>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <DocumentationTableTab
                    clientId={currentProject.companyId}
                    extraFilters={{ projectId: currentProject.id }}
                  />
                </Form.Column>
              </>
            );
          }

          return (
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <LoserHero />
            </Form.Column>
          );
        }
        return (
          <>
            {benchmark ? (
              <>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={'Benchmark ' + title} />
                </Form.Column>
                <Form.Column sm={24} md={12} lg={12} xl={8}>
                  <BoxFile onClick={downloadFile}>
                    <p style={{ margin: 0 }}>{t('downloadBenchmark')}</p>
                    <b>{benchmark?.fileName}</b>
                  </BoxFile>
                </Form.Column>
              </>
            ) : null}
          </>
        );

      case AINA_MANAGER:
        return (
          <>
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('insurersOffers')} />
            </Form.Column>
            <Form.Column
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ paddingTop: 0 }}
              paddingTop={0}
              paddingBottom={16}
              marginTop={0}
            >
              <OfferTable
                rowCount={rowCount}
                sortModel={sortModel}
                loading={loadingTable}
                paginationModel={paginationModel}
                documents={rows}
                onDelete={(row) => onDeleteOffer(row)}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={onSortModelChange}
                downloadDocumentation={downloadOfferFile}
                addDocumentHandler={
                  onlyRead ? undefined : () => setOpenDocumentDialog(true)
                }
              />
            </Form.Column>
          </>
        );

      case CLIENT_USER:
        if (currentRound === PROJECT_STATUS_FINALIZED_ID) {
          return (
            <>
              <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                <ClientWinnerHero />
              </Form.Column>
              <Form.Column sm={24} md={24} lg={24} xl={24}>
                <BoxFile onClick={downloadOffer}>
                  <p style={{ margin: 0 }}>{t('downloadWinnerOffer')}</p>
                  <b>{projectWinner?.offer?.fileName}</b>
                </BoxFile>
              </Form.Column>
            </>
          );
        }
        return (
          <>
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('insurersOffers')} />
            </Form.Column>
            <Form.Column
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              style={{ paddingTop: 0 }}
              paddingTop={0}
              paddingBottom={16}
              marginTop={0}
            >
              <OfferTable
                rowCount={rowCount}
                sortModel={sortModel}
                loading={loadingTable}
                paginationModel={paginationModel}
                documents={rows}
                onDelete={(row) => onDeleteOffer(row)}
                onPaginationModelChange={onPaginationModelChange}
                onSortModelChange={onSortModelChange}
                downloadDocumentation={downloadOfferFile}
                addDocumentHandler={undefined}
              />
            </Form.Column>
          </>
        );

      default:
        break;
    }
  }

  return (
    <Form.Container>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{title}</Form.Title>
      <Form.Title size="text">{date}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }}>{getTopRow()}</Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }}>{getBottomRow()}</Form.Row>

          {[AINA_MANAGER].includes(userState.role) ? (
            <Form.Footer
              onClickSecondary={() => navigate('/projects')}
              loading={loading}
              primaryBtnDisabled={onlyRead}
            >
              <SelectBtn
                name="statusId"
                options={[
                  { label: t('active'), value: 1, disabled: true },
                  { label: t('roundOne'), value: 2 },
                  { label: t('roundTwo'), value: 3 },
                  { label: t('roundFinish'), value: 4 },
                  { label: t('roundCancel'), value: 5 },
                ]}
              />
            </Form.Footer>
          ) : null}
        </Form>
      </FormProvider>
      <ConfirmationDialog
        isDeleting={false}
        loading={loading}
        title={t('confirmationDialog.title')}
        message={
          PROJECT_STATUS_FINALIZED_ID === currentRound
            ? t('confirmationDialog.insurerMessage', {
                insurer: `<strong>${methods.getValues('insurerId')?.name}</strong>`,
              })
            : t('confirmationDialog.message')
        }
        open={openConfirmationDialog}
        onPrimaryBtnClick={onDialogConfirm}
        onClose={() => setOpenConfirmationDialog(false)}
      />

      <OfferDialog
        onClose={() => setOpenDocumentDialog(false)}
        open={openDocumentDialog}
        createDocumentation={handleCreateOffer}
      />
    </Form.Container>
  );
};

ProjectOffersForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
  onDeleteOffer: PropTypes.func,
  rows: PropTypes.array,
  rowCount: PropTypes.number,
  loadingTable: PropTypes.bool,
  onPaginationModelChange: PropTypes.func,
  refreshOffers: PropTypes.func,
  onSortModelChange: PropTypes.func,
  paginationModel: PropTypes.object,
  currentProject: PropTypes.object,
  date: PropTypes.string,
  benchmark: PropTypes.object,
  currentRound: PropTypes.string,
  sortModel: PropTypes.array,
  updateProject: PropTypes.func,
  onStatusChange: PropTypes.func,
  projectWinner: PropTypes.object,
  title: PropTypes.string,
};

export default ProjectOffersForm;
