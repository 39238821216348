import axios from 'axios';
import Project from 'models/Project';

export default async function getProjectStart(projectId) {
  try {
    const { data } = await axios.get('/projects/' + projectId + '/start');
    return new Project({
      id: data.id,
      code: data.codigodeproyecto,
      startDate: data.fechainicio,
      roundOneDate: data.fechaRonda1,
      roundTwoDate: data.fechaRonda2,
      newInsuranceDate: data.fechaNuevaPoliza,
      statusId: data.estadoLookupId,
      branchId: data.ramoLookupId,
      numWorkers: data.numTrabajadores,
      procedureIT: data.tramitacionIT,
      agreement: data.convenio,
      currentInsurerId: data.aseguradoraPolizaActualLookupId,
      currentPolicy: data.polizaActual,
      currentPolicyId: data.polizaActualLookupId,
      preferencesPb: data.pbPreferencias,
      preferencesPremium: data.primaPreferencias,
      preferencesAdhesion: data.adhesionesPreferencias,
      preferencesSolvency: data.solvenciaPreferencias,
      currentPb: data.pbActual,
      expirationDate: data.fechaVencimientoActual,
      managerId: data.gestorLookupId,
      currentPremium: data.primaActual,
      companyId: data.empresaLookupId,
      credits: data.costeCreditos,
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
