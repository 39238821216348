import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FilterContainer = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

FilterContainer.propTypes = {
  children: PropTypes.node,
};

export default FilterContainer;
