import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      user: yup.string().required(t('mandatory', { field: t('user.user') })),
      accessName: yup
        .string()
        .required(t('mandatory', { field: t('user.accessName') })),
      code: yup.string().required(t('mandatory', { field: t('user.code') })),
      userEmail: yup
        .string()
        .email(t('invalidFormat', { field: t('user.userEmail') }))
        .required(t('mandatory', { field: t('user.userEmail') })),
      observations: yup
        .string()
        .required(t('mandatory', { field: t('observations') })),
    })
    .required();

export default validationSchema;
