import PropTypes from 'prop-types';
import Button from '@mui/material/Button';

import styles from './styles.module.scss';

const SecondaryBtn = ({ text, onClick, extraClassName, isBold, ...props }) => {
  return (
    <Button
      variant="text"
      color="secondary"
      classes={{
        root: `${styles.root} ${isBold ? styles['root--bold'] : ''}`,
      }}
      className={`${styles.button} ${isBold ? styles['button--bold'] : ''} ${extraClassName ? styles[`button--${extraClassName}`] : styles[`button--default}`]}`}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  );
};

SecondaryBtn.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  extraClassName: PropTypes.string,
  isBold: PropTypes.bool,
};

export default SecondaryBtn;
