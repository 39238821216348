import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAppStore } from 'context/AppProvider/hooks';
import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Title from 'UI/atoms/Title';
import Form from 'UI/molecules/Form';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';

import validationSchema from './validationSchema';
import styles from './styles.module.scss';
import createContactMessage from 'services/contact/createContactMessage';

const typeMap = new Map();

const ContactMessageDialog = ({ open, onClose, ...props }) => {
  const [t] = useTranslation('contactMessageDialog');
  const [appState] = useAppStore();
  const [loading, setLoading] = useState(false);
  const [errorsText, setErrorsText] = useState({});
  typeMap.set('Support', t('support'));
  typeMap.set('General Question', t('consultManager'));
  const schema = validationSchema(t);

  const formDefaultValues = {
    notificationType: '',
    message: '',
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
  } = methods;

  const handleOnClose = () => {
    methods.reset(formDefaultValues);
    onClose();
  };

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  async function onSubmit(data) {
    setLoading(true);
    const result = await createContactMessage(data);
    if (result.status === 200) {
      showNotification(t('success'));
      handleOnClose();
      return;
    }
    showErrorNotification(t('error'));
    setLoading(false);
  }

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <div className={styles.container}>
          <Title size="medium">{t('title')}</Title>
          <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form.Select
                options={appState.notificationTypes?.map((type) => ({
                  label: typeMap.get(type.name),
                  value: type.id,
                }))}
                withValidation
                style={{ marginBottom: 0 }}
                extraClassName="no-margin-label"
                color="grey"
                name="notificationType"
                label={t('notificationType')}
              />
              <Form.Textarea
                withValidation
                placeholder={t('message.placeholder')}
                name="message"
                label={t('message')}
              />
              <Grid container rowGap={2} justifyContent="space-between">
                <Grid item xs={12} md={6} marginTop={{ md: 3, lg: 6 }}>
                  <PrimaryBtn
                    loading={loading}
                    text={t('confirm')}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                  <SecondaryBtn
                    style={{ margin: 0 }}
                    text={t('cancelAction')}
                    onClick={onClose}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormProvider>
        </div>
      </Dialog>
    </>
  );
};

ContactMessageDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ContactMessageDialog;
