import axios from 'axios';
import ClientDocumentation from 'models/ClientDocumentation';

export default async function getDocumentationList(projectId, filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    search: filter.searchBarText ?? null,
    TipoLookupId: filter?.documentTypeId ?? null,
    verificado: filter.verified ?? null,
    FromCreatedDate: filter?.fromCreatedDate ?? null,
    ToCreatedDate: filter?.toCreatedDate ?? null,
    Start: filter?.start ?? null,
    Length: filter?.length ?? null,
    Sortcolumn: filter?.sortColumn ?? null,
    Sortorder: filter?.sortOrder ?? null,
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    if (value !== null) {
      params.append(key, value);
    }
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get(
      '/projects/' + projectId + '/documentation',
      config,
    );

    return {
      resultList: data.resultList.map(
        (item) =>
          new ClientDocumentation({
            id: item.id,
            fileRef: item.fileLeafRef,
            companyName: item.empresa,
            companyId: item.empresaLookupId,
            documentType: item.tipo,
            documentTypeId: item.tipoLookupId,
            verified: item.verificado,
            fileName: item.nombreDeArchivo,
            creationDate: item.createdDateTime,
            lastModificationDate: item.lastModifiedDateTime,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
