import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import ProjectOffersTab from 'components/project/ProjectOffersTab';
import { useNavigate } from 'react-router';
import getProjectStart from 'services/projects/getProjectStart';
import dayjs from 'dayjs';
import Steps from 'UI/atoms/Steps';
import ProjectBasePage from '../base';
import { useTranslation } from 'react-i18next';
import Loader from 'UI/atoms/Loader';
import getBenchmark from 'services/projects/getBenchmark';
import getProjectWinner from 'services/projects/getProjectWinner';
import getInsurer from 'services/insurers/getInsurer';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
  PROJECT_STATUS_FINALIZED_ID,
} from 'utils/constants';
import { showErrorNotification } from 'utils/showToastNotifications';

const Phases = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    code: '',
    companyName: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('projects');
  const [userState] = useUserStore();

  const [benchmark, setBenchmark] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [project, setProject] = useState(null);
  const [projectWinner, setProjectWinner] = useState(null);
  const [projectStatus, setProjectStatus] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    if ([AINA_MANAGER].includes(userState.role)) {
      clientDispatch({ type: SELECT_TAB, payload: 1 });
      return;
    }

    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  useEffect(() => {
    retrieveProjectStart();
  }, [clientData]);

  async function retrieveProjectStart(newStatus) {
    setLoading(true);
    if (clientData.id) {
      const retrievedProject = await getProjectStart(clientData.id);

      navigateTo(retrievedProject.statusId);
      if (retrievedProject.statusId === PROJECT_STATUS_FINALIZED_ID) {
        await retrieveProjectEnd();
      }

      const retrievedBenchmark = await getBenchmark(
        retrievedProject.id,
        newStatus ?? retrievedProject.statusId,
      );
      setProjectStatus(newStatus ?? retrievedProject.statusId);
      setBenchmark(retrievedBenchmark);
      setProject(retrievedProject);
      setLoading(false);
    }
  }

  async function retrieveProjectEnd() {
    setLoadingEnd(true);
    if (clientData.id) {
      const retrievedWinner = await getProjectWinner(clientData.id);
      if (!retrievedWinner) {
        showErrorNotification(t('error'));
        setLoadingEnd(false);
        return;
      }
      const insurer = await getInsurer(retrievedWinner.currentInsurerId);
      setProjectWinner({ ...retrievedWinner, insurerId: insurer });
    }
    setLoadingEnd(false);
  }

  async function navigateTo(statusId) {
    switch (statusId) {
      case '1':
      case '2':
      case '3':
      case '4':
      case '5':
        break;

      default:
        navigate('/projects');
    }
  }

  function getTitle() {
    switch (projectStatus) {
      case '2':
        return 'Ronda 1';
      case '3':
        return 'Ronda 2';
      case '4':
        return 'Final';
      default:
        return '';
    }
  }
  function getDate() {
    switch (projectStatus) {
      case '2':
        return dayjs(project?.roundOneDate).format('YYYY-MM-DD');
      case '3':
        return dayjs(project?.roundTwoDate).format('YYYY-MM-DD');
      case '4':
        return dayjs(project?.newInsuranceDate).format('YYYY-MM-DD');
      default:
        return '';
    }
  }

  function handleOnStatusChange(v) {
    if (
      ![AINA_MANAGER, INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(
        userState.role,
      )
    )
      return;

    const value = v.toString();

    if (value <= project.statusId) {
      switch (value) {
        case '2':
        case '3':
        case '4':
          retrieveProjectStart(value);
          break;

        default:
          break;
      }
    }
  }

  return (
    <>
      <ProjectBasePage
        breadcrumbs={[
          { path: '/', text: t('breadcrumbsHome') },
          { path: '/projects', text: t('title') },
          {
            path: `/projects/${clientState.clientId}`,
            text: clientData.code,
            disabled: ![AINA_MANAGER].includes(userState.role),
          },
          {
            path: `/projects/${clientState.clientId}/phases`,
            text: t('phases'),
          },
        ]}
      >
        {loading || loadingEnd ? (
          <Loader text={t('loading')} />
        ) : (
          <>
            <Steps
              onStepChange={(v) => handleOnStatusChange(v + 2)}
              currentStep={projectStatus - 2}
              steps={[
                {
                  stepText: 'Ronda 1',
                  date: dayjs(project?.roundOneDate).format('YYYY-MM-DD'),
                },
                {
                  stepText: 'Ronda 2',
                  date: dayjs(project?.roundTwoDate).format('YYYY-MM-DD'),
                },
                {
                  stepText: 'Final',
                  date: dayjs(project?.newInsuranceDate).format('YYYY-MM-DD'),
                },
              ]}
            />

            <ProjectOffersTab
              title={getTitle()}
              date={getDate()}
              projectWinner={projectWinner}
              onStatusChange={retrieveProjectStart}
              benchmark={benchmark}
              currentProject={project}
              currentRound={projectStatus ?? project.statusId}
            />
          </>
        )}
      </ProjectBasePage>
    </>
  );
};

export default Phases;
