export const getElementTitleByLookupId = (list, lookupId) => {
  return list?.find((element) => element?.id === lookupId)?.title ?? '';
};

export const findElementByLookupId = (list, lookupId) => {
  return list?.find((element) => element?.id === lookupId) ?? null;
};

export const findRoleId = (list, role) => {
  return list?.find((element) => element?.role === role).id ?? null;
};

export const findCCAA = (provinces, ccaa, provinceId) => {
  if (!ccaa) return null;

  const ccaaId =
    provinces?.find((element) => element?.id === provinceId)?.parent ?? null;

  return ccaa.find((element) => element.id === ccaaId) ?? null;
};

export const filterListByCodINE = (list, codINE) => {
  return list?.filter((element) => element.codINE === codINE);
};

export const filterListByField = (list, field, value) => {
  return list?.filter((element) => element[field] === value);
};

export const findElementByField = (list, field, value) => {
  return list?.find((element) => element[field] === value);
};
