import axios from 'axios';
import Insurer from 'models/Insurer';

export default async function getInsurers(filter) {
  const params = new URLSearchParams();

  const pagination = {
    Start: filter.start,
    Length: filter.length,
  };

  const mappedFilters = {
    search: filter?.searchBarText ?? '',
    onboarding: filter?.onboarding ?? '',
    NPolizasStart: filter?.ainaPoliciesStart ?? '',
    NPolizasEnd: filter?.ainaPoliciesEnd ?? '',
    AdelantoDeComision: filter?.advanceCommission ?? '',
    CreditosDisponiblesStart: filter?.availableCreditsStart ?? '',
    CreditosDisponiblesEnd: filter?.availableCreditsEnd ?? '',
    CCAALookupId: filter?.ccaaId ?? '',
    ProvinceLookupId: filter?.provinceId ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(
    fuseFilters(pagination, mappedFilters),
  )) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/companies', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new Insurer({
            id: item.id,
            name: item.razonSocial,
            advanceCommission: item.adelantoDeComision,
            availableCredits: item.creditosDisponibles,
            ainaPolicies: item.polizasAINA,
            onboarding: item.onboarding,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    if (error.status === 401) {
      return [];
    }
    return undefined;
  }
}

function fuseFilters(pagination, filters) {
  if (pagination.Start !== undefined && pagination.Length !== undefined) {
    return { ...pagination, ...filters };
  } else {
    return { ...filters };
  }
}
