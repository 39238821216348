import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Title = ({ size = 'big', children, style }) => (
  <p style={style} className={`${styles.title} ${styles[`title--${size}`]}`}>
    {children}
  </p>
);

Title.propTypes = {
  size: PropTypes.string,
  style: PropTypes.object,
  children: PropTypes.node,
};

export default Title;
