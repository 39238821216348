import BriefcaseIcon from './briefcase.svg';
import UserIcon from './user.svg';
import UserWhiteIcon from './user-white.svg';
import RocketIcon from './rocket.svg';
import RocketWhiteIcon from './rocket-white.svg';
import ShieldIcon from './shield.svg';
import ShieldWhiteIcon from './shield-white.svg';
import TicketIcon from './ticket.svg';
import WalletIcon from './wallet.svg';
import NotificationIcon from './notification-icon.svg';
import WhiteArrow from './white-arrow.svg';
import CheckIcon from './check.svg';
import ArrowRightIcon from './arrow-right.svg';
import { ReactComponent as BellIcon } from './bell.svg';
import { ReactComponent as AvatarIcon } from './avatar.svg';
import { ReactComponent as FilterIcon } from './filter.svg';
import { ReactComponent as EditIcon } from './edit-action.svg';
import { ReactComponent as DeleteIcon } from './delete-action.svg';
import { ReactComponent as DownloadIcon } from './download.svg';
import { ReactComponent as ChatIcon } from './chat.svg';
import { ReactComponent as CheckedIcon } from './checked.svg';
import { ReactComponent as SquareCheckedIcon } from './square-checked.svg';
import { ReactComponent as SquareUncheckedIcon } from './square-unchecked.svg';
import { ReactComponent as UncheckedIcon } from './unchecked.svg';
import { ReactComponent as BlueFilterIcon } from './blue-filter.svg';
import { ReactComponent as CaretDownIcon } from './caret-down.svg';

const UserIconComponent = () => {
  return <img src={UserIcon} alt="User Icon" />;
};
const RocketIconComponent = () => {
  return <img src={RocketIcon} alt="Rocket Icon" />;
};

export {
  ArrowRightIcon,
  AvatarIcon,
  BellIcon,
  BlueFilterIcon,
  BriefcaseIcon,
  CaretDownIcon,
  ChatIcon,
  CheckIcon,
  CheckedIcon,
  FilterIcon,
  UserIcon,
  UserIconComponent,
  UserWhiteIcon,
  DownloadIcon,
  UncheckedIcon,
  RocketIcon,
  RocketIconComponent,
  RocketWhiteIcon,
  ShieldIcon,
  ShieldWhiteIcon,
  SquareCheckedIcon,
  SquareUncheckedIcon,
  TicketIcon,
  NotificationIcon,
  WalletIcon,
  WhiteArrow,
  EditIcon,
  DeleteIcon,
};
