class ClientDocumentation {
  constructor(params) {
    this.id = params.id;
    this.fileRef = params.fileRef;
    this.companyName = params.companyName;
    this.companyId = params.companyId;
    this.documentType = params.documentType;
    this.documentTypeId = params.documentTypeId;
    this.verified = params.verified;
    this.fileName = params.fileName;
    this.creationDate = params.creationDate;
    this.lastModificationDate = params.lastModificationDate;
  }
}

export default ClientDocumentation;
