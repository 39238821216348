import { useEffect } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';

import ProfileBasePage from './base';
import ProfileDataTab from 'components/profile/ProfileDataTab';

const ProfileUserDataPage = () => {
  const [, clientDispatch] = useClientStore();

  const [t] = useTranslation('profile');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  return (
    <ProfileBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/profile', text: t('profile') },
      ]}
    >
      <ProfileDataTab />
    </ProfileBasePage>
  );
};

export default ProfileUserDataPage;
