import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import { useTable } from 'hooks/useTable';
import Title from 'UI/atoms/Title';
import getClientsUsers from 'services/clients/getClientsUsers';
import UsersTable from '../UsersTable';
import UsersSearch from '../UsersSearch';
import { clientsUsersMapper } from 'utils/columnsMapper';
import UserDialog from '../UserDialog';
import { useState } from 'react';
import updateUser from 'services/clients/updateUser';
import createUser from 'services/clients/createUser';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import postResetPassword from 'services/auth/postResetPassword';
import { showNotification } from 'utils/showToastNotifications';
import { useDialog } from 'hooks/useDialog';

const UsersTableTab = () => {
  const [t] = useTranslation('user');
  const [loadingEmail, setLoadingEmail] = useState(false);
  const [clientState] = useClientStore();
  const [userState] = useUserStore();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    refreshRows,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
  } = useTable(
    getClientsUsers,
    t('error'),
    true,
    clientState.clientId,
    undefined,
    clientsUsersMapper,
  );

  const {
    loadingDialog,
    openDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleOnAdd,
    handleOnClose,
    handleOnEdit,
  } = useDialog(updateUser, createUser, refreshRows);

  async function handleOnEmailClick(email) {
    if (!loadingEmail) {
      setLoadingEmail(true);
      const res = await postResetPassword({ email });
      setLoadingEmail(false);
      if (res) {
        showNotification(t('linkSent', { email }));
      }
    }
  }

  return (
    <>
      <Title style={{ marginBottom: 16 }} size="medium">
        {t('user.title')}
      </Title>
      <UsersSearch onSearch={handleOnSearch} />
      <UsersTable
        users={rows}
        rowCount={rowCount}
        loading={loading}
        loadingEmail={loadingEmail}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        addButtonOnClick={
          [AINA_MANAGER].includes(userState.role)
            ? () => handleOnAdd()
            : undefined
        }
        onEmailClick={handleOnEmailClick}
        onEdit={handleOnEdit}
        paginationModel={paginationModel}
        sortModel={sortModel}
      />
      {openDialog ? (
        <UserDialog
          selectedUser={selectedElement}
          loading={loadingDialog}
          open={openDialog}
          onClose={handleOnClose}
          onCreateOrUpdate={(parentId, element) =>
            handleCreateOrUpdateElement(parentId, undefined, element)
          }
        />
      ) : null}
    </>
  );
};

export default UsersTableTab;
