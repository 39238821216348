import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import FeatureImage1 from 'assets/icons/feature-icon1.svg';
import FeatureImage2 from 'assets/icons/feature-icon2.svg';
import FeatureImage3 from 'assets/icons/feature-icon3.svg';

const Features = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={styles.features}>
        <div className={styles.container}>
          <div className={styles.wrapper}>
            <div
              className={`${styles.feature} wow fadeInUp`}
              data-wow-delay="0.5s"
            >
              <div className={styles.icon}>
                <img src={FeatureImage1} />
              </div>

              <div className={styles.info}>
                <p className={styles.text}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('features.infoText1'),
                    }}
                  ></span>
                </p>
              </div>
            </div>

            <div
              className={`${styles.feature} wow fadeInUp`}
              data-wow-delay="0.6s"
            >
              <div className={styles.icon}>
                <img src={FeatureImage2} />
              </div>

              <div className={styles.info}>
                <p className={styles.text}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('features.infoText2'),
                    }}
                  ></span>
                </p>
              </div>
            </div>

            <div
              className={`${styles.feature} wow fadeInUp`}
              data-wow-delay="0.7s"
            >
              <div className={styles.icon}>
                <img src={FeatureImage3} />
              </div>

              <div className={styles.info}>
                <p className={styles.text}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t('features.infoText3'),
                    }}
                  ></span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
