import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import QuoteImage1 from 'assets/images/quote1.svg';
import QuoteImage2 from 'assets/images/quote2.svg';
import QuoteImage3 from 'assets/images/quote3.svg';
import QuoteImage4 from 'assets/images/quote4.svg';

const QuoteBlock = ({ blockNumber }) => {
  const [t] = useTranslation('home');

  if (blockNumber === 1) {
    return (
      <>
        <div className={`${styles.quote} ${styles.quoteReverse}`}>
          <div className={styles.quoteText}>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('quoteBlock.text1'),
                }}
              ></span>
            </p>
          </div>

          <div className={styles.quoteImage}>
            <img src={QuoteImage1} />
          </div>
        </div>
      </>
    );
  }
  if (blockNumber === 2) {
    return (
      <>
        <div className={styles.quote}>
          <div className={styles.quoteText}>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('quoteBlock.text2'),
                }}
              ></span>
            </p>
          </div>

          <div className={styles.quoteImage}>
            <img src={QuoteImage2} />
          </div>
        </div>
      </>
    );
  }
  if (blockNumber === 3) {
    return (
      <>
        <div className={styles.quote}>
          <div className={styles.quoteText}>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('quoteBlock.text3'),
                }}
              ></span>
            </p>
          </div>

          <div className={styles.quoteImage}>
            <img src={QuoteImage3} />
          </div>
        </div>
      </>
    );
  }
  if (blockNumber === 4) {
    return (
      <>
        <div className={`${styles.quote} ${styles.quoteReverse}`}>
          <div className={styles.quoteText}>
            <p>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('quoteBlock.text4'),
                }}
              ></span>
            </p>
          </div>

          <div className={styles.quoteImage}>
            <img src={QuoteImage4} />
          </div>
        </div>
      </>
    );
  }
};

QuoteBlock.propTypes = {
  blockNumber: PropTypes.number,
};

export default QuoteBlock;
