import { useNavigate } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';
export const useElementCreate = (
  createFunction,
  successNotificationMessage,
  errorNotificationMessage,
  onSuccessNavigateTo,
) => {
  const navigate = useNavigate();
  async function handleOnSubmit(elementData) {
    const res = await createFunction(elementData);
    if (res.status === 200) {
      showSuccessNotification(successNotificationMessage);
      navigate(onSuccessNavigateTo);
    } else {
      showErrorNotification(errorNotificationMessage);
    }
  }

  return { handleOnSubmit };
};
