class User {
  constructor(params) {
    this.id = params.id;
    this.email = params.email;
    this.name = params.name;
    this.roleId = params.roleId;
    this.role = params.role;
    this.surname = params.surname;
    this.phone = params.phone;
    this.position = params.position;
    this.companyId = params.companyId;
    this.company = params.company;
    this.password = params.password;
    this.insurerId = params.insurerId;
    this.insurerRole = params.insurerRole;
    this.insurer = params.insurer;
    this.credits = params.credits;
  }
}

export default User;
