import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { useAppStore } from 'context/AppProvider/hooks';
import Autocomplete from 'UI/atoms/Autocomplete';
import { createFilterOptions } from '@mui/material';

const filterOptions = createFilterOptions({
  matchFrom: 'any',
  stringify: (option) => `${option.name} - ${option.code}`,
});

const SICFilter = ({ fieldName }) => {
  const [t] = useTranslation('filters');
  const [appState] = useAppStore();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(appState.sic);
  }, [appState]);

  return (
    <Autocomplete
      label={t('sic')}
      options={options}
      renderOption={(props, option) => (
        <li {...props}>{`${option.name} - ${option.code}`}</li>
      )}
      getOptionLabel={(option) => `${option.name}`}
      name={fieldName}
      filterOptions={filterOptions}
    />
  );
};

SICFilter.propTypes = {
  fieldName: PropTypes.string,
};

export default SICFilter;
