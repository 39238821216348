import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import {
  Select as SelectMUI,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';

import styles from './styles.module.scss';

const Select = ({
  name,
  label,
  options,
  extraClassName,
  withValidation,
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <>
          <FormControl
            variant="standard"
            className={`${styles.container} ${extraClassName ? styles[`container--${extraClassName}`] : ''} `}
          >
            <InputLabel
              classes={{
                root: styles.label,
              }}
            >
              {label}
            </InputLabel>
            <SelectMUI
              {...field}
              error={!!(withValidation && fieldState.error)}
              className={styles.select}
              variant="standard"
              classes={{
                disabled: styles.disabled,
                select: styles.input,
              }}
              label={label}
              inputProps={{
                MenuProps: {
                  classes: {
                    list: styles.list,
                  },
                },
              }}
              {...props}
            >
              {options.map((option) => (
                <MenuItem
                  key={option.value}
                  value={option.value}
                  classes={{
                    root: styles.item,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </SelectMUI>
          </FormControl>
        </>
      )}
    />
  );
};

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  extraClassName: PropTypes.string,
  withValidation: PropTypes.bool,
  options: PropTypes.array,
};
export default Select;
