import axios from 'axios';

export default async function requestAccess(projectId) {
  try {
    const res = await axios.post(`/projects/${projectId}/request-access`);

    return res;
  } catch (error) {
    return error.response;
  }
}
