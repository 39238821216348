import axios from 'axios';
import CNAE from 'models/CNAE';

export default async function getCNAEList() {
  const { data } = await axios.get('/list/cnae').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new CNAE({
        id: item.id,
        name: item.title,
        code: item.coD_CNAE2009,
      }),
  );
}
