import { useEffect } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import CreditsBasePage from '../base';
import CreditsConsumptionsTableAndSearch from 'components/creditConsumptions/CreditConsumptionsTableAndSearch';
import { useUserStore } from 'context/UserProvider/hooks';

const Historic = () => {
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();

  const [t] = useTranslation('credits');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 1 });
  }, []);

  return (
    <CreditsBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/credits', text: t('title') },
        { path: '/credits/historic', text: t('historicTitle') },
      ]}
    >
      <CreditsConsumptionsTableAndSearch
        filters={{ insurerId: userState.insurerId }}
      />
    </CreditsBasePage>
  );
};

export default Historic;
