import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import InsurerBasePage from '../base';
import ReceiptsTableAndSearch from 'components/receipts/ReceiptsTableAndSearch';
import { useDecodedData } from 'hooks/useDecodedData';

const RECEIPT_TYPE_ID = 1;

const Policy = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, name: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('receipts');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 6 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <InsurerBasePage
      insurerName={clientData.name}
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/insurers', text: t('insurers') },
        {
          path: `/insurers/${clientState.clientId}`,
          text: clientData.name,
        },
        {
          path: `/insurers/${clientState.clientId}/receipts/policy`,
          text: t('policyReceipts'),
        },
      ]}
    >
      {clientData.id ? (
        <ReceiptsTableAndSearch
          filters={{ typeId: RECEIPT_TYPE_ID, insurerId: clientData.id }}
        />
      ) : null}
    </InsurerBasePage>
  );
};

export default Policy;
