import axios from 'axios';

async function postResetPassword(body) {
  try {
    const res = await axios.post('/auth/resetpasswordlink', body);
    return res;
  } catch (error) {
    return null;
  }
}

export default postResetPassword;
