import { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { PropTypes } from 'prop-types';

import AinaLogo from 'assets/aina-logo.png';
import NavigationButton from 'UI/atoms/NavigationButton';
import ResponsiveNavigationMenu from 'components/blocks/ResponsiveNavigationMenu';
import UserMenu from '../UserMenu';

import styles from './styles.module.scss';
import navigationButtons from './navigationButtons';
import InsurerCredits from '../InsurerCredits';

const Header = ({ children, selected }) => {
  const [showResponsiveMenu, setShowResponsiveMenu] = useState(false);

  const buttons = navigationButtons(selected);

  return (
    <>
      <ResponsiveNavigationMenu
        show={showResponsiveMenu}
        selected={selected}
        navigationButtons={buttons}
        onClick={() => setShowResponsiveMenu(false)}
      />
      <header className={styles.header}>
        <div className={styles.leftColumn}>
          <div
            className={styles.menu}
            onClick={() => setShowResponsiveMenu(true)}
          >
            <MenuRoundedIcon classes={{ root: styles.icon }} />
          </div>
          <Link to={'/'}>
            <img src={AinaLogo} alt="Aina" />
          </Link>
        </div>
        <div className={styles.navigation}>
          {buttons.map((navBtn) => (
            <NavigationButton
              key={navBtn.text}
              text={navBtn.text}
              route={navBtn.route}
              icon={navBtn.icon}
              selected={navBtn.selected}
            />
          ))}
          <InsurerCredits />
          <div className={styles.icons}>
            <UserMenu></UserMenu>
          </div>
        </div>
      </header>

      {children}
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
  selected: PropTypes.any,
};

export default Header;
