import axios from 'axios';

import DocumentType from 'models/DocumentType';

export default async function getDocumentTypeList() {
  const { data } = await axios.get('/list/documenttypes').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new DocumentType({
        id: item.id,
        name: item.tipu,
        group: item.group,
      }),
  );
}
