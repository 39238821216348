import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import { useTable } from 'hooks/useTable';
import getClientDocumentation from 'services/clients/getClientDocumentation';
import Title from 'UI/atoms/Title';
import DocumentationTable from '../DocumentationTable';
import DocumentationSearch from '../DocumentationSearch';
import DocumentationDialog from '../DocumentationDialog';
import updateDocumentation from 'services/clients/updateDocumentation';
import createDocumentation from 'services/clients/createDocumentation';
import downloadDocumentation from 'services/clients/downloadDocumentation';
import deleteDocument from 'services/clients/deleteDocument';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import { PropTypes } from 'prop-types';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import { useDialog } from 'hooks/useDialog';

const DocumentationTableTab = ({ clientId, extraFilters }) => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();
  const [userState] = useUserStore();
  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    refreshRows,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
  } = useTable(
    (id, filters) => getClientDocumentation(clientId || id, filters),
    t('error'),
    true,
    clientState.clientId,
    extraFilters,
  );

  const {
    openConfirmationDialog,
    loadingDialog,
    openDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleOnAdd,
    handleOnClose,
    handleOnDelete,
    handleDeleteElement,
    handleOnEdit,
  } = useDialog(
    updateDocumentation,
    createDocumentation,
    refreshRows,
    deleteDocument,
  );

  return (
    <>
      <Title size="medium">
        {clientId ? t('documentationClient') : t('title')}
      </Title>
      <DocumentationSearch onSearch={handleOnSearch} />
      <DocumentationTable
        documents={rows}
        rowCount={rowCount}
        loading={loading}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addDocumentHandler={
          [AINA_MANAGER].includes(userState.role)
            ? () => handleOnAdd()
            : undefined
        }
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        downloadDocumentation={downloadDocumentation}
      />
      {openDialog ? (
        <DocumentationDialog
          selectedDocument={selectedElement}
          documentType="client"
          loading={loadingDialog}
          open={openDialog}
          overwriteParentId={clientId}
          onClose={handleOnClose}
          onCreateOrUpdate={handleCreateOrUpdateElement}
          downloadDocumentation={downloadDocumentation}
        />
      ) : null}
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement()}
        onClose={handleOnClose}
      />
    </>
  );
};

DocumentationTableTab.propTypes = {
  clientId: PropTypes.number,
  extraFilters: PropTypes.object,
};
export default DocumentationTableTab;
