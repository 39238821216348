import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';
import InsurerFilter from '../InsurerFilter';

const formDefaultValues = {
  ainaPolicies: [0, 20],
  availableCredits: [0, 100000],
  onboarding: false,
};

const InsurerSearch = ({ onSearch }) => {
  const [t] = useTranslation('insurersComponent');
  const [searchValue, setSearchValue] = useState('');

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  function getRangeValue(field, index) {
    return formDefaultValues[field][index] === methods.getValues(field)[index]
      ? ''
      : methods.getValues(field)[index];
  }

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      onboarding: methods.getValues('onboarding')
        ? methods.getValues('onboarding')
        : '',
      ainaPoliciesStart: getRangeValue('ainaPolicies', 0),
      ainaPoliciesEnd: getRangeValue('ainaPolicies', 1),
      availableCreditsStart: getRangeValue('availableCredits', 0),
      availableCreditsEnd: getRangeValue('availableCredits', 1),
    };
    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => <InsurerFilter methods={methods} />}
    />
  );
};

InsurerSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default InsurerSearch;
