import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { columns as columsConfiguration } from './tableConfiguration';
import CustomTable from 'components/blocks/CustomTable';
import { useUserStore } from 'context/UserProvider/hooks';

const CreditConsumptionsTable = ({
  loading,
  creditConsumptions,
  rowCount,
  onRowClick,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
}) => {
  const { t } = useTranslation('creditConsumptionsComponent');
  const [userState] = useUserStore();

  const columns = columsConfiguration(t, userState.role);
  return (
    <CustomTable
      loading={loading}
      onRowClick={onRowClick}
      rows={creditConsumptions}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={columns}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
    />
  );
};

CreditConsumptionsTable.propTypes = {
  loading: PropTypes.bool,
  creditConsumptions: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default CreditConsumptionsTable;
