import { useNavigate } from 'react-router-dom';

export const useDecodedData = (encodedData) => {
  const navigate = useNavigate();

  function getDecodedData() {
    if (encodedData) {
      try {
        const decodedData = JSON.parse(atob(encodedData));
        return decodedData;
      } catch (error) {
        console.error(error);
        navigate('/not-found');
        return null;
      }
    }
  }
  return { getDecodedData };
};
