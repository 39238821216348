import axios from 'axios';
import User from 'models/User';

export default async function getClientsUsers(id, filter) {
  const params = new URLSearchParams();

  const pagination = {
    Start: filter.start,
    Length: filter.length,
  };

  const mappedFilters = {
    Search: filter.searchBarText ?? '',
    cargo: filter.position ?? '',
    emailAddress: filter.email ?? '',
    telefono: filter.phone ?? '',
    Sortcolumn: filter.sortColumn ?? '',
    Sortorder: filter.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(
    fuseFilters(pagination, mappedFilters),
  )) {
    if (value !== '') {
      params.append(key, value);
    }
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/clients/' + id + '/users', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new User({
            id: item.id,
            name: item.name,
            surname: item.surname,
            email: item.emailAddress,
            role: item.role,
            roleId: item.roleLookupId,
            phone: item.telefono,
            position: item.cargo,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

function fuseFilters(pagination, filters) {
  if (pagination.Start !== undefined && pagination.Length !== undefined) {
    return { ...pagination, ...filters };
  } else {
    return { ...filters };
  }
}
