import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import InputAdornment from '@mui/material/InputAdornment';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton } from '@mui/material';

import Form from 'UI/molecules/Form';
import PublicForm from 'components/blocks/PublicForm';
import PublicPage from 'UI/molecules/PublicPage';
import postLogin from 'services/auth/postLogin';
import { showErrorNotification } from 'utils/showToastNotifications';
import { retrieveFields, useAppStore } from 'context/AppProvider/hooks';
import {
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import getInsurerOnboarding from 'services/insurers/getInsurerOnboarding';
import { useCookies } from 'react-cookie';

const validationSchema = (t) =>
  yup
    .object({
      email: yup
        .string()
        .email(t('invalidEmail'))
        .required(t('mandatoryEmail')),
      password: yup.string().required(t('mandatoryPassword')),
    })
    .required();

const defaultFormValues = {
  email: '',
  password: '',
};

const Login = () => {
  const [t] = useTranslation('login');
  const [loading, setLoading] = useState(false);
  const schema = validationSchema(t);
  const [, appDispatch] = useAppStore();
  const [showPassword, setShowPassword] = useState(false);
  const [, setCookie] = useCookies();
  const methods = useForm({
    defaultValues: defaultFormValues,
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate();

  function handleOnClickSecondary() {
    navigate('/recover-password');
  }

  async function onSubmit() {
    setLoading(true);

    let fieldsData = [];
    let userData = null;
    let insurerOnboardingData = null;

    const res = await postLogin(methods.getValues());

    switch (res.status) {
      case 401:
        showErrorNotification(t('wrongUEmailrPassword'));
        break;
      case 200:
        setCookie('token', res.data.token, { path: '/' });
        fieldsData = await retrieveFields();
        fieldsData.map((fieldData) => appDispatch(fieldData));
        userData = res.data.user;

        if (
          [INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(
            userData.role,
          )
        ) {
          const res = await getInsurerOnboarding(
            userData.aseguradoraAsociadaLookupId,
          );
          insurerOnboardingData = res.onboarding;
        }

        // eslint-disable-next-line no-case-declarations
        const formattedData = {
          id: userData.id,
          token: res.data.token,
          role: userData.role,
          findId: userData.roleLookupId,
          companyId: userData.clienteAsociadoLookupId,
          insurerId: userData.aseguradoraAsociadaLookupId,
          credits: userData.credits,
          onboarding: insurerOnboardingData,
        };
        setCookie('userData', formattedData, { path: '/' });
        navigate('/dashboard');
        break;
      default:
        showErrorNotification(t('error'));
    }

    setLoading(false);
  }

  return (
    <PublicPage>
      <PublicForm
        title={t('title')}
        onSubmit={onSubmit}
        methods={methods}
        text={
          <>
            {t('welcomeTo')} <b>Aina</b>. {t('login')}:
          </>
        }
        fields={() => (
          <>
            <Form.Input
              label={t('emailLabel')}
              withValidation
              type="email"
              name="email"
              styleContainer={{ marginRight: 0 }}
            />
            <Form.Input
              label={t('passwordLabel')}
              withValidation
              type={showPassword ? 'text' : 'password'}
              name="password"
              styleContainer={{ marginRight: 0 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </>
        )}
        actions={() => (
          <>
            <Form.PrimaryButton
              text={t('primaryBtn')}
              type="submit"
              loading={loading}
            />
            <Form.SecondaryButton
              text={t('secondaryBtn')}
              isBold
              onClick={handleOnClickSecondary}
            />
          </>
        )}
      ></PublicForm>
    </PublicPage>
  );
};

Login.propTypes = {
  onLoad: PropTypes.func,
  loading: PropTypes.bool,
};

export default Login;
