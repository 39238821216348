import axios from 'axios';

export default async function downloadDocumentation(projectId, documentId) {
  try {
    const { data } = await axios.get(
      `/projects/${projectId}/documentation/${documentId}/download`,
      {
        responseType: 'blob',
        timeout: 30000,
      },
    );
    return data;
  } catch (error) {
    console.error(error);
    return null;
  }
}
