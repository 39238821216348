import { useTranslation } from 'react-i18next';

import { useUserStore } from 'context/UserProvider/hooks';
import useInterval from 'hooks/useInterval';
import getProfileData from 'services/auth/getProfileData';
import { showNotification } from 'utils/showToastNotifications';
import { useCookies } from 'react-cookie';

const CreditsChecker = () => {
  const [t] = useTranslation('creditsComponent');
  const [userState] = useUserStore();
  const [, setCookie] = useCookies();

  useInterval(
    async () => {
      if (userState.insurerId === null) return;
      const newUserData = await getProfileData();
      if (userState.credits !== newUserData.credits) {
        setCookie(
          'userData',
          { ...userState, credits: newUserData.credits },
          { path: '/' },
        );

        showNotification(
          t('creditsUpdated'),
          null,
          null,
          'creditsToast',
          'top-center',
        );
      }
    },
    { delay: 300000 },
  );

  return <></>;
};

export default CreditsChecker;
