import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';
import ProjectBasePage from '../base';
import DocumentationTableTab from 'components/documentation/DocumentationTableTab';

const DocumentationClient = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    code: '',
    companyId: null,
    companyName: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('projects');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 3 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <ProjectBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/projects', text: t('title') },
        {
          path: `/projects/${clientState.clientId}`,
          text: clientData.code,
        },
        {
          path: `/projects/${clientState.clientId}/documentation-client`,
          text: t('documentationClient'),
        },
      ]}
    >
      {clientData.companyId ? (
        <DocumentationTableTab clientId={clientData.companyId} />
      ) : null}
    </ProjectBasePage>
  );
};

export default DocumentationClient;
