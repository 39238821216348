import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Select from 'UI/atoms/Select';
import Title from 'UI/atoms/Title';
import Input from 'UI/atoms/Input';
import { findElementByLookupId } from 'utils/lookupIdMapper';
import { useAppStore } from 'context/AppProvider/hooks';
import { useEffect, useState } from 'react';
import validationSchema from './validationSchema';
import Form from 'UI/molecules/Form';

const BranchDialog = ({
  open,
  onClose,
  selectedBranch,
  onAdd,
  currentBranches,
  ...props
}) => {
  const [t] = useTranslation('insurersComponent');
  const [shrinkLabel, setShrinkLabel] = useState(undefined);
  const [appState] = useAppStore();
  const schema = validationSchema(t);

  const methods = useForm({
    defaultValues: {
      ...selectedBranch,
      branch: selectedBranch ? selectedBranch?.id : null,
    },
    resolver: yupResolver(schema),
  });

  const watchPreference = methods.watch('preference');
  // const watchCommission = methods.watch('commission');

  useEffect(() => {
    if (watchPreference === 'Excluidos') {
      methods.setValue('commission', 0);
      setShrinkLabel(true);
    } else {
      setShrinkLabel(undefined);
    }
  }, [watchPreference, methods.setValue]);

  function onSubmit() {
    onAdd({
      id: methods.getValues('branch'),
      branch: findElementByLookupId(
        appState.branches,
        methods.getValues('branch'),
      ).name,
      commission: methods.getValues('commission'),
      preference: methods.getValues('preference'),
    });
  }

  return (
    <>
      <Dialog open={open} showCloseButton={true} onClose={onClose} {...props}>
        <Title size="medium">
          {selectedBranch ? t('editBranch') : t('createBranch')}
        </Title>
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(onSubmit)}>
            <Grid
              container
              rowGap={2}
              justifyContent="space-between"
              alignItems="flex-end"
            >
              <Grid item xs={12} md={12}>
                <Select
                  options={currentBranches.map((branch) => ({
                    value: branch.id,
                    label: branch.name,
                  }))}
                  style={{ marginBottom: 0 }}
                  name="branch"
                  withValidation
                  label={t('dialog.branch')}
                />
              </Grid>
              <Grid item xs={12} md={5}>
                <Select
                  options={[
                    { label: t('preferred'), value: 'Preferente' },
                    { label: t('excluded'), value: 'Excluidos' },
                  ]}
                  color="grey"
                  style={{ marginBottom: 0 }}
                  name="preference"
                  withValidation
                  label={t('preference')}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Input
                  name="commission"
                  withValidation
                  label={t('commission')}
                  shrinkLabel={shrinkLabel}
                  disabled={watchPreference === 'Excluidos'}
                  inputProps={{ type: 'number' }}
                />
              </Grid>

              <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                <PrimaryBtn
                  type="submit"
                  text={selectedBranch ? t('saveAction') : t('addAction')}
                />
              </Grid>
              <Grid item xs={12} md={7} marginTop={{ md: 3, lg: 6 }}>
                <SecondaryBtn
                  extraClassName="modal"
                  onClick={onClose}
                  text={t('cancelAction')}
                />
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

BranchDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAdd: PropTypes.func,
  selectedBranch: PropTypes.object,
  currentBranches: PropTypes.array,
};

export default BranchDialog;
