import axios from 'axios';
import ClientOnboarding from 'models/ClientOnboardingData';

export default async function getInsurerOnboarding(id) {
  const { data } = await axios
    .get('/companies/' + id + '/onboarding')
    .catch(() => new ClientOnboarding({}));

  if (typeof data === 'undefined') {
    return new ClientOnboarding({});
  }

  return new ClientOnboarding({
    id: data.id,
    contactName: data.nombrePersonaDeContacto,
    contactSurname: data.apellidosPersonaDeContacto,
    phone: data.tfnPersonaDeContacto,
    email: data.aseguradoraEmail,
    representativeName: data.nombreApoderado,
    representativeSurname: data.apellidosApoderado,
    representativeNid: data.dniApoderado,
    representativeNidExpirationDate: data.fechaVencDNIApoderado,
    onboarding: data.onboarding,
    attachment: data.anexoAINA,
  });
}
