import axios from 'axios';
import AccessCode from 'models/AccessCode';

export default async function getAccessCodes(id, filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    Start: filter.start,
    Length: filter.length,
    Sortcolumn: filter.sortColumn ?? '',
    Sortorder: filter.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get(
      '/companies/' + id + '/accesscodes',
      config,
    );
    return {
      resultList: data.resultList.map(
        (item) =>
          new AccessCode({
            id: item.id,
            accessName: item.nombreAcceso,
            user: item.usuario,
            userEmail: item.emailUsuario,
            code: item.codigo,
            observations: item.observaciones,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
