import { useDecodedData } from './useDecodedData';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';

const DOCUMENT_TYPE_RECEIPT = '26';

export const useReceiptUpdate = (
  encodedData,
  updateFunction,
  documentFunction,
  successNotificationMessage,
  errorNotificationMessage,
) => {
  const { getDecodedData } = useDecodedData(encodedData);

  async function handleOnSubmit(elementData) {
    const decodedData = getDecodedData();
    if (decodedData) {
      const res = await updateFunction(decodedData.id, elementData);
      if (res.status === 200) {
        if (
          elementData?.file?.length !== 0 &&
          elementData?.file !== undefined
        ) {
          await documentFunction(decodedData.id, elementData.file, {
            verified: false,
            documentTypeId: DOCUMENT_TYPE_RECEIPT,
          });
        }

        showSuccessNotification(successNotificationMessage);
      } else {
        showErrorNotification(errorNotificationMessage);
      }
    }
  }

  return { handleOnSubmit };
};
