import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';

import FormImage from 'assets/images/form-image.svg';

const Form = () => {
  const [t] = useTranslation('home');

  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
    script.async = true;
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: 'eu1',
          portalId: '144442446',
          formId: 'f085f1a2-1517-4aeb-9018-107e86f0682f',
          target: '#contact_form_hubspot',
          css: '.hs-button { background-color: red !important; }',
          onFormReady: ($form) => {
            $form.querySelectorAll('.hs-form__virality-link')[0].remove();
            $form.querySelectorAll('.hs-button')[0].style = `
              background: #d667bf;
              color: #fff;
              outline: none;
              text-transform: uppercase;
              font-size: 16px;
              font-weight: bold;
              box-shadow: 0 5px 0 0 #b62bb3;
              text-decoration: none;
              display: inline-block;
              padding: 13px 5px;
              width: 100%;
              text-align: center;
              border-radius: 12px;
              transition: box-shadow 1s ease-out, background-color 1s ease-out;
            `;
          },
          locale: 'es',
        });
      }
    });
  }, []);

  return (
    <>
      <div className={styles.form}>
        <div id="more-info" className={styles.anchor}></div>
        <div className={styles.formWrapper}>
          <p className={`${styles.title} ${styles.titleXS} wow fadeInUp`}>
            <span
              dangerouslySetInnerHTML={{
                __html: t('form.title'),
              }}
            ></span>
          </p>
          <div className={`${styles.imageWrapper} wow fadeInUp`}>
            <p className={`${styles.title} ${styles.titleXL}`}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('form.title'),
                }}
              ></span>
            </p>
            <img
              className={styles.formImage}
              src={FormImage}
              width="654"
              height="454"
            />
          </div>

          <div className={`${styles.formBlock} wow fadeInUp`}>
            <p className={styles.formTitle}>
              <span
                dangerouslySetInnerHTML={{
                  __html: t('form.boxTitle'),
                }}
              ></span>
            </p>
            <div id="contact_form_hubspot"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
