import axios from 'axios';
import Benchmark from 'models/Benchmark';

export default async function getBenchmark(projectId, statusId) {
  try {
    const { data } = await axios.get(
      '/projects/' + projectId + '/benchmark/' + statusId,
    );
    return new Benchmark({
      fileRef: data.fileLeafRef,
      verified: data.verificado,
      statusId: data.estadoLookupId,
      status: data.estado,
      fileName: data.nombreDeArchivo,
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
