import { useEffect } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import ReceiptBasePage from '../base';
import ReceiptsTableAndSearch from 'components/receipts/ReceiptsTableAndSearch';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const RECEIPT_TYPE_ID = 2;

const Commission = () => {
  const [t] = useTranslation('receipts');
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 1 });
  }, []);

  function getFIlter() {
    switch (userState.role) {
      case INSURER_MANAGER:
      case INSURER_SUPPORT:
      case INSURER_USER:
        return { insurerId: userState.insurerId, typeId: RECEIPT_TYPE_ID };

      case CLIENT_USER:
        return { companyId: userState.companyId, typeId: RECEIPT_TYPE_ID };

      default:
        return { typeId: RECEIPT_TYPE_ID };
    }
  }

  return (
    <ReceiptBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/receipts/commission', text: t('title') },
      ]}
    >
      <ReceiptsTableAndSearch filters={getFIlter()} />
    </ReceiptBasePage>
  );
};

export default Commission;
