import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const CreditsCounter = ({ children }) => (
  <div className={styles.counter}>
    <i className={styles.icon}></i>{' '}
    <span className={styles.text}>{children}</span>
  </div>
);

CreditsCounter.propTypes = {
  children: PropTypes.node,
};

export default CreditsCounter;
