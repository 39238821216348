import axios from 'axios';
import Policy from 'models/Policy';

export default async function getPolicyData(id) {
  const { data } = await axios
    .get('/policies/' + id + '/generaldata')
    .catch(() => new Policy({}));

  if (typeof data === 'undefined') {
    return new Policy({});
  }

  return new Policy({
    id: data.id,
    policyNum: data.numeroDePoliza,
    insurer: data.aseguradora,
    insurerId: data.aseguradoraLookupId,
    companyName: data.empresaCliente,
    companyId: data.empresaClienteLookupId,
    projectId: data.proyectoLookupId,
    projectName: data.proyecto,
    cif: data.empresaCliente_x003a__x0020_CIF,
    managerId: data.gestorLookupId,
    traditionalBranchesCommission: data.comisionEmisionRamosTradicionale,
    pb: data.pb,
    numWorkersWithInsurance: data.trabajadoresAsegurados,
    numWorkersOffWork: data.trabajadoresBajaLaboral,
    policyReceipt: data.reciboPoliza,
    branch: data.ramo,
    branchId: data.ramoLookupId,
    agreement: data.convenio,
    expirationDate: data.fechaVencimiento,
    netPremium: data.primaNeta,
    totalPremium: data.primaTotal,
    periodicityId: data.periodicidadLookupId,
    periodicity: data.periodicidad,
    receiptPolicyActivationMovement: data.reciboMovimientoAltaPoliza,
    receiptPolicyTerminationMovement: data.reciboMovimientoBajaPoliza,
  });
}
