import dayjs from 'dayjs';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

export const columns = (t, role) => {
  const columns = {
    id: { field: 'id' },
    code: {
      field: 'code',
      headerName: t('codeHeader'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
    },
    cif: {
      field: 'cif',
      headerName: t('cif'),
      align: 'left',
      headerAlign: 'left',
      minWidth: 150,
    },
    companyName: {
      field: 'companyName',
      headerName: t('socialReason'),
      flex: 1,
      minWidth: 150,
    },
    status: {
      field: 'status',
      headerName: t('statusHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    branch: {
      field: 'branch',
      headerName: t('branchHeader'),
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 150,
    },
    currentPolicy: {
      field: 'currentPolicy',
      headerName: t('numPolicyHeader'),
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 175,
    },
    credits: {
      field: 'credits',
      headerName: t('creditsHeader'),
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 130,
    },
    currentPremium: {
      field: 'currentPremium',
      headerName: t('currentPremiumHeader'),
      type: 'number',
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 150,
      valueGetter: (params) => {
        if (!params.value) {
          return '-';
        }
        return Math.round(params.value * 100) / 100 + ' €';
      },
    },

    expirationDate: {
      field: 'expirationDate',
      headerName: t('expirationDateHeader'),
      minWidth: 175,
      flex: 1,
      valueGetter: (params) => {
        if (!params.value) {
          return '-';
        }
        return dayjs(params.value).format('DD/MM/YYYY');
      },
    },
    currentInsurer: {
      field: 'currentInsurer',
      headerName: t('currentInsurerHeader'),
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 150,
    },
    participantNames: {
      field: 'participantNames',
      headerName: t('participantsHeader'),
      align: 'left',
      flex: 1,
      headerAlign: 'left',
      minWidth: 175,
      sortable: false,
    },
  };

  switch (role) {
    case AINA_MANAGER:
      return [
        columns.id,
        columns.code,
        columns.cif,
        columns.companyName,
        columns.status,
        columns.branch,
        columns.currentPolicy,
        columns.currentPremium,
        columns.expirationDate,
        columns.currentInsurer,
        columns.credits,
        columns.participantNames,
      ];
    case CLIENT_USER:
      return [
        columns.id,
        columns.code,
        columns.cif,
        columns.status,
        columns.branch,
        columns.currentPolicy,
        columns.currentPremium,
        columns.expirationDate,
      ];
    case INSURER_SUPPORT:
      return [
        columns.id,
        columns.code,
        columns.companyName,
        columns.status,
        columns.branch,
        columns.credits,
        columns.currentPremium,
        columns.expirationDate,
      ];
    case INSURER_MANAGER:
    case INSURER_USER:
      return [
        columns.id,
        columns.code,
        columns.status,
        columns.branch,
        columns.credits,
        columns.currentPremium,
        columns.expirationDate,
      ];
    default:
      return [];
  }
};
