import axios from 'axios';

export default async function getRolesList() {
  const { data } = await axios.get('/list/role').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data;
}
