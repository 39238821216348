import axios from 'axios';
import Client from 'models/Client';

export default async function updateClientData(clientId, data) {
  const client = new Client(data);

  const body = {
    id: client.id,
    cif: client.cif,
    razonSocial: client.name,
    marcaComercial: client.trademark,
    cnaeLookupId: client.cnaeId,
    sicLookupId: client.sicId,
    numTrabajadores: client.numWorkers,
    edadMedia: client.averageAge,
    telefono: client.phone,
    gestorLookupId: client.managerId,
    emailAddress: client.email,
    direccion: client.address,
    provinciaLookupId: client.provinceId,
    codigoPostal: client.zipCode,
    preferenciaContacto: client.preferredContact,
    dominio: client.domain,
    observaciones: client.notes,
    ciudad: client.city,
  };

  try {
    const res = await axios.put('/clients/' + clientId + '/generaldata', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
