import axios from 'axios';
import Project from 'models/Project';

export default async function getProjects(filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    id: filter?.id ?? '',
    search: filter?.searchBarText ?? '',
    Empresa: filter?.companyName ?? '',
    EmpresaLookupId: filter?.companyId ?? '',
    EstadoLookupId: filter?.statusId ?? '',
    RamoLookupId: filter?.branchId ?? '',
    ParticipantsLookupId: filter?.participantsId ?? '',
    accessGranted: filter?.accessGranted ?? '',
    AseguradoraPolizaActualLookupId: filter?.currentInsurerId ?? '',
    PolizaActualLookupId: filter?.currentPolicy ?? '',
    Start: filter?.start ?? '',
    Length: filter?.length ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    if (value !== '') {
      params.append(key, value);
    }
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/projects', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new Project({
            id: item.id,
            agreement: item.convenio,
            code: item.codigodeproyecto,
            companyName: item.empresa,
            companyId: item.empresaLookupId,
            cif: item.empresa_x003a__x0020_CIF,
            averageAge: item.empresa_x003a__x0020_EdadMedia,
            cnae: item.empresa_x003a__x0020_CNAEValueCo,
            numWorkers: item.numTrabajadores,
            manager: item.gestor,
            status: item.estado,
            statusId: item.estadoLookupId,
            branch: item.ramo,
            branchId: item.ramoLookupId,
            currentPolicy: item.polizaActual,
            currentPremium: item.polizaActual_x003a__x0020_PrimaT,
            expirationDate: item.fechaVencimientoActual,
            currentInsurerId: item.aseguradoraPolizaActualLookupId,
            currentInsurer: item.aseguradoraPolizaActual,
            participants: item.participants,
            accessGranted: item.accessGranted,
            credits: item.costeCreditos,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
