class Province {
  constructor(params) {
    this.id = params.id;
    this.name = params.name;
    this.code = params.code;
    this.parent = params.parent;
  }
}

export default Province;
