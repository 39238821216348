import { useContext } from 'react';

import getCNAEList from 'services/fieldLists/getCNAEList';
import getProvinceList from 'services/fieldLists/getProvinceList';
import getSICList from 'services/fieldLists/getSICList';
import getDocumentTypeList from 'services/fieldLists/getDocumentTypeList';
import getNotificationTypeList from 'services/fieldLists/getNotificationTypeList';
import getSectorList from 'services/fieldLists/getSectorList';

import { AppContext } from '.';
import {
  UPDATE_BRANCH,
  UPDATE_CNAE,
  UPDATE_DOCUMENT_TYPES,
  UPDATE_NOTIFICATION_TYPES,
  UPDATE_GEOGRAPHIC_DATA,
  UPDATE_MANAGER,
  UPDATE_PERIODICITY,
  UPDATE_PROJECTS_STATUSES,
  UPDATE_RECEIPTS_STATUSES,
  UPDATE_ROLES_LIST,
  UPDATE_SECTOR,
  UPDATE_SIC,
} from './constants';
import getBranchList from 'services/fieldLists/getBranchList';
import getRolesList from 'services/fieldLists/getRolesList';
import getProjectsStatuses from 'services/fieldLists/getProjectsStatuses';
import getManagerList from 'services/fieldLists/getManagerList';
import getPeriodicityList from 'services/fieldLists/getPeriodicityList';
import getReceiptsStatuses from 'services/fieldLists/getReceiptsStatuses';

export const useAppStore = () => {
  return useContext(AppContext);
};

export const retrieveFields = () => {
  const promises = [];

  if (!JSON.parse(window.localStorage.getItem('sicList'))) {
    promises.push(() =>
      getSICList().then((results) => ({
        type: UPDATE_SIC,
        payload: results,
      })),
    );
  }
  if (!JSON.parse(window.localStorage.getItem('receiptStatusesList'))) {
    promises.push(() =>
      getReceiptsStatuses().then((results) => ({
        type: UPDATE_RECEIPTS_STATUSES,
        payload: results,
      })),
    );
  }
  if (!JSON.parse(window.localStorage.getItem('sectorList'))) {
    promises.push(() =>
      getSectorList().then((results) => ({
        type: UPDATE_SECTOR,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('branchesList'))) {
    promises.push(() =>
      getBranchList().then((results) => ({
        type: UPDATE_BRANCH,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('managersList'))) {
    promises.push(() =>
      getManagerList().then((results) => ({
        type: UPDATE_MANAGER,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('cnaeList'))) {
    promises.push(() =>
      getCNAEList().then((results) => ({
        type: UPDATE_CNAE,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('geographicDataList'))) {
    promises.push(() =>
      getProvinceList().then((results) => ({
        type: UPDATE_GEOGRAPHIC_DATA,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('periodicityList'))) {
    promises.push(() =>
      getPeriodicityList().then((results) => ({
        type: UPDATE_PERIODICITY,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('documentTypeList'))) {
    promises.push(() =>
      getDocumentTypeList().then((results) => ({
        type: UPDATE_DOCUMENT_TYPES,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('notificationTypeList'))) {
    promises.push(() =>
      getNotificationTypeList().then((results) => ({
        type: UPDATE_NOTIFICATION_TYPES,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('statusesList'))) {
    promises.push(() =>
      getProjectsStatuses().then((results) => ({
        type: UPDATE_PROJECTS_STATUSES,
        payload: results,
      })),
    );
  }

  if (!JSON.parse(window.localStorage.getItem('rolesList'))) {
    promises.push(() =>
      getRolesList().then((results) => ({
        type: UPDATE_ROLES_LIST,
        payload: results,
      })),
    );
  }

  return Promise.all(promises.map((fn) => retry(fn)))
    .then((results) => {
      return results;
    })
    .catch((error) => {
      console.error('Error retrieving fields:', error);
      return [];
    });
};

function retry(fn, retries = 2) {
  return new Promise((resolve, reject) => {
    function attempt() {
      fn()
        .then(resolve)
        .catch((error) => {
          console.error('Error in request, retrying...', error);
          if (retries > 0) {
            retries--;
            attempt();
          } else {
            reject(error);
          }
        });
    }
    attempt();
  });
}
