import axios from 'axios';
import Client from 'models/Client';

export default async function getClientData(clientId) {
  const { data } = await axios
    .get('/clients/' + clientId + '/generaldata')
    .catch(() => new Client({}));

  if (typeof data === 'undefined') {
    return new Client({});
  }

  return new Client({
    id: data.id,
    cif: data.cif,
    name: data.razonSocial,
    trademark: data.marcaComercial,
    provinceId: data.provinciaLookupId,
    city: data.ciudad,
    cnaeId: data.cnaeLookupId,
    sicId: data.sicLookupId,
    numWorkers: data.numTrabajadores,
    averageAge: data.edadMedia,
    phone: data.telefono,
    email: data.emailAddress,
    managerId: data.gestorLookupId,
    address: data.direccion,
    ainaPolicies: data.polizasAina,
    onboarding: data.onboarding,
    activeProjects: data.proyectosActivos,
    letterOfAppointment: data.cartaNombramiento,
    zipCode: data.codigoPostal,
    preferredContact: data.preferenciaContacto,
    domain: data.dominio,
    notes: data.observaciones,
  });
}
