import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const PageContainer = ({ children, extraClass }) => {
  return (
    <div
      className={`${styles.container} ${
        extraClass ? styles[`container--${extraClass}`] : ''
      }`}
    >
      {children}
    </div>
  );
};

PageContainer.propTypes = {
  children: PropTypes.node,
  extraClass: PropTypes.string
};

export default PageContainer;
