export const columns = (t) => [
  { field: 'id' },

  {
    field: 'name',
    headerName: t('user.name'),
    flex: 1,
    minWidth: 200,
    valueGetter: (params) => {
      return `${params.row.name || ''} ${params.row.surname || ''}`;
    },
  },
  {
    field: 'position',
    headerName: t('user.position'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'email',
    headerName: t('contactEmail'),
    align: 'left',
    headerAlign: 'left',
    width: 175,
  },
  {
    field: 'phone',
    headerName: t('contactPhone'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 175,
  },
];
export const columnsAccessCodes = (t) => [
  { field: 'id' },

  {
    field: 'accessName',
    headerName: t('user.accessName'),
    width: 250,
  },
  {
    field: 'user',
    headerName: t('user.user'),
    align: 'left',
    headerAlign: 'left',
    width: 250,
  },
  {
    field: 'userEmail',
    headerName: t('user.userEmail'),
    align: 'left',
    headerAlign: 'left',
    width: 200,
  },
  {
    field: 'code',
    headerName: t('user.code'),
    align: 'left',
    headerAlign: 'left',
    width: 175,
  },
  {
    field: 'observations',
    headerName: t('observations'),
    align: 'left',
    headerAlign: 'left',
    minWidth: 200,
    flex: 1,
  },
];
