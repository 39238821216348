import { useTranslation } from 'react-i18next';
import Loader from 'UI/atoms/Loader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useElementSubmit } from 'hooks/useElementSubmit';
import InsurerUsersAndCodeForm from '../InsurerUsersAndCodeForm';
import { useTable } from 'hooks/useTable';
import getInsurerUsers from 'services/insurers/getInsurerUsers';
import CustomTable from 'components/blocks/CustomTable';
import {
  columns as columsConfiguration,
  columnsAccessCodes as columnsConfigurationAccess,
} from './tableConfiguration';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EditIcon } from 'assets/icons/IconsLibrary';
import { useEffect, useState } from 'react';
import { useDecodedData } from 'hooks/useDecodedData';
import { useAppStore } from 'context/AppProvider/hooks';
import { findRoleId } from 'utils/lookupIdMapper';
import { showErrorNotification } from 'utils/showToastNotifications';
import { accessCodesMapper, insurersUsersMapper } from 'utils/columnsMapper';
import updateInsurerUser from 'services/insurers/updateInsurerUser';
import UserDialog from 'components/user/UserDialog';
import updateUser from 'services/insurers/updateUser';
import AccessCodeDialog from '../AccessCodeDialog';
import getAccessCodes from 'services/insurers/getAccessCodes';
import updateAccessCode from 'services/insurers/updateAccessCode';
import {
  AINA_MANAGER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import { useDialog } from 'hooks/useDialog';
import createAccessCode from 'services/insurers/createAccessCode';
import createUser from 'services/insurers/createUser';

const InsurerUsersAndCodesTab = () => {
  const [t] = useTranslation('insurersComponent');
  const [appState] = useAppStore();
  const [clientState] = useClientStore();
  const [userState] = useUserStore();
  const [loading, setLoading] = useState(true);
  const [admins, setAdmins] = useState([]);
  const [supports, setSupports] = useState([]);

  const columns = columsConfiguration(t);
  const columnsAccessCodes = columnsConfigurationAccess(t);
  const { getDecodedData } = useDecodedData(clientState.clientId);

  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateInsurerUser,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  const {
    rows,
    rowCount,
    loading: loadingTable,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    refreshRows: refreshRowsUsers,
    handleOnSortModelChange,
  } = useTable(
    getInsurerUsers,
    t('error'),
    true,
    clientState.clientId,
    {
      roleLookupId: findRoleId(appState.roles, INSURER_USER),
    },
    insurersUsersMapper,
  );

  const {
    rows: accessCodes,
    rowCount: rowCountCodes,
    loading: loadingTableCodes,
    sortModel: sortModelCodes,
    paginationModel: paginationModelCodes,
    refreshRows: refreshRowsCodes,
    handleOnPaginationModelChange: handleOnPaginationModelChangeCodes,
    handleOnSortModelChange: handleOnSortModelChangeCodes,
  } = useTable(
    getAccessCodes,
    t('error'),
    true,
    clientState.clientId,
    undefined,
    accessCodesMapper,
  );

  const {
    loadingDialog: loadingDialogUser,
    openDialog: openDialogUser,
    selectedElement: selectedUser,
    handleCreateOrUpdateElement: handleCreateOrUpdateUser,
    handleOnAdd: handleOnAddUser,
    handleOnClose: handleOnCloseUser,
    handleOnEdit: handleOnEditUser,
  } = useDialog(updateUser, createUser, refreshRowsUsers);

  const {
    loadingDialog: loadingDialogCode,
    openDialog: openDialogCode,
    selectedElement: selectedCode,
    handleCreateOrUpdateElement: handleCreateOrUpdateCode,
    handleOnAdd: handleOnAddCode,
    handleOnClose: handleOnCloseCode,
    handleOnEdit: handleOnEditCode,
  } = useDialog(updateAccessCode, createAccessCode, refreshRowsCodes);

  useEffect(() => {
    retrieveUsers();
  }, [clientState]);

  async function formatAndSubmit(admins, supports) {
    await Promise.all([
      ...admins.map((admin) => handleOnSubmit(admin)),
      ...supports.map((support) => handleOnSubmit(support)),
    ]);
  }

  function retrieveUsers() {
    setLoading(true);
    const decodedData = getDecodedData()?.id;
    if (decodedData) {
      Promise.all([
        getInsurerUsers(decodedData, {
          roleLookupId: findRoleId(appState.roles, INSURER_MANAGER),
        }),
        getInsurerUsers(decodedData, {
          roleLookupId: findRoleId(appState.roles, INSURER_SUPPORT),
        }),
      ])
        .then(([admins, supports]) => {
          setAdmins(admins.resultList);
          setSupports(supports.resultList);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          showErrorNotification(t('error'));
          setLoading(false);
        });
    }
  }

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return (
    <>
      <InsurerUsersAndCodeForm
        defaultFormValues={{}}
        admins={admins ?? []}
        supports={supports ?? []}
        rows={rows}
        onSubmit={formatAndSubmit}
        userTable={
          <CustomTable
            loading={loadingTable}
            rows={rows}
            rowCount={rowCount}
            paginationModel={paginationModel}
            sortModel={sortModel}
            addButtonText={t('newUser')}
            addButtonOnClick={
              [AINA_MANAGER].includes(userState.role)
                ? () => handleOnAddUser()
                : undefined
            }
            pageSizeOptions={[10, 20, 50, 100]}
            columns={[
              ...columns,
              {
                field: 'actions',
                type: 'actions',
                headerAlign: 'left',
                align: 'right',
                headerName: t('actionsHeader'),
                getActions: (params) => [
                  <GridActionsCellItem
                    key="edit"
                    icon={<EditIcon />}
                    label={t('edit')}
                    onClick={() => {
                      handleOnEditUser(params.row);
                    }}
                  />,
                ],
              },
            ]}
            onPaginationModelChange={handleOnPaginationModelChange}
            onSortModelChange={handleOnSortModelChange}
            onRowClick={handleOnEditUser}
          />
        }
        accessTable={
          [AINA_MANAGER].includes(userState.role) ? (
            <CustomTable
              rows={accessCodes}
              loading={loadingTableCodes}
              rowCount={rowCountCodes}
              paginationModel={paginationModelCodes}
              sortModel={sortModelCodes}
              addButtonText={t('newAccess')}
              addButtonOnClick={
                [AINA_MANAGER].includes(userState.role)
                  ? () => handleOnAddCode()
                  : undefined
              }
              pageSizeOptions={[10, 20, 50, 100]}
              columns={[
                ...columnsAccessCodes,
                {
                  field: 'actions',
                  type: 'actions',
                  headerAlign: 'left',
                  align: 'right',
                  headerName: t('actionsHeader'),
                  getActions: (params) => [
                    <GridActionsCellItem
                      key="edit"
                      icon={<EditIcon />}
                      label={t('edit')}
                      onClick={() => {
                        handleOnEditCode(params.row);
                      }}
                    />,
                  ],
                },
              ]}
              onPaginationModelChange={handleOnPaginationModelChangeCodes}
              onSortModelChange={handleOnSortModelChangeCodes}
              onRowClick={handleOnEditCode}
            />
          ) : null
        }
      />
      {openDialogCode ? (
        <AccessCodeDialog
          onClose={handleOnCloseCode}
          open={openDialogCode}
          loading={loadingDialogCode}
          selectedCode={selectedCode}
          onCreateOrUpdate={(parentId, code) =>
            handleCreateOrUpdateCode(parentId, undefined, code)
          }
        />
      ) : null}

      {openDialogUser ? (
        <UserDialog
          onClose={handleOnCloseUser}
          open={openDialogUser}
          loading={loadingDialogUser}
          selectedUser={selectedUser}
          onCreateOrUpdate={(parentId, user) =>
            handleCreateOrUpdateUser(parentId, undefined, user)
          }
        />
      ) : null}
    </>
  );
};

export default InsurerUsersAndCodesTab;
