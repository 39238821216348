import { useTranslation } from 'react-i18next';

import {
  UserIcon,
  BriefcaseIcon,
  RocketIcon,
  ShieldIcon,
  TicketIcon,
  WalletIcon,
} from 'assets/icons/IconsLibrary';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_USER,
  INSURER_SUPPORT,
  INSURER_MANAGER,
} from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';

const navigationButtons = (selected) => {
  const [userState] = useUserStore();

  const [t] = useTranslation('header');

  const buttonsList = {
    clients: {
      text: t('clients'),
      route: '/clients',
      icon: UserIcon,
      selected,
    },
    insurers: {
      text: t('insurers'),
      route: '/insurers',
      icon: BriefcaseIcon,
      selected,
    },
    projects: {
      text: t('projects'),
      route: '/projects',
      icon: RocketIcon,
      selected,
    },
    policies: {
      text: t('policies'),
      route: '/policies',
      icon: ShieldIcon,
      selected,
    },
    receipts: {
      text: t('receipts'),
      route: '/receipts/policy',
      icon: TicketIcon,
      selected,
    },

    credits: {
      text: t('credits'),
      route: '/credits',
      icon: WalletIcon,
      selected,
    },
  };

  let navigationButtons = [];

  switch (userState.role) {
    case AINA_MANAGER:
      navigationButtons = [
        buttonsList.clients,
        buttonsList.insurers,
        buttonsList.projects,
        buttonsList.policies,
        buttonsList.receipts,
        buttonsList.credits,
      ];
      break;
    case CLIENT_USER:
      navigationButtons = [
        buttonsList.projects,
        buttonsList.policies,
        buttonsList.receipts,
      ];
      break;
    case INSURER_USER:
    case INSURER_SUPPORT:
    case INSURER_MANAGER:
      navigationButtons = [
        buttonsList.projects,
        buttonsList.policies,
        buttonsList.receipts,
      ];
      break;
    default:
      navigationButtons = [];
  }

  return navigationButtons;
};

export default navigationButtons;
