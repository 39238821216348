import axios from 'axios';
import ClientDocumentation from 'models/ClientDocumentation';
import ProjectWinner from 'models/ProjectWinner';

export default async function getProjectWinner(projectId) {
  try {
    const { data } = await axios.get('/projects/' + projectId + '/end');
    return new ProjectWinner({
      currentInsurerId: data.aseguradoraGanaradoraLookupId,
      currentInsurer: data.aseguradoraGanaradora,
      isWinner: data.esGanador,
      offer: new ClientDocumentation({
        id: data.offer?.id,
        fileRef: data.offer?.fileLeafRef,
        fileName: data.offer?.nombreDeArchivo,
        creationDate: data.offer?.createdDateTime,
        lastModificationDate: data.offer?.lastModifiedDateTime,
        companyName: data.offer?.aseguradora,
        companyId: data.offer?.aseguradoraLookupId,
      }),
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
