import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import ClientBasePage from '../base';
import ReceiptsTableAndSearch from 'components/receipts/ReceiptsTableAndSearch';
import { useDecodedData } from 'hooks/useDecodedData';

const RECEIPT_TYPE_ID = 2;

const Commission = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, name: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('receipts');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 7 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <ClientBasePage
      clientName={clientData.name}
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/clients', text: t('clients') },
        {
          path: `/clients/${clientState.clientId}`,
          text: clientData.name,
        },
        {
          path: `/clients/${clientState.clientId}/receipts/commission`,
          text: t('commissionReceipts'),
        },
      ]}
    >
      {clientData.id ? (
        <ReceiptsTableAndSearch
          filters={{ typeId: RECEIPT_TYPE_ID, companyId: clientData.id }}
        />
      ) : null}
    </ClientBasePage>
  );
};

export default Commission;
