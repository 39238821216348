import { useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { findElementByLookupId, findCCAA } from 'utils/lookupIdMapper';
import { usePrevious } from 'hooks/usePreviousFormValue';
import { useAppStore } from 'context/AppProvider/hooks';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import ProvincesFilter from 'components/filters/ProvincesFilter';
import CNAEFilter from 'components/filters/CNAEFilter';
import SICFilter from 'components/filters/SICFilter';

const CompanyDataForm = ({ defaultFormValues }) => {
  const [appState] = useAppStore();
  const { t } = useTranslation('user');
  const schema = validationSchema(t);

  const sicList = appState.sic;
  const cnaeList = appState.cnae;
  const geographicList = appState.geographicData;

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      sicId: findElementByLookupId(sicList, defaultFormValues?.sicId),
      cnaeId: findElementByLookupId(cnaeList, defaultFormValues?.cnaeId),
      ccaaId: findCCAA(
        geographicList.provinces,
        geographicList.ccaa,
        defaultFormValues?.provinceId,
      ),
      provinceId: findElementByLookupId(
        geographicList.provinces,
        defaultFormValues?.provinceId,
      ),
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    watch,
    setValue,
  } = methods;

  const watchCCAA = watch('ccaaId');
  const prevWatchCCAA = usePrevious(watchCCAA);

  useEffect(() => {
    if (prevWatchCCAA && prevWatchCCAA?.code !== watchCCAA?.code) {
      setValue('provinceId', null);
    }
  }, [watchCCAA, prevWatchCCAA, setValue]);

  return (
    <Form.Container>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="cif" label={t('cif')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="name" label={t('socialReason')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="trademark" label={t('commercialBrand')} />
            </Form.Column>
            <Form.Column xl={3} lg={6} md={8}>
              <CNAEFilter fieldName="cnaeId" />
            </Form.Column>
            <Form.Column xl={3} lg={6} md={8}>
              <SICFilter fieldName="sicId" />
            </Form.Column>
            <Form.Column xl={2} lg={6} md={8}>
              <Form.Input
                name="numWorkers"
                label={t('numWorkers')}
                containerSize="medium"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xl={2} lg={6} md={8}>
              <Form.Input
                name="averageAge"
                label={t('avgAge')}
                containerSize="small"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="address" label={t('address')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Autocomplete
                label={t('ccaa')}
                options={geographicList.ccaa}
                getOptionLabel={(option) => `${option.name}`}
                name="ccaaId"
              />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <ProvincesFilter
                fieldName="provinceId"
                selectedCCAA={watchCCAA}
              />
            </Form.Column>
            <Form.Column xl={3} lg={6} md={8}>
              <Form.Input
                name="city"
                label={t('city')}
                containerSize="medium"
              />
            </Form.Column>
            <Form.Column xl={3} lg={6} md={8}>
              <Form.Input
                name="zipCode"
                label={t('zipCode')}
                containerSize="medium"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xl={4} lg={6} md={8}>
              <Form.Input name="email" label={t('contactEmail')} />
            </Form.Column>
            <Form.Column xl={4} lg={6} md={8}>
              <Form.Input
                name="phone"
                label={t('contactPhone')}
                containerSize="medium"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column lg={12} xl={10} sm={24} md={16} xs={24}>
              <Form.RadioButton
                name="preferredContact"
                buttonsText={[
                  { value: 'Teléfono', text: t('contactPhone') },
                  { value: 'Email', text: t('contactEmail') },
                  { value: 'Carta', text: t('contactLetter') },
                ]}
                title={t('preferredContactWay')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Input name="domain" label={t('domain')} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.Textarea name="notes" label={t('observations')} />
            </Form.Column>
          </Form.Row>
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

CompanyDataForm.propTypes = {
  defaultFormValues: PropTypes.array,
  onSubmit: PropTypes.func,
};

export default CompanyDataForm;
