import { useTranslation } from 'react-i18next';
import ReceiptDataForm from '../ReceiptDataForm';
import { useLocation } from 'react-router-dom';
import create from 'services/receipts/create';
import createDocumentation from 'services/receipts/createDocumentation';
import { useReceiptCreate } from 'hooks/useReceiptCreate';

const RECEIPT_TYPE_POLICY = '1';
const RECEIPT_TYPE_COMMISSION = '2';

const CreateReceiptTab = () => {
  const [t] = useTranslation('receiptsComponent');
  const { pathname } = useLocation();

  const { handleOnSubmit } = useReceiptCreate(
    create,
    createDocumentation,
    t('dataCreatedSuccessfully'),
    t('error'),
    pathname === '/receipts/policy/create'
      ? '/receipts/policy'
      : '/receipts/commission',
  );

  return (
    <ReceiptDataForm
      onSubmit={handleOnSubmit}
      defaultFormValues={{
        typeId:
          pathname === '/receipts/policy/create'
            ? RECEIPT_TYPE_POLICY
            : RECEIPT_TYPE_COMMISSION,
      }}
    />
  );
};

export default CreateReceiptTab;
