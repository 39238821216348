import { useEffect } from 'react';
import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { usePrevious } from 'hooks/usePreviousFormValue';

import ProvincesFilter from 'components/filters/ProvincesFilter';
import SICFilter from 'components/filters/SICFilter';
import CCAAFilter from 'components/filters/CCAAFilter';
import CNAEFilter from 'components/filters/CNAEFilter';
import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';

const UserFilter = ({ methods }) => {
  const { watch, setValue } = methods;
  const [t] = useTranslation('user');

  const watchCCAA = watch('ccaa');
  const prevWatchCCAA = usePrevious(watchCCAA);

  useEffect(() => {
    if (prevWatchCCAA && prevWatchCCAA?.code !== watchCCAA?.code) {
      setValue('provinceId', null);
    }
  }, [watchCCAA, prevWatchCCAA, setValue]);

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={12}>
          <Form.Column xs={12} sm={12} md={12} lg={3} xl={3}>
            <Form.Row columns={12}>
              <Form.Column xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Switch
                  name="letterOfAppointment"
                  label={t('letterOfAppointment')}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12}>
              <Form.Column xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Switch name="activeProjects" label={t('activeProject')} />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12}>
              <Form.Column xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Switch name="onboarding" label={t('onboarding')} />
              </Form.Column>
            </Form.Row>
          </Form.Column>
          <Form.Column xs={12} sm={12} md={12} lg={9} xl={9}>
            <Form.Row columns={12}>
              <Form.Column sm={12} md={6} lg={4} xl={4} marginBottom={1}>
                <CNAEFilter fieldName="cnaeId" />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={4} xl={4} marginBottom={1}>
                <SICFilter fieldName="sicId" />
              </Form.Column>
              <Form.Column sm={12} md={12} lg={4} xl={4}>
                <Form.Slider
                  name="numWorkers"
                  step={10}
                  max={100}
                  label={t('numWorkers')}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12}>
              <Form.Column sm={12} md={12} lg={4} xl={4}>
                <Form.Slider
                  name="averageAge"
                  label={t('avgAge')}
                  max={65}
                  min={18}
                />
              </Form.Column>
              <Form.Column sm={12} md={12} lg={4} xl={4}>
                <Form.Slider
                  name="ainaPolicies"
                  label={t('numInsurers')}
                  max={100}
                />
              </Form.Column>
              <Form.Column sm={12} md={12} lg={4} xl={4}>
                <Form.Slider
                  name="billing"
                  step={50000}
                  max={1000000}
                  label={t('billing')}
                />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12}>
              <Form.Column sm={12} md={4} lg={4} xl={4}>
                <CCAAFilter fieldName="ccaa" />
              </Form.Column>
              <Form.Column sm={12} md={4} lg={4} xl={4}>
                <ProvincesFilter
                  fieldName="provinceId"
                  selectedCCAA={watchCCAA}
                />
              </Form.Column>
              <Form.Column sm={12} md={4} lg={4} xl={4}>
                <Form.Input name="city" label={t('city')} />
              </Form.Column>
            </Form.Row>
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

UserFilter.propTypes = {
  methods: PropTypes.any,
};

export default UserFilter;
