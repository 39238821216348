import axios from 'axios';

import Notification from 'models/Notification';

export default async function getNotificationTypeList() {
  const { data } = await axios.get('/list/notification-types').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new Notification({
        id: item.id,
        name: item.name,
      }),
  );
}
