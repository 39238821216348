import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Textarea as TextareaMUI } from '@mui/joy';

import styles from './styles.module.scss';

const Textarea = ({ name, label, withValidation, containerSize, ...props }) => {
  const { control } = useFormContext();
  return (
    <div
      className={`${styles.container} ${
        containerSize === 'small' ? styles['container--small'] : ''
      }`}
    >
      <p className={styles.label}>{label}</p>
      <Controller
        control={control}
        name={name}
        render={({ field, fieldState }) => (
          <>
            <TextareaMUI
              {...field}
              error={!!(withValidation && fieldState.error)}
              sx={{ '--Textarea-focusedThickness': 0 }}
              className={styles.input}
              variant="plain"
              maxRows={4}
              {...props}
            />
          </>
        )}
      />
    </div>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  withValidation: PropTypes.bool,
  containerSize: PropTypes.string,
};
export default Textarea;
