import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropTypes } from 'prop-types';

import Form from 'UI/molecules/Form';

import validationSchema from './validationSchema';

const OnboardingDataForm = ({ defaultFormValues, onSubmit }) => {
  const [errorsText, setErrorsText] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [t] = useTranslation('onboarding');
  const schema = validationSchema(t);

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      representativeNidExpirationDate: new Date(
        defaultFormValues.representativeNidExpirationDate,
      ),
      onboarding: defaultFormValues.onboarding ?? false,
      letterOfAppointment: defaultFormValues.letterOfAppointment ?? false,
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  const handleOnSubmit = async (data) => {
    const letterOfAppointment = methods.getValues('letterOfAppointment');
    const onboarding = methods.getValues('onboarding');

    setSubmitting(true);

    await onSubmit({
      ...methods.getValues(),
      letterOfAppointment:
        typeof letterOfAppointment === 'boolean'
          ? letterOfAppointment
          : /true/.test(letterOfAppointment),
      onboarding:
        typeof onboarding === 'boolean' ? onboarding : /true/.test(onboarding),
    });

    setSubmitting(false);
  };

  return (
    <Form.Container>
      <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 3 }} alignItems="flex-end">
            <Form.Column sm={24} md={8} lg={8} xl={3}>
              <Form.Input name="contactName" label={t('contactName')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={8} xl={4}>
              <Form.Input name="contactSurname" label={t('contactSurname')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={3}>
              <Form.Input
                name="phone"
                label={t('contactPhone')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="email" label={t('email')} />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 3 }}>
            <Form.Column sm={24} md={8} lg={8} xl={3}>
              <Form.Input name="representativeName" label={t('name')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={8} xl={4}>
              <Form.Input name="representativeSurname" label={t('surname')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={3}>
              <Form.Input name="representativeNid" label={t('dni')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={8} xl={4}>
              <Form.Date
                name="representativeNidExpirationDate"
                label={t('dniExpirationDate')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xl={24} sm={24} lg={24} md={24} xs={24}>
              <Form.SectionTitle
                title={t('letterOfAppointment')}
              ></Form.SectionTitle>
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.RadioButton
                name="onboarding"
                buttonsText={[
                  { value: true, text: t('yes') },
                  { value: false, text: t('no') },
                ]}
                title={t('onboarding')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.RadioButton
                name="letterOfAppointment"
                buttonsText={[
                  { value: true, text: t('yes') },
                  { value: false, text: t('no') },
                ]}
                title={t('letterOfAppointment')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Footer
            loading={submitting}
            onClickSecondary={() => navigate('/clients')}
          />
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

OnboardingDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default OnboardingDataForm;
