import { useEffect, useState } from 'react';

import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';

import ClientBasePage from '../base';
import { useTranslation } from 'react-i18next';
import { useDecodedData } from 'hooks/useDecodedData';
import PoliciesTableAndSearch from 'components/policies/PoliciesTableAndSearch';

const Policies = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, name: '' });
  const [t] = useTranslation('clients');
  const { getDecodedData } = useDecodedData(clientState.clientId);

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 5 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <ClientBasePage
      clientName={clientData.name}
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/clients', text: t('title') },
        {
          path: `/clients/${clientState.clientId}`,
          text: clientData.name,
        },
        {
          path: `/clients/${clientState.clientId}/policies`,
          text: t('policies'),
        },
      ]}
    >
      {clientData.id ? (
        <PoliciesTableAndSearch filters={{ companyId: clientData.id }} />
      ) : null}
    </ClientBasePage>
  );
};

export default Policies;
