import axios from 'axios';

export default async function updateCredits(creditPurchase, newStatusId) {
  const body = {
    aseguradoraLookupId: creditPurchase.code,
    solicitud: creditPurchase.createdAt,
    Pago: creditPurchase.paymentDate,
    Nodecreditos: creditPurchase.credits,
    importe: creditPurchase.price,
    IVA: creditPurchase.taxes,
    EstadoLookupId: newStatusId,
  };

  try {
    const res = await axios.put(`/credits/${creditPurchase.id}`, body);

    return res;
  } catch (error) {
    return error.response;
  }
}
