import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import {
  CheckBoxOutlineBlankOutlined,
  CheckBoxOutlined,
} from '@mui/icons-material';

const CheckButton = ({ text, onClick, isChecked }) => {
  return (
    <Button
      classes={{
        disabled: styles.disabled,
        root: styles.root,
      }}
      onClick={onClick}
      endIcon={
        isChecked ? (
          <CheckBoxOutlined color="info" />
        ) : (
          <CheckBoxOutlineBlankOutlined color="info" />
        )
      }
    >
      {text}
    </Button>
  );
};

CheckButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  isChecked: PropTypes.bool,
};

export default CheckButton;
