import axios from 'axios';
import Sector from 'models/Sector';

export default async function getSectorList() {
  const { data } = await axios.get('/list/sector').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new Sector({
        id: item.id,
        name: item.title,
      }),
  );
}
