import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { columns as columsConfiguration } from './tableConfiguration';
import { DeleteIcon, DownloadIcon, EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import getDocumentationList from 'services/receipts/getDocumentationList';
import { showErrorNotification } from 'utils/showToastNotifications';
import downloadDocumentation from 'services/receipts/downloadDocumentation';
import { useDownloadFile } from 'hooks/useDownloadFile';
import VisibilityIcon from '@mui/icons-material/Visibility';

const DOCUMENT_RECEIPT_ID = '26';

const ReceiptsTable = ({
  loading,
  receipts,
  rowCount,
  paginationModel,
  sortModel,
  onDelete,
  onPaginationModelChange,
  onSortModelChange,
  addButtonOnClick,
  onRowClick,
  navigateTo,
}) => {
  const { t } = useTranslation('receiptsComponent');
  const [userState] = useUserStore();
  const columns = columsConfiguration(t, userState.role);

  const { downloadFile } = useDownloadFile(
    (...params) => downloadDocumentation(...params),
    undefined,
    t('errorDownloadFile'),
  );

  const downloadReceiptFile = async (params) => {
    const receiptFiles = await getDocumentationList(params.row.id, {
      documentTypeId: DOCUMENT_RECEIPT_ID,
    });

    let receiptFile = null;

    if (receiptFiles?.resultList?.length > 0) {
      receiptFile = receiptFiles.resultList[0];
    }

    if (!receiptFile) {
      showErrorNotification(t('errorDownloadFile'));
      return false;
    }

    downloadFile(params.row.companyId, receiptFile.id, receiptFile.fileName);
  };

  const getActions = (params) => {
    switch (userState.role) {
      case AINA_MANAGER:
        return [
          <GridActionsCellItem
            key="download"
            icon={<DownloadIcon />}
            label={t('download')}
            onClick={() => downloadReceiptFile(params)}
          />,
          <GridActionsCellItem
            key="delete"
            icon={<DeleteIcon />}
            label={t('delete')}
            onClick={() => onDelete(params.row)}
          />,
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            label={t('edit')}
            onClick={() => {
              onRowClick(params.row, navigateTo, ['receipt']);
            }}
          />,
        ];
      case INSURER_MANAGER:
      case INSURER_SUPPORT:
        return [
          <GridActionsCellItem
            key="download"
            icon={<DownloadIcon />}
            label={t('download')}
            onClick={() => downloadReceiptFile(params)}
          />,
          <GridActionsCellItem
            key="edit"
            icon={<EditIcon />}
            label={t('edit')}
            onClick={() => {
              onRowClick(params.row, navigateTo, ['receipt']);
            }}
          />,
        ];
      case INSURER_USER:
      case CLIENT_USER:
        return [
          <GridActionsCellItem
            key="download"
            icon={<DownloadIcon />}
            label={t('download')}
            onClick={() => downloadReceiptFile(params)}
          />,
          <GridActionsCellItem
            key="see"
            icon={<VisibilityIcon color="primary" />}
            label={t('see')}
            onClick={() => {
              onRowClick(params.row, navigateTo, ['receipt']);
            }}
          />,
        ];
      default:
        return [];
    }
  };

  return (
    <CustomTable
      loading={loading}
      rows={receipts}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          flex: 1,
          minWidth: 150,
          headerName: t('actionsHeader'),
          getActions,
        },
      ]}
      addButtonText={t('newReceipt')}
      addButtonOnClick={addButtonOnClick}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      onRowClick={(row) => onRowClick(row, navigateTo, ['receipt'])}
    />
  );
};

ReceiptsTable.propTypes = {
  loading: PropTypes.bool,
  receipts: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onDelete: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
  navigateTo: PropTypes.string,
};

export default ReceiptsTable;
