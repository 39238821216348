import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SELECT_CLIENT, SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import ProfileBasePage from './base';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import InsurerDocumentationTab from 'components/insurer/InsurerDocumentationTab';
import DocumentationTableTab from 'components/documentation/DocumentationTableTab';

const ProfileDocumentationPage = () => {
  const [, clientDispatch] = useClientStore();
  const [userState] = useUserStore();
  const [relatedEntityId, setRelatedEntityId] = useState(null);
  const [t] = useTranslation('profile');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 1 });

    if ([CLIENT_USER].includes(userState.role)) {
      clientDispatch({
        type: SELECT_CLIENT,
        payload: window.btoa(JSON.stringify({ id: userState.companyId })),
      });
      setRelatedEntityId(userState.companyId);
    }

    if (
      [INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(userState.role)
    ) {
      clientDispatch({
        type: SELECT_CLIENT,
        payload: window.btoa(JSON.stringify({ id: userState.insurerId })),
      });
      setRelatedEntityId(userState.insurerId);
    }
  }, []);

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 3 });
  }, []);

  return (
    <ProfileBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/profile', text: t('profile') },
        { path: '/profile/documentation', text: t('documentation') },
      ]}
    >
      {relatedEntityId && (
        <>
          {[CLIENT_USER].includes(userState.role) && (
            <>
              <DocumentationTableTab />
            </>
          )}
          {[INSURER_MANAGER, INSURER_USER, INSURER_SUPPORT].includes(
            userState.role,
          ) && (
            <>
              <InsurerDocumentationTab />
            </>
          )}
        </>
      )}
    </ProfileBasePage>
  );
};

export default ProfileDocumentationPage;
