import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import image from 'assets/images/aina-happy.png';
import { RocketWhiteIcon } from 'assets/icons/IconsLibrary';
import Title from 'UI/atoms/Title';
import Button from 'UI/atoms/Button';
import HeroActions from 'UI/atoms/HeroActions';
import Hero from 'UI/atoms/Hero';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import ContactManagerButton from 'UI/molecules/ContactManagerButton';
import DOMPurify from 'dompurify';

const DashboardHero = () => {
  const [t] = useTranslation('dashboardHero');
  const navigate = useNavigate();
  const [userState] = useUserStore();

  // const [movementDialogType, setMovementDialogType] = useState(null);
  //  const [openMovementDialog, setOpenMovementDialog] = useState(false);

  function getText() {
    switch (userState.role) {
      case AINA_MANAGER:
        return t('text.manager');

      case CLIENT_USER:
        return t('text.client');

      case INSURER_MANAGER:
      case INSURER_SUPPORT:
      case INSURER_USER:
        return t('text.insurer');
    }
  }

  return (
    <>
      <Hero type="green" image={image}>
        <Title size="hero">{t('title')}</Title>

        <div
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(getText()) }}
        ></div>

        <HeroActions>
          {[
            CLIENT_USER,
            INSURER_MANAGER,
            INSURER_SUPPORT,
            INSURER_USER,
          ].includes(userState.role) ? (
            <ContactManagerButton />
          ) : (
            <Button
              text={t('button.project')}
              onClick={() => {
                navigate('/projects/create');
              }}
              icon={RocketWhiteIcon}
            ></Button>
          )}

          {/* {[CLIENT_USER].includes(userState.role) && (
            <Button
              color="green"
              text={t('button.acquisitionTransaction')}
              icon={ShieldWhiteIcon}
              onClick={() => {
                setMovementDialogType('resource_update');
                setOpenMovementDialog(true);
              }}
            ></Button>
          )} */}
          {/* {[CLIENT_USER].includes(userState.role) && (
            <Button
              color="green"
              text={t('button.accidentTransaction')}
              icon={ShieldWhiteIcon}
              onClick={() => {
                setMovementDialogType('accident');
                setOpenMovementDialog(true);
              }}
            ></Button>
          )} */}
        </HeroActions>
      </Hero>
      {/* <CreateMovementDialog
        type={movementDialogType}
        onClose={() => {
          setOpenMovementDialog(false);
        }}
        open={openMovementDialog}
      /> */}
    </>
  );
};

export default DashboardHero;
