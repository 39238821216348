import React from 'react';
import { PropTypes } from 'prop-types';

import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';

import styles from './styles.module.scss';

const FormFooter = ({
  onClickPrimary,
  loading = false,
  onClickSecondary,
  primaryBtnDisabled,
  children,
}) => {
  return (
    <div className={styles.footer}>
      <div className={styles.container}>
        {children}

        <PrimaryBtn
          loading={loading}
          disabled={primaryBtnDisabled}
          style={{ backgroundColor: '#08465e' }}
          sx={{ maxHeight: '40px', maxWidth: '165px' }}
          text={'Guardar'}
          type="submit"
          onClick={onClickPrimary}
        />

        <SecondaryBtn text={'Volver'} onClick={onClickSecondary} />
      </div>
    </div>
  );
};

FormFooter.propTypes = {
  children: PropTypes.node,
  loading: PropTypes.bool,
  primaryBtnDisabled: PropTypes.bool,
  onClickPrimary: PropTypes.func,
  onClickSecondary: PropTypes.func,
};

export default FormFooter;
