import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import PolicyBasePage from '../base';
import ReceiptsTableAndSearch from 'components/receipts/ReceiptsTableAndSearch';
import { useDecodedData } from 'hooks/useDecodedData';

const RECEIPT_TYPE_ID = 2;

const Commission = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, policyNum: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('receipts');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 4 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <PolicyBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/policies', text: t('policies') },
        {
          path: `/policies/${clientState.clientId}`,
          text: clientData.policyNum,
        },
        {
          path: `/policies/${clientState.clientId}/receipts/commission`,
          text: t('commissionReceipts'),
        },
      ]}
    >
      {clientData.id && clientData.policyNum ? (
        <ReceiptsTableAndSearch
          filters={{ typeId: RECEIPT_TYPE_ID, policyId: clientData.id }}
        />
      ) : null}
    </PolicyBasePage>
  );
};

export default Commission;
