import { FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import DocumentTypeFilter from 'components/filters/DocumentTypeFilter';
import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';

const DocumentationFilter = ({ methods }) => {
  const [t] = useTranslation('documentation');
  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={12}>
          <Form.Column xs={12} sm={6} md={6} lg={6} xl={6}>
            <DocumentTypeFilter fieldName="documentTypeId" />
          </Form.Column>
          <Form.Column xs={12} sm={6} md={6} lg={6} xl={6}>
            <Form.Switch
              name="verified"
              label={t('verified')}
              stylesContainer={{ width: '100%' }}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row columns={12}>
          <Form.Column xs={12} sm={6} md={6} lg={6} xl={6}>
            <Form.Date name="fromCreatedDate" label={t('fromDate')} />
          </Form.Column>
          <Form.Column xs={12} sm={6} md={6} lg={6} xl={6}>
            <Form.Date name="toCreatedDate" label={t('toDate')} />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

DocumentationFilter.propTypes = {
  methods: PropTypes.any,
};

export default DocumentationFilter;
