import axios from 'axios';

export default async function deleteOffer(projectId, offerId) {
  try {
    const res = await axios.delete(
      '/projects/' + projectId + '/offers/' + offerId,
    );

    return res;
  } catch (error) {
    return error.response;
  }
}
