import { createContext, useEffect, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { PropTypes } from 'prop-types';

import initialState from './initialState';
import reducer from './reducer';

export const UserContext = createContext();

const { Provider } = UserContext;

const UserProvider = (props) => {
  const { value } = props;
  const [cookies] = useCookies(['userData']);
  const [state, dispatch] = useReducer(reducer, value || initialState);

  useEffect(() => {
    if (cookies.userData) {
      const userData = cookies.userData;
      dispatch({ type: 'SET_USER_DATA', payload: userData });
    }
  }, [cookies.userData]);

  if (!state.id && cookies.userData) {
    return null;
  }

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

UserProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
};

export default UserProvider;
