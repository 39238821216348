import axios from 'axios';
import ClientDocumentation from 'models/ClientDocumentation';

export default async function getReceiptDocument(receiptId) {
  try {
    const { data } = await axios.get(
      `/receipts/${receiptId}/DocumentoDeRecibo`,
    );
    return new ClientDocumentation({
      fileName: data.nombreDeArchivo,
      id: data.id,
    });
  } catch (error) {
    console.error(error);
    return null;
  }
}
