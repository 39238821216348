import axios from 'axios';
import Insurer from 'models/Insurer';

export default async function getInsurer(id) {
  try {
    const { data } = await axios.get('/companies/' + id);
    return new Insurer({
      id: data.id,
      name: data.razonSocial,
      advanceCommission: data.adelantoDeComision,
      availableCredits: data.creditosDisponibles,
      ainaPolicies: data.polizasAINA,
      onboarding: data.onboarding,
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
