import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      issueDate: yup.date().required(t('mandatory', { field: t('issueDate') })),
      receipt: yup
        .string()
        .required(t('mandatory', { field: t('form.receipt') })),
      statusId: yup.number().required(t('mandatory', { field: t('status') })),
      policyId: yup.object().required(t('mandatory', { field: t('policy') })),
      companyId: yup
        .object()
        .required(t('mandatory', { field: t('clientName') })),
      totalAmount: yup
        .number()
        .typeError(t('invalidFormat', { field: t('totalAmount') }))
        .required(t('mandatory', { field: t('totalAmount') })),
    })
    .required();

export default validationSchema;
