import PropTypes from 'prop-types';
import { Button } from '@mui/material';

import styles from './styles.module.scss';

const ClearFilterButton = ({ text, onClick }) => (
  <Button
    variant="text"
    color="secondary"
    classes={{
      root: styles.root,
    }}
    disableTouchRipple
    className={styles.button}
    onClick={onClick}
  >
    {text}
  </Button>
);

ClearFilterButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default ClearFilterButton;
