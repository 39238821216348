import { useTranslation } from 'react-i18next';
import updateClientData from 'services/clients/updateClientData';
import getClientData from 'services/clients/getClientData';
import UserDataForm from 'components/user/UserDataForm';
import { useClientStore } from 'context/ClientProvider/hooks';
import Loader from 'UI/atoms/Loader';
import { useElementData } from 'hooks/useElementData';
import { useElementSubmit } from 'hooks/useElementSubmit';

const UserDataTab = () => {
  const [t] = useTranslation('user');
  const [clientState] = useClientStore();
  const { loading, data } = useElementData(clientState.clientId, getClientData);
  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateClientData,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return <UserDataForm defaultFormValues={data} onSubmit={handleOnSubmit} />;
};

export default UserDataTab;
