import React from 'react';
import { PropTypes } from 'prop-types';
import { Link } from 'react-router-dom';

import styles from './styles.module.scss';

const NavigationButton = ({ text, icon, selected, route, isResponsive }) => {
  return (
    <Link
      className={`${isResponsive ? styles.buttonResponsive : styles.button} ${
        selected === text ? styles.selected : ''
      }`}
      to={route}
    >
      <div
        className={
          isResponsive ? styles.imgContainerResponsive : styles.imgContainer
        }
      >
        <img src={icon} alt={text}></img>
      </div>
      {text}
    </Link>
  );
};

NavigationButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  selected: PropTypes.any,
  route: PropTypes.string,
  isResponsive: PropTypes.bool
};

export default NavigationButton;
