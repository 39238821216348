import { useTranslation } from 'react-i18next';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { PropTypes } from 'prop-types';

import { DeleteIcon, DownloadIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import { columns as columsConfiguration } from './tableConfiguration';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import { useDownloadFile } from 'hooks/useDownloadFile';

const OfferTable = ({
  loading,
  documents,
  rowCount,
  paginationModel,
  onDelete,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  addDocumentHandler,
  downloadDocumentation,
}) => {
  const { t } = useTranslation('documentation');
  const [userState] = useUserStore();
  const columns = columsConfiguration(t);

  const { downloadFile } = useDownloadFile(
    (...params) => downloadDocumentation(...params),
    undefined,
    t('errorDownloadFile'),
  );

  function getActions(params) {
    if ([AINA_MANAGER].includes(userState.role)) {
      return [
        <GridActionsCellItem
          key="delete"
          icon={<DeleteIcon />}
          label={t('delete')}
          onClick={() => onDelete(params.row)}
        />,

        <GridActionsCellItem
          key="download"
          icon={<DownloadIcon />}
          label={t('download')}
          onClick={() =>
            downloadFile(
              params.row.companyId,
              params.row.id,
              params.row.fileName,
            )
          }
        />,
      ];
    }
    return [
      <GridActionsCellItem
        key="download"
        icon={<DownloadIcon />}
        label={t('download')}
        onClick={() =>
          downloadFile(params.row.companyId, params.row.id, params.row.fileName)
        }
      />,
    ];
  }

  return (
    <CustomTable
      loading={loading}
      rows={documents ?? []}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      onRowClick={function () {
        return undefined;
      }}
      style={{ height: '300px' }}
      pageSizeOptions={[10, 20, 50, 100]}
      autoHeight={false}
      styleContainer={{ marginTop: 0 }}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actions'),
          flex: 0.15,
          minWidth: 150,
          getActions,
        },
      ]}
      addButtonText={t('createOffer')}
      addButtonOnClick={addDocumentHandler}
      onPaginationModelChange={(model) => onPaginationModelChange(model)}
      onSortModelChange={(model) => onSortModelChange(model)}
    />
  );
};

OfferTable.propTypes = {
  documents: PropTypes.array,
  rowCount: PropTypes.number,
  loading: PropTypes.bool,
  onPaginationModelChange: PropTypes.func,
  onDelete: PropTypes.func,
  onSortModelChange: PropTypes.func,
  downloadDocumentation: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
  addDocumentHandler: PropTypes.func,
};

export default OfferTable;
