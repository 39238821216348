import { useTranslation } from 'react-i18next';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useElementData } from 'hooks/useElementData';
import { useElementSubmit } from 'hooks/useElementSubmit';
import Loader from 'UI/atoms/Loader';
import { useEffect, useState } from 'react';
import getInsurer from 'services/insurers/getInsurer';
import getClient from 'services/clients/getClient';
import updateData from 'services/policies/updateData';
import PolicyDataForm from '../PolicyDataForm';
import getPolicyData from 'services/policies/getPolicyData';

const PolicyDataTab = () => {
  const [t] = useTranslation('policiesComponent');
  const [loadingInsurer, setLoadingInsurer] = useState(true);

  const [clientState] = useClientStore();
  const [value, setValue] = useState();
  const { data } = useElementData(clientState.clientId, getPolicyData);
  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateData,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  useEffect(() => {
    if (data) {
      retrieveData(data.insurerId, data.companyId);
    }
  }, [data]);

  async function retrieveData(insurerId, companyId) {
    setLoadingInsurer(true);
    const insurer = await getInsurer(insurerId);
    const client = await getClient(companyId);
    setValue({ ...data, insurerId: insurer, companyId: client });
    setLoadingInsurer(false);
  }

  if (loadingInsurer) {
    return <Loader text={t('loading')} />;
  }

  return <PolicyDataForm onSubmit={handleOnSubmit} defaultFormValues={value} />;
};

export default PolicyDataTab;
