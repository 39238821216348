import { useTranslation } from 'react-i18next';
import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import CustomTabs from 'UI/molecules/Tabs';
import { useNavigate } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import BigCalendar from 'components/blocks/BigCalendar';
import getCalendarProjects from 'services/projects/getCalendarProjects';
import Loader from 'UI/atoms/Loader';
import dayjs from 'dayjs';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const ProjectsCalendar = () => {
  const navigate = useNavigate();
  const [t] = useTranslation('projects');
  const [events, setEvents] = useState([]);
  const [currentDate, setCurrentDate] = useState();
  const [userState] = useUserStore();
  const [loading, setLoading] = useState(true);
  const tabOptions = [
    { label: t('list'), route: `/projects` },
    { label: t('calendar'), route: `/projects/calendar` },
  ];

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route);
  };

  useEffect(() => {
    retrieveProjects({
      fromDate: dayjs().startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs().endOf('month').format('YYYY-MM-DD'),
    });
  }, []);

  async function retrieveProjects(value) {
    setLoading(true);
    const data = await getCalendarProjects(
      value,
      [INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(userState.role),
    );
    setEvents(data);
    setLoading(false);
  }

  function handleOnNavigate(d) {
    setCurrentDate(d);
    retrieveProjects({
      fromDate: dayjs(d).startOf('month').format('YYYY-MM-DD'),
      toDate: dayjs(d).endOf('month').format('YYYY-MM-DD'),
    });
  }
  const { defaultDate } = useMemo(
    () => ({
      defaultDate: new Date(),
    }),
    [],
  );

  return (
    <PageContainer>
      <Header selected={t('title')}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[
            { path: '/', text: t('breadcrumbsHome') },
            { path: '/projects', text: t('title') },
          ]}
        >
          <CustomTabs tabs={tabOptions} selected={1} onChange={changeTab} />
        </SubHeader>
      </Header>
      <ContentContainer>
        {loading ? (
          <Loader text={t('loading')} />
        ) : (
          <BigCalendar
            currentDate={currentDate}
            defaultDate={defaultDate}
            events={events}
            onNavigate={(d) => handleOnNavigate(d)}
          />
        )}
      </ContentContainer>
    </PageContainer>
  );
};

export default ProjectsCalendar;
