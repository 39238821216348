import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FilterSecondLevelContainer = ({ children }) => (
  <div className={styles.container}>{children}</div>
);

FilterSecondLevelContainer.propTypes = {
  children: PropTypes.node,
};

export default FilterSecondLevelContainer;
