import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import CircularProgress from 'UI/atoms/CircularProgress';
import { UserIconComponent } from 'assets/icons/IconsLibrary';
import styles from './styles.module.scss';
import ContactMessageDialog from 'components/blocks/ContactMessageDialog';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ContactManagerButton = ({ extraClassName, loading }) => {
  const [openContactDialog, setOpenContactDialog] = useState(false);
  const [t] = useTranslation('dashboardHero');

  return (
    <>
      <Button
        endIcon={<UserIconComponent />}
        classes={{
          iconSizeMedium: styles.icon,
          root: `${styles.button} ${extraClassName ? styles[`button--${extraClassName}`] : ''}`,
        }}
        onClick={() => setOpenContactDialog(true)}
      >
        {loading ? (
          <CircularProgress size={24} customColor="white" />
        ) : (
          t('button.contact')
        )}
      </Button>
      <ContactMessageDialog
        onClose={() => setOpenContactDialog(false)}
        open={openContactDialog}
      />
    </>
  );
};

ContactManagerButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.node,
  extraClassName: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,

  onClick: PropTypes.func,
};

export default ContactManagerButton;
