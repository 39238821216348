import { useTranslation } from 'react-i18next';
import Loader from 'UI/atoms/Loader';
import updateProfileData from 'services/auth/updateProfileData';
import { useEffect, useState } from 'react';
import getProfileData from 'services/auth/getProfileData';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';
import ProfileDataForm from '../ProfileDataForm';

const ProfileDataTab = () => {
  const [t] = useTranslation('profileComponent');
  const [loading, setLoading] = useState(true);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    retrieveData();
  }, []);

  async function retrieveData() {
    setLoading(true);

    const res = await getProfileData();
    setFormData(res);
    setLoading(false);
  }

  async function handleOnSubmit(data) {
    const res = await updateProfileData(data);
    if (res.status === 200) {
      showSuccessNotification(t('dataUpdatedSuccessfully'));
    } else {
      showErrorNotification(t('error'));
    }
  }

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return (
    <ProfileDataForm defaultFormValues={formData} onSubmit={handleOnSubmit} />
  );
};

export default ProfileDataTab;
