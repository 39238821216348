import axios from 'axios';

export default async function deleteReceipt(receiptId) {
  try {
    const res = await axios.delete('/receipts/' + receiptId);

    return res;
  } catch (error) {
    return error.response;
  }
}
