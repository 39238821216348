// Pages
import home from './pages/home.json';
import login from './pages/login.json';
import clients from './pages/clients.json';
import policies from './pages/policies.json';
import projects from './pages/projects.json';
import insurers from './pages/insurers.json';
import receipts from './pages/receipts.json';
import credits from './pages/credits.json';
import dashboard from './pages/dashboard.json';
import error from './pages/error.json';
import modifyPassword from './pages/modifyPassword.json';
import renamePassword from './pages/renamePassword.json';
import profile from './pages/profile.json';

// Components
import contactMessageDialog from './components/blocks/contactMessageDialog.json';
import customTable from './components/blocks/customTable.json';
import branchTable from './components/blocks/branchTable.json';
import insurerCredits from './components/blocks/insurerCredits.json';
import dashboardHero from './components/blocks/dashboardHero.json';
import phasesHero from './components/blocks/phasesHero.json';
import userMenu from './components/blocks/userMenu.json';
import header from './components/blocks/header.json';
import subHeader from './components/blocks/subHeader.json';
import searchAndFilterContainer from './components/blocks/searchAndFilterContainer.json';
import documentation from './components/documentation/documentation.json';
import onboarding from './components/onboarding/onboarding.json';
import user from './components/user/user.json';
import insurersComponent from './components/insurers/insurers.json';
import receiptsComponent from './components/receipts/receipts.json';
import creditsComponent from './components/credits/credits.json';
import addCreditsDialog from './components/credits/addCreditsDialog.json';
import editCreditsPurchaseDialog from './components/credits/editCreditsPurchaseDialog.json';
import creditConsumptionsComponent from './components/creditConsumptions/creditConsumptions.json';
import accessProjectDialog from './components/projects/accessProjectDialog.json';
import projectsComponent from './components/projects/projects.json';
import policiesComponent from './components/policies/policies.json';
import createMovementDialog from './components/policies/createMovementDialog.json';
import profileComponent from './components/profile/profile.json';
import filters from './components/filters/filters.json';

const translation = {
  // Pages
  home,
  login,
  clients,
  projects,
  insurers,
  error,
  dashboard,
  modifyPassword,
  policies,
  profile,
  renamePassword,
  receipts,
  credits,

  // Components
  contactMessageDialog,
  customTable,
  branchTable,
  dashboardHero,
  phasesHero,
  insurerCredits,
  policiesComponent,
  createMovementDialog,
  profileComponent,
  onboarding,
  header,
  subHeader,
  searchAndFilterContainer,
  insurersComponent,
  receiptsComponent,
  creditsComponent,
  addCreditsDialog,
  editCreditsPurchaseDialog,
  accessProjectDialog,
  projectsComponent,
  creditConsumptionsComponent,
  documentation,
  user,
  userMenu,
  filters,
};

export default translation;
