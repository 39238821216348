import { useTranslation } from 'react-i18next';

import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import ContentContainer from 'UI/atoms/ContentContainer';
import DashboardHero from 'components/blocks/DashboardHero';

const Dashboard = () => {
  const [t] = useTranslation('dashboard');

  return (
    <PageContainer>
      <Header selected={''}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[{ path: '/', text: t('breadcrumbsHome') }]}
        >
          {/* chips={[{label: "Proyecto", icon: null}, {label: "Cliente", icon: null}]} */}
        </SubHeader>
      </Header>
      <ContentContainer>
        <DashboardHero />
      </ContentContainer>
    </PageContainer>
  );
};

export default Dashboard;
