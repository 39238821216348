import axios from 'axios';
import User from 'models/User';

async function getProfileData() {
  try {
    const { data } = await axios.get('/auth/me');

    return new User({
      id: data.id,
      name: data.name,
      surname: data.surname,
      email: data.emailAddress,
      role: data.role,
      roleId: data.roleLookupId,
      phone: data.telefono,
      position: data.cargo,
      password: data.password,
      credits: data.credits,
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

export default getProfileData;
