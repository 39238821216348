export const columns = (t) => [
  { field: 'id' },

  {
    field: 'name',
    headerName: t('socialReason'),
    minWidth: 200,
    flex: 1,
  },
  {
    field: 'availableCredits',
    headerName: t('availableCreditsHeader'),
    align: 'left',
    headerAlign: 'left',
    width: 200,
  },
  {
    field: 'ainaPolicies',
    headerName: t('insurances'),
    type: 'number',
    align: 'left',
    headerAlign: 'left',
    width: 175,
  },

  {
    field: 'onboarding',
    headerName: t('onboardingHeader'),
    align: 'left',
    headerAlign: 'left',
    type: 'boolean',
    width: 150,
  },
];
