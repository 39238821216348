import axios from 'axios';
import ClientOnboarding from 'models/ClientOnboardingData';

export default async function updateOnboardingData(clientId, data) {
  const client = new ClientOnboarding(data);

  const body = {
    id: client.id,
    nombrePersonaDeContacto: client.contactName,
    apellidosPersonaDeContacto: client.contactSurname,
    tfnPersonaDeContacto: client.phone,
    nombreApoderado: 'lorem',
    apellidosApoderado: 'lorem',
    dniApoderado: new Date(),
    fechaVencDNIApoderado: new Date(),
    aseguradoraEmail: client.email,
    onboarding: client.onboarding,
    anexoAINA: client.attachment,
  };

  try {
    const res = await axios.put('/companies/' + clientId + '/onboarding', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
