import axios from 'axios';
import User from 'models/User';

export default async function createUser(id, data) {
  const user = new User(data);

  const body = {
    emailAddress: user.email,
    name: user.name,
    surname: user.surname,
    roleLookupId: user.roleId,
    telefono: user.phone,
    cargo: user.position,
  };

  try {
    const res = await axios.post('/companies/' + id + '/users', body);

    return res;
  } catch (error) {
    return null;
  }
}
