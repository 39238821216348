import dayjs from 'dayjs';
import {
  AINA_MANAGER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

export const columns = (t, role) => {
  const columns = {
    id: { field: 'id' },
    project: {
      field: 'project',
      headerName: t('projectHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    company: {
      field: 'company',
      headerName: t('companyHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    insurer: {
      field: 'insurer',
      headerName: t('insurerHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    branch: {
      field: 'branch',
      headerName: t('branchHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
    date: {
      field: 'date',
      headerName: t('dateHeader'),
      minWidth: 175,
      valueGetter: (params) => {
        if (!params.value) {
          return '-';
        }
        return dayjs(params.value).format('DD/MM/YYYY');
      },
    },
    credits: {
      field: 'credits',
      headerName: t('creditsHeader'),
      align: 'left',
      headerAlign: 'left',
      flex: 1,
      minWidth: 150,
    },
  };

  switch (role) {
    case AINA_MANAGER:
      return [
        columns.id,
        columns.project,
        columns.company,
        columns.insurer,
        columns.branch,
        columns.date,
        columns.credits,
      ];
    case INSURER_MANAGER:
    case INSURER_SUPPORT:
    case INSURER_USER:
      return [
        columns.id,
        columns.project,
        columns.branch,
        columns.date,
        columns.credits,
      ];
    default:
      return [];
  }
};
