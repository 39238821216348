import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import TextField from '@mui/material/TextField';
import { StyledEngineProvider } from '@mui/material/styles';

import styles from './styles.module.scss';

const Input = ({
  // error,
  label,
  withValidation,
  name,
  type,
  styleContainer,
  shrinkLabel,
  color = 'default',
  ...props
}) => {
  const { control } = useFormContext();
  return (
    <StyledEngineProvider injectFirst>
      <div
        style={styleContainer}
        className={`${styles.container} ${styles[`container--${type}`]}`}
      >
        <Controller
          control={control}
          name={name}
          render={({ field, fieldState }) => (
            <TextField
              {...field}
              className={styles.input}
              variant="standard"
              label={label}
              error={!!(withValidation && fieldState.error)}
              helperText={withValidation ? fieldState.error?.message : ''}
              type={type}
              classes={{
                root: styles.textField,
              }}
              InputLabelProps={{
                shrink: shrinkLabel,
                classes: {
                  root: styles.label,
                  shrink: styles['label--shrink'],
                },
              }}
              FormHelperTextProps={{
                className: styles.formHelperText,
              }}
              {...props}
              InputProps={{
                classes: {
                  disabled: styles.disabled,
                  root: styles[`input--${color}`],
                },
                ...props.InputProps,
              }}
            />
          )}
        />
      </div>
    </StyledEngineProvider>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  withValidation: PropTypes.bool,
  shrinkLabel: PropTypes.bool,
  error: PropTypes.bool,
  styleContainer: PropTypes.object,
  InputProps: PropTypes.object,
  /**
   * Input type
   * @example 'email'
   * @example 'password'
   */
  type: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
};
export default Input;
