import { useTranslation } from 'react-i18next';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useElementData } from 'hooks/useElementData';
import Loader from 'UI/atoms/Loader';
import { useEffect, useState } from 'react';
import getInsurer from 'services/insurers/getInsurer';
import getClient from 'services/clients/getClient';
import ReceiptDataForm from '../ReceiptDataForm';
import getReceipt from 'services/receipts/getReceipt';
import updateData from 'services/receipts/updateData';
import getPolicy from 'services/policies/getPolicy';
import getReceiptDocument from 'services/receipts/getReceiptDocument';
import updateDocumentation from 'services/receipts/updateDocumentation';
import { useReceiptUpdate } from 'hooks/useReceiptUpdate';

const ReceiptDataTab = () => {
  const [t] = useTranslation('receiptsComponent');
  const [loadingData, setLoadingData] = useState(true);

  const [clientState] = useClientStore();
  const [value, setValue] = useState();
  const { loading, data } = useElementData(clientState.clientId, getReceipt);
  const { handleOnSubmit } = useReceiptUpdate(
    clientState.clientId,
    updateData,
    updateDocumentation,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  useEffect(() => {
    if (data) {
      retrieveData(data.insurerId, data.companyId, data.policyId, data.id);
    }
  }, [data]);

  async function retrieveData(insurerId, companyId, policyId, receiptId) {
    setLoadingData(true);
    const insurer = await getInsurer(insurerId);
    const client = await getClient(companyId);
    const policy = await getPolicy(policyId);
    const document = await getReceiptDocument(receiptId);
    setValue({
      ...data,
      fileName: document?.fileName,
      fileId: document?.id,
      insurerId: insurer,
      companyId: client,
      policyId: policy,
    });
    setLoadingData(false);
  }

  if (loading || loadingData) {
    return <Loader text={t('loading')} />;
  }

  return (
    <ReceiptDataForm onSubmit={handleOnSubmit} defaultFormValues={value} />
  );
};

export default ReceiptDataTab;
