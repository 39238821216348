import * as yup from 'yup';

const baseSchema = (t) =>
  yup.object({
    benchmarkFile: yup
      .mixed()
      .required(t('file'))
      .test('fileRequired', t('file'), (value) => {
        return value && value.length > 0;
      }),
    verified: yup.bool().required(t('mandatory', { field: t('verified') })),
  });

const insurerSchema = (t) =>
  yup.object({
    insurerId: yup.object().required(t('mandatory', { field: t('insurer') })),
  });

const validationSchema = (t, projectWinner) =>
  projectWinner ? insurerSchema(t) : baseSchema(t);

export default validationSchema;
