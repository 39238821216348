import dayjs from 'dayjs';

export const columns = (t) => [
  {
    field: 'documentType',
    headerName: t('fileType'),
    minWidth: 150,
    flex: 0.2,
    sortable: false,
  },
  {
    field: 'fileName',
    headerName: t('fileName'),
    minWidth: 200,
    flex: 0.4,
  },
  {
    field: 'companyName',
    headerName: t('insurer'),
    flex: 1,
    minWidth: 250,
    headerAlign: 'left',
    align: 'left',
  },
  {
    field: 'lastModificationDate',
    headerName: t('lastModifiedDate'),
    flex: 0.2,
    minWidth: 200,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return dayjs(params.value).format('DD/MM/YYYY');
    },
  },
];
