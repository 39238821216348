class Benchmark {
  constructor(params) {
    this.fileRef = params.fileRef;
    this.status = params.status;
    this.statusId = params.statusId;
    this.verified = params.verified;
    this.fileName = params.fileName;
  }
}

export default Benchmark;
