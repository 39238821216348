import axios from 'axios';
import ClientOnboarding from 'models/ClientOnboardingData';

export default async function getClientData(clientId) {
  const { data } = await axios
    .get('/clients/' + clientId + '/onboarding')
    .catch(() => new ClientOnboarding({}));

  if (typeof data === 'undefined') {
    return new ClientOnboarding({});
  }

  return new ClientOnboarding({
    id: data.id,
    contactName: data.nombrePersonaContacto,
    contactSurname: data.apellidosPersonaContacto,
    phone: data.telefono,
    email: data.emailAddress,
    representativeName: data.nombreApoderado,
    representativeSurname: data.apellidosApoderado,
    representativeNid: data.dniApoderado,
    representativeNidExpirationDate: data.fechaVencDNIApoderado,
    letterOfAppointment: data.cartaNombramiento,
    onboarding: data.onboarding,
  });
}
