import axios from 'axios';
import Project from 'models/Project';

export default async function createProject(data) {
  const project = new Project(data);

  const body = {
    codigodeproyecto: project.code,
    empresaLookupId: project.companyId,
    ramoLookupId: project.branchId,
    fechainicio: project.startDate,
    fechaRonda1: project.roundOneDate,
    fechaRonda2: project.roundTwoDate,
    fechaNuevaPoliza: project.newInsuranceDate,
    numTrabajadores: project.numWorkers,
    tramitacionIT: project.procedureIT,
    convenio: project.agreement,
    aseguradoraPolizaActualLookupId: project.currentInsurerId,
    polizaActualLookupId: project.currentPolicyId,
    polizaActual: project.currentPolicy,
    pbPreferencias: project.preferencesPb,
    primaPreferencias: project.preferencesPremium,
    adhesionesPreferencias: project.preferencesAdhesion,
    solvenciaPreferencias: project.preferencesSolvency,
    pbActual: project.currentPb,
    fechaVencimientoActual: project.expirationDate,
    gestorLookupId: project.managerId,
    primaActual: project.currentPremium,
    costeCreditos: project.credits,
  };

  try {
    const res = await axios.post('/projects', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
