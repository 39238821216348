import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { SELECT_CLIENT } from 'context/ClientProvider/constants';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';
import { useDecodedData } from 'hooks/useDecodedData';
import {
  RocketIconComponent,
  UserIconComponent,
} from 'assets/icons/IconsLibrary';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER, CLIENT_USER } from 'utils/constants';

const PolicyBasePage = ({ children, breadcrumbs, extraClass }) => {
  const [clientId, setClientId] = useState(null);
  const { pathname } = useLocation();
  const params = useParams();
  const [userState] = useUserStore();
  const [clientState, clientDispatch] = useClientStore();
  const navigate = useNavigate();

  const [clientData, setClientData] = useState({
    id: null,
    companyId: null,
    companyName: '',
    projectId: null,
    projectName: '',
    policyNum: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('policies');

  const tabOptions = () => {
    const defaultTabs = [
      {
        label: t('generalData'),
        route: `/policies/${clientId}`,
      },
      {
        label: t('documentation'),
        route: `/policies/${clientId}/documentation`,
      },
      {
        label: t('movements'),
        route: `/policies/${clientId}/movements`,
      },
      {
        label: t('policyReceipts'),
        route: `/policies/${clientId}/receipts/policy`,
      },
    ];
    if ([CLIENT_USER].includes(userState.role)) {
      return defaultTabs;
    }
    return [
      ...defaultTabs,
      {
        label: t('commissionReceipts'),
        route: `/policies/${clientId}/receipts/commission`,
      },
    ];
  };

  useEffect(() => {
    if (!clientId) return;
    setClientData(getDecodedData());
  }, [clientId]);

  useEffect(() => {
    const { clientId } = params;
    if (!clientId) {
      navigate(`/policies`);
      return;
    }
    setClientId(clientId);
    clientDispatch({ type: SELECT_CLIENT, payload: clientId });
  }, [params]);

  const changeTab = (selectedTab) => {
    const newTab = tabOptions()[selectedTab];
    navigate(newTab.route, { state: { clientId } });
  };

  function handleOnChipClick(chip) {
    const encodedData = btoa(
      JSON.stringify({ id: chip.id, ...chip.extraFields }),
    );

    navigate(`/${chip.entity}/${encodedData}`, {
      state: { clientId: encodedData },
    });
  }

  function getChips() {
    const chipClient = {
      id: clientData?.companyId,
      labelEntityName: clientData?.companyName,
      extraFields: { name: clientData?.companyName },
      entity: 'clients',
      label: t('client') + ': ',
      icon: <UserIconComponent />,
    };
    if (clientData?.projectId) {
      return [
        chipClient,
        {
          id: clientData?.projectId,
          labelEntityName: clientData?.projectName,
          extraFields: {
            code: clientData?.projectName,
            companyName: clientData?.companyName,
            companyId: clientData?.companyId,
          },
          entity: 'projects',
          label: t('project') + ': ',
          icon: <RocketIconComponent />,
        },
      ];
    }
    return [chipClient];
  }

  return (
    <PageContainer extraClass={extraClass}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={`${t('policy')} ${clientData.policyNum}`}
            onChipClick={handleOnChipClick}
            chips={[AINA_MANAGER].includes(userState.role) ? getChips() : null}
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            <CustomTabs
              tabs={tabOptions()}
              selected={clientState.tab}
              onChange={changeTab}
            />
          </SubHeader>
        </Header>
        {clientId ? <ContentContainer>{children}</ContentContainer> : null}
      </>
    </PageContainer>
  );
};

PolicyBasePage.propTypes = {
  extraClass: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default PolicyBasePage;
