import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';
import { useAppStore } from 'context/AppProvider/hooks';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';

const PoliciesFilter = ({ methods, insurers, loading, onInputChange }) => {
  const [t] = useTranslation('policiesComponent');
  const [appState] = useAppStore();
  const [userState] = useUserStore();

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={24} alignItems={'flex-end'}>
          <Form.Column xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Slider
              name="netPremium"
              step={1000}
              max={1000000}
              label={t('netPremium')}
            />
          </Form.Column>
          <Form.Column xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Slider
              name="totalPremium"
              step={1000}
              max={1000000}
              label={t('totalPremium')}
            />
          </Form.Column>

          <Form.Column xs={24} sm={24} md={8} lg={8} xl={8}>
            <Form.Slider name="pb" label={t('pb')} max={100} />
          </Form.Column>

          {/* <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Input name="policyNum" label={t('policyNum')} />
          </Form.Column> */}
        </Form.Row>

        <Form.Row columns={12} alignItems={'flex-end'} rowSpacing={2}>
          {[AINA_MANAGER].includes(userState.role) && (
            <>
              <Form.Column xs={12} sm={12} md={6} lg={6} xl={6}>
                <Form.Autocomplete
                  options={insurers}
                  filterOptions={(x) => x}
                  onInputChange={(e, newValue) => onInputChange(newValue)}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option.name ?? ''}
                  loading={loading}
                  name="insurerId"
                  label={t('insurer')}
                />
              </Form.Column>
            </>
          )}

          <Form.Column xs={12} sm={12} md={6} lg={6} xl={6}>
            <Form.Input name="cif" label={t('cif')} />
          </Form.Column>
          <Form.Column xs={12} sm={12} md={6} lg={4} xl={4}>
            <Form.Select
              options={appState.periodicity.map((period) => ({
                label: period.periodicity,
                value: period.id,
              }))}
              style={{ marginBottom: 0 }}
              name="periodicityId"
              label={t('periodicity')}
            />
          </Form.Column>
          <Form.Column xs={12} sm={6} md={6} lg={4} xl={4}>
            <Form.Date
              name="expirationDateFrom"
              label={t('expirationDateFrom')}
            />
          </Form.Column>
          <Form.Column xs={12} sm={6} md={6} lg={4} xl={4}>
            <Form.Date name="expirationDateTo" label={t('expirationDateTo')} />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

PoliciesFilter.propTypes = {
  methods: PropTypes.any,
  insurers: PropTypes.array,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default PoliciesFilter;
