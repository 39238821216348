import axios from 'axios';
import ClientDocumentation from 'models/ClientDocumentation';

export default async function getOffers(projectId, filter) {
  const params = new URLSearchParams();

  const pagination = {
    Start: filter.start,
    Length: filter.length,
  };

  const mappedFilters = {
    EstadoLookupId: filter?.statusId ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(
    fuseFilters(pagination, mappedFilters),
  )) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get(
      '/projects/' + projectId + '/offers',
      config,
    );

    return {
      resultList: data.resultList.map(
        (item) =>
          new ClientDocumentation({
            id: item.id,
            fileRef: item.fileLeafRef,
            companyName: item.aseguradora,
            companyId: item.aseguradoraLookupId,
            documentType: item.tipu,
            documentTypeId: item.tipuLookupId,
            verified: item.verificado,
            fileName: item.nombreDeArchivo,
            creationDate: item.createdDateTime,
            lastModificationDate: item.lastModifiedDateTime,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

function fuseFilters(pagination, filters) {
  if (pagination.Start !== undefined && pagination.Length !== undefined) {
    return { ...pagination, ...filters };
  } else {
    return { ...filters };
  }
}
