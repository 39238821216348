import { useTranslation } from 'react-i18next';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { EditIcon, DeleteIcon } from 'assets/icons/IconsLibrary';
import { PropTypes } from 'prop-types';
import { columns as columsConfiguration } from './tableConfiguration';
import CustomTable from '../CustomTable';

const BranchTable = ({ rows, loading, onEdit, onAddButtonClick, onDelete }) => {
  const [t] = useTranslation('branchTable');
  const columns = columsConfiguration(t);
  return (
    <CustomTable
      loading={loading}
      rows={rows}
      addButtonOnClick={onAddButtonClick}
      addButtonText={t('addBtn')}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actions'),
          flex: 0.15,
          minWidth: 150,
          getActions: (params) => [
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label={t('edit')}
              onClick={() => onEdit(params.row)}
            />,
            <GridActionsCellItem
              key="delete"
              icon={<DeleteIcon />}
              label={t('delete')}
              onClick={() => onDelete(params.row)}
            />,
          ],
        },
      ]}
      customPagination={<p>&nbsp;</p>}
      onRowClick={onEdit}
    />
  );
};

export default BranchTable;

BranchTable.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  loading: PropTypes.bool,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onAddButtonClick: PropTypes.func,
  title: PropTypes.string,
};
