import PropTypes from 'prop-types';

import styles from './styles.module.scss';
import { Select, MenuItem } from '@mui/material';
import { Controller } from 'react-hook-form';
import { CaretDownIcon } from 'assets/icons/IconsLibrary';

const SelectBtn = ({ loading, control, name, options }) => {
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <Select
              value={value}
              onChange={(event) => {
                onChange(event);
              }}
              variant="standard"
              IconComponent={CaretDownIcon}
              disableUnderline
              classes={{
                root: styles.root,
                icon: styles.icon,
                select: styles.selectContainer,
              }}
              //  onClick={onClick}
              disabled={loading}
            >
              {options.map((option) => (
                <MenuItem
                  disabled={option.disabled}
                  key={option.value}
                  value={option.value}
                  classes={{
                    root: styles.item,
                  }}
                >
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
        )}
      />
    </>
  );
};

SelectBtn.propTypes = {
  name: PropTypes.string,
  control: PropTypes.object,
  options: PropTypes.array,
  loading: PropTypes.bool,
};

export default SelectBtn;
