import axios from 'axios';
import Receipt from 'models/Receipt';

export default async function getReceipts(filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    TiposDeRecibosLookupId: filter?.typeId ?? '',
    Search: filter?.searchBarText ?? '',
    polizaLookupId: filter?.policyId ?? '',
    AseguradoraLookupId: filter?.insurerId ?? '',
    EmpresaLookupId: filter?.companyId ?? '',
    PeriodicidadLookupId: filter?.periodicityId ?? '',
    PrimaNetaStart: filter?.netPremiumStart ?? '',
    PrimaNetaEnd: filter?.netPremiumEnd ?? '',
    ramoLookupId: filter?.branchId ?? '',
    estadoLookupId: filter?.statusId ?? '',
    PrimaTotalStart: filter?.totalPremiumStart ?? '',
    PrimaTotalEnd: filter?.totalPremiumEnd ?? '',
    comisionStart: filter?.commissionStart ?? '',
    comisionEnd: filter?.commissionEnd ?? '',
    importeStart: filter?.totalAmountStart ?? '',
    importeEnd: filter?.totalAmountEnd ?? '',
    emisionStart: filter?.issueDateFrom ?? '',
    emisionEnd: filter?.issueDateTo ?? '',
    Start: filter?.start ?? '',
    Length: filter?.length ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    if (value !== '') {
      params.append(key, value);
    }
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/receipts', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new Receipt({
            id: item.id,
            receipt: item.recibo,
            insurer: item.aseguradora,
            insurerId: item.aseguradoraLookupId,
            policyId: item.polizaLookupId,
            policy: item.poliza,
            branch: item.ramo,
            branchId: item.ramoLookupId,
            companyName: item.empresaCliente,
            companyId: item.empresaClienteLookupId,
            type: item.tiposDeRecibos,
            typeId: item.tiposDeRecibosLookupId,
            issueDate: item.emision,
            expirationDate: item.vencimiento,
            totalAmount: item.importe,
            commission: item.comision,
            netPremium: item.primaNeta,
            totalPremium: item.primaTotal,
            periodicityId: item.periodicidadLookupId,
            periodicity: item.periodicidad,
            status: item.estado,
            statusId: item.estadoLookupId,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
