export const columns = (t) => [
  { field: 'id' },
  {
    field: 'name',
    headerName: t('user.contactName'),
    flex: 1,
    minWidth: 300,
    valueGetter: (params) => {
      return `${params.row.name || ''} ${params.row.surname || ''}`;
    },
  },
  {
    field: 'position',
    headerName: t('user.position'),
    align: 'left',
    headerAlign: 'left',
    flex: 1,
    minWidth: 300,
  },
  {
    field: 'email',
    headerName: t('contactEmail'),
    align: 'left',
    headerAlign: 'left',
    width: 250,
  },
  {
    field: 'phone',
    headerName: t('contactPhone'),
    align: 'left',
    headerAlign: 'left',
    width: 200,
  },
];
