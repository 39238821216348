import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import Form from 'UI/molecules/Form';
import { findElementByField } from 'utils/lookupIdMapper';
import { useAppStore } from 'context/AppProvider/hooks';
import downloadDocumentation from 'services/policies/downloadDocumentation';
import updateDocumentation from 'services/policies/updateDocumentation';
import createDocumentation from 'services/policies/createDocumentation';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import { useDownloadFile } from 'hooks/useDownloadFile';

const POLICY_DOCUMENT_ID = '4';

const DocumentationFileRow = ({ selectedDocument, companyId }) => {
  const [t] = useTranslation('documentation');
  const [appState] = useAppStore();
  const [userState] = useUserStore();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const { downloadFile } = useDownloadFile(
    () =>
      downloadDocumentation(selectedDocument?.companyId, selectedDocument?.id),
    selectedDocument?.fileName,
    t('errorDownloadFile'),
  );

  const formDefaultValues = {
    documentType: selectedDocument
      ? findElementByField(
          appState.documentTypes,
          'name',
          selectedDocument?.documentType,
        )
      : null,
    verified: selectedDocument?.verified ?? false,
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  async function handleUpload() {
    setLoading(true);
    let data = null;
    if (selectedDocument) {
      data = await updateDocumentation(
        companyId,
        methods.getValues('formFile')[0],
        {
          id: selectedDocument.id,
          verified: methods.getValues('verified'),
          documentTypeId: POLICY_DOCUMENT_ID,
        },
      );
    } else {
      data = await createDocumentation(
        companyId,
        methods.getValues('formFile')[0],
        {
          verified: methods.getValues('verified'),
          documentTypeId: POLICY_DOCUMENT_ID,
        },
      );
    }
    setLoading(false);
    if (data !== null) {
      showNotification(t('success'));
      return;
    }
    showErrorNotification(t('error'));
  }

  return (
    <FormProvider {...methods}>
      <Form onSubmit={methods.handleSubmit(handleUpload)}>
        <Form.Row
          rowSpacing={{ xs: 2 }}
          style={{ marginBottom: '40px' }}
          alignItems={{ xs: 'flex-end', md: 'flex-start' }}
        >
          <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.SectionTitle title={t('policyDocument')} />
          </Form.Column>

          <Form.Column xs={12} md={12} lg={12} xl={12}>
            <Form.InputFile
              name="formFile"
              disabled={![AINA_MANAGER].includes(userState.role)}
              fileName={selectedDocument?.fileName ?? t('noFile')}
              register={methods.register}
              id="formFileId"
              onDownloadIconClick={selectedDocument ? downloadFile : undefined}
            />
          </Form.Column>

          {[AINA_MANAGER].includes(userState.role) ? (
            <Form.Column xs={12} md={6}>
              <Form.Select
                options={[
                  { label: t('yes'), value: true },
                  { label: t('no'), value: false },
                ]}
                style={{ marginBottom: 0 }}
                extraClassName="no-margin-label"
                color="grey"
                name="verified"
                label={t('verified')}
              />
            </Form.Column>
          ) : null}
        </Form.Row>

        {[AINA_MANAGER].includes(userState.role) ? (
          <Form.Footer
            loading={loading}
            onClickSecondary={() => navigate('/policies')}
          />
        ) : null}
      </Form>
    </FormProvider>
  );
};

DocumentationFileRow.propTypes = {
  selectedDocument: PropTypes.object,
  companyId: PropTypes.string,
};

export default DocumentationFileRow;
