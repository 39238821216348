import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';

import { columns as columsConfiguration } from './tableConfiguration';

const CreditsTable = ({
  loading,
  receipts,
  rowCount,
  paginationModel,
  sortModel,
  onRowClick,
  onPaginationModelChange,
  onSortModelChange,
}) => {
  const { t } = useTranslation('creditsComponent');
  const columns = columsConfiguration(t);

  return (
    <CustomTable
      loading={loading}
      rows={receipts}
      rowCount={rowCount}
      onRowClick={onRowClick}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actionsHeader'),
          getActions: (params) => [
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label={t('edit')}
              onClick={() => {
                onRowClick(params.row);
              }}
            />,
          ],
        },
      ]}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
    />
  );
};

CreditsTable.propTypes = {
  loading: PropTypes.bool,
  receipts: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
  navigateTo: PropTypes.string,
};

export default CreditsTable;
