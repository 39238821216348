import { useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Alert, Grid } from '@mui/material';

import { useUserStore } from 'context/UserProvider/hooks';
import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Title from 'UI/atoms/Title';
import CreditsCost from 'UI/atoms/CreditsCost';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';
import requestAccess from 'services/projects/requestAccess';

import styles from './styles.module.scss';
import { UPDATE_CREDITS } from 'context/UserProvider/constants';

const AccessProjectDialog = ({
  open,
  onClose,
  project,
  handleAccessAccepted,
  ...props
}) => {
  const [t] = useTranslation('accessProjectDialog');
  const [userState, userDispatch] = useUserStore();
  const [loading, setLoading] = useState(false);

  const handleOnClose = () => {
    onClose();
  };

  async function handleRequestAccess() {
    setLoading(true);
    const result = await requestAccess(project.id);

    if (result.status === 200) {
      showNotification(t('success'));
      userDispatch({
        type: UPDATE_CREDITS,
        payload: result.data.availableCredits,
      });
      handleAccessAccepted();
      handleOnClose();
      return;
    }
    showErrorNotification(t('error'));
    setLoading(false);
  }

  if (!project) return <></>;

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <div className={styles.container}>
          <Title size="medium">{t('title')}</Title>
          <Grid container rowGap={2} justifyContent="space-between">
            <Grid item xs={12}>
              <CreditsCost>
                {t('creditsCost', { credits: project.credits })}
              </CreditsCost>
            </Grid>

            {parseInt(userState.credits, 10) <
              parseInt(project.credits, 10) && (
              <Grid item xs={12}>
                <Alert severity="error">{t('notEnoughCredits')}</Alert>
              </Grid>
            )}
            {parseInt(userState.credits, 10) >=
              parseInt(project.credits, 10) && (
              <Grid item xs={12} md={6} marginTop={{ md: 3, lg: 3 }}>
                <PrimaryBtn
                  loading={loading}
                  text={t('confirm')}
                  onClick={handleRequestAccess}
                />
              </Grid>
            )}
            <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 3 }}>
              <SecondaryBtn
                style={{ margin: 0 }}
                text={t('cancelAction')}
                onClick={onClose}
              />
            </Grid>
          </Grid>
        </div>
      </Dialog>
    </>
  );
};

AccessProjectDialog.propTypes = {
  open: PropTypes.bool,
  handleAccessAccepted: PropTypes.func,
  onClose: PropTypes.func,
  project: PropTypes.object,
};

export default AccessProjectDialog;
