import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import QuoteBlock from 'components/home/QuoteBlock';

import styles from './styles.module.scss';

import ImgBlock1 from 'assets/images/imgblock-1.jpg';
import ImgBlock1XS from 'assets/images/imgblock-2-xs.jpg';
import ImgBlock2 from 'assets/images/imgblock-2.png';
import ImgBlock3 from 'assets/images/imgblock-3.jpg';

const ImageTextBlock = ({ blockNumber }) => {
  const [t] = useTranslation('home');

  if (blockNumber === 1) {
    return (
      <>
        <div className={styles.imageTextBlock}>
          <div id="rules" className={styles.anchor}></div>
          <div className={styles.container}>
            <div className={`${styles.content} ${styles.reverse}`}>
              <div className={styles.infoWrapper}>
                <div className={`${styles.info} wow fadeInRight`}>
                  <p className={styles.title}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('imageTextBlock.title1'),
                      }}
                    ></span>
                  </p>
                  <div className={styles.text}>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('imageTextBlock.text1'),
                        }}
                      ></span>
                    </p>
                  </div>

                  <QuoteBlock blockNumber={2} />
                </div>
              </div>

              <div className={`${styles.image} wow fadeInLeft`}>
                <img src={ImgBlock1} width="845" className={styles.imageXL} />
                <img src={ImgBlock1XS} width="750" className={styles.imageXS} />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (blockNumber === 2) {
    return (
      <>
        <div className={styles.imageTextBlock}>
          <div id="benefits" className={styles.anchor}></div>
          <div className={styles.container}>
            <div className={`${styles.content} ${styles.reverse}`}>
              <div className={styles.infoWrapper}>
                <div className={`${styles.info} wow fadeInRight`}>
                  <p className={styles.title}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('imageTextBlock.title2'),
                      }}
                    ></span>
                  </p>
                  <div className={styles.text}>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('imageTextBlock.text2'),
                        }}
                      ></span>
                    </p>
                  </div>
                  <QuoteBlock blockNumber={3} />
                </div>
              </div>

              <div className={`${styles.image} wow fadeInLeft`}>
                <img src={ImgBlock2} width="845" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  if (blockNumber === 3) {
    return (
      <>
        <div className={styles.imageTextBlock}>
          <div className={styles.container}>
            <div className={styles.content}>
              <div className={styles.infoWrapper}>
                <div className={`${styles.info} wow fadeInLeft`}>
                  <p className={styles.title}>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: t('imageTextBlock.title3'),
                      }}
                    ></span>
                  </p>
                  <div className={styles.text}>
                    <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: t('imageTextBlock.text3'),
                        }}
                      ></span>
                    </p>
                  </div>
                  <QuoteBlock blockNumber={4} />
                </div>
              </div>

              <div className={`${styles.image} wow fadeInRight`}>
                <img src={ImgBlock3} width="845" />
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

ImageTextBlock.propTypes = {
  blockNumber: PropTypes.number,
};

export default ImageTextBlock;
