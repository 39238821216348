import axios from 'axios';
import CreditConsumption from 'models/CreditConsumption';

export default async function getCreditConsumptions(filter) {
  const params = new URLSearchParams();

  const mappedFilters = {
    AseguradoraLookupId: filter?.insurerId ?? '',
    Start: filter?.start ?? '',
    Length: filter?.length ?? '',
    Sortcolumn: filter?.sortColumn ?? '',
    Sortorder: filter?.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/credits/consumption/history', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new CreditConsumption({
            id: item.id,
            project: item.proyecto,
            projectId: item.proyectoLookupId,
            company: item.empresa,
            companyId: item.empresaLookupId,
            cif: item.empresa_x003a__x0020_CIF,
            insurerId: item.aseguradoraLookupId,
            insurer: item.aseguradora,
            branch: item.ramo,
            branchId: item.ramoLookupId,
            date: item.consumo,
            credits: item.nodecreditos,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
