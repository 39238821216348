import axios from 'axios';

async function postUpdatePassword(body) {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const objectData = {
    ...body,
    email: urlParams.get('email'),
    code: urlParams.get('code'),
  };

  try {
    const res = await axios.post('/auth/resetpassword', objectData);
    return res;
  } catch (error) {
    // return error.response.data.statusMessage;
    return null;
  }
}

export default postUpdatePassword;
