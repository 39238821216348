import axios from 'axios';
import Client from 'models/Client';

export default async function createClient(data) {
  const client = new Client(data);

  const body = {
    cif: client.cif,
    razonSocial: client.name,
    marcaComercial: client.trademark,
    cnaeLookupId: client.cnaeId,
    sicLookupId: client.sicId,
    numTrabajadores: client.numWorkers,
    managerId: data.gestorLookupId,
    gestorLookupId: client.managerId,
    edadMedia: client.averageAge,
    direccion: client.address,
    provinciaLookupId: client.provinceId,
    codigoPostal: client.zipCode,
    preferenciaContacto: client.preferredContact,
    dominio: client.domain,
    observaciones: client.notes,
    ciudad: client.city,
  };

  try {
    const res = await axios.post('/clients', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
