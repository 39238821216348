import { toast } from 'react-toastify';
import ToastNotification from 'UI/atoms/ToastNotification';

export const showNotification = (
  message,
  action,
  actionMessage,
  toastId,
  position,
) => {
  ToastNotification({
    type: 'info',
    message,
    action,
    actionMessage,
    autoClose: 3000,
    toastId,
    position,
  });
};

export const showSuccessNotification = (
  message,
  action,
  actionMessage,
  toastId,
) => {
  ToastNotification({
    type: 'success',
    message,
    action,
    actionMessage,
    autoClose: 3000,
    toastId,
  });
};

export const showOnlyThisNotification = (message, action, actionMessage) => {
  ToastNotification({
    type: 'error',
    message,
    action,
    toastId: 'uniqueInfoWarning',
    actionMessage,
    autoClose: 3000,
  });
};

export const showErrorNotification = (message, action, actionMessage) => {
  toast.dismiss();
  ToastNotification({
    type: 'error',
    message,
    action,
    actionMessage,
    autoClose: 3000,
  });
};
