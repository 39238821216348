import { useState } from 'react';
import { useTable } from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import { creditsPurchaseMapper } from 'utils/columnsMapper';
import { useNavigate } from 'react-router-dom';
import getCredits from 'services/credits/getCredits';
import CreditsTable from '../CreditsTable';
import CreditsSearch from '../CreditsSearch';
import EditCreditsPurchaseDialog from '../EditCreditsPurchaseDialog';

const CreditsTableAndSearch = () => {
  const [t] = useTranslation('credits');
  const navigate = useNavigate();
  const [credit, setCredit] = useState(null);

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    refreshRows,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
  } = useTable(
    getCredits,
    t('error'),
    false,
    undefined,
    undefined,
    creditsPurchaseMapper,
  );

  return (
    <>
      <CreditsSearch onSearch={handleOnSearch} />
      <CreditsTable
        receipts={rows}
        rowCount={rowCount}
        loading={loading}
        onRowClick={(row) => setCredit(row)}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addButtonOnClick={() => navigate('/receipts/policy/create')}
      />
      <EditCreditsPurchaseDialog
        open={credit !== null}
        creditPurchase={credit}
        refreshRows={refreshRows}
        onClose={() => setCredit(null)}
      />
    </>
  );
};

export default CreditsTableAndSearch;
