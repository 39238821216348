import axios from 'axios';

export default async function updateProjectWinner(projectId, insurerId) {
  const body = {
    AseguradoraGanaradoraLookupId: insurerId,
  };

  try {
    const res = await axios.put('/projects/' + projectId + '/end', body);
    return res;
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
