import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useClientStore } from 'context/ClientProvider/hooks';
import Title from 'UI/atoms/Title';
import Loader from 'UI/atoms/Loader';
import { useDecodedData } from 'hooks/useDecodedData';
import getDocuments from 'services/policies/getDocuments';

import DocumentationFileRow from '../DocumentationFileRow';
import PolicyDocumentationTable from '../PolicyDocumentationTable';

const POLICY_DOCUMENT_ID = '4';

const PolicyDocumentation = () => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [loading, setLoading] = useState(true);

  const { getDecodedData } = useDecodedData(clientState.clientId);

  useEffect(() => {
    if (clientState.clientId) {
      retrievePolicyDocument();
    }
  }, [clientState.clientId]);

  async function retrievePolicyDocument() {
    setLoading(true);
    const retrievedDocuments = await getDocuments(getDecodedData().id, {
      documentTypeId: POLICY_DOCUMENT_ID,
      start: 0,
      length: 10,
    });

    setSelectedDocument(
      retrievedDocuments.resultList.find(
        (row) => row.documentTypeId === POLICY_DOCUMENT_ID,
      ),
    );
    setLoading(false);
  }

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return (
    <>
      <Title size="medium">{t('title')}</Title>

      {!loading ? (
        <DocumentationFileRow
          companyId={getDecodedData().id}
          selectedDocument={selectedDocument}
        />
      ) : null}

      <PolicyDocumentationTable
        documentTypeId={5}
        documentTypeName={t('certificates')}
      />
    </>
  );
};

export default PolicyDocumentation;
