import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { IconButton } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import { AvatarIcon } from 'assets/icons/IconsLibrary';

import styles from './styles.module.scss';
import ConfirmationDialog from '../ConfirmationDialog';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'utils/showToastNotifications';
import { useCookies } from 'react-cookie';

const UserMenu = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [loading, setLoading] = useState(false);
  const open = Boolean(anchorEl);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [t] = useTranslation('userMenu');
  const [, , removeCookie] = useCookies();

  const navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onClose = () => {
    setAnchorEl(null);
  };

  const logout = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      onClose();
      removeCookie('token', { path: '/' });
      removeCookie('userData', { path: '/' });
      navigate('/login');
      showNotification(t('loggedOutSuccessfully'));
    }, 1500);
  };

  return (
    <>
      <IconButton onClick={handleClick} className={styles.button}>
        <AvatarIcon />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={onClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
          classes: {
            root: styles.list,
          },
        }}
      >
        <Link to={'/profile'}>
          <MenuItem onClick={onClose}>Mi Cuenta</MenuItem>
        </Link>
        <MenuItem onClick={() => setOpenConfirmationDialog(true)}>
          Cerrar Sesión
        </MenuItem>
      </Menu>

      <ConfirmationDialog
        loading={loading}
        isDeleting={false}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={logout}
        onClose={() => setOpenConfirmationDialog(false)}
      />
    </>
  );
};

export default UserMenu;
