import { useTranslation } from 'react-i18next';

import Title from 'UI/atoms/Title';
import PolicyDocumentationTable from '../PolicyDocumentationTable';

const PolicyMovements = () => {
  const [t] = useTranslation('documentation');

  return (
    <>
      <Title size="medium">{t('movements')}</Title>
      <PolicyDocumentationTable
        documentTypeId={22}
        documentTypeName={'Altas'}
      />
      <PolicyDocumentationTable
        documentTypeId={23}
        documentTypeName={'Bajas'}
      />
    </>
  );
};

export default PolicyMovements;
