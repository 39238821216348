import { I18nextProvider } from 'react-i18next';

import Home from 'pages/home';
import i18n from 'translations/i18n';

function App() {
  return <I18nextProvider i18n={i18n}>{<Home />}</I18nextProvider>;
}

export default App;
