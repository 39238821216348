import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      name: yup.string().required(t('mandatory', { field: t('name') })),
      surname: yup.string().required(t('mandatory', { field: t('surname') })),
      position: yup
        .string()
        .required(t('mandatory', { field: t('user.position') })),
      email: yup
        .string()
        .email(t('invalidFormat', { field: t('contactEmail') }))
        .required(t('mandatory', { field: t('contactEmail') })),
      phone: yup
        .number()
        .typeError(t('invalidFormat', { field: t('contactPhone') }))
        .positive(t('positive', { field: t('contactPhone') }))
        .required(t('mandatory', { field: t('contactPhone') })),
    })
    .required();

export default validationSchema;
