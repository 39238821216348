import { useState } from 'react';
import { Calendar, Navigate, dayjsLocalizer } from 'react-big-calendar';
import { PropTypes } from 'prop-types';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/es';
import { cutString } from 'utils/miscellaneous';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import ProjectDialog from 'components/project/ProjectDialog';

const djLocalizer = dayjsLocalizer(dayjs);

const CustomEvent = ({ event, eventNames }) => {
  const [t] = useTranslation('projectsComponent');
  const [userState] = useUserStore();

  function retrieveText(name) {
    switch (name) {
      case 'roundOne':
        return t('projectStatus1');
      case 'roundTwo':
        return t('projectStatus2');
      case 'startDate':
        return t('startDate');
      case 'expirationPolicy':
      case 'expirationDate':
        return t('expirationPolicyShort');
      default:
        return '';
    }
  }

  function retrieveCircleColor(name) {
    switch (name) {
      case 'roundOne':
        return <span className={styles.circleBlue}></span>;
      case 'roundTwo':
        return <span className={styles.circleYellow}></span>;
      case 'startDate':
        return <span className={styles.circleBlueGreen}></span>;
      case 'expirationPolicy':
      case 'expirationDate':
        return <span className={styles.circleRed}></span>;
      default:
        return '';
    }
  }

  return (
    <>
      {eventNames.includes(event.name) ? (
        <div className={styles.eventContainer}>
          {retrieveCircleColor(event.name)}

          <div>
            {[AINA_MANAGER].includes(userState.role) ? (
              <div className={styles.companyName}>
                {cutString(event.companyName, 10)}
              </div>
            ) : null}
            <div className={styles.code}>{cutString(event.code, 10)}</div>
            <div className={styles.status}>{retrieveText(event.name)}</div>
          </div>
        </div>
      ) : null}
    </>
  );
};

const customDayPropGetter = (date) => {
  return { className: styles.day };
};

const CustomToolbar = ({ onNavigate, label, eventNames, setEventNames }) => {
  const [t] = useTranslation('projectsComponent');
  const [userState] = useUserStore();

  function toggleEventName(eventName) {
    if (eventNames.includes(eventName)) {
      setEventNames(eventNames.filter((name) => name !== eventName));
    } else {
      setEventNames((oldArray) => {
        return [...oldArray, eventName];
      });
    }
  }
  return (
    <div className={styles.toolbar}>
      <div className={styles.navButtons}>
        <div className={styles.beforeNextContainer}>
          <button
            className={styles.button}
            onClick={() => onNavigate(Navigate.PREVIOUS)}
          >
            {'<'}
          </button>
          <button
            className={styles.button}
            onClick={() => onNavigate(Navigate.NEXT)}
          >
            {'>'}
          </button>
        </div>
        <button
          className={styles.buttonToday}
          onClick={() => onNavigate(Navigate.TODAY)}
        >
          {t('today')}
        </button>
      </div>
      <h5 className={styles.label}>{label}</h5>
      {[INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(
        userState.role,
      ) ? (
        <div className={styles.legendContainer}>
          <button
            className={`${styles.legend} ${!eventNames.includes('startDate') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('startDate')}
          >
            <span className={styles.circleBlueGreen}></span>
            {t('startDate')}
          </button>
          <button
            className={`${styles.legend} ${!eventNames.includes('expirationDate') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('expirationDate')}
          >
            <span className={styles.circleRed}></span>
            {t('expirationPolicyShort')}
          </button>
        </div>
      ) : (
        <div className={styles.legendContainer}>
          <button
            className={`${styles.legend} ${!eventNames.includes('startDate') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('startDate')}
          >
            <span className={styles.circleBlueGreen}></span>
            {t('startDate')}
          </button>
          <button
            className={`${styles.legend} ${!eventNames.includes('roundOne') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('roundOne')}
          >
            <span className={styles.circleBlue}></span>
            {t('projectStatus1')}
          </button>
          <button
            className={`${styles.legend} ${!eventNames.includes('roundTwo') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('roundTwo')}
          >
            <span className={styles.circleYellow}></span>
            {t('projectStatus2')}
          </button>
          <button
            className={`${styles.legend} ${!eventNames.includes('expirationPolicy') ? styles['legend--hidden'] : ''}`}
            onClick={() => toggleEventName('expirationPolicy')}
          >
            <span className={styles.circleRed}></span>
            {t('expirationPolicyShort')}
          </button>
        </div>
      )}

      {/* <button onClick={() => props.onView(Views.MONTH)}>Mont</button>
      <button onClick={() => props.onView(Views.WEEK)}>W</button>
      <button onClick={() => props.onView(Views.DAY)}>Day</button> */}
    </div>
  );
};

const BigCalendar = ({ defaultDate, events, onNavigate, currentDate }) => {
  const [openModal, setOpenModal] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventNames, setEventNames] = useState([
    'startDate',
    'roundOne',
    'roundTwo',
    'expirationDate',
    'expirationPolicy',
  ]);

  function handleOnSelectEvent(event) {
    setSelectedEvent(event);
    setOpenModal(true);
  }

  return (
    <div className={styles.bigCalendar}>
      <Calendar
        step={60}
        date={currentDate ?? defaultDate}
        components={{
          event: (props) => <CustomEvent {...props} eventNames={eventNames} />,
          toolbar: (props) => (
            <CustomToolbar
              {...props}
              eventNames={eventNames}
              setEventNames={setEventNames}
            />
          ),
        }}
        events={events.filter((event) => eventNames.includes(event.name))}
        onNavigate={(d) => onNavigate(d)}
        defaultDate={defaultDate}
        eventPropGetter={() => {
          return { className: styles.event };
        }}
        culture="es"
        showAllEvents
        dayPropGetter={customDayPropGetter}
        localizer={djLocalizer}
        onSelectEvent={(element, event) => handleOnSelectEvent(element)}
      />
      <ProjectDialog
        open={openModal}
        selectedProject={selectedEvent}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
};

export default BigCalendar;

BigCalendar.propTypes = {
  currentDate: PropTypes.object,
  defaultDate: PropTypes.object,
  onNavigate: PropTypes.func,
  events: PropTypes.array,
};

CustomToolbar.propTypes = {
  onNavigate: PropTypes.func,
  setEventNames: PropTypes.func,
  label: PropTypes.string,
  eventNames: PropTypes.array,
};

CustomEvent.propTypes = {
  event: PropTypes.object,
  eventNames: PropTypes.array,
};
