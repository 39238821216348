import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { SELECT_CLIENT } from 'context/ClientProvider/constants';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';

const ClientBasePage = ({ breadcrumbs, children, extraClass, clientName }) => {
  const [clientId, setClientId] = useState(null);
  const { pathname } = useLocation();
  const params = useParams();
  const [clientState, clientDispatch] = useClientStore();
  const [t] = useTranslation('clients');
  const navigate = useNavigate();

  const tabOptions = [
    { label: t('generalData'), route: `/clients/${clientId}` },
    { label: t('users'), route: `/clients/${clientId}/users` },
    { label: t('onboarding'), route: `/clients/${clientId}/onboarding` },
    { label: t('documentation'), route: `/clients/${clientId}/documentation` },
    { label: t('projects'), route: `/clients/${clientId}/projects` },
    { label: t('policies'), route: `/clients/${clientId}/policies` },
    {
      label: t('policyReceipts'),
      route: `/clients/${clientId}/receipts/policy`,
    },
    {
      label: t('commissionReceipts'),
      route: `/clients/${clientId}/receipts/commission`,
    },
  ];

  useEffect(() => {
    const { clientId } = params;
    if (!clientId) {
      navigate(`/clients`);
      return;
    }
    setClientId(clientId);
    clientDispatch({ type: SELECT_CLIENT, payload: clientId });
  }, [params]);

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route, { state: { clientId } });
  };

  return (
    <PageContainer extraClass={extraClass}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={clientName}
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            <CustomTabs
              tabs={tabOptions}
              selected={clientState.tab}
              onChange={changeTab}
            />
          </SubHeader>
        </Header>
        {clientId ? <ContentContainer>{children}</ContentContainer> : null}
      </>
    </PageContainer>
  );
};

ClientBasePage.propTypes = {
  extraClass: PropTypes.string,
  clientName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default ClientBasePage;
