import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { useAppStore } from 'context/AppProvider/hooks';
import { yupResolver } from '@hookform/resolvers/yup';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import { findCCAA, findElementByLookupId } from 'utils/lookupIdMapper';
import { usePrevious } from 'hooks/usePreviousFormValue';
import SectorTable from 'components/blocks/SectorTable';
import ProvincesFilter from 'components/filters/ProvincesFilter';
import { PropTypes } from 'prop-types';
import BranchTable from 'components/blocks/BranchTable';
import BranchDialog from '../BranchDialog';
import { AINA_MANAGER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';

const InsurerDataForm = ({ defaultFormValues, onSubmit }) => {
  const { t } = useTranslation('insurersComponent');
  const [appState] = useAppStore();
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const schema = validationSchema(t);

  const [userState] = useUserStore();
  const navigate = useNavigate();
  const geographicList = appState.geographicData;
  const sectors = appState.sectors;

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      preferredSectors: defaultFormValues.preferredSectors.map((e) =>
        e.id.toString(),
      ),
      ccaaId: findCCAA(
        geographicList.provinces,
        geographicList.ccaa,
        defaultFormValues?.provinceId,
      ),
      provinceId: findElementByLookupId(
        geographicList.provinces,
        defaultFormValues?.provinceId,
      ),
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const watchCCAA = watch('ccaaId');
  const branches = watch('branches');
  const prevWatchCCAA = usePrevious(watchCCAA);

  const filteredBranches = appState.branches
    .filter(
      (branch) =>
        !branches.some(
          (currentBranch) =>
            currentBranch !== selectedBranch &&
            currentBranch.id === branch.id &&
            currentBranch.preference !== 'Neutral',
        ),
    )
    .filter((branch) => !branch.ainaBranch);

  useEffect(() => {
    if (prevWatchCCAA && prevWatchCCAA?.code !== watchCCAA?.code) {
      setValue('provinceId', null);
    }
  }, [watchCCAA, prevWatchCCAA, setValue]);

  const handleOnSubmit = async (data) => {
    setSubmitting(true);
    await onSubmit({
      ...methods.getValues(),
      accessAccidents: data.accessAccidents,
      accessHealth: data.accessHealth,
      accessLife: data.accessLife,
      accidents: data.accidents,
      life: data.life,
      health: data.health,
      provinceId: data.provinceId.id,
    });
    setSubmitting(false);
  };
  function handleOnEdit(row) {
    setSelectedBranch(row);
    setOpenDialog(true);
  }
  function handleOpenDialog() {
    setSelectedBranch(null);
    setOpenDialog(true);
  }

  function handleOnClose() {
    setOpenDialog(false);
    setSelectedBranch(null);
  }
  function handleOnDelete(row) {
    setValue(
      'branches',
      methods.getValues('branches').map((branch) => {
        if (branch.id === row.id)
          return { ...branch, preference: 'Neutral', commission: 0 };
        return branch;
      }),
    );
  }

  function handleOnAdd(row) {
    setValue(
      'branches',
      methods.getValues('branches').map((branch) => {
        if (branch.id === row.id) return row;
        return branch;
      }),
    );
    setOpenDialog(false);
  }

  return (
    <Form.Container>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 3 }}>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <Form.Input name="cif" label={t('cif')} />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <Form.Input name="name" label={t('socialReason')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="trademark" label={t('commercialBrand')} />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <Form.Input name="email" label={t('contactEmail')} />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <Form.Input
                name="phone"
                label={t('contactPhone')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 3 }}>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="address" label={t('address')} />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <Form.Autocomplete
                label={t('ccaa')}
                options={geographicList.ccaa}
                getOptionLabel={(option) => `${option.name}`}
                name="ccaaId"
              />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={4}>
              <ProvincesFilter
                fieldName="provinceId"
                selectedCCAA={watchCCAA}
              />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={3}>
              <Form.Input
                name="zipCode"
                label={t('zipCode')}
                containerSize="medium"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={3}>
              <Form.Input
                name="insurerURL"
                label={t('insurerURL')}
                containerSize="medium"
              />
            </Form.Column>
            <Form.Column sm={12} md={8} lg={6} xl={3}>
              <Form.Input
                name="solvency"
                label={t('solvency')}
                containerSize="medium"
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          {[AINA_MANAGER].includes(userState.role) ? (
            <>
              <Form.Row rowSpacing={{ xs: 1 }}>
                <Form.Column
                  xs={24}
                  sm={24}
                  md={24}
                  lg={24}
                  xl={24}
                  extraClassName="no-margin-bottom"
                >
                  <Form.Title size="medium">
                    {t('economicConditions')}
                  </Form.Title>
                </Form.Column>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={t('commissionTraditionalBranch')} />
                </Form.Column>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <BranchTable
                    onAddButtonClick={
                      branches.filter(
                        (branch) => branch.preference === 'Neutral',
                      ).length > 0
                        ? handleOpenDialog
                        : undefined
                    }
                    rows={branches.filter(
                      (branch) => branch.preference !== 'Neutral',
                    )}
                    onDelete={handleOnDelete}
                    onEdit={handleOnEdit}
                  />
                </Form.Column>
              </Form.Row>
              <Form.Row rowSpacing={{ xs: 1 }} paddingTop={1}>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={t('commissionAinaBranch')} />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="life"
                    label={t('life')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="accidents"
                    label={t('accidents')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="health"
                    label={t('health')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
              </Form.Row>
              <Form.Row rowSpacing={{ xs: 1 }}>
                <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                  <Form.SectionTitle title={t('commissionAccessBranch')} />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="accessLife"
                    label={t('life')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="accessAccidents"
                    label={t('accidents')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
                <Form.Column xs={24} sm={12} md={8} lg={4} xl={2}>
                  <Form.Input
                    color="grey"
                    name="accessHealth"
                    label={t('health')}
                    inputProps={{ type: 'number' }}
                  />
                </Form.Column>
              </Form.Row>
            </>
          ) : null}

          <Form.Row rowSpacing={{ xs: 2 }}>
            <Form.Column
              xs={24}
              sm={24}
              md={24}
              lg={24}
              xl={24}
              extraClassName="no-margin-bottom"
            >
              <Form.Title size="medium">{t('preferences')}</Form.Title>
            </Form.Column>
            <Form.Column xs={24} sm={12} md={12} lg={5} xl={5}>
              <Form.Input
                color="grey"
                name="averageAge"
                label={t('averageAge')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={12} lg={5} xl={5}>
              <Form.Select
                options={[
                  { label: t('yes'), value: true },
                  { label: t('no'), value: false },
                ]}
                color="grey"
                name="internacionalEspecialization"
                label={t('internacionalEspecialization')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <SectorTable
                title={t('preferredSectors')}
                rows={sectors}
                onRowSelectionModelChange={(value) =>
                  methods.setValue('preferredSectors', value)
                }
                selectedRows={methods.getValues('preferredSectors')}
              />
            </Form.Column>
          </Form.Row>

          {[AINA_MANAGER].includes(userState.role) ? (
            <Form.Footer
              onClickSecondary={() => navigate('/insurers')}
              loading={submitting}
            />
          ) : null}
        </Form>
        {openDialog ? (
          <BranchDialog
            onAdd={handleOnAdd}
            open={openDialog}
            onClose={handleOnClose}
            selectedBranch={selectedBranch}
            currentBranches={filteredBranches}
          />
        ) : null}
      </FormProvider>
    </Form.Container>
  );
};

export default InsurerDataForm;

InsurerDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};
