class AccessCode {
  constructor(params) {
    this.id = params.id;
    this.accessName = params.accessName;
    this.user = params.user;
    this.userEmail = params.userEmail;
    this.code = params.code;
    this.observations = params.observations;
  }
}

export default AccessCode;
