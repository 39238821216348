import { PropTypes } from 'prop-types';
import MUIDialog from '@mui/material/Dialog';

import styles from './styles.module.scss';

const Dialog = (props) => {
  const { children, showCloseButton, onClose, red, ...other } = props;

  return (
    <>
      <MUIDialog
        onClose={onClose}
        className={styles.dialog}
        classes={{
          paper: styles.container,
        }}
        {...other}
      >
        <div className={styles.body}>
          {showCloseButton && (
            <i
              className={`${styles.closeIcon} ${red ? styles['closeIcon--black'] : ''}`}
              onClick={onClose}
            ></i>
          )}
          {children}
        </div>
      </MUIDialog>
    </>
  );
};

Dialog.propTypes = {
  showCloseButton: PropTypes.bool,
  red: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default Dialog;
