const initialState = {
  sic: JSON.parse(window.localStorage.getItem('sicList')) ?? [],
  branches: JSON.parse(window.localStorage.getItem('branchesList')) ?? [],
  managers: JSON.parse(window.localStorage.getItem('managersList')) ?? [],
  sectors: JSON.parse(window.localStorage.getItem('sectorList')) ?? [],
  cnae: JSON.parse(window.localStorage.getItem('cnaeList')) ?? [],
  periodicity: JSON.parse(window.localStorage.getItem('periodicityList')) ?? [],
  statuses: JSON.parse(window.localStorage.getItem('statusesList')) ?? [],
  receiptStatuses:
    JSON.parse(window.localStorage.getItem('receiptStatusesList')) ?? [],
  geographicData: JSON.parse(
    window.localStorage.getItem('geographicDataList'),
  ) ?? {
    ccaa: [],
    provinces: [],
  },
  documentTypes:
    JSON.parse(window.localStorage.getItem('documentTypeList')) ?? [],
  notificationTypes:
    JSON.parse(window.localStorage.getItem('notificationTypeList')) ?? [],
  roles: JSON.parse(window.localStorage.getItem('rolesList')) ?? [],
};

export default initialState;
