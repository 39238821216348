import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';
import { useAppStore } from 'context/AppProvider/hooks';

const CreditsFilter = ({ methods, insurers, loading, onInputChange }) => {
  const [t] = useTranslation('creditsComponent');
  const [appState] = useAppStore();
  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row
          columns={24}
          alignItems={'flex-end'}
          marginBottom={2}
          rowSpacing={2}
        >
          <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Autocomplete
              options={insurers}
              onInputChange={(e, newValue) =>
                onInputChange('insurer', newValue)
              }
              getOptionLabel={(option) => option.name ?? ''}
              loading={loading}
              name="insurerId"
              label={t('insurer')}
            />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Select
              options={appState.receiptStatuses.map((period) => ({
                label: period.status,
                value: period.id,
              }))}
              style={{ marginBottom: 0 }}
              name="paymentStatusId"
              label={t('paymentStatus')}
            />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={8} lg={6} xl={6}>
            <Form.Slider
              name="credits"
              step={1000}
              max={100000}
              label={t('creditsNumber')}
            />
          </Form.Column>
        </Form.Row>
        <Form.Row columns={24} alignItems={'flex-end'} rowSpacing={2}>
          <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Date name="paymentDateStart" label={t('paymentDateStart')} />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Date name="paymentDateEnd" label={t('paymentDateEnd')} />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Date name="createdAtStart" label={t('createdAtStart')} />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={12} lg={6} xl={6}>
            <Form.Date name="createdAtEnd" label={t('createdAtEnd')} />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

CreditsFilter.propTypes = {
  methods: PropTypes.any,
  insurers: PropTypes.array,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default CreditsFilter;
