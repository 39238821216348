import { useState } from 'react';
import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';

import { Slider as SliderMUI } from '@mui/material';

import styles from './styles.module.scss';

const Slider = ({
  label,
  name,
  containerSize,
  styleContainer,
  min,
  max,
  ...props
}) => {
  const { control } = useFormContext();
  // eslint-disable-next-line no-unused-vars
  const [range, setRange] = useState([min ?? 0, max]);

  function abbreviateNumber(number) {
    const SI_SYMBOL = ['', 'K', 'M', 'B', 'T'];

    const tier = (Math.log10(Math.abs(number)) / 3) | 0;

    if (tier === 0) return number === max ? number + '+' : number;

    const suffix = SI_SYMBOL[tier];
    const scale = Math.pow(10, tier * 3);

    const scaledNumber = number / scale;
    const formattedNumber = scaledNumber.toFixed(1);

    return number === max
      ? `${formattedNumber + suffix}+`
      : formattedNumber + suffix;
  }

  return (
    <div style={styleContainer} className={styles.container}>
      <h5 className={styles.label}>
        {label} ({abbreviateNumber(range[0])} - {abbreviateNumber(range[1])})
      </h5>

      <Controller
        control={control}
        name={name}
        render={({ field: { value, onChange } }) => (
          <SliderMUI
            value={value}
            onChange={(e, v) => {
              setRange(v);
              onChange(v);
            }}
            className={styles.slider}
            valueLabelDisplay="auto"
            min={min}
            max={max}
            valueLabelFormat={(value) => <div>{abbreviateNumber(value)}</div>}
            {...props}
          />
        )}
      />
    </div>
  );
};

Slider.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  containerSize: PropTypes.string,
  styleContainer: PropTypes.object,
  min: PropTypes.number,
  max: PropTypes.number,
};
export default Slider;
