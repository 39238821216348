import { useEffect } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import CreditsBasePage from '../base';
import CreditsTableAndSearch from 'components/credits/CreditsTableAndSearch';

const Credits = () => {
  const [, clientDispatch] = useClientStore();

  const [t] = useTranslation('credits');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  return (
    <CreditsBasePage
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/credits', text: t('title') },
        { path: '/credits', text: t('purchasesTitle') },
      ]}
    >
      <CreditsTableAndSearch />
    </CreditsBasePage>
  );
};

export default Credits;
