import { PropTypes } from 'prop-types';
import Chip from '@mui/material/Chip';

import styles from './styles.module.scss';

const CustomChip = ({ label, icon, onClick }) => {
  return (
    <Chip className={styles.chip} icon={icon} onClick={onClick} label={label} />
  );
};

CustomChip.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.node,
};

export default CustomChip;
