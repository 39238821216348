import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Autocomplete as AutocompleteMUI, TextField } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { esES } from '@mui/material/locale';

import styles from './styles.module.scss';

const Autocomplete = ({
  name,
  label,
  options,
  ref,
  renderOption,
  getOptionLabel,
  onInputChange,
  containerSize,
  ...props
}) => {
  const { control } = useFormContext();
  const theme = createTheme(esES);

  return (
    <div
      className={`${styles.autocomplete} ${
        containerSize === 'small' ? styles['autocomplete--small'] : ''
      }`}
    >
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <>
            <ThemeProvider theme={theme}>
              <AutocompleteMUI
                slotProps={{
                  paper: {
                    className: styles.paper,
                  },
                }}
                onInputChange={onInputChange}
                onChange={(e, data) => onChange(data)}
                value={value}
                ListboxProps={{
                  className: styles.listbox,
                }}
                classes={{
                  root: styles.input,
                  endAdornment: styles.endAdornment,
                }}
                options={options}
                renderOption={renderOption}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={getOptionLabel}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      classes: {
                        root: styles.textFieldRoot,
                        input: styles.textFieldInput,
                        notchedOutline: styles.notchedOutline,
                      },
                    }}
                    label={label}
                    InputLabelProps={{
                      classes: {
                        root: styles.label,
                        shrink: styles['label--shrink'],
                      },
                    }}
                  />
                )}
                {...props}
              />
            </ThemeProvider>
          </>
        )}
      />
    </div>
  );
};

Autocomplete.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  /**
   * Select type
   * @example 'email'
   * @example 'password'
   */
  type: PropTypes.string,
  value: PropTypes.string || PropTypes.number,
  onChange: PropTypes.func,
  onInputChange: PropTypes.func,
  containerSize: PropTypes.string,
  options: PropTypes.array,
  ref: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.any }),
  ]),
  renderOption: PropTypes.func,
  getOptionLabel: PropTypes.func,
  name: PropTypes.string,
};
export default Autocomplete;
