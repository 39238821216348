import { PropTypes } from 'prop-types';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { StepConnector } from '@mui/material';

import styles from './styles.module.scss';

const Steps = ({ currentStep, steps, onStepChange }) => {
  function handleOnClick(index) {
    if (index === currentStep) return;
    onStepChange(index);
  }

  return (
    <Stepper
      nonLinear
      connector={
        <StepConnector
          classes={{
            root: styles.connector,
            line: styles.line,
          }}
        />
      }
      className={styles.stepper}
      activeStep={currentStep}
    >
      {steps.map((step, index) => (
        <Step
          key={step.stepText}
          classes={{
            root: styles.step,
          }}
        >
          <StepLabel
            onClick={() => handleOnClick(index)}
            className={styles.stepLabel}
            classes={{
              label: styles.textContainer,
              iconContainer: styles.iconContainer,
            }}
            StepIconProps={{
              classes: {
                root: styles.icon,
                text: styles.iconText,
                active: styles.iconActive,
              },
            }}
          >
            <p className={styles.text}>{step.stepText}</p>
            <p className={styles.date}>{step?.date}</p>
          </StepLabel>
        </Step>
      ))}
    </Stepper>
  );
};

Steps.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.array,
  onStepChange: PropTypes.func,
};

export default Steps;
