import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { UserWhiteIcon } from 'assets/icons/IconsLibrary';
import Title from 'UI/atoms/Title';
import Button from 'UI/atoms/Button';
import HeroActions from 'UI/atoms/HeroActions';
import Hero from 'UI/atoms/Hero';
import ContactMessageDialog from '../ContactMessageDialog';
import { INSURER_MANAGER, INSURER_USER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import imageWinner from 'assets/images/aina-winner.png';
const WinnerHero = () => {
  const [t] = useTranslation('phasesHero');

  const [userState] = useUserStore();

  const [openContactDialog, setOpenContactDialog] = useState(false);

  return (
    <>
      <Hero type="green" image={imageWinner}>
        <Title size="hero">{t('winnerHero.title')}</Title>

        <p>{t('winnerHero.selected')}</p>
        <p>{t('winnerHero.documentation')}</p>
        <p>{t('winnerHero.contactManager')}</p>

        <HeroActions>
          {[INSURER_MANAGER, INSURER_USER].includes(userState.role) && (
            <Button
              text={t('button.contact')}
              onClick={() => {
                setOpenContactDialog(true);
              }}
              icon={UserWhiteIcon}
            ></Button>
          )}
        </HeroActions>
      </Hero>
      <ContactMessageDialog
        onClose={() => setOpenContactDialog(false)}
        open={openContactDialog}
      />
    </>
  );
};

export default WinnerHero;
