import { useTranslation } from 'react-i18next';

import { UserWhiteIcon } from 'assets/icons/IconsLibrary';
import Title from 'UI/atoms/Title';
import Button from 'UI/atoms/Button';
import HeroActions from 'UI/atoms/HeroActions';
import Hero from 'UI/atoms/Hero';
import { CLIENT_USER } from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import imageWinner from 'assets/images/aina-winner.png';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';

const ClientWinnerHero = () => {
  const [t] = useTranslation('phasesHero');
  const navigate = useNavigate();
  const [userState] = useUserStore();

  return (
    <>
      <Hero type="green" image={imageWinner}>
        <Title size="hero">{t('clientWinnerHero.title')}</Title>

        <p className={styles.text}>{t('clientWinnerHero.selected')}</p>
        <p className={styles.text}>{t('clientWinnerHero.contact')}</p>

        <HeroActions>
          {[CLIENT_USER].includes(userState.role) && (
            <Button
              text={t('clientWinnerHero.button.contact')}
              onClick={() => {
                navigate('/policies');
              }}
              icon={UserWhiteIcon}
            ></Button>
          )}
        </HeroActions>
      </Hero>
    </>
  );
};

export default ClientWinnerHero;
