import { useTranslation } from 'react-i18next';
import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import getClients from 'services/clients/getClients';
import UserTable from 'components/user/UserTable';
import UserSearch from 'components/user/UserSearch';
import { useTable } from 'hooks/useTable';
import { useNavigate } from 'react-router-dom';

const Clients = () => {
  const [t] = useTranslation('clients');
  const navigate = useNavigate();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
    handleOnRowClick,
  } = useTable(getClients, t('error'), false, undefined);

  return (
    <PageContainer>
      <Header selected={t('title')}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[
            { path: '/', text: t('breadcrumbsHome') },
            { path: '/clients', text: t('title') },
          ]}
        />
      </Header>
      <ContentContainer>
        <UserSearch onSearch={handleOnSearch} />
        <UserTable
          clients={rows}
          rowCount={rowCount}
          loading={loading}
          onPaginationModelChange={handleOnPaginationModelChange}
          onSortModelChange={handleOnSortModelChange}
          paginationModel={paginationModel}
          sortModel={sortModel}
          onRowClick={handleOnRowClick}
          addButtonOnClick={() => navigate('/clients/create')}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default Clients;
