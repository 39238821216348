import { useTranslation } from 'react-i18next';
import UserDataForm from 'components/user/UserDataForm';
import { useElementCreate } from 'hooks/useElementCreate';
import createClient from 'services/clients/createClient';

const CreateUser = () => {
  const [t] = useTranslation('user');

  const { handleOnSubmit } = useElementCreate(
    createClient,
    t('elementCreatedSuccessfully'),
    t('error'),
    '/clients',
  );

  return <UserDataForm defaultFormValues={[]} onSubmit={handleOnSubmit} />;
};

export default CreateUser;
