import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const Paragraph = ({ children }) => <p className={styles.text}>{children}</p>;

Paragraph.propTypes = {
  children: PropTypes.node,
};

export default Paragraph;
