import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropTypes } from 'prop-types';

import Form from 'UI/molecules/Form';

import validationSchema from './validationSchema';

const InsurerOnboardingDataForm = ({ defaultFormValues, onSubmit }) => {
  const [errorsText, setErrorsText] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [t] = useTranslation('onboarding');
  const schema = validationSchema(t);

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      onboarding: defaultFormValues.onboarding ?? false,
      attachment: defaultFormValues.attachment ?? false,
    },
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  const handleOnSubmit = async (data) => {
    setSubmitting(true);

    await onSubmit({
      ...methods.getValues(),

      onboarding: data.onboarding,
      attachment: data.attachment,
    });

    setSubmitting(false);
  };

  return (
    <Form.Container>
      <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }}>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="contactName" label={t('contactName')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="contactSurname" label={t('contactSurname')} />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input
                name="phone"
                label={t('contactPhone')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column sm={24} md={8} lg={6} xl={4}>
              <Form.Input name="email" label={t('email')} />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.RadioButton
                name="onboarding"
                buttonsText={[
                  { value: true, text: t('yes') },
                  { value: false, text: t('no') },
                ]}
                title={t('onboarding')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row>
            <Form.Column xs={24} sm={24} md={12} lg={8} xl={8}>
              <Form.RadioButton
                name="attachment"
                buttonsText={[
                  { value: true, text: t('yes') },
                  { value: false, text: t('no') },
                ]}
                title={t('attachment')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Footer
            loading={submitting}
            onClickSecondary={() => navigate('/clients')}
          />
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

InsurerOnboardingDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default InsurerOnboardingDataForm;
