import axios from 'axios';
import Periodicity from 'models/Periodicity';

export default async function getPeriodicityList() {
  const { data } = await axios.get('/list/Periodicidad').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new Periodicity({
        id: item.id,
        periodicity: item.periodicidad,
      }),
  );
}
