import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import { useTable } from 'hooks/useTable';
import Title from 'UI/atoms/Title';
import DocumentationTable from '../DocumentationTable';
import DocumentationSearch from '../DocumentationSearch';
import DocumentationDialog from '../DocumentationDialog';
import getDocumentationList from 'services/projects/getDocumentationList';
import downloadDocumentation from 'services/projects/downloadDocumentation';
import updateDocumentation from 'services/projects/updateDocumentation';
import createDocumentation from 'services/projects/createDocumentation';
import deleteDocument from 'services/projects/deleteDocument';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import { useDialog } from 'hooks/useDialog';

const ProjectDocumentation = () => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();
  const [userState] = useUserStore();
  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    refreshRows,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
  } = useTable(getDocumentationList, t('error'), true, clientState.clientId);

  const {
    openConfirmationDialog,
    loadingDialog,
    openDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleOnAdd,
    handleOnClose,
    handleOnDelete,
    handleDeleteElement,
    handleOnEdit,
  } = useDialog(
    updateDocumentation,
    createDocumentation,
    refreshRows,
    deleteDocument,
  );

  return (
    <>
      <Title size="medium">{t('title')}</Title>
      <DocumentationSearch onSearch={handleOnSearch} />
      <DocumentationTable
        documents={rows}
        rowCount={rowCount}
        loading={loading}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addDocumentHandler={
          [AINA_MANAGER].includes(userState.role)
            ? () => handleOnAdd()
            : undefined
        }
        onEdit={handleOnEdit}
        onDelete={handleOnDelete}
        downloadDocumentation={downloadDocumentation}
      />
      {openDialog ? (
        <DocumentationDialog
          selectedDocument={selectedElement}
          documentType="mixed"
          loading={loadingDialog}
          open={openDialog}
          onClose={handleOnClose}
          onCreateOrUpdate={handleCreateOrUpdateElement}
          downloadDocumentation={downloadDocumentation}
        />
      ) : null}
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement()}
        onClose={handleOnClose}
      />
    </>
  );
};

export default ProjectDocumentation;
