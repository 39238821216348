import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import { useAppStore } from 'context/AppProvider/hooks';
import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Title from 'UI/atoms/Title';
import Form from 'UI/molecules/Form';
import updateCredits from 'services/credits/updateCredits';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';

import styles from './styles.module.scss';

const EditCreditsPurchaseDialog = ({
  open,
  onClose,
  refreshRows,
  creditPurchase,
  ...props
}) => {
  const [t] = useTranslation('editCreditsPurchaseDialog');
  const [appState] = useAppStore();
  const [loading, setLoading] = useState(false);
  const [errorsText, setErrorsText] = useState({});

  const formDefaultValues = {
    status: '',
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
  } = methods;

  const handleOnClose = () => {
    methods.reset(formDefaultValues);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (creditPurchase) {
      methods.reset({ status: parseInt(creditPurchase.paymentStatusId, 10) });
    }
  }, [creditPurchase]);

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  async function onSubmit(data) {
    setLoading(true);
    const result = await updateCredits(creditPurchase, data.status);

    setLoading(false);

    if (result.status === 200) {
      showNotification(t('success'));
      refreshRows();
      handleOnClose();
      return;
    }

    showErrorNotification(t('error'));
  }

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <div className={styles.container}>
          <Title size="medium">{t('title')}</Title>
          <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form.Select
                options={appState.receiptStatuses?.map((type) => ({
                  label: type.status,
                  value: type.id,
                }))}
                withValidation
                style={{ marginBottom: 0 }}
                extraClassName="no-margin-label"
                color="grey"
                name="status"
                label={t('label')}
              />
              <Grid container rowGap={2} justifyContent="space-between">
                <Grid item xs={12} md={6} marginTop={{ md: 3, lg: 6 }}>
                  <PrimaryBtn
                    loading={loading}
                    text={t('confirm')}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                  <SecondaryBtn
                    style={{ margin: 0 }}
                    text={t('cancelAction')}
                    onClick={handleOnClose}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormProvider>
        </div>
      </Dialog>
    </>
  );
};

EditCreditsPurchaseDialog.propTypes = {
  open: PropTypes.bool,
  creditPurchase: PropTypes.object,
  onClose: PropTypes.func,
  refreshRows: PropTypes.func,
};

export default EditCreditsPurchaseDialog;
