import { useTranslation } from 'react-i18next';
import getClientData from 'services/clients/getClientData';
import { useClientStore } from 'context/ClientProvider/hooks';
import Loader from 'UI/atoms/Loader';
import { useElementData } from 'hooks/useElementData';
import CompanyDataForm from '../CompanyDataForm';

const CompanyDataTab = () => {
  const [t] = useTranslation('user');
  const [clientState] = useClientStore();
  const { loading, data } = useElementData(clientState.clientId, getClientData);

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return <CompanyDataForm defaultFormValues={data} />;
};

export default CompanyDataTab;
