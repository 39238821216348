import { useState } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.scss';
import { IconButton } from '@mui/material';
import { DownloadIcon } from 'assets/icons/IconsLibrary';

const InputFile = ({
  register,
  styleContainer,
  styleFlexContainer,
  id,
  onDownloadIconClick,
  fileName,
  ...props
}) => {
  const [fileText, setFileText] = useState(fileName);
  const { onChange, name, onBlur, ref } = register(props.name);

  function handleOnChange(e) {
    setFileText(e.target.value.split('\\')[2]);
    onChange(e);
  }

  return (
    <div
      style={styleFlexContainer}
      className={`${styles.flexContainer} ${onDownloadIconClick ? styles['flexContainer--withIcon'] : ''} `}
    >
      <div style={styleContainer} className={`${styles.container}`}>
        <label
          title={fileText}
          className={`${styles.label} ${props.disabled ? styles['label--disabled'] : ''}`}
          htmlFor={id}
        >
          {fileText ?? ' '}
        </label>
        <input
          className={styles.inputFile}
          id={id}
          type="file"
          name={name}
          onBlur={onBlur}
          onChange={handleOnChange}
          ref={ref}
          {...props}
        />
      </div>
      {onDownloadIconClick ? (
        <IconButton
          className={styles.downloadIcon}
          onClick={onDownloadIconClick}
        >
          <DownloadIcon />
        </IconButton>
      ) : null}
    </div>
  );
};

InputFile.propTypes = {
  register: PropTypes.func,
  disabled: PropTypes.bool,
  onDownloadIconClick: PropTypes.func,
  id: PropTypes.string,
  styleContainer: PropTypes.object,
  styleFlexContainer: PropTypes.object,
  name: PropTypes.string,
  fileName: PropTypes.string,
};
export default InputFile;
