import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';
import { useDecodedData } from './useDecodedData';
import { useClientStore } from 'context/ClientProvider/hooks';

export const useDialog = (updateFn, createFn, refreshFn, deleteFn) => {
  const [t] = useTranslation('documentation');
  const [openDialog, setOpenDialog] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [selectedElement, setSelectedElement] = useState(null);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [clientState] = useClientStore();

  const { getDecodedData } = useDecodedData(clientState.clientId);

  async function handleCreateOrUpdateElement(parentId, selectedFile, element) {
    let res = null;
    setLoadingDialog(true);

    res = await handleUpdateOrCreateInternal(parentId, selectedFile, element);

    setLoadingDialog(false);

    if (res !== null) {
      refreshFn();
      setOpenDialog(false);
      showSuccessNotification(t('success'));
      return;
    }

    showErrorNotification(t('error'));
  }

  async function handleDeleteElement(noParentId) {
    setLoadingDialog(true);
    let res;
    if (noParentId) {
      res = await deleteFn(selectedElement.id);
    } else {
      res = await deleteFn(getDecodedData().id, selectedElement.id);
    }
    setLoadingDialog(false);

    if (res.status === 200) {
      refreshFn();
      showSuccessNotification(t('documentDeleted'));
      setOpenConfirmationDialog(false);
      return;
    }

    showErrorNotification(t('error'));
  }

  async function handleUpdateOrCreateInternal(parentId, selectedFile, element) {
    if (selectedFile) {
      if (element.id) {
        return await updateFn(parentId, selectedFile, element);
      }
      return await createFn(parentId, selectedFile, element);
    }

    if (element.id) {
      return await updateFn(parentId, element);
    }
    return await createFn(parentId, element);
  }

  const handleOnClose = () => {
    setOpenDialog(false);
    setOpenConfirmationDialog(false);
    setSelectedElement(null);
  };

  const handleOnAdd = (params) => {
    if (params) {
      setSelectedElement(params);
    } else {
      setSelectedElement(null);
    }
    setOpenDialog(true);
  };

  const handleOnEdit = (row) => {
    setSelectedElement(row);
    setOpenDialog(true);
  };

  const handleOnDelete = (row) => {
    setSelectedElement(row);
    setOpenConfirmationDialog(true);
  };

  return {
    openDialog,
    openConfirmationDialog,
    loadingDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleDeleteElement,
    handleOnAdd,
    handleOnDelete,
    handleOnClose,
    handleOnEdit,
  };
};
