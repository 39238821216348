import { useTranslation } from 'react-i18next';
import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import { useTable } from 'hooks/useTable';
import InsurerTable from 'components/insurer/InsurerTable';
import getInsurers from 'services/insurers/getInsurers';
import InsurerSearch from 'components/insurer/InsurerSearch';

const Insurers = () => {
  const [t] = useTranslation('insurers');

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    handleOnSortModelChange,
    handleOnRowClick,
  } = useTable(getInsurers, t('error'));

  return (
    <PageContainer>
      <Header selected={t('title')}>
        <SubHeader
          text={t('title')}
          breadcrumbs={[
            { path: '/', text: t('breadcrumbsHome') },
            { path: '/insurers', text: t('title') },
          ]}
        />
      </Header>
      <ContentContainer>
        <InsurerSearch onSearch={handleOnSearch} />
        <InsurerTable
          insurers={rows}
          rowCount={rowCount}
          loading={loading}
          onRowClick={handleOnRowClick}
          onPaginationModelChange={handleOnPaginationModelChange}
          onSortModelChange={handleOnSortModelChange}
          paginationModel={paginationModel}
          sortModel={sortModel}
        />
      </ContentContainer>
    </PageContainer>
  );
};

export default Insurers;
