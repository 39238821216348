import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      startDate: yup.date().required(t('mandatory', { field: t('startDate') })),
      roundOneDate: yup
        .date()
        .required(t('mandatory', { field: t('projectStatus1') })),
      roundTwoDate: yup
        .date()
        .required(t('mandatory', { field: t('projectStatus2') })),
      newInsuranceDate: yup
        .date()
        .required(t('mandatory', { field: t('newPolicy') })),
      code: yup.string().required(t('mandatory', { field: t('code') })),
      statusId: yup.number().required(t('mandatory', { field: t('status') })),
      branchId: yup.object().required(t('mandatory', { field: t('branch') })),
      managerId: yup.object().required(t('mandatory', { field: t('manager') })),
      numWorkers: yup
        .string()
        .required(t('mandatory', { field: t('numWorkersWithInsurance') })),
      procedureIT: yup
        .number()
        .typeError(t('invalidFormat', { field: t('numWorkersOff') }))
        .moreThan(-1, t('positive', { field: t('numWorkersOff') }))
        .required(t('mandatory', { field: t('numWorkersOff') })),
      agreement: yup.bool().required(t('mandatory', { field: t('agreement') })),
      preferencesPb: yup
        .number()
        .typeError(t('invalidFormat', { field: t('pb') }))
        .moreThan(-1, t('positive', { field: t('pb') }))
        .required(t('mandatory', { field: t('pb') })),
      preferencesPremium: yup
        .number()
        .typeError(t('invalidFormat', { field: t('premium') }))
        .positive(t('positive', { field: t('premium') }))
        .required(t('mandatory', { field: t('premium') })),
      preferencesAdhesion: yup
        .number()
        .typeError(t('invalidFormat', { field: t('adhesion') }))
        .positive(t('positive', { field: t('adhesion') }))
        .required(t('mandatory', { field: t('adhesion') })),
      preferencesSolvency: yup
        .number()
        .typeError(t('invalidFormat', { field: t('currentPremium') }))
        .positive(t('positive', { field: t('currentPremium') }))
        .required(t('mandatory', { field: t('currentPremium') })),
    })
    .required();

export default validationSchema;
