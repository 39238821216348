import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Hero = ({ children, image, type }) => (
  <div
    className={`${styles.container} ${type ? styles[`container--${type}`] : ''}`}
  >
    <div className={styles.content}>{children}</div>
    {image && (
      <div className={styles.imageWrapper}>
        <img src={image} className={styles.image} />
      </div>
    )}
  </div>
);

Hero.propTypes = {
  children: PropTypes.node,
  image: PropTypes.node,
  type: PropTypes.string,
};

export default Hero;
