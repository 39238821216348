import { useState, useEffect } from 'react';

const defaultSearchParams = {
  searchBarText: '',
  start: 0,
  length: 10,
};

export const useDebouncer = (getFn, setEntities, isDefault, extraFilters) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [defaultEntities, setDefaultEntities] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      const entityData = await getFn({
        ...defaultSearchParams,
        ...extraFilters,
      });

      if (!isDefault) {
        setEntities(entityData?.resultList ?? []);
      }
      setDefaultEntities(entityData?.resultList ?? []);
      setLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      if (searchTerm.length === 0) {
        setEntities(defaultEntities);
        setLoading(false);
        return;
      }
      if (searchTerm.length === 1) {
        setEntities([]);
        setLoading(false);
        return;
      }
      if (searchTerm.length >= 2) {
        retrieveFunction();
      }
    }, 500);

    return () => clearTimeout(debounceTimer);
  }, [searchTerm]);

  function handleOnInputChange(value) {
    setLoading(true);
    setSearchTerm(value);
  }

  async function retrieveFunction() {
    setLoading(true);
    const data = await getFn({
      ...defaultSearchParams,
      searchBarText: searchTerm,
      ...extraFilters,
    });

    setEntities(data?.resultList ?? []);
    setLoading(false);
  }

  return {
    loading,
    handleOnInputChange,
  };
};
