import * as yup from 'yup';
import dayjs from 'dayjs';

const validationSchema = (t) =>
  yup
    .object({
      contactName: yup
        .string()
        .required(t('mandatory', { field: t('contactName') })),
      contactSurname: yup
        .string()
        .required(t('mandatory', { field: t('contactSurname') })),
      phone: yup
        .number()
        .typeError(t('invalidFormat', { field: t('contactPhone') }))
        .positive(t('invalidFormat', { field: t('contactPhone') }))
        .required(t('mandatory', { field: t('contactPhone') })),
      representativeName: yup
        .string()
        .required(t('mandatory', { field: t('name') })),
      representativeSurname: yup
        .string()
        .required(t('mandatory', { field: t('surname') })),
      representativeNid: yup
        .string()
        .required(t('mandatory', { field: t('dni') })),
      representativeNidExpirationDate: yup
        .date()
        .transform(function (value, originalValue) {
          if (this.isType(value)) {
            return value;
          }
          const result = dayjs(originalValue, 'DD/MM/YYYY');
          return result;
        })
        .typeError(t('invalidFormat', { field: t('dniExpirationDate') }))
        .required(t('mandatory', { field: t('dniExpirationDate') })),
      onboarding: yup.boolean(),
      letterOfAppointment: yup.boolean(),
    })
    .required();

export default validationSchema;
