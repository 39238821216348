import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';
import { useAppStore } from 'context/AppProvider/hooks';
import getClients from 'services/clients/getClients';
import getInsurers from 'services/insurers/getInsurers';
import { useState } from 'react';
import { useDebouncer } from 'hooks/useDebouncer';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';

const ReceiptsFilter = ({ methods }) => {
  const [t] = useTranslation('receiptsComponent');
  const [appState] = useAppStore();
  const [userState] = useUserStore();

  const [insurers, setInsurers] = useState([]);
  const [clients, setClients] = useState([]);

  const { loading: loadingClients, handleOnInputChange } = useDebouncer(
    getClients,
    setClients,
    false,
  );

  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
  } = useDebouncer(getInsurers, setInsurers, false);

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row
          columns={24}
          alignItems="flex-end"
          marginBottom={2}
          rowSpacing={2}
        >
          <Form.Column xs={24} sm={12} md={12} lg={8} xl={8}>
            <Form.Slider
              name="totalAmount"
              step={1000}
              max={1000000}
              label={t('totalAmount')}
            />
          </Form.Column>
          {![INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(
            userState.role,
          ) ? (
            <Form.Column xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Autocomplete
                options={insurers}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) =>
                  handleOnInputChangeInsurer(newValue)
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingInsurers}
                name="insurerId"
                label={t('insurer')}
              />
            </Form.Column>
          ) : null}
          {![CLIENT_USER].includes(userState.role) ? (
            <Form.Column xs={24} sm={12} md={12} lg={8} xl={8}>
              <Form.Autocomplete
                options={clients}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) => handleOnInputChange(newValue)}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingClients}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                name="companyId"
                label={t('socialReason')}
              />
            </Form.Column>
          ) : null}
        </Form.Row>

        <Form.Row
          columns={24}
          alignItems="flex-end"
          rowSpacing={2}
          marginBottom={2}
        >
          <Form.Column xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Autocomplete
              name="branchId"
              getOptionLabel={(option) => option.name}
              options={appState.branches}
              label={t('branch')}
            />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={12} lg={12} xl={12}>
            <Form.Select
              options={appState.receiptStatuses.map((status) => ({
                label: status.status,
                value: status.id,
              }))}
              label={t('status')}
              name="statusId"
            />
          </Form.Column>
        </Form.Row>

        <Form.Row columns={24} alignItems="flex-end" rowSpacing={2}>
          <Form.Column xs={24} sm={12} md={8} lg={8} xl={8}>
            <Form.Select
              options={appState.periodicity.map((period) => ({
                label: period.periodicity,
                value: period.id,
              }))}
              style={{ marginBottom: 0 }}
              name="periodicityId"
              label={t('periodicity')}
            />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={8} lg={8} xl={8}>
            <Form.Date name="issueDateFrom" label={t('issueDateFrom')} />
          </Form.Column>
          <Form.Column xs={24} sm={12} md={8} lg={8} xl={8}>
            <Form.Date name="issueDateTo" label={t('issueDateTo')} />
          </Form.Column>
        </Form.Row>
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

ReceiptsFilter.propTypes = {
  methods: PropTypes.any,
  insurers: PropTypes.array,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default ReceiptsFilter;
