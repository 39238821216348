import axios from 'axios';
import ProjectCalendar from 'models/ProjectCalendar';

export default async function getCalendarProjects(filter, isInsurer) {
  const params = new URLSearchParams();

  const mappedFilters = {
    FromDate: filter?.fromDate ?? '',
    ToDate: filter?.toDate ?? '',
  };

  for (const [key, value] of Object.entries(mappedFilters)) {
    params.append(key, value);
  }

  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/projects', config);
    const calendars = [];
    if (isInsurer) {
      data.resultList.forEach((item) => {
        calendars.push(
          getNewProjectCalendar(
            item,
            'expirationDate',
            item.fechaVencimientoActual,
          ),
          getNewProjectCalendar(item, 'startDate', item.fechainicio),
        );
      });
    } else {
      data.resultList.forEach((item) => {
        calendars.push(
          getNewProjectCalendar(item, 'roundOne', item.fechaRonda1),
          getNewProjectCalendar(item, 'roundTwo', item.fechaRonda2),
          getNewProjectCalendar(item, 'startDate', item.fechainicio),
          getNewProjectCalendar(
            item,
            'expirationPolicy',
            item.fechaNuevaPoliza,
          ),
        );
      });
    }
    return calendars;
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

function getNewProjectCalendar(item, name, date) {
  return new ProjectCalendar({
    id: item.id,
    code: item.codigodeproyecto,
    agreement: item.convenio,
    companyName: item.empresa,
    companyId: item.empresaLookupId,
    status: item.estado,
    statusId: item.estadoLookupId,
    name,
    start: new Date(date),
    end: new Date(date),
    branch: item.ramo,
    expirationDate: new Date(item.fechaVencimientoActual),
    averageAge: item.empresa_x003a__x0020_EdadMedia,
    cnae: item.empresa_x003a__x0020_CNAEValueCo,
    currentPremium: item.polizaActual_x003a__x0020_PrimaT,
    numWorkers: item.numTrabajadores,
  });
}
