import { useNavigate } from 'react-router-dom';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';

const DOCUMENT_TYPE_RECEIPT = '26';

export const useReceiptCreate = (
  createFunction,
  documentFunction,
  successNotificationMessage,
  errorNotificationMessage,
  onSuccessNavigateTo,
) => {
  const navigate = useNavigate();

  async function handleOnSubmit(elementData) {
    const res = await createFunction(elementData);
    if (res.status === 200) {
      if (elementData?.file?.length !== 0 && elementData?.file !== undefined) {
        await documentFunction(res.data.id, elementData.file, {
          verified: false,
          documentTypeId: DOCUMENT_TYPE_RECEIPT,
        });
      }

      navigate(onSuccessNavigateTo);
      showSuccessNotification(successNotificationMessage);
    } else {
      showErrorNotification(errorNotificationMessage);
    }
  }

  return { handleOnSubmit };
};
