import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';
import ProjectBasePage from '../base';
import ProjectConfigurationTab from 'components/project/ProjectConfigurationTab';

const Configuration = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    code: '',
    companyName: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('projects');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <ProjectBasePage
      extraClass="form"
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/projects', text: t('title') },
        {
          path: `/projects/${clientState.clientId}`,
          text: clientData.code,
        },
      ]}
    >
      <ProjectConfigurationTab />
    </ProjectBasePage>
  );
};

export default Configuration;
