import { PropTypes } from 'prop-types';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import { Link as RouterLink } from 'react-router-dom';

import styles from './styles.module.scss';

const CustomBreadcrumbs = ({ breadcrumbs, initialText }) => {
  return (
    <>
      <p className={styles.text}>{initialText}</p>
      <Breadcrumbs
        separator=">"
        classes={{
          root: styles.breadcrumbs,
          li: styles.item,
          separator: styles.separator,
        }}
      >
        {breadcrumbs.map((breadcrumb) => (
          <Link
            key={`${breadcrumb.text}-${breadcrumb.path}`}
            component={RouterLink}
            underline="always"
            color="inherit"
            classes={{
              root: `${styles.link} ${breadcrumb.disabled ? styles['link--disabled'] : ''}`,
            }}
            to={breadcrumb.path}
          >
            {breadcrumb.text}
          </Link>
        ))}
      </Breadcrumbs>
    </>
  );
};

CustomBreadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
  initialText: PropTypes.string,
};

export default CustomBreadcrumbs;
