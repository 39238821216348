import Button from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const FilterButton = ({ text, onClick }) => {
  return (
    <Button
      classes={{
        root: styles.root,
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

FilterButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default FilterButton;
