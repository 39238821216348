import axios from 'axios';
import Project from 'models/Project';

export default async function updateProject(projectId, data) {
  const project = new Project(data);

  const body = {
    codigodeproyecto: project.code,
    fechainicio: project.startDate,
    fechaRonda1: project.roundOneDate,
    fechaRonda2: project.roundTwoDate,
    fechaNuevaPoliza: project.newInsuranceDate,
    estadoLookupId: project.statusId,
    ramoLookupId: project.branchId,
    numTrabajadores: project.numWorkers,
    tramitacionIT: project.procedureIT,
    convenio: project.agreement,
    empresaLookupId: project.companyId,
    fechaVencimientoActual: project.expirationDate,
    aseguradoraPolizaActualLookupId: project.currentPolicyId,
    polizaActualLookupId: project.currentPolicyId,
    pbPreferencias: project.preferencesPb,
    primaPreferencias: project.preferencesPremium,
    adhesionesPreferencias: project.preferencesAdhesion,
    solvenciaPreferencias: project.preferencesSolvency,
    gestorLookupId: project.managerId,
    costeCreditos: project.credits,
  };
  try {
    const res = await axios.put('/projects/' + projectId, body);
    return res;
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
