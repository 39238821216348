import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import HeroImage from 'assets/images/hero.png';

const Hero = () => {
  const [t] = useTranslation('home');

  return (
    <>
      <div className={styles.hero}>
        <div className={styles.container}>
          <div className={styles.imageWrapper}>
            <p className={`${styles.title} wow fadeIn`} data-wow-delay="0.4s">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('hero.text1'),
                }}
              ></span>
            </p>
            <div className={`${styles.image} wow fadeIn`} data-wow-delay="0.4s">
              <img src={HeroImage} width="823" height="552" />
            </div>
          </div>

          <div className={`${styles.textWrapper}`} data-wow-delay="0.4s">
            <p className={`${styles.title} wow fadeIn`} data-wow-delay="0.5s">
              <span
                dangerouslySetInnerHTML={{
                  __html: t('hero.text2'),
                }}
              ></span>
            </p>
            <div
              className={`${styles.moreInfo} wow fadeIn`}
              data-wow-delay="0.5s"
            >
              <p className={styles.infoText}>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('hero.text3'),
                  }}
                ></span>
              </p>
              <a className={styles.link} href="#more-info">
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('hero.moreInfo'),
                  }}
                ></span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Hero;
