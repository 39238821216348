import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useAppStore } from 'context/AppProvider/hooks';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import getClients from 'services/clients/getClients';
import { findElementByLookupId } from 'utils/lookupIdMapper';
import SelectBtn from 'UI/atoms/SelectBtn';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import getPolicies from 'services/policies/getPolicies';
import { usePrevious } from 'hooks/usePreviousFormValue';
import { useDebouncer } from 'hooks/useDebouncer';

const ProjectConfigurationForm = ({ onSubmit, defaultFormValues }) => {
  const { t } = useTranslation('projectsComponent');
  const [appState] = useAppStore();
  const [errorsText, setErrorsText] = useState({});
  const [submitting, setSubmitting] = useState(false);
  const [shrinkLabel, setShrinkLabel] = useState(!!defaultFormValues);
  const [shrinkLabelPolicies, setShrinkLabelPolicies] =
    useState(!!defaultFormValues);

  const [policies, setPolicies] = useState([]);
  const [clients, setClients] = useState([]);
  const [loadingList, setLoadingList] = useState(true);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);

  const { loading: loadingClients, handleOnInputChange } = useDebouncer(
    getClients,
    setClients,
    defaultFormValues?.companyId,
  );
  const schema = validationSchema(t);

  const navigate = useNavigate();

  const cnaeList = appState.cnae;

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      companyId: defaultFormValues?.companyId,
      branchId: findElementByLookupId(
        appState.branches,
        defaultFormValues?.branchId,
      ),
      managerId: findElementByLookupId(
        appState.managers,
        defaultFormValues?.managerId,
      ),
      statusId: defaultFormValues?.statusId ?? '1',
      cif: defaultFormValues?.companyId?.cif,
      cnae: findElementByLookupId(
        cnaeList,
        defaultFormValues?.companyId?.cnaeId,
      )?.code,
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors, dirtyFields },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const watchClient = watch('companyId');
  const prevClient = usePrevious(watchClient);
  const watchPolicy = watch('currentPolicyId');

  useEffect(() => {
    if ((prevClient && prevClient?.id !== watchClient?.id) || !watchClient) {
      setValue('currentPolicyId', null);
      setValue('cnae', '');
      setValue('cif', '');
      setShrinkLabel(false);
    }
    if (watchClient) {
      setShrinkLabel(true);
      setValue('cif', watchClient.cif);
      retrievePolicies('');
      setValue(
        'cnae',
        findElementByLookupId(cnaeList, watchClient.cnaeId).code,
      );
    }
  }, [watchClient, prevClient, setValue]);

  useEffect(() => {
    if (watchPolicy) {
      setValue('currentPbForm', watchPolicy.pb);
      setValue('currentInsurerIdForm', watchPolicy.insurer);
      setValue('expirationDate', watchPolicy.expirationDate);
      setValue('currentPremiumForm', watchPolicy.totalPremium);
      setShrinkLabelPolicies(true);
    } else {
      setValue('currentPbForm', '');
      setValue('currentInsurerIdForm', '');
      setValue('expirationDate', null);
      setValue('currentPremiumForm', '');
      setShrinkLabelPolicies(false);
    }
  }, [watchPolicy, setValue]);

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  const handleOnSubmit = async (data) => {
    if (
      defaultFormValues &&
      methods.getValues('statusId').toString() !== defaultFormValues.statusId &&
      dirtyFields.statusId
    ) {
      setOpenConfirmationDialog(true);
    } else {
      updateFields();
    }
  };

  async function updateFields() {
    setSubmitting(true);
    await onSubmit({
      ...methods.getValues(),
      currentPolicyId: methods.getValues('currentPolicyId')?.id,
      agreement: methods.getValues('agreement'),
      statusId: methods.getValues('statusId').toString(),
      managerId: methods.getValues('managerId').id,
      branchId: methods.getValues('branchId').id,
      companyId: methods.getValues('companyId')?.id,
    });
    setSubmitting(false);
  }

  async function handleOnDialogConfirm() {
    await updateFields();
    setOpenConfirmationDialog(false);
  }

  async function retrievePolicies(value) {
    setLoadingList(true);
    const data = await getPolicies({
      policyNumStart: value,
      policyNumEnd: value,
      companyId: watchClient.id,
    });
    setPolicies(data.resultList);
    setLoadingList(false);
  }

  return (
    <Form.Container>
      <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title.start')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={12} lg={6} xl={4}>
              <Form.Input name="code" label={t('code')} />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={4}>
              <Form.Autocomplete
                name="branchId"
                getOptionLabel={(option) => option.name}
                options={appState.branches}
                label={t('branch')}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={4}>
              <Form.Autocomplete
                name="managerId"
                getOptionLabel={(option) => option.name}
                options={appState.managers}
                label={t('manager')}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={4}>
              <Form.Select
                options={appState.statuses.map((status) => ({
                  label: status.status,
                  value: status.id,
                }))}
                label={t('status')}
                disabled
                name="statusId"
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={4}>
              <Form.Input name="credits" label={t('credits')} />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('clientData')} />
            </Form.Column>
            <Form.Column sm={24} md={24} lg={6} xl={5}>
              <Form.Autocomplete
                options={clients}
                filterOptions={(x) => x}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                onInputChange={(e, newValue) => handleOnInputChange(newValue)}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingClients}
                name="companyId"
                label={t('clientName')}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={5}>
              <Form.Input
                name="cif"
                disabled
                label={t('cif')}
                shrinkLabel={shrinkLabel}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={4} xl={3}>
              <Form.Input
                name="cnae"
                disabled
                label={t('cnae')}
                shrinkLabel={shrinkLabel}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column lg={6} xl={5}>
              <Form.Input
                name="numWorkers"
                label={t('numWorkersWithInsurance')}
              />
            </Form.Column>
            <Form.Column lg={6} xl={5}>
              <Form.Input
                name="procedureIT"
                label={t('numWorkersOff')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column lg={6} xl={5}>
              <Form.Select
                options={[
                  { label: t('yes'), value: true },
                  { label: t('no'), value: false },
                ]}
                name="agreement"
                label={t('agreement')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('currentPolicy')} />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={5}>
              <Form.Autocomplete
                options={policies}
                disabled={!watchClient ? true : loadingList}
                inputValue={watchPolicy?.policyNum ?? ''}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.policyNum}
                    </li>
                  );
                }}
                getOptionLabel={(option) => `${option.policyNum}` ?? ''}
                loading={loadingList}
                name="currentPolicyId"
                label={t('currentPolicyNum')}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={5}>
              <Form.Input
                disabled
                shrinkLabel={shrinkLabelPolicies}
                name="currentInsurerIdForm"
                label={t('currentInsurerPolicy')}
              />
            </Form.Column>
            <Form.Column sm={24} md={12} lg={6} xl={5}>
              <Form.Date
                disabled
                shrinkLabel={shrinkLabelPolicies}
                label={t('expirationDate')}
                name="expirationDate"
              />
            </Form.Column>
          </Form.Row>
          <Form.Row alignItems="flex-end">
            <Form.Column xs={12} xl={4}>
              <Form.Input
                shrinkLabel={shrinkLabelPolicies}
                disabled
                name="currentPremiumForm"
                label={t('currentPremium')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={12} xl={4}>
              <Form.Input
                disabled
                shrinkLabel={shrinkLabelPolicies}
                name="currentPbForm"
                label={t('pb')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('preferencesClient')} />
            </Form.Column>

            <Form.Column xs={12} sm={6} md={5} lg={4} xl={4}>
              <Form.Input
                name="preferencesPremium"
                color="grey"
                label={t('premium')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={12} sm={6} md={5} lg={4} xl={4}>
              <Form.Input
                name="preferencesPb"
                color="grey"
                label={t('pb')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={12} sm={6} md={5} lg={4} xl={4}>
              <Form.Input
                name="preferencesAdhesion"
                color="grey"
                label={t('adhesion')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={12} sm={6} md={5} lg={4} xl={4}>
              <Form.Input
                name="preferencesSolvency"
                color="grey"
                label={t('solvency')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('projectCalendar')} />
            </Form.Column>

            <Form.Column xs={12} sm={12} md={6} lg={6} xl={5}>
              <Form.Date name="startDate" label={t('startDate')} />
            </Form.Column>
            <Form.Column xs={12} sm={12} md={6} lg={6} xl={5}>
              <Form.Date name="roundOneDate" label={t('projectStatus1')} />
            </Form.Column>
            <Form.Column xs={12} sm={12} md={6} lg={6} xl={5}>
              <Form.Date name="roundTwoDate" label={t('projectStatus2')} />
            </Form.Column>
            <Form.Column xs={12} sm={12} md={6} lg={6} xl={5}>
              <Form.Date name="newInsuranceDate" label={t('newPolicy')} />
            </Form.Column>
          </Form.Row>

          <Form.Footer
            onClickSecondary={() => navigate('/projects')}
            loading={submitting}
          >
            {defaultFormValues ? (
              <SelectBtn
                name="statusId"
                options={[
                  { label: t('active'), value: 1, disabled: true },
                  { label: t('roundOne'), value: 2 },
                  { label: t('roundTwo'), value: 3 },
                  { label: t('roundFinish'), value: 4 },
                  { label: t('roundCancel'), value: 5 },
                ]}
              />
            ) : null}
          </Form.Footer>
        </Form>
      </FormProvider>
      <ConfirmationDialog
        loading={submitting}
        isDeleting={false}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={handleOnDialogConfirm}
        onClose={() => setOpenConfirmationDialog(false)}
      />
    </Form.Container>
  );
};

ProjectConfigurationForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default ProjectConfigurationForm;
