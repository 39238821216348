import { useState, useEffect } from 'react';
import { showErrorNotification } from 'utils/showToastNotifications';
import { useDecodedData } from './useDecodedData';
import { columnsMapper } from 'utils/columnsMapper';
import { useNavigate } from 'react-router-dom';

const defaultPaginationSettings = {
  page: 0,
  pageSize: 10,
};

const defaultSortModel = {
  sortColumn: 'id',
  sortOrder: 'desc',
};

export const useTable = (
  getRows,
  errorNotificationMessage,
  needsId,
  elementState,
  extraFilters,
  customColumnsSortMappper,
) => {
  const [filters, setFilters] = useState(null);
  const [paginationModel, setPaginationModel] = useState(
    defaultPaginationSettings,
  );
  const [sortModel, setSortModel] = useState([]);
  const [internalSortModel, setInternalSortModel] = useState(defaultSortModel);
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [rowCount, setRowCount] = useState(0);
  const navigate = useNavigate();
  const { getDecodedData } = useDecodedData(elementState);

  useEffect(() => {
    if (!needsId) {
      retrieveRows({
        ...defaultSortModel,
        start: paginationModel.page,
        length: paginationModel.pageSize,
      });
    }
  }, []);

  useEffect(() => {
    if (elementState) {
      retrieveRows({
        ...defaultSortModel,
        start: paginationModel.page,
        length: paginationModel.pageSize,
      });
    }
  }, [elementState]);

  async function retrieveRows(filter) {
    setLoading(true);
    const filters = { ...filter, ...extraFilters };
    let data = null;
    if (needsId) {
      data = await getRows(getDecodedData().id, filters);
    } else data = await getRows(filters);

    setLoading(false);

    if (data) {
      setRows(data.resultList);
      setRowCount(data.filtered);
      return;
    }

    showErrorNotification(errorNotificationMessage);
  }

  const handleOnSearch = (newFilterConfiguration) => {
    setFilters(newFilterConfiguration);
    retrieveRows({
      ...internalSortModel,
      ...newFilterConfiguration,
      start: paginationModel.page * paginationModel.pageSize,
      length: paginationModel.pageSize,
    });
  };

  const refreshRows = () => {
    retrieveRows({
      ...filters,
      ...internalSortModel,
      start: paginationModel.page * paginationModel.pageSize,
      length: paginationModel.pageSize,
    });
  };

  const handleOnPaginationModelChange = (model) => {
    setPaginationModel(model);
    retrieveRows({
      ...filters,
      ...internalSortModel,
      start: model.page * model.pageSize,
      length: model.pageSize,
    });
  };

  const handleOnSortModelChange = (model) => {
    if (model.length) {
      const sortColumn = customColumnsSortMappper
        ? customColumnsSortMappper.get(model[0]?.field)
        : columnsMapper.get(model[0]?.field);
      setSortModel(model);
      setInternalSortModel({
        sortColumn: sortColumn ?? '',
        sortOrder: model[0]?.sort ?? '',
      });

      retrieveRows({
        ...filters,
        start: paginationModel.page * paginationModel.pageSize,
        length: paginationModel.pageSize,
        sortColumn: sortColumn ?? '',
        sortOrder: model[0]?.sort ?? '',
      });
    } else {
      setSortModel([]);
      retrieveRows({
        ...defaultSortModel,
        start: paginationModel.page * paginationModel.pageSize,
        length: paginationModel.pageSize,
      });
    }
  };

  const handleOnRowClick = (row, navigateTo) => {
    const id = btoa(JSON.stringify({ id: row.id, name: row.name }));
    navigate(`/${navigateTo}/${id}`, {
      state: { clientId: id },
    });
  };

  const handleOnRowClickWithFields = (row, navigateTo, fields) => {
    const defaultField = { id: row.id };
    const object = fields.reduce((acc, field) => {
      return { ...acc, [field]: row[field] };
    }, defaultField);
    const id = btoa(JSON.stringify(object));
    navigate(`/${navigateTo}/${id}`, {
      state: { clientId: id },
    });
  };

  return {
    sortModel,
    paginationModel,
    loading,
    rowCount,
    rows,
    refreshRows,
    handleOnSearch,
    handleOnRowClickWithFields,
    handleOnPaginationModelChange,
    handleOnSortModelChange,
    handleOnRowClick,
  };
};
