import dayjs from 'dayjs';

export const columns = (t) => [
  {
    field: 'documentType',
    headerName: t('fileType'),
    minWidth: 150,
    flex: 0.2,
  },
  {
    field: 'fileName',
    headerName: t('fileName'),
    minWidth: 200,
    flex: 0.4,
  },
  {
    field: 'verified',
    headerName: t('verified'),
    flex: 0.15,
    minWidth: 120,
    headerAlign: 'left',
    align: 'left',
    valueGetter: (params) => {
      if (params.value === undefined) {
        return '-';
      }
      return params.value ? t('yes') : t('no');
    },
  },
  {
    field: 'lastModificationDate',
    headerName: t('lastModifiedDate'),
    flex: 0.2,
    minWidth: 150,
    valueGetter: (params) => {
      if (!params.value) {
        return '-';
      }
      return dayjs(params.value).format('DD/MM/YYYY');
    },
  },
];
