import axios from 'axios';
import CCAA from 'models/CCAA';
import Province from 'models/Province';

export default async function getProvinceList() {
  const { data } = await axios.get('/list/provinces').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  const provinces = [];
  const ccaa = [];

  data.map((item) => {
    provinces.push(new Province({
      id: item.id,
      name: item.title,
      code: item.codINE,
      parent: item.codINECCAA,
    }));
    if (!ccaa.find((auxItem) => auxItem.id === item.codINECCAA)) {
      ccaa.push(new CCAA({
        id: item.codINECCAA,
        code: item.codINECCAA,
        name: item.ccaa,
      }));
    }
    return false;
  });

  return {
    provinces,
    ccaa,
  };
}
