import { useLocation } from 'react-router-dom';

import ContentContainer from 'UI/atoms/ContentContainer';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';
import CreateReceiptTab from 'components/receipts/CreateReceiptTab';

const Create = () => {
  const [t] = useTranslation('receipts');

  const { pathname } = useLocation();

  const path = pathname.substring(0, pathname.length - 7);

  return (
    <PageContainer>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={
              path === '/receipts/commission'
                ? t('newReceiptCommission')
                : t('newReceiptPolicy')
            }
            breadcrumbs={[
              { path: '/', text: t('breadcrumbsHome') },
              { path, text: t('title') },
              {
                path: ``,
                text:
                  path === '/receipts/commission'
                    ? t('newReceiptCommission')
                    : t('newReceiptPolicy'),
              },
            ]}
          />
        </Header>

        <ContentContainer>
          <CreateReceiptTab />
        </ContentContainer>
      </>
    </PageContainer>
  );
};

export default Create;
