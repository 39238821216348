import { PropTypes } from 'prop-types';
import { createContext, useReducer } from 'react';

import initialState from './initialState';
import reducer from './reducer';

export const ClientContext = createContext();

const { Provider } = ClientContext;

const ClientProvider = (props) => {
  const { value } = props;
  const [state, dispatch] = useReducer(reducer, value || initialState);

  return <Provider value={[state, dispatch]}>{props.children}</Provider>;
};

ClientProvider.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
};

export default ClientProvider;
