import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import InsurerBasePage from '../base';
import { useTranslation } from 'react-i18next';
import InsurerDocumentationTab from 'components/insurer/InsurerDocumentationTab';

const Documentation = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, name: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('insurers');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 3 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <InsurerBasePage
      insurerName={clientData.name}
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/insurers', text: t('title') },
        {
          path: `/insurers/${clientState.clientId}`,
          text: clientData.name,
        },
        {
          path: `/insurers/${clientState.clientId}/documentation`,
          text: t('documentation'),
        },
      ]}
    >
      <InsurerDocumentationTab />
    </InsurerBasePage>
  );
};

export default Documentation;
