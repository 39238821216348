import { PropTypes } from 'prop-types';
import { Grid } from '@mui/material';

import styles from './styles.module.scss';

const FormColumn = ({
  xs = 24,
  sm = 12,
  md = 6,
  lg = 3,
  xl = 2,
  extraClassName = 'default',
  children,
  ...props
}) => {
  const hiddenColumns = [];
  if (xs === 0) {
    hiddenColumns.push('no-xs');
  }
  if (sm === 0) {
    hiddenColumns.push('no-sm');
  }
  if (md === 0) {
    hiddenColumns.push('no-md');
  }
  if (lg === 0) {
    hiddenColumns.push('no-lg');
  }
  if (xl === 0) {
    hiddenColumns.push('no-xl');
  }

  return (
    <Grid
      item
      paddingBottom={0}
      className={`${styles.column} ${styles[`column--${extraClassName}`]} ${hiddenColumns.map((i) => styles[i]).join(' ')}`}
      xs={xs}
      sm={sm}
      md={md}
      lg={lg}
      xl={xl}
      {...props}
    >
      {children}
    </Grid>
  );
};

FormColumn.propTypes = {
  xs: PropTypes.number,
  sm: PropTypes.number,
  md: PropTypes.number,
  lg: PropTypes.number,
  xl: PropTypes.number,
  extraClassName: PropTypes.string,
  children: PropTypes.node,
};

export default FormColumn;
