import { showErrorNotification } from 'utils/showToastNotifications';

export const useDownloadFile = (downloadFn, fileName, errorMessage) => {
  const downloadFile = async (...params) => {
    let fileData = null;
    let paramFileName = null;
    if (params) {
      const [parentId, documentId, fileName] = params;
      fileData = await downloadFn(parentId, documentId);
      paramFileName = fileName;
    } else {
      fileData = await downloadFn();
    }
    if (!fileData) {
      showErrorNotification(errorMessage);
      return false;
    }
    const fileurl = window.URL.createObjectURL(new Blob([fileData]));
    const link = document.createElement('a');
    link.href = fileurl;
    link.setAttribute('download', paramFileName ?? fileName);
    document.body.appendChild(link);
    link.click();
  };

  return {
    downloadFile,
  };
};
