import React from 'react';
import { PropTypes } from 'prop-types';
import TextField from '@mui/material/TextField';

import styles from './styles.module.scss';

const SearchBar = ({ value, onChange, error, type, label }) => {
  return (
    <div className={styles.container}>
      <TextField
        className={styles.searchBar}
        variant="standard"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        label={label}
        error={error}
        type={type}
        InputProps={{
          classes: {
            root: styles.input,
          },
        }}
        InputLabelProps={{
          classes: {
            root: styles.label,
            shrink: styles['label--shrink'],
          },
        }}
      />
    </div>
  );
};

SearchBar.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
};


export default SearchBar;
