import React from 'react';
import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const ContentContainer = ({ children, extraClass }) => {
  return (
    <div
      className={`${styles.container} ${
        extraClass ? styles[`container--${extraClass}`] : ''
      }`}
    >
      {children}
    </div>
  );
};

ContentContainer.propTypes = {
  children: PropTypes.node,
  extraClass: PropTypes.string,
};


export default ContentContainer;
