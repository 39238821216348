import { PropTypes } from 'prop-types';

import { Tabs as MuiTabs, Tab } from '@mui/material';

import styles from './styles.module.scss';

const Tabs = ({ selected, onChange, tabs }) => {
  return (
    <MuiTabs
      className={styles.tabs}
      classes={{
        indicator: styles.indicator,
        scroller: styles.scroller,
      }}
      value={selected}
      onChange={(e, newValue) => onChange(newValue)}
    >
      {tabs.map((tab) => (
        <Tab
          disabled={!onChange}
          key={tab.label}
          className={styles.tab}
          label={tab.label}
        />
      ))}
    </MuiTabs>
  );
};

Tabs.propTypes = {
  selected: PropTypes.number,
  onChange: PropTypes.func,
  tabs: PropTypes.array,
};

export default Tabs;
