export const UPDATE_CNAE = 'UPDATE_CNAE';
export const UPDATE_SIC = 'UPDATE_SIC';
export const UPDATE_SECTOR = 'UPDATE_SECTOR';
export const UPDATE_BRANCH = 'UPDATE_BRANCH';
export const UPDATE_MANAGER = 'UPDATE_MANAGER';
export const UPDATE_GEOGRAPHIC_DATA = 'UPDATE_GEOGRAPHIC_DATA';
export const UPDATE_PERIODICITY = 'UPDATE_PERIODICITY';
export const UPDATE_DOCUMENT_TYPES = 'UPDATE_DOCUMENT_TYPES';
export const UPDATE_NOTIFICATION_TYPES = 'UPDATE_NOTIFICATION_TYPES';
export const UPDATE_PROJECTS_STATUSES = 'UPDATE_PROJECTS_STATUSES';
export const UPDATE_RECEIPTS_STATUSES = 'UPDATE_RECEIPTS_STATUSES';
export const UPDATE_ROLES_LIST = 'UPDATE_ROLES_LIST';
