import { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { useAppStore } from 'context/AppProvider/hooks';
import Form from 'UI/molecules/Form';
import validationSchema from './validationSchema';
import getClients from 'services/clients/getClients';
import { findElementByLookupId } from 'utils/lookupIdMapper';
import getInsurers from 'services/insurers/getInsurers';
import getInsurerData from 'services/insurers/getInsurerData';
import { usePrevious } from 'hooks/usePreviousFormValue';
import { useDebouncer } from 'hooks/useDebouncer';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER, CLIENT_USER } from 'utils/constants';

const PolicyDataForm = ({ onSubmit, defaultFormValues }) => {
  const { t } = useTranslation('policiesComponent');
  const [appState] = useAppStore();
  const [userState] = useUserStore();
  const [submitting, setSubmitting] = useState(false);
  const [shrinkLabel, setShrinkLabel] = useState(!!defaultFormValues);
  const [shrinkLabelCommission, setShrinkLabelCommission] =
    useState(!!defaultFormValues);
  const [insurers, setInsurers] = useState([]);
  const [insurerData, setInsurerData] = useState(null);
  const [clients, setClients] = useState([]);
  const schema = validationSchema(t);
  const { loading: loadingClients, handleOnInputChange } = useDebouncer(
    getClients,
    setClients,
    defaultFormValues?.companyId,
  );
  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
    // TODO: change properties name, it should be insurerId and insurerName
    // removing insurerId which is a whole object and doesn't make sense
  } = useDebouncer(getInsurers, setInsurers, defaultFormValues?.insurer);

  const navigate = useNavigate();

  const methods = useForm({
    defaultValues: {
      ...defaultFormValues,
      branchId: findElementByLookupId(
        appState.branches,
        defaultFormValues?.branchId,
      ),
      managerId: findElementByLookupId(
        appState.managers,
        defaultFormValues?.managerId,
      ),
      cif: defaultFormValues?.companyId?.cif,
    },
    resolver: yupResolver(schema),
  });
  const {
    formState: { errors },
    handleSubmit,
    watch,
    setValue,
  } = methods;

  const watchClient = watch('companyId');
  const watchInsurer = watch('insurerId');
  const prevInsurer = usePrevious(watchInsurer);
  const watchBranch = watch('branchId');
  const watchPb = watch('pb');

  useEffect(() => {
    if (watchBranch) {
      setValue('traditionalBranchesCommission', watchBranch.commission);
      setShrinkLabelCommission(true);
    } else {
      setValue('traditionalBranchesCommission', '');
      setShrinkLabelCommission(false);
    }
  }, [watchBranch, setValue]);

  useEffect(() => {
    if (watchClient) {
      setShrinkLabel(true);
      setValue('cif', watchClient.cif);
    } else {
      setShrinkLabel(false);
      setValue('cif', '');
    }
  }, [watchClient, setValue]);
  useEffect(() => {
    if (watchPb === '' || watchPb < 0) {
      setValue('pb', 0);
    }
  }, [watchPb, setValue]);

  useEffect(() => {
    if (watchInsurer) {
      retrieveInsurerData(watchInsurer.id);
    }
    if (prevInsurer && prevInsurer?.id !== watchInsurer?.id) {
      setValue('branchId', null);
    }
  }, [watchInsurer, setValue, prevInsurer]);

  const handleOnSubmit = async (data) => {
    setSubmitting(true);
    await onSubmit({
      ...methods.getValues(),
      insurerId: methods.getValues('insurerId').id,
      agreement: data.agreement,
      managerId: methods.getValues('managerId').id,
      branchId: methods.getValues('branchId').id,
      companyId: methods.getValues('companyId').id,
    });
    setSubmitting(false);
  };

  async function retrieveInsurerData(id) {
    const data = await getInsurerData(id);
    setInsurerData(data);
  }

  return (
    <Form.Container>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input name="policyNum" label={t('policyNum')} />
            </Form.Column>

            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                options={insurers}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) =>
                  handleOnInputChangeInsurer(newValue)
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingInsurers}
                name="insurerId"
                label={t('insurer')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                name="managerId"
                getOptionLabel={(option) => option.name}
                options={appState.managers}
                label={t('manager')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                name="branchId"
                disabled={!insurerData || !watchInsurer || loadingInsurers}
                getOptionLabel={(option) => option.name}
                options={
                  insurerData ? insurerData?.branches : appState.branches
                }
                label={t('branch')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Autocomplete
                options={clients}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) => handleOnInputChange(newValue)}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingClients}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                name="companyId"
                label={t('clientName')}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="cif"
                disabled
                label={t('cif')}
                shrinkLabel={shrinkLabel}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Date label={t('expirationDate')} name="expirationDate" />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="numWorkersWithInsurance"
                label={t('numWorkersWithInsurance')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="numWorkersOffWork"
                label={t('numWorkersOff')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Select
                options={[
                  { label: t('yes'), value: true },
                  { label: t('no'), value: false },
                ]}
                style={{ marginBottom: 0 }}
                name="agreement"
                label={t('agreement')}
              />
            </Form.Column>
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('premium')} />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="netPremium"
                label={t('netPremium')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>

            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="totalPremium"
                label={t('totalPremium')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="pb"
                label={t('pb')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>

            {[CLIENT_USER].includes(userState.role) ? null : (
              <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
                <Form.Input
                  disabled
                  shrinkLabel={shrinkLabelCommission}
                  name="traditionalBranchesCommission"
                  label={t('traditionalBranchesCommission')}
                  inputProps={{ type: 'number' }}
                />
              </Form.Column>
            )}
          </Form.Row>
          <Form.Row rowSpacing={{ xs: 2 }} alignItems="flex-end">
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Input
                name="policyReceipt"
                label={t('hiringReceipt')}
                inputProps={{ type: 'number' }}
              />
            </Form.Column>
            <Form.Column xs={24} sm={12} md={6} lg={5} xl={4}>
              <Form.Select
                options={appState.periodicity.map((period) => ({
                  label: period.periodicity,
                  value: period.id,
                }))}
                style={{ marginBottom: 0 }}
                name="periodicityId"
                label={t('periodicity')}
              />
            </Form.Column>
          </Form.Row>
          {[AINA_MANAGER].includes(userState.role) ? (
            <Form.Footer
              onClickSecondary={() => navigate('/policies')}
              loading={submitting}
            />
          ) : null}
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

PolicyDataForm.propTypes = {
  defaultFormValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default PolicyDataForm;
