import { PropTypes } from 'prop-types';

import AinaLogo from 'assets/aina-logo-big.png';
import PageContainer from 'UI/molecules/PageContainer';
import PublicHeader from 'UI/molecules/PublicHeader';

const PublicPage = ({ children }) => (
  <PageContainer extraClass={'public'}>
    <PublicHeader>
      <img alt="Aina" src={AinaLogo} />
    </PublicHeader>
    {children}
  </PageContainer>
);

PublicPage.propTypes = {
  children: PropTypes.node,
};

export default PublicPage;
