import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const PublicHeader = ({ children }) => (
  <div className={styles.header}>{children}</div>
);

PublicHeader.propTypes = {
  children: PropTypes.node,
};

export default PublicHeader;
