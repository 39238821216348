import { useEffect, useState } from 'react';
import { SELECT_CLIENT, SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import PolicyBasePage from '../base';
import PolicyDocumentation from 'components/documentation/PolicyDocumentation';

const Documentation = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({
    id: null,
    policyNum: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('policies');

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 1 });
  }, []);

  useEffect(() => {
    const { clientId } = params;
    if (!clientId) {
      navigate(`/policies`);
      return;
    }
    clientDispatch({ type: SELECT_CLIENT, payload: clientId });
  }, [params]);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <PolicyBasePage
      extraClass="form"
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/policies', text: t('title') },
        {
          path: `/policies/${clientState.clientId}`,
          text: clientData.policyNum,
        },
      ]}
    >
      <PolicyDocumentation />
    </PolicyBasePage>
  );
};

export default Documentation;
