import { useTranslation } from 'react-i18next';
import ProjectConfigurationForm from '../ProjectConfigurationForm';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useElementData } from 'hooks/useElementData';
import { useElementSubmit } from 'hooks/useElementSubmit';
import getProjectStart from 'services/projects/getProjectStart';
import updateProject from 'services/projects/updateProject';
import Loader from 'UI/atoms/Loader';
import { useEffect, useState } from 'react';
import getClient from 'services/clients/getClient';
import getPolicy from 'services/policies/getPolicy';

const ProjectConfigurationTab = () => {
  const [t] = useTranslation('projectsComponent');
  const [loadingInsurer, setLoadingInsurer] = useState(true);

  const [clientState] = useClientStore();
  const [value, setValue] = useState();
  const { loading, data } = useElementData(
    clientState.clientId,
    getProjectStart,
  );
  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateProject,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  useEffect(() => {
    if (data) {
      retrieveData(data.currentPolicyId, data.companyId);
    }
  }, [data]);

  async function retrieveData(policyId, companyId) {
    setLoadingInsurer(true);
    const insurer = await getPolicy(policyId);
    const client = await getClient(companyId);
    setValue({ ...data, currentPolicyId: insurer, companyId: client });
    setLoadingInsurer(false);
  }

  if (loading || loadingInsurer) {
    return <Loader text={t('loading')} />;
  }

  return (
    <ProjectConfigurationForm
      onSubmit={handleOnSubmit}
      defaultFormValues={value}
    />
  );
};

export default ProjectConfigurationTab;
