import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { columns as columsConfiguration } from './tableConfiguration';
import { EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER } from 'utils/constants';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import CircularProgress from 'UI/atoms/CircularProgress';
import { useState } from 'react';

const UsersTable = ({
  loading,
  loadingEmail,
  users,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  addButtonOnClick,
  onEmailClick,
  onSortModelChange,
  onEdit,
}) => {
  const { t } = useTranslation('user');
  const [userState] = useUserStore();
  const [selectedUser, setSelectedUser] = useState(null);
  const columns = columsConfiguration(t);

  function getColumns() {
    if ([AINA_MANAGER].includes(userState.role)) {
      return [
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actionsHeader'),
          getActions: (params) => [
            <GridActionsCellItem
              key="mail"
              icon={
                loadingEmail && selectedUser === params.row.id ? (
                  <CircularProgress customColor="blue" size={16} />
                ) : (
                  <MailOutlineIcon color="primary" />
                )
              }
              title={t('retrievePassword')}
              label={t('email')}
              onClick={() => {
                setSelectedUser(params.row.id);
                onEmailClick(params.row.email);
              }}
            />,
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label={t('edit')}
              onClick={() => onEdit(params.row)}
            />,
          ],
        },
      ];
    }
    return [...columns];
  }

  return (
    <CustomTable
      loading={loading}
      rows={users}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[1, 2, 10, 20, 50, 100]}
      columns={getColumns()}
      addButtonText={t('newUser')}
      addButtonOnClick={addButtonOnClick}
      onPaginationModelChange={(model) => onPaginationModelChange(model)}
      onSortModelChange={(model) => onSortModelChange(model)}
      onRowClick={(row) => onEdit(row)}
    />
  );
};

UsersTable.propTypes = {
  loading: PropTypes.bool,
  loadingEmail: PropTypes.bool,
  users: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  onEmailClick: PropTypes.func,
  onEdit: PropTypes.func,
  onSortModelChange: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default UsersTable;
