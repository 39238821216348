import { Grid } from '@mui/material';
import { PropTypes } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Select from 'UI/atoms/Select';
import Title from 'UI/atoms/Title';
import { useEffect, useState } from 'react';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';
import { useDecodedData } from 'hooks/useDecodedData';
import { useClientStore } from 'context/ClientProvider/hooks';
import InputFile from 'UI/atoms/InputFile';
import Autocomplete from 'UI/atoms/Autocomplete';
import getInsurers from 'services/insurers/getInsurers';
import { useDebouncer } from 'hooks/useDebouncer';
import { yupResolver } from '@hookform/resolvers/yup';
import validationSchema from './validationSchema';
import Form from 'UI/molecules/Form';

const formDefaultValues = {
  insurer: null,
  file: null,
  verified: false,
};

const OfferDialog = ({ open, onClose, createDocumentation, ...props }) => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();
  const [insurers, setInsurers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [companyId, setCompanyId] = useState();
  const schema = validationSchema(t);
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
  } = useDebouncer(getInsurers, setInsurers, false);

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors, isDirty },
  } = methods;

  useEffect(() => {
    if (!clientState.clientId) return;
    setCompanyId(getDecodedData().id);
  }, [clientState.clientId]);

  async function handleUpload() {
    setLoading(true);
    const data = await createDocumentation(
      companyId,
      methods.getValues('file')[0],
      {
        verified: methods.getValues('verified'),
        insurerId: methods.getValues('insurer')?.id,
      },
    );

    setLoading(false);
    if (data !== null) {
      showNotification(t('success'));
      handleOnClose();
      return;
    }
    showErrorNotification(t('error'));
  }

  const handleOnClose = () => {
    methods.reset(formDefaultValues);
    onClose();
  };

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <Title size="medium">{t('createOffer')}</Title>
        <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleUpload)}>
            <Grid container rowGap={2} justifyContent="space-between">
              <Grid item xs={12}>
                <Autocomplete
                  filterOptions={(x) => x}
                  options={insurers}
                  onInputChange={(e, newValue) =>
                    handleOnInputChangeInsurer(newValue)
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  getOptionLabel={(option) => option.name ?? ''}
                  loading={loadingInsurers}
                  name="insurer"
                  label={t('insurerLabel')}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputFile
                  name="file"
                  fileName={t('selectFile')}
                  register={methods.register}
                  id="fileId"
                />
              </Grid>

              <Grid item xs={12} md={5}>
                <Select
                  options={[
                    { label: t('yes'), value: true },
                    { label: t('no'), value: false },
                  ]}
                  color="grey"
                  extraClassName="no-margin-label"
                  name="verified"
                  label={t('verified')}
                />
              </Grid>

              <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                <PrimaryBtn
                  loading={loading}
                  type="submit"
                  disabled={!isDirty}
                  text={t('addAction')}
                />
              </Grid>
              <Grid item xs={12} md={7} marginTop={{ md: 3, lg: 6 }}>
                <SecondaryBtn
                  extraClassName="modal"
                  onClick={onClose}
                  text={t('cancelAction')}
                />
              </Grid>
            </Grid>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

OfferDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  createDocumentation: PropTypes.func,
  downloadDocumentation: PropTypes.func,
  selectedDocument: PropTypes.object,
};

export default OfferDialog;
