import { useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import Form from 'UI/molecules/Form';
import PublicPage from 'UI/molecules/PublicPage';
import PublicForm from 'components/blocks/PublicForm';
import postResetPassword from 'services/auth/postResetPassword';
import {
  showErrorNotification,
  showSuccessNotification,
} from 'utils/showToastNotifications';

const validationSchema = (t) =>
  yup
    .object({
      email: yup.string().required(t('mandatoryEmail')),
    })
    .required();

const RecoverPassword = () => {
  const [t] = useTranslation('renamePassword');
  const [loading, setLoading] = useState(false);
  const schema = validationSchema(t);
  const methods = useForm({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();

  async function onSubmit() {
    setLoading(true);
    const res = await postResetPassword(methods.getValues());
    setLoading(false);
    if (res) {
      showSuccessNotification(t('linkSentSuccessfully'));
    } else {
      showErrorNotification(t('error'));
    }
  }

  return (
    <PublicPage>
      <PublicForm
        title={t('title')}
        onSubmit={onSubmit}
        methods={methods}
        text={t('text')}
        fields={() => (
          <>
            <Form.Input
              label={t('emailLabel')}
              withValidation
              type="email"
              name="email"
            />
          </>
        )}
        actions={() => (
          <>
            <Form.PrimaryButton
              text={t('title')}
              type="submit"
              loading={loading}
            />
            <Form.SecondaryButton
              text={t('backToLogin')}
              isBold
              onClick={() => navigate(-1)}
            />
          </>
        )}
      ></PublicForm>
    </PublicPage>
  );
};

export default RecoverPassword;
