import Dialog from 'UI/atoms/Dialog';
import React from 'react';
import dayjs from 'dayjs';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import 'dayjs/locale/es';
import { useNavigate } from 'react-router-dom';
import { useUserStore } from 'context/UserProvider/hooks';
import {
  AINA_MANAGER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import { PropTypes } from 'prop-types';

const ProjectDialog = ({ open, onClose, selectedProject }) => {
  const [t] = useTranslation('projectsComponent');
  const [userState] = useUserStore();
  const navigate = useNavigate();
  const handleOnRowClickWithFields = () => {
    const fields = {
      id: selectedProject.id,
      companyName: selectedProject.companyName,
      code: selectedProject.code,
    };
    const id = btoa(JSON.stringify(fields));
    if ([AINA_MANAGER].includes(userState.role)) {
      navigate(`/projects/${id}`, {
        state: { clientId: id },
      });
    } else {
      navigate(`/projects/${id}/phases`, {
        state: { clientId: id },
      });
    }
  };

  function getGoToProjectBtn() {
    switch (userState.role) {
      case AINA_MANAGER:
        return (
          <PrimaryBtn
            text={t('goToProject')}
            onClick={handleOnRowClickWithFields}
          />
        );

      case INSURER_MANAGER:
      case INSURER_SUPPORT:
      case INSURER_USER:
        if (/true/.test(selectedProject?.accessGranted)) {
          return (
            <PrimaryBtn
              text={t('goToProject')}
              onClick={handleOnRowClickWithFields}
            />
          );
        }
        return null;
      default:
        return null;
    }
  }

  return (
    <Dialog
      classes={{
        paper: styles.container,
      }}
      open={open}
      showCloseButton={true}
      onClose={onClose}
    >
      <p className={styles.modalText}>
        <b>{t('expirationPolicy')}: </b>
        {dayjs(selectedProject?.expirationDate).format('YYYY-MM-DD')}
      </p>
      {[AINA_MANAGER].includes(userState.role) ? (
        <p className={styles.modalText}>
          <b>{t('client')}: </b>
          {selectedProject?.companyName}
        </p>
      ) : null}
      <p className={styles.modalText}>
        <b>{t('code')}: </b>
        {selectedProject?.code}
      </p>
      <p className={styles.modalText}>
        <b>{t('sector')}: </b>
        {selectedProject?.cnae}
      </p>
      <p className={styles.modalText}>
        <b>{t('branch')}: </b>
        {selectedProject?.branch}
      </p>
      <p className={styles.modalText}>
        <b>{t('status')}: </b>
        {selectedProject?.status}
      </p>
      <p className={styles.modalText}>
        <b>{t('numWorkers')}: </b>
        {selectedProject?.numWorkers}
      </p>
      <p className={styles.modalText}>
        {selectedProject?.branch === 'ACCIDENTES' ? (
          <>
            <b>{t('agreement')}: </b>
            {selectedProject?.agreement ? t('yes') : t('no')}
          </>
        ) : (
          <>
            <b>{t('averageAgeCalendar')}: </b>
            {Math.round(selectedProject?.averageAge * 100) / 100}
          </>
        )}
      </p>
      <p className={styles.modalText}>
        <b>{t('totalPremium')}: </b>
        {Math.round(selectedProject?.currentPremium * 100) / 100}€
      </p>
      {getGoToProjectBtn()}
    </Dialog>
  );
};

export default ProjectDialog;

ProjectDialog.propTypes = {
  open: PropTypes.bool,
  selectedProject: PropTypes.object,
  onClose: PropTypes.func,
};
