import axios from 'axios';
import Client from 'models/Client';

export default async function getClients(filter) {
  const params = new URLSearchParams();

  const pagination = {
    Start: filter.start,
    Length: filter.length,
  };

  const mappedFilters = {
    search: filter.searchBarText ?? '',
    Ciudad: filter.city ?? '',
    activeProject: filter.activeProjects ?? '',
    onboarding: filter.onboarding ?? '',
    letterofappointment: filter.letterOfAppointment ?? '',
    NumTrabajadoresStart: filter.numWorkersStart ?? '',
    NumTrabajadoresEnd: filter.numWorkersEnd ?? '',
    EdadMediaStart: filter.averageAgeStart ?? '',
    EdadMediaEnd: filter.averageAgeEnd ?? '',
    NPolizasStart: filter.ainaPoliciesStart ?? '',
    NPolizasEnd: filter.ainaPoliciesEnd ?? '',
    FacurationStart: filter.billingStart ?? '',
    FacurationEnd: filter.billingEnd ?? '',
    CNAELookupId: filter.cnaeId ?? '',
    SICLookupId: filter.sicId ?? '',
    CCAALookupId: filter.ccaaId ?? '',
    ProvinceLookupId: filter.provinceId ?? '',
    Sortcolumn: filter.sortColumn ?? '',
    Sortorder: filter.sortOrder ?? '',
  };

  for (const [key, value] of Object.entries(
    fuseFilters(pagination, mappedFilters),
  )) {
    if (value !== '') params.append(key, value);
  }
  const config = {
    params,
  };

  try {
    const { data } = await axios.get('/clients', config);
    return {
      resultList: data.resultList.map(
        (item) =>
          new Client({
            id: item.id,
            cif: item.cif,
            name: item.razonSocial,
            provinceId: item.provinciaLookupId,
            city: item.ciudad,
            cnaeId: item.cnaeLookupId,
            sicId: item.sicLookupId,
            numWorkers: item.numTrabajadores,
            averageAge: item.edadMedia,
            address: item.direccion,
            ainaPolicies: item.polizasAina,
            onboarding: item.onboarding,
            activeProjects: item.proyectosActivos,
            letterOfAppointment: item.cartaNombramiento,
          }),
      ),
      total: data.total,
      filtered: data.filtered,
    };
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}

function fuseFilters(pagination, filters) {
  if (pagination.Start !== undefined && pagination.Length !== undefined) {
    return { ...pagination, ...filters };
  } else {
    return { ...filters };
  }
}
