import { useEffect, useState } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useDecodedData } from 'hooks/useDecodedData';
import { useTranslation } from 'react-i18next';
import InsurerBasePage from '../base';
import InsurerDataTab from 'components/insurer/InsurerDataTab';

const GeneralData = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [clientData, setClientData] = useState({ id: null, name: '' });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('insurers');

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  useEffect(() => {
    if (!clientState.clientId) return;
    setClientData(getDecodedData());
  }, [clientState.clientId]);

  return (
    <InsurerBasePage
      insurerName={clientData.name}
      extraClass="form"
      breadcrumbs={[
        { path: '/', text: t('breadcrumbsHome') },
        { path: '/insurers', text: t('title') },
        {
          path: `/insurers/${clientState.clientId}`,
          text: clientData.name,
        },
        {
          path: `/insurers/${clientState.clientId}`,
          text: t('generalData'),
        },
      ]}
    >
      <InsurerDataTab />
    </InsurerBasePage>
  );
};

export default GeneralData;
