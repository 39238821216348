import MuiButton from '@mui/material/Button';
import PropTypes from 'prop-types';

import styles from './styles.module.scss';

const Button = ({ size, icon, color, type, text, onClick }) => {
  return (
    <MuiButton
      classes={{
        root: `${styles.button} ${type ? styles[`button--${type}`] : ''} ${color ? styles[`button--${color}`] : ''} ${size ? styles[`button--${size}`] : ''}`,
      }}
      onClick={onClick}
    >
      {icon && <img src={icon} />}
      {text && <span className={styles.text}>{text}</span>}
    </MuiButton>
  );
};

Button.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
};

export default Button;
