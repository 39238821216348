import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import Autocomplete from 'UI/atoms/Autocomplete';
import { useAppStore } from 'context/AppProvider/hooks';

const typeMap = new Map();
typeMap.set('client', 'Cliente');
typeMap.set('insurer', 'Aseguradora');
typeMap.set('general', 'General');

const extraInsurerDocuments = ['11', '7'];
const extraClientDocuments = ['17'];
const mixedDocuments = ['7', '8', '5', '12', '11', '10', '5', '25'];

const DocumentTypeFilter = ({ fieldName, disabled, documentType }) => {
  const [t] = useTranslation('filters');
  const [appState] = useAppStore();
  const [options, setOptions] = useState([]);

  useEffect(() => {
    setOptions(appState.documentTypes);
  }, [appState]);

  function getOptions() {
    switch (documentType) {
      case 'insurer':
        return options.filter(
          (option) =>
            option.group === typeMap.get(documentType) ||
            extraInsurerDocuments.includes(option.id),
        );

      case 'mixed':
        return options.filter((option) => mixedDocuments.includes(option.id));

      case 'client':
        return options.filter(
          (option) =>
            option.group === typeMap.get(documentType) ||
            extraClientDocuments.includes(option.id),
        );

      default:
        return options.filter(
          (option) => option.group === typeMap.get('general'),
        );
    }
  }
  return (
    <Autocomplete
      label={t('fileType')}
      name={fieldName}
      options={getOptions()}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
    />
  );
};

DocumentTypeFilter.propTypes = {
  fieldName: PropTypes.string,
  documentType: PropTypes.string,
  disabled: PropTypes.bool,
};

export default DocumentTypeFilter;
