import { useTranslation } from 'react-i18next';
import ProjectOffersForm from '../ProjectOffersForm';
import { useClientStore } from 'context/ClientProvider/hooks';

import { useTable } from 'hooks/useTable';
import getOffers from 'services/projects/getOffers';
import updateBenchmark from 'services/projects/updateBenchmark';
import updateProjectStatus from 'services/projects/updateProjectStatus';
import { PropTypes } from 'prop-types';
import createBenchmark from 'services/projects/crreateBenchmark';
import deleteOffer from 'services/projects/deleteOffer';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import { Alert } from '@mui/material';
import { PROJECT_STATUS_CANCELLED_ID } from 'utils/constants';
import { useDialog } from 'hooks/useDialog';

const ProjectOffersTab = ({
  title,
  date,
  currentRound,
  benchmark,
  currentProject,
  projectWinner,
  onStatusChange,
}) => {
  const [t] = useTranslation('projectsComponent');
  const [clientState] = useClientStore();
  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    refreshRows,
    handleOnRowClick,
    handleOnPaginationModelChange,
    handleOnSortModelChange,
  } = useTable(getOffers, t('error'), true, clientState.clientId, {
    statusId: currentRound,
  });

  const {
    openConfirmationDialog,
    loadingDialog,
    handleDeleteElement,
    handleOnClose,
    handleOnDelete,
  } = useDialog(undefined, undefined, refreshRows, deleteOffer);

  return (
    <>
      {currentProject.statusId === PROJECT_STATUS_CANCELLED_ID ? (
        <Alert severity="error" style={{ marginBottom: 42 }}>
          {t('projectCancelled')}
        </Alert>
      ) : null}

      <ProjectOffersForm
        title={title}
        date={date}
        projectWinner={projectWinner}
        benchmark={benchmark}
        rows={rows}
        rowCount={rowCount}
        sortModel={sortModel}
        paginationModel={paginationModel}
        loadingTable={loading}
        onDeleteOffer={handleOnDelete}
        onStatusChange={onStatusChange}
        onRowClick={handleOnRowClick}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        refreshOffers={refreshRows}
        currentRound={currentRound}
        currentProject={currentProject}
        updateProject={updateProjectStatus}
        onSubmit={benchmark ? updateBenchmark : createBenchmark}
      />
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.messageDeleteOffer')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement()}
        onClose={handleOnClose}
      />
    </>
  );
};

export default ProjectOffersTab;

ProjectOffersTab.propTypes = {
  currentRound: PropTypes.string,
  currentProject: PropTypes.object,
  benchmark: PropTypes.object,
  projectWinner: PropTypes.object,
  date: PropTypes.string,
  onStatusChange: PropTypes.func,
  title: PropTypes.string,
};
