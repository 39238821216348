import {
  UPDATE_BRANCH,
  UPDATE_CNAE,
  UPDATE_DOCUMENT_TYPES,
  UPDATE_NOTIFICATION_TYPES,
  UPDATE_GEOGRAPHIC_DATA,
  UPDATE_MANAGER,
  UPDATE_PERIODICITY,
  UPDATE_PROJECTS_STATUSES,
  UPDATE_RECEIPTS_STATUSES,
  UPDATE_ROLES_LIST,
  UPDATE_SECTOR,
  UPDATE_SIC,
} from './constants';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_CNAE: {
      const newCnaeList = JSON.stringify(action.payload);
      window.localStorage.setItem('cnaeList', newCnaeList);

      return {
        ...state,
        cnae: action.payload,
      };
    }

    case UPDATE_SIC: {
      const newSicList = JSON.stringify(action.payload);
      window.localStorage.setItem('sicList', newSicList);

      return {
        ...state,
        sic: action.payload,
      };
    }
    case UPDATE_PROJECTS_STATUSES: {
      const newStatusesList = JSON.stringify(action.payload);
      window.localStorage.setItem('statusesList', newStatusesList);

      return {
        ...state,
        statuses: action.payload,
      };
    }
    case UPDATE_RECEIPTS_STATUSES: {
      const newStatusesList = JSON.stringify(action.payload);
      window.localStorage.setItem('receiptStatusesList', newStatusesList);

      return {
        ...state,
        receiptStatuses: action.payload,
      };
    }
    case UPDATE_SECTOR: {
      const newSectorList = JSON.stringify(action.payload);
      window.localStorage.setItem('sectorList', newSectorList);

      return {
        ...state,
        sectors: action.payload,
      };
    }
    case UPDATE_PERIODICITY: {
      const periodicityList = JSON.stringify(action.payload);
      window.localStorage.setItem('periodicityList', periodicityList);

      return {
        ...state,
        periodicity: action.payload,
      };
    }
    case UPDATE_BRANCH: {
      const newBranchList = JSON.stringify(action.payload);
      window.localStorage.setItem('branchesList', newBranchList);

      return {
        ...state,
        branches: action.payload,
      };
    }
    case UPDATE_MANAGER: {
      const newManagerList = JSON.stringify(action.payload);
      window.localStorage.setItem('managersList', newManagerList);

      return {
        ...state,
        managers: action.payload,
      };
    }
    case UPDATE_ROLES_LIST: {
      const newRoleList = JSON.stringify(action.payload);
      window.localStorage.setItem('rolesList', newRoleList);

      return {
        ...state,
        roles: action.payload,
      };
    }

    case UPDATE_GEOGRAPHIC_DATA: {
      const newGeographicDataList = JSON.stringify(action.payload);
      window.localStorage.setItem('geographicDataList', newGeographicDataList);

      return {
        ...state,
        geographicData: action.payload,
      };
    }

    case UPDATE_DOCUMENT_TYPES: {
      const newDocumentTypesList = JSON.stringify(action.payload);
      window.localStorage.setItem('documentTypeList', newDocumentTypesList);

      return {
        ...state,
        documentTypes: action.payload,
      };
    }

    case UPDATE_NOTIFICATION_TYPES: {
      const newNotificationTypesList = JSON.stringify(action.payload);
      window.localStorage.setItem(
        'notificationTypeList',
        newNotificationTypesList,
      );

      return {
        ...state,
        notificationTypes: action.payload,
      };
    }

    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
};

export default reducer;
