class Client {
  constructor(params) {
    this.id = params.id;
    this.cif = params.cif;
    this.name = params.name;
    this.provinceId = params.provinceId;
    this.city = params.city;
    this.cnaeId = params.cnaeId;
    this.sicId = params.sicId;
    this.numWorkers = params.numWorkers;
    this.averageAge = params.averageAge;
    this.address = params.address;
    this.zipCode = params.zipCode;
    this.phone = params.phone;
    this.managerId = params.managerId;
    this.email = params.email;
    this.turnover = params.turnover;
    this.preferredContact = params.preferredContact;
    this.ainaPolicies = params.ainaPolicies;
    this.onboarding = params.onboarding;
    this.activeProjects = params.activeProjects;
    this.letterOfAppointment = params.letterOfAppointment;
    this.trademark = params.trademark;
    this.domain = params.domain;
    this.notes = params.notes;
  }
}

export default Client;
