import axios from 'axios';
import Status from 'models/Status';

export default async function getProjectsStatuses() {
  const { data } = await axios.get('/list/projectstatuses').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  return data.map(
    (item) =>
      new Status({
        id: item.id,
        status: item.estado,
      }),
  );
}
