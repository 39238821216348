import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { SELECT_CLIENT } from 'context/ClientProvider/constants';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';
import { useDecodedData } from 'hooks/useDecodedData';
import { UserIconComponent } from 'assets/icons/IconsLibrary';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER, INSURER_MANAGER } from 'utils/constants';

const ProjectBasePage = ({ children, breadcrumbs, extraClass }) => {
  const [clientId, setClientId] = useState(null);
  const { pathname } = useLocation();
  const params = useParams();
  const [userState] = useUserStore();
  const [clientState, clientDispatch] = useClientStore();
  const navigate = useNavigate();

  const [clientData, setClientData] = useState({
    id: null,
    code: '',
    companyId: null,
    companyName: '',
  });
  const { getDecodedData } = useDecodedData(clientState.clientId);
  const [t] = useTranslation('projects');

  const tabOptionsDefault = [
    {
      label: t('phases'),
      route: `/projects/${clientId}/phases`,
    },
  ];
  const tabOptionsInsurerManager = [
    {
      label: t('phases'),
      route: `/projects/${clientId}/phases`,
    },
    {
      label: t('documentationProject'),
      route: `/projects/${clientId}/documentation`,
    },
  ];
  const tabOptionsManager = [
    {
      label: t('configuration'),
      route: `/projects/${clientId}`,
    },
    {
      label: t('phases'),
      route: `/projects/${clientId}/phases`,
    },
    {
      label: t('documentationProject'),
      route: `/projects/${clientId}/documentation`,
    },
    {
      label: t('documentationClient'),
      route: `/projects/${clientId}/documentation-client`,
    },
  ];

  useEffect(() => {
    if (!clientId) return;
    setClientData(getDecodedData());
  }, [clientId]);

  useEffect(() => {
    const { clientId } = params;
    if (!clientId) {
      navigate(`/projects`);
      return;
    }
    setClientId(clientId);
    clientDispatch({ type: SELECT_CLIENT, payload: clientId });
  }, [params]);

  const changeTab = (selectedTab) => {
    let newTab;
    if ([AINA_MANAGER].includes(userState.role)) {
      newTab = tabOptionsManager[selectedTab];
    } else {
      newTab = tabOptionsInsurerManager[selectedTab];
    }
    navigate(newTab.route, { state: { clientId } });
  };

  function handleOnChipClick(chip) {
    const encodedData = btoa(
      JSON.stringify({ id: chip.id, name: chip.labelEntityName }),
    );

    navigate(`/clients/${encodedData}`, {
      state: { clientId: encodedData },
    });
  }

  return (
    <PageContainer extraClass={extraClass}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={clientData.code}
            onChipClick={handleOnChipClick}
            chips={
              [AINA_MANAGER].includes(userState.role) &&
              clientData.companyId &&
              clientData.companyName
                ? [
                    {
                      id: clientData?.companyId,
                      labelEntityName: clientData?.companyName,
                      label: 'Cliente: ',
                      icon: <UserIconComponent />,
                    },
                  ]
                : null
            }
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            {[AINA_MANAGER].includes(userState.role) ? (
              <CustomTabs
                tabs={tabOptionsManager}
                selected={clientState.tab}
                onChange={changeTab}
              />
            ) : [INSURER_MANAGER].includes(userState.role) ? (
              <CustomTabs
                tabs={tabOptionsInsurerManager}
                selected={clientState.tab}
                onChange={changeTab}
              />
            ) : (
              <CustomTabs tabs={tabOptionsDefault} selected={0} />
            )}
          </SubHeader>
        </Header>

        {clientId ? <ContentContainer>{children}</ContentContainer> : null}
      </>
    </PageContainer>
  );
};

ProjectBasePage.propTypes = {
  extraClass: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default ProjectBasePage;
