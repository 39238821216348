import axios from 'axios';
import Receipt from 'models/Receipt';

export default async function create(data) {
  const receipt = new Receipt(data);

  const body = {
    recibo: receipt.receipt,
    aseguradoraLookupId: receipt.insurerId,
    polizaLookupId: receipt.policyId,
    ramoLookupId: receipt.branchId,
    empresaClienteLookupId: receipt.companyId,
    emision: receipt.issueDate,
    vencimiento: new Date(),
    importe: receipt.totalAmount,
    comision: receipt.commission,
    primaNeta: receipt.netPremium,
    primaTotal: receipt.totalPremium,
    periodicidadLookupId: receipt.periodicityId,
    estadoLookupId: receipt.statusId,
    tiposDeRecibosLookupId: receipt.typeId,
  };

  try {
    const res = await axios.post('/receipts', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
