import axios from 'axios';

export default async function deleteDocument(policyId, documentId) {
  try {
    const res = await axios.delete(
      '/policies/' + policyId + '/documentation/' + documentId,
    );

    return res;
  } catch (error) {
    return error.response;
  }
}
