import { FormProvider } from 'react-hook-form';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';

import Form from 'UI/molecules/Form';
import FilterSecondLevelContainer from 'UI/atoms/FilterSecondLevelContainer';
import { useAppStore } from 'context/AppProvider/hooks';
import getInsurers from 'services/insurers/getInsurers';
import getClients from 'services/clients/getClients';
import { useDebouncer } from 'hooks/useDebouncer';
import { useState } from 'react';
import { useUserStore } from 'context/UserProvider/hooks';
import { AINA_MANAGER, CLIENT_USER, INSURER_SUPPORT } from 'utils/constants';

const ProjectFilter = ({ methods }) => {
  const [t] = useTranslation('projectsComponent');
  const [appState] = useAppStore();

  const [userState] = useUserStore();
  const [insurers, setInsurers] = useState([]);
  const [clients, setClients] = useState([]);
  const [insurersParticipants, setInsurersParticipants] = useState([]);

  const { loading: loadingClients, handleOnInputChange } = useDebouncer(
    getClients,
    setClients,
    false,
  );

  const {
    loading: loadingInsurers,
    handleOnInputChange: handleOnInputChangeInsurer,
  } = useDebouncer(getInsurers, setInsurers, false);

  const {
    loading: loadingInsurersParticipants,
    handleOnInputChange: handleOnInputChangeInsurerParticipants,
  } = useDebouncer(getInsurers, setInsurersParticipants, false);

  return (
    <FormProvider {...methods}>
      <FilterSecondLevelContainer>
        <Form.Row columns={24} alignItems="flex-end">
          {[AINA_MANAGER, INSURER_SUPPORT].includes(userState.role) ? (
            <Form.Column xs={24} sm={12} md={8} lg={8} xl={8}>
              <Form.Autocomplete
                options={clients}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) => handleOnInputChange(newValue)}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingClients}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                name="companyId"
                label={t('socialReason')}
              />
            </Form.Column>
          ) : null}

          <Form.Column
            xs={24}
            sm={12}
            md={8}
            lg={8}
            xl={8}
            marginTop={{ xs: 1 }}
          >
            <Form.Autocomplete
              name="branchId"
              getOptionLabel={(option) => option.name}
              options={appState.branches}
              label={t('branch')}
            />
          </Form.Column>
          {[AINA_MANAGER, CLIENT_USER].includes(userState.role) ? (
            <Form.Column
              xs={24}
              sm={24}
              md={8}
              lg={8}
              xl={8}
              marginTop={{ xs: 1 }}
            >
              <Form.Autocomplete
                options={insurersParticipants}
                onInputChange={(e, newValue) =>
                  handleOnInputChangeInsurerParticipants(newValue)
                }
                filterOptions={(x) => x}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingInsurersParticipants}
                name="participantsId"
                label={t('participants')}
              />
            </Form.Column>
          ) : null}
        </Form.Row>

        {[AINA_MANAGER, CLIENT_USER].includes(userState.role) ? (
          <Form.Row columns={24} alignItems="flex-end">
            <Form.Column
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              marginTop={{ xs: 1 }}
            >
              <Form.Autocomplete
                options={insurers}
                filterOptions={(x) => x}
                onInputChange={(e, newValue) =>
                  handleOnInputChangeInsurer(newValue)
                }
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.name ?? ''}
                loading={loadingInsurers}
                name="currentInsurerId"
                label={t('currentInsurer')}
              />
            </Form.Column>

            <Form.Column
              xs={24}
              sm={12}
              md={8}
              lg={8}
              xl={8}
              marginTop={{ xs: 1 }}
            >
              <Form.Input
                name="currentPolicy"
                inputProps={{ type: 'number' }}
                label={t('numPolicy')}
              />
            </Form.Column>
          </Form.Row>
        ) : null}
      </FilterSecondLevelContainer>
    </FormProvider>
  );
};

ProjectFilter.propTypes = {
  methods: PropTypes.any,
  insurers: PropTypes.array,
  clients: PropTypes.array,
  loading: PropTypes.bool,
  onInputChange: PropTypes.func,
};

export default ProjectFilter;
