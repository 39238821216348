import { useTable } from 'hooks/useTable';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import getReceipts from 'services/receipts/getReceipts';
import ReceiptsSearch from '../ReceiptsSearch';
import ReceiptsTable from '../ReceiptsTable';
import { receiptsMapper } from 'utils/columnsMapper';
import Title from 'UI/atoms/Title';
import { useNavigate } from 'react-router-dom';
import deleteReceipt from 'services/receipts/deleteReceipt';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import { useDialog } from 'hooks/useDialog';

const RECEIPT_TYPE_POLICY = 1;

const ReceiptsTableAndSearch = ({ filters }) => {
  const [t] = useTranslation('receipts');
  const navigate = useNavigate();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    handleOnSearch,
    refreshRows,
    handleOnRowClickWithFields,
    handleOnSortModelChange,
  } = useTable(
    getReceipts,
    t('error'),
    false,
    undefined,
    filters,
    receiptsMapper,
  );

  const {
    openConfirmationDialog,
    loadingDialog,
    handleOnClose,
    handleOnDelete,
    handleDeleteElement,
  } = useDialog(undefined, undefined, refreshRows, deleteReceipt);

  return (
    <>
      {filters.typeId === RECEIPT_TYPE_POLICY ? (
        <Title size="medium">{t('policyReceipts')}</Title>
      ) : (
        <Title size="medium">{t('commissionReceipts')}</Title>
      )}
      <ReceiptsSearch onSearch={handleOnSearch} />
      <ReceiptsTable
        receipts={rows}
        rowCount={rowCount}
        loading={loading}
        onDelete={handleOnDelete}
        navigateTo={
          filters.typeId === RECEIPT_TYPE_POLICY
            ? 'receipts/policy'
            : 'receipts/commission'
        }
        onRowClick={handleOnRowClickWithFields}
        onPaginationModelChange={handleOnPaginationModelChange}
        onSortModelChange={handleOnSortModelChange}
        paginationModel={paginationModel}
        sortModel={sortModel}
        addButtonOnClick={() =>
          navigate(
            filters.typeId === RECEIPT_TYPE_POLICY
              ? '/receipts/policy/create'
              : '/receipts/commission/create',
          )
        }
      />
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement(true)}
        onClose={handleOnClose}
      />
    </>
  );
};

ReceiptsTableAndSearch.propTypes = {
  filters: PropTypes.object,
};
export default ReceiptsTableAndSearch;
