import { useEffect } from 'react';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import { useClientStore } from 'context/ClientProvider/hooks';
import { useTranslation } from 'react-i18next';
import CustomTabs from 'UI/molecules/Tabs';

import SubHeader from 'components/blocks/SubHeader';
import Header from 'components/blocks/Header';
import PageContainer from 'UI/molecules/PageContainer';
import { useLocation } from 'react-router-dom';
import ContentContainer from 'UI/atoms/ContentContainer';
import CreateUser from 'components/user/CreateUser';

const Create = () => {
  const [clientState, clientDispatch] = useClientStore();
  const [t] = useTranslation('clients');
  const { pathname } = useLocation();
  const tabOptions = [
    { label: t('generalData'), route: `/clients` },
    { label: t('users'), route: `/clients/users` },
    { label: t('onboarding'), route: `/clients/onboarding` },
    { label: t('documentation'), route: `/clients/documentation` },
    { label: t('projects'), route: `/clients` },
    { label: t('policies'), route: `/clients` },
    { label: t('policyReceipts'), route: `/clients` },
    { label: t('commissionReceipts'), route: `/clients` },
  ];

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  return (
    <PageContainer extraClass={'form'}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={t('title')}
            breadcrumbs={[
              { path: '/', text: t('breadcrumbsHome') },
              { path: '/clients', text: t('title') },
              {
                path: `/clients/${clientState.clientId}`,
                text: t('newClient'),
              },
            ]}
            currentPagePath={pathname}
          >
            <CustomTabs tabs={tabOptions} selected={clientState.tab} />
          </SubHeader>
        </Header>
        <ContentContainer>
          <CreateUser />
        </ContentContainer>
      </>
    </PageContainer>
  );
};

export default Create;
