import axios from 'axios';
import Receipt from 'models/Receipt';

export default async function getReceipt(id) {
  const { data } = await axios
    .get('/receipts/' + id)
    .catch(() => new Receipt({}));

  if (typeof data === 'undefined') {
    return new Receipt({});
  }

  return new Receipt({
    id: data.id,
    receipt: data.recibo,
    insurer: data.aseguradora,
    insurerId: data.aseguradoraLookupId,
    policyId: data.polizaLookupId,
    branch: data.ramo,
    branchId: data.ramoLookupId,
    companyName: data.empresaCliente,
    companyId: data.empresaClienteLookupId,
    type: data.tiposDeRecibos,
    typeId: data.tiposDeRecibosLookupId,
    issueDate: data.emision,
    expirationDate: data.vencimiento,
    totalAmount: data.importe,
    commission: data.comision,
    netPremium: data.primaNeta,
    totalPremium: data.primaTotal,
    periodicity: data.periodicidad,
    periodicityId: data.periodicidadLookupId,
    status: data.estado,
    statusId: data.estadoLookupId,
  });
}
