import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      ccaaId: yup.object().required(t('mandatory', { field: t('ccaa') })),
      city: yup.string().required(t('mandatory', { field: t('city') })),
      cnaeId: yup.object().required(t('mandatory', { field: t('cnae') })),
      provinceId: yup
        .object()
        .required(t('mandatory', { field: t('province') })),
      cif: yup.string().required(t('mandatory', { field: t('cif') })),
      zipCode: yup
        .number()
        .typeError(t('invalidFormat', { field: t('zipCode') }))
        .positive(t('positive', { field: t('zipCode') }))
        .required(t('mandatory', { field: t('zipCode') })),
      address: yup.string().required(t('mandatory', { field: t('address') })),
      averageAge: yup
        .number()
        .typeError(t('invalidFormat', { field: t('avgAge') }))
        .positive(t('positive', { field: t('avgAge') }))
        .required(t('mandatory', { field: t('avgAge') })),
      numWorkers: yup
        .number()
        .typeError(t('invalidFormat', { field: t('numWorkers') }))
        .positive(t('positive', { field: t('numWorkers') }))
        .required(t('mandatory', { field: t('numWorkers') })),
      phone: yup
        .number()
        .typeError(t('invalidFormat', { field: t('contactPhone') }))
        .positive(t('invalidFormat', { field: t('contactPhone') })),
      name: yup.string().required(t('mandatory', { field: t('socialReason') })),
      preferredContact: yup
        .string()
        .required(t('mandatory', { field: t('preferredContactWay') })),
      domain: yup.string().required(t('mandatory', { field: t('domain') })),
      trademark: yup
        .string()
        .required(t('mandatory', { field: t('commercialBrand') })),
      email: yup
        .string()
        .email(t('invalidFormat', { field: t('contactEmail') }))
        .optional(),
    })
    .required();

export default validationSchema;
