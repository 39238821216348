import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DataGrid, GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import styles from './styles.module.scss';
import SectionTitle from 'UI/atoms/SectionTitle';
import {
  SquareCheckedIcon,
  SquareUncheckedIcon,
} from 'assets/icons/IconsLibrary';
import { Checkbox } from '@mui/material';
import { PropTypes } from 'prop-types';

function CustomPagination() {
  return null;
}

const columns = [
  { field: 'id' },
  {
    field: 'name',
    flex: 1,
  },
  { ...GRID_CHECKBOX_SELECTION_COL_DEF },
];
const SectorTable = ({
  rows,
  loading,
  selectedRows,
  onRowSelectionModelChange,
  title,
}) => {
  const [selectedItemsNum, setSelectedItemsNum] = useState(
    selectedRows.length ?? 0,
  );
  const [rowSelectionModel, setRowSelectionModel] = useState(selectedRows);
  const [t] = useTranslation('customTable');

  return (
    <Box sx={{ width: '100%', marginTop: 2, position: 'relative' }}>
      <SectionTitle title={`${title} (${selectedItemsNum})`} />
      <DataGrid
        className={styles.table}
        loading={loading}
        slotProps={{
          row: { className: styles.row },

          cell: { className: styles.cell },
        }}
        localeText={{
          noRowsLabel: t('noResults'),
          noResultsOverlayLabel: t('noResults'),
          footerRowSelected: () => null,
        }}
        classes={{
          columnHeaders: styles.columnHeaders,
          columnHeader: styles.columnHeader,
          'columnHeader--sorted': styles['columnHeader--sorted'],
          iconButtonContainer: styles.iconButtonContainer,
          sortIcon: styles.sortIcon,
          columnHeaderTitleContainer: styles.columnHeaderTitleContainer,
          footerContainer: styles.footerContainer,
          virtualScroller: styles.virtualScroller,
        }}
        slots={{
          pagination: CustomPagination,
          baseCheckbox: ({ ...props }) => (
            <Checkbox
              {...props}
              icon={<SquareUncheckedIcon />}
              checkedIcon={<SquareCheckedIcon />}
            />
          ),

          columnHeaders: () => null,
        }}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        rows={rows}
        columns={columns}
        // onRowClick={(params) => onRowClick(params.row)}
        disableColumnMenu
        disableColumnSelector
        rowSelectionModel={rowSelectionModel}
        onRowSelectionModelChange={(model, details) => {
          setRowSelectionModel(model);
          onRowSelectionModelChange(model);

          setSelectedItemsNum(model.length);
        }}
        checkboxSelection
      />
    </Box>
  );
};

export default SectorTable;

SectorTable.propTypes = {
  rows: PropTypes.array,
  selectedRows: PropTypes.array,
  loading: PropTypes.bool,
  onRowSelectionModelChange: PropTypes.func,
  title: PropTypes.string,
};
