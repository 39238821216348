import { PropTypes } from 'prop-types';
import styles from './styles.module.scss';

const ErrorBox = ({ errors, text }) => {
  return (
    <>
      {
        <div
          className={`${styles.errorBox} ${
            Object.keys(errors).length !== 0 ? '' : styles['errorBox--hidden']
          }`}
        >
          <p className={styles.text}>{text}</p>
          {Object.values(errors).map((error) => (
            <p className={styles.errors} key={error.message}>
              - {error.message}
            </p>
          ))}
        </div>
      }
    </>
  );
};

ErrorBox.propTypes = {
  errors: PropTypes.object,
  text: PropTypes.string,
};

export default ErrorBox;
