import * as yup from 'yup';

const validationSchema = (t) =>
  yup.object({
    insurer: yup
      .object()
      .required(t('mandatory', { field: t('insurerLabel') })),
    file: yup
      .mixed()
      .required(t('file'))
      .test('fileRequired', t('file'), (value) => {
        return value && value.length > 0;
      }),
  });

export default validationSchema;
