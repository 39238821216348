import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';

import { useTranslation } from 'react-i18next';
import UsersFilter from '../UsersFilter';

const UsersSearch = ({ onSearch }) => {
  const [searchValue, setSearchValue] = useState('');
  const [t] = useTranslation('user');

  const formDefaultValues = {
    position: '',
    email: '',
    phone: '',
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      ...methods.getValues(),
    };

    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search.user')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => <UsersFilter methods={methods} />}
    />
  );
};

UsersSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default UsersSearch;
