import { PropTypes } from 'prop-types';

import FormRow from 'UI/atoms/FormRow';
import FormContainer from 'UI/atoms/FormContainer';
import Input from 'UI/atoms/Input';
import Autocomplete from 'UI/atoms/Autocomplete';
import FormBox from 'UI/atoms/FormBox';
import ErrorBox from 'UI/atoms/ErrorBox';
import Date from 'UI/atoms/Date';
import RadioButton from 'UI/atoms/RadioButton';
import Textarea from 'UI/atoms/Textarea';
import FormFooter from '../FormFooter';
import Title from 'UI/atoms/Title';
import SectionTitle from 'UI/atoms/SectionTitle';
import FormColumn from 'UI/atoms/FormColumn';
import Switch from 'UI/atoms/Switch';
import Select from 'UI/atoms/Select';
import Slider from 'UI/atoms/Slider';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import InputFile from 'UI/atoms/InputFile';

import styles from './styles.module.scss';

const Form = (props) => (
  <form className={styles.form} {...props}>
    {props.children}
  </form>
);

Form.Row = FormRow;

Form.Column = FormColumn;

Form.Container = FormContainer;

Form.Autocomplete = Autocomplete;

Form.Select = Select;

Form.Input = Input;

Form.InputFile = InputFile;

Form.Box = FormBox;

Form.RadioButton = RadioButton;

Form.ErrorBox = ErrorBox;

Form.Date = Date;

Form.Textarea = Textarea;

Form.Footer = FormFooter;

Form.Switch = Switch;

Form.Title = Title;

Form.Slider = Slider;

Form.SectionTitle = SectionTitle;

Form.PrimaryButton = PrimaryBtn;

Form.SecondaryButton = SecondaryBtn;

Form.propTypes = {
  children: PropTypes.node,
};

export default Form;
