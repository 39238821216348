import axios from 'axios';
import Insurer from 'models/Insurer';

export default async function updateInsurerData(id, data) {
  const insurer = new Insurer(data);

  const body = {
    id: insurer.id,
    cif: insurer.cif,
    razonSocial: insurer.name,
    solvencia: insurer.solvency,
    marcaComercial: insurer.trademark,
    direccion: insurer.address,
    provinciaLookupId: insurer.provinceId,
    codigoPostal: insurer.zipCode,
    urlAseguradora: insurer.insurerURL,
    adelantoDeComision: insurer.advanceCommission,
    vida: insurer.life,
    accidentes: insurer.accidents,
    salud: insurer.health,
    acessoAPujaVida: insurer.accessLife,
    acessoAPujaAccidentes: insurer.accessAccidents,
    acessoAPujaSalud: insurer.accessHealth,
    aseguradoraEmail: insurer.email,
    aseguradoraTelefono: insurer.phone,
    edadMediaPerfilCliente: insurer.averageAge,
    especializadoEnCobertInternacion: insurer.internacionalEspecialization,
    ramos: insurer.branches.map((branch) => ({
      ramoLookupId: branch.id,
      preferencia: branch.preference,
      comision: branch.commission,
    })),
    sectoresPreferentesLookupId: insurer.preferredSectors,
  };

  try {
    const res = await axios.put('/companies/' + id + '/generaldata', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
