import { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';

import Dialog from 'UI/atoms/Dialog';
import PrimaryBtn from 'UI/atoms/PrimaryBtn';
import SecondaryBtn from 'UI/atoms/SecondaryBtn';
import Title from 'UI/atoms/Title';
import Form from 'UI/molecules/Form';
import {
  showErrorNotification,
  showNotification,
} from 'utils/showToastNotifications';

import styles from './styles.module.scss';
import purchaseCredits from 'services/credits/purchaseCredits';

const AddCreditsDialog = ({ open, onClose, ...props }) => {
  const [t] = useTranslation('addCreditsDialog');
  const [loading, setLoading] = useState(false);
  const [errorsText, setErrorsText] = useState({});

  const credits = [
    { label: t('option', { credits: '50', price: '50' }), value: 50 },
    { label: t('option', { credits: '100', price: '100' }), value: 100 },
    { label: t('option', { credits: '250', price: '250' }), value: 250 },
    { label: t('option', { credits: '500', price: '500' }), value: 500 },
    { label: t('option', { credits: '1.000', price: '1.000' }), value: 1000 },
    { label: t('option', { credits: '2.500', price: '2.500' }), value: 2500 },
    { label: t('option', { credits: '5.000', price: '5.000' }), value: 5000 },
    {
      label: t('option', { credits: '10.000', price: '10.000' }),
      value: 10000,
    },
    {
      label: t('option', { credits: '25.000', price: '25.000' }),
      value: 25000,
    },
    {
      label: t('option', { credits: '50.000', price: '50.000' }),
      value: 50000,
    },
    {
      label: t('option', { credits: '100.000', price: '100.000' }),
      value: 100.0,
    },
  ];

  const formDefaultValues = {
    credits: 50,
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  const {
    formState: { errors },
  } = methods;

  const handleOnClose = () => {
    methods.reset(formDefaultValues);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    setErrorsText(errors);
  }, [errors]);

  async function onSubmit(data) {
    setLoading(true);
    const result = await purchaseCredits(data.credits);
    setLoading(false);

    if (result.status === 200) {
      showNotification(t('success'));
      handleOnClose();
      return;
    }
    showErrorNotification(t('error'));
  }

  return (
    <>
      <Dialog
        open={open}
        showCloseButton={true}
        onClose={handleOnClose}
        {...props}
      >
        <div className={styles.container}>
          <Title size="medium">{t('title')}</Title>
          <Form.ErrorBox errors={errorsText} text={t('errorBoxText')} />
          <FormProvider {...methods}>
            <Form onSubmit={methods.handleSubmit(onSubmit)}>
              <Form.Select
                options={credits}
                withValidation
                style={{ marginBottom: 0 }}
                extraClassName="no-margin-label"
                color="grey"
                name="credits"
                label={t('label')}
              />
              <Grid container rowGap={2} justifyContent="space-between">
                <Grid item xs={12} md={6} marginTop={{ md: 3, lg: 6 }}>
                  <PrimaryBtn
                    loading={loading}
                    text={t('confirm')}
                    type="submit"
                  />
                </Grid>
                <Grid item xs={12} md={5} marginTop={{ md: 3, lg: 6 }}>
                  <SecondaryBtn
                    style={{ margin: 0 }}
                    text={t('cancelAction')}
                    onClick={onClose}
                  />
                </Grid>
              </Grid>
            </Form>
          </FormProvider>
        </div>
      </Dialog>
    </>
  );
};

AddCreditsDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default AddCreditsDialog;
