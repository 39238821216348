import { PropTypes } from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { yupResolver } from '@hookform/resolvers/yup';

import Dialog from 'UI/atoms/Dialog';
import Title from 'UI/atoms/Title';
import { useEffect, useState } from 'react';
import { useDecodedData } from 'hooks/useDecodedData';
import { useClientStore } from 'context/ClientProvider/hooks';

import validationSchema from './validationSchema';
import Form from 'UI/molecules/Form';

const INSURER_USER_ID = '3';

const UserDialog = ({
  open,
  loading,
  onClose,
  selectedUser,
  onCreateOrUpdate,
  ...props
}) => {
  const [t] = useTranslation('user');
  const schema = validationSchema(t);
  const [clientState] = useClientStore();

  const [parentId, setParentId] = useState();
  const { getDecodedData } = useDecodedData(clientState.clientId);

  const formDefaultValues = {
    name: selectedUser?.name ?? '',
    surname: selectedUser?.surname ?? '',
    phone: selectedUser?.phone ?? '',
    position: selectedUser?.position ?? '',
    email: selectedUser?.email ?? '',
    roleId: INSURER_USER_ID,
  };

  const methods = useForm({
    defaultValues: formDefaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
  } = methods;

  useEffect(() => {
    if (!clientState.clientId) return;
    setParentId(getDecodedData().id);
  }, [clientState.clientId]);

  async function handleUpload() {
    await onCreateOrUpdate(parentId, {
      ...methods.getValues(),
      id: selectedUser?.id,
    });
  }

  return (
    <>
      <Dialog open={open} showCloseButton={true} onClose={onClose} {...props}>
        <Title size="medium">
          {selectedUser ? t('editUser') : t('newUser')}
        </Title>
        <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
        <FormProvider {...methods}>
          <Form onSubmit={methods.handleSubmit(handleUpload)}>
            <Form.Row columns={12} rowSpacing={0}>
              <Form.Column xs={12} sm={12} md={12} lg={12} xl={12}>
                <Form.Input name="email" label={t('contactEmail')} />
              </Form.Column>
            </Form.Row>
            <Form.Row columns={12} rowSpacing={2}>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="name" label={t('name')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="surname" label={t('surname')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input name="position" label={t('user.position')} />
              </Form.Column>
              <Form.Column sm={12} md={6} lg={6} xl={6}>
                <Form.Input
                  name="phone"
                  label={t('contactPhone')}
                  inputProps={{ type: 'number' }}
                />
              </Form.Column>
            </Form.Row>

            <Form.Row columns={12}>
              <Form.Column xs={6} sm={6} md={6} lg={6} xl={6}>
                <Form.PrimaryButton
                  text={t('saveAction')}
                  type="submit"
                  loading={loading}
                />
              </Form.Column>
              <Form.Column xs={2} sm={2} md={2} lg={2} xl={2}>
                <Form.SecondaryButton
                  text={t('cancelAction')}
                  onClick={onClose}
                />
              </Form.Column>
            </Form.Row>
          </Form>
        </FormProvider>
      </Dialog>
    </>
  );
};

UserDialog.propTypes = {
  open: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onCreateOrUpdate: PropTypes.func,
  selectedUser: PropTypes.object,
};

export default UserDialog;
