import axios from 'axios';
import Client from 'models/Client';

export default async function getClient(id) {
  try {
    const { data } = await axios.get('/clients/' + id);
    return new Client({
      id: data.id,
      cif: data.cif,
      name: data.razonSocial,
      provinceId: data.provinciaLookupId,
      city: data.ciudad,
      cnaeId: data.cnaeLookupId,
      sicId: data.sicLookupId,
      numWorkers: data.numTrabajadores,
      averageAge: data.edadMedia,
      address: data.direccion,
      ainaPolicies: data.polizasAina,
      onboarding: data.onboarding,
      activeProjects: data.proyectosActivos,
      letterOfAppointment: data.cartaNombramiento,
    });
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
