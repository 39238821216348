import axios from 'axios';
import ClientOnboarding from 'models/ClientOnboardingData';

export default async function updateOnboardingData(clientId, data) {
  const client = new ClientOnboarding(data);

  const body = {
    id: client.id,
    nombrePersonaContacto: client.contactName,
    apellidosPersonaContacto: client.contactSurname,
    telefono: client.phone,
    emailAddress: client.email,
    nombreApoderado: client.representativeName,
    apellidosApoderado: client.representativeSurname,
    dniApoderado: client.representativeNid,
    fechaVencDNIApoderado: client.representativeNidExpirationDate,
    cartaNombramiento: client.letterOfAppointment,
    onboarding: client.onboarding,
  };

  try {
    const res = await axios.put('/clients/' + clientId + '/onboarding', body);

    return res;
  } catch (error) {
    return error.response;
  }
}
