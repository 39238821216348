import axios from 'axios';
import Project from 'models/Project';

export default async function updateProjectStatus(projectId, data) {
  const project = new Project(data);

  const body = {
    estadoLookupId: project.statusId,
  };

  try {
    const res = await axios.put('/projects/' + projectId + '/estado', body);
    return res;
  } catch (error) {
    console.error('ERROR RETRIEVED', error);
    return undefined;
  }
}
