import axios from 'axios';
import Branch from 'models/Branch';

export default async function getBranchList() {
  const { data } = await axios.get('/list/ramo').catch(() => ({
    results: [],
  }));

  if (typeof data === 'undefined') {
    return {
      results: [],
    };
  }

  const branches = data.map(
    (item) =>
      new Branch({
        id: item.id,
        name: item.ramo,
        ainaBranch: item.ramoAina,
      }),
  );

  branches.sort((a, b) => {
    const weightA = a.ainaBranch ? 10000 : 0;
    const weightB = b.ainaBranch ? 10000 : 0;

    if (!a.ainaBranch && !b.ainaBranch) {
      return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
    }

    if (a.ainaBranch && b.ainaBranch) {
      return a.id - b.id;
    }

    return weightB - weightA;
  });

  return branches;
}
