import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import { columns as columsConfiguration } from './tableConfiguration';
import { EditIcon } from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';

const InsurerTable = ({
  loading,
  insurers,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  onRowClick,
}) => {
  const { t } = useTranslation('insurersComponent');
  const columns = columsConfiguration(t);

  return (
    <CustomTable
      loading={loading}
      rows={insurers}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actionsHeader'),
          getActions: (params) => [
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label={t('edit')}
              onClick={() => {
                onRowClick(params.row, 'insurers');
              }}
            />,
          ],
        },
      ]}
      onPaginationModelChange={onPaginationModelChange}
      onSortModelChange={onSortModelChange}
      onRowClick={(row) => {
        onRowClick(row, 'insurers');
      }}
    />
  );
};

InsurerTable.propTypes = {
  loading: PropTypes.bool,
  insurers: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default InsurerTable;
