import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import { SELECT_TAB } from 'context/ClientProvider/constants';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';
import CreatePolicyTab from 'components/policies/CreatePolicyTab';

const CreatePolicy = () => {
  const [clientState, clientDispatch] = useClientStore();

  const [t] = useTranslation('policies');

  const { pathname } = useLocation();

  const tabOptions = [
    {
      label: t('generalData'),
      route: `/policies/create`,
    },
    {
      label: t('documentation'),
      route: '',
    },
    {
      label: t('movements'),
      route: '',
    },
    {
      label: t('policyReceipts'),
      route: '',
    },
    {
      label: t('commissionReceipts'),
      route: '',
    },
  ];

  useEffect(() => {
    clientDispatch({ type: SELECT_TAB, payload: 0 });
  }, []);

  return (
    <PageContainer extraClass="form">
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={t('newPolicy')}
            breadcrumbs={[
              { path: '/', text: t('breadcrumbsHome') },
              { path: '/policies', text: t('title') },
              {
                path: '',
                text: t('newPolicy'),
              },
            ]}
            currentPagePath={pathname}
          >
            <CustomTabs tabs={tabOptions} selected={clientState.tab} />
          </SubHeader>
        </Header>

        <ContentContainer>
          <CreatePolicyTab />
        </ContentContainer>
      </>
    </PageContainer>
  );
};

export default CreatePolicy;
