import { useLocation, useNavigate } from 'react-router-dom';
import { PropTypes } from 'prop-types';

import ContentContainer from 'UI/atoms/ContentContainer';
import CustomTabs from 'UI/molecules/Tabs';
import Header from 'components/blocks/Header';
import SubHeader from 'components/blocks/SubHeader';
import { useClientStore } from 'context/ClientProvider/hooks';
import PageContainer from 'UI/molecules/PageContainer';
import { useTranslation } from 'react-i18next';

const CreditsBasePage = (props) => {
  const { breadcrumbs, children } = props;
  const { pathname } = useLocation();
  const [clientState] = useClientStore();
  const [t] = useTranslation('credits');
  const navigate = useNavigate();

  const tabOptions = [
    { label: t('creditsBuy'), route: `/credits` },
    {
      label: t('historic'),
      route: `/credits/historic`,
    },
  ];

  const changeTab = (selectedTab) => {
    const newTab = tabOptions[selectedTab];
    navigate(newTab.route);
  };

  return (
    <PageContainer extraClass={props.extraClass}>
      <>
        <Header selected={t('title')}>
          <SubHeader
            text={t('title')}
            breadcrumbs={breadcrumbs}
            currentPagePath={pathname}
          >
            <CustomTabs
              tabs={tabOptions}
              selected={clientState.tab}
              onChange={changeTab}
            />
          </SubHeader>
        </Header>
        <ContentContainer>{children}</ContentContainer>
      </>
    </PageContainer>
  );
};

CreditsBasePage.propTypes = {
  extraClass: PropTypes.string,
  breadcrumbs: PropTypes.array,
  children: PropTypes.node,
};

export default CreditsBasePage;
