import { useTranslation } from 'react-i18next';
import { useElementCreate } from 'hooks/useElementCreate';
import PolicyDataForm from '../PolicyDataForm';
import createPolicy from 'services/policies/create';

const CreatePolicyTab = () => {
  const [t] = useTranslation('policiesComponent');

  const { handleOnSubmit } = useElementCreate(
    createPolicy,
    t('dataCreatedSuccessfully'),
    t('error'),
    '/policies',
  );

  return <PolicyDataForm onSubmit={handleOnSubmit} defaultFormValues={null} />;
};

export default CreatePolicyTab;
