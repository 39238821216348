import { useTranslation } from 'react-i18next';

import { useClientStore } from 'context/ClientProvider/hooks';
import InsurerDataForm from 'components/insurer/InsurerDataForm';
import { useElementSubmit } from 'hooks/useElementSubmit';
import { useElementData } from 'hooks/useElementData';
import getInsurerData from 'services/insurers/getInsurerData';
import updateInsurerData from 'services/insurers/updateInsurerData';
import Loader from 'UI/atoms/Loader';

const InsurerDataTab = () => {
  const [t] = useTranslation('insurersComponent');
  const [clientState] = useClientStore();

  const { loading, data } = useElementData(
    clientState.clientId,
    getInsurerData,
  );

  const { handleOnSubmit } = useElementSubmit(
    clientState.clientId,
    updateInsurerData,
    t('dataUpdatedSuccessfully'),
    t('error'),
  );

  if (loading) {
    return <Loader text={t('loading')} />;
  }

  return <InsurerDataForm defaultFormValues={data} onSubmit={handleOnSubmit} />;
};

export default InsurerDataTab;
