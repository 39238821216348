import axios from 'axios';
import Insurer from 'models/Insurer';

export default async function getInsurerData(id) {
  const { data } = await axios
    .get('/companies/' + id + '/generaldata')
    .catch(() => new Insurer({}));

  if (typeof data === 'undefined') {
    return new Insurer({});
  }

  return new Insurer({
    id: data.id,
    cif: data.cif,
    name: data.razonSocial,
    solvency: data.solvencia,
    trademark: data.marcaComercial,
    address: data.direccion,
    provinceId: data.provinciaLookupId,
    zipCode: data.codigoPostal,
    insurerURL: data.urlAseguradora,
    advanceCommission: data.adelantoDeComision,
    life: data.vida,
    accidents: data.accidentes,
    health: data.salud,
    email: data.aseguradoraEmail,
    phone: data.aseguradoraTelefono,
    accessLife: data.acessoAPujaVida,
    accessAccidents: data.acessoAPujaAccidentes,
    accessHealth: data.acessoAPujaSalud,
    averageAge: data.edadMediaPerfilCliente,
    internacionalEspecialization: data.especializadoEnCobertInternacion,
    branches: data.ramos.map((e) => ({
      id: e.ramoLookupId,
      name: e.ramo,
      commission: e.comision,
      preference: e.preferencia,
    })),
    preferredSectors: data.sectoresPreferentes.map((e) => ({
      id: e.lookupId,
      name: e.lookupValue,
    })),
  });
}
