import * as yup from 'yup';

const validationSchema = (t) =>
  yup
    .object({
      expirationDate: yup
        .date()
        .required(t('mandatory', { field: t('expirationDate') })),
      policyNum: yup
        .string()
        .required(t('mandatory', { field: t('policyNum') })),
      branchId: yup.object().required(t('mandatory', { field: t('branch') })),
      managerId: yup.object().required(t('mandatory', { field: t('manager') })),
      insurerId: yup.object().required(t('mandatory', { field: t('insurer') })),
      companyId: yup
        .object()
        .required(t('mandatory', { field: t('clientName') })),
      numWorkersWithInsurance: yup
        .number()
        .typeError(t('invalidFormat', { field: t('numWorkersWithInsurance') }))
        .positive(t('positive', { field: t('numWorkersWithInsurance') }))
        .required(t('mandatory', { field: t('numWorkersWithInsurance') })),
      numWorkersOffWork: yup
        .number()
        .typeError(t('invalidFormat', { field: t('numWorkersOff') }))
        .moreThan(-1, t('positive', { field: t('numWorkersOff') }))
        .required(t('mandatory', { field: t('numWorkersOff') })),
      agreement: yup.bool().required(t('mandatory', { field: t('agreement') })),
      periodicityId: yup
        .number()
        .required(t('mandatory', { field: t('periodicity') })),
      netPremium: yup
        .number()
        .typeError(t('invalidFormat', { field: t('netPremium') }))
        .positive(t('positive', { field: t('netPremium') }))
        .required(t('mandatory', { field: t('netPremium') })),
      totalPremium: yup
        .number()
        .typeError(t('invalidFormat', { field: t('totalPremium') }))
        .positive(t('positive', { field: t('totalPremium') }))
        .required(t('mandatory', { field: t('totalPremium') })),
      pb: yup.number().typeError(t('invalidFormat', { field: t('pb') })),
    })
    .required();

export default validationSchema;
