import axios from 'axios';
import AccessCode from 'models/AccessCode';

export default async function createAccessCode(id, data) {
  const accessCode = new AccessCode(data);

  const body = {
    nombreAcceso: accessCode.accessName,
    usuario: accessCode.user,
    emailUsuario: accessCode.userEmail,
    codigo: accessCode.code,
    observaciones: accessCode.observations,
  };

  try {
    const res = await axios.post('/companies/' + id + '/accesscodes', body);

    return res;
  } catch (error) {
    return null;
  }
}
