import { PropTypes } from 'prop-types';

import styles from './styles.module.scss';

const SectionTitle = ({ title, style }) => {
  return (
    <div className={styles.container} style={style}>
      <p className={styles.text}>{title}</p>
    </div>
  );
};

SectionTitle.propTypes = {
  title: PropTypes.string,
  style: PropTypes.object,
};

export default SectionTitle;
