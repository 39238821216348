import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { useClientStore } from 'context/ClientProvider/hooks';
import ConfirmationDialog from 'components/blocks/ConfirmationDialog';
import { useTable } from 'hooks/useTable';
import getDocuments from 'services/policies/getDocuments';
import downloadDocumentation from 'services/policies/downloadDocumentation';
import deleteDocument from 'services/policies/deleteDocument';
import updateDocumentation from 'services/policies/updateDocumentation';
import createDocumentation from 'services/policies/createDocumentation';
import Form from 'UI/molecules/Form';

import DocumentationTable from '../DocumentationTable';
import DocumentationDialog from '../DocumentationDialog';
import {
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
} from 'utils/constants';
import { useUserStore } from 'context/UserProvider/hooks';
import { useDialog } from 'hooks/useDialog';

const PolicyDocumentationTable = ({ documentTypeId, documentTypeName }) => {
  const [t] = useTranslation('documentation');
  const [clientState] = useClientStore();
  const [userState] = useUserStore();

  const {
    rows,
    rowCount,
    loading,
    sortModel,
    paginationModel,
    handleOnPaginationModelChange,
    refreshRows,
    handleOnSortModelChange,
  } = useTable(getDocuments, t('error'), true, clientState.clientId, {
    documentTypeId,
  });

  const {
    openConfirmationDialog,
    loadingDialog,
    openDialog,
    selectedElement,
    handleCreateOrUpdateElement,
    handleOnAdd,
    handleOnClose,
    handleOnDelete,
    handleDeleteElement,
    handleOnEdit,
  } = useDialog(
    updateDocumentation,
    createDocumentation,
    refreshRows,
    deleteDocument,
  );

  return (
    <>
      <Form.Row>
        <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.SectionTitle title={documentTypeName} />
          <DocumentationTable
            documents={rows}
            rowCount={rowCount}
            loading={loading}
            onPaginationModelChange={handleOnPaginationModelChange}
            onSortModelChange={handleOnSortModelChange}
            paginationModel={paginationModel}
            sortModel={sortModel}
            addDocumentHandler={
              [INSURER_MANAGER, INSURER_SUPPORT, INSURER_USER].includes(
                userState.role,
              )
                ? undefined
                : () => handleOnAdd({ documentType: documentTypeName })
            }
            onEdit={handleOnEdit}
            onDelete={handleOnDelete}
            downloadDocumentation={downloadDocumentation}
            hideColumns={['documentType']}
          />
        </Form.Column>
      </Form.Row>

      {openDialog ? (
        <DocumentationDialog
          selectedDocument={selectedElement}
          loading={loadingDialog}
          open={openDialog}
          onClose={handleOnClose}
          onCreateOrUpdate={handleCreateOrUpdateElement}
          downloadDocumentation={downloadDocumentation}
          disableDocumentTypeFilter
        />
      ) : null}
      <ConfirmationDialog
        loading={loadingDialog}
        title={t('confirmationDialog.title')}
        message={t('confirmationDialog.message')}
        open={openConfirmationDialog}
        onPrimaryBtnClick={() => handleDeleteElement()}
        onClose={handleOnClose}
      />
    </>
  );
};

PolicyDocumentationTable.propTypes = {
  documentTypeId: PropTypes.number,
  documentTypeName: PropTypes.string,
};

export default PolicyDocumentationTable;
