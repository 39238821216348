import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { columns as columsConfiguration } from './tableConfiguration';
import { EditIcon } from 'assets/icons/IconsLibrary';
import { findCCAA, findElementByLookupId } from 'utils/lookupIdMapper';
import { useAppStore } from 'context/AppProvider/hooks';
import CustomTable from 'components/blocks/CustomTable';

const UserTable = ({
  loading,
  clients,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onRowClick,
  addButtonOnClick,
  onSortModelChange,
}) => {
  const [appState] = useAppStore();
  const { t } = useTranslation('user');
  const columns = columsConfiguration(t);

  const sicList = appState.sic;
  const cnaeList = appState.cnae;
  const geographicDataList = appState.geographicData;
  const ccaaList = geographicDataList.ccaa;
  const provincesList = geographicDataList.provinces;

  function getMappedRows() {
    return clients?.map((client) => ({
      ...client,
      sic: findElementByLookupId(sicList, client?.sicId)?.name,
      ccaa: findCCAA(provincesList, ccaaList, client?.provinceId)?.name,
      cnae: findElementByLookupId(cnaeList, client?.cnaeId)?.name,
      province: findElementByLookupId(provincesList, client.provinceId)?.name,
    }));
  }

  return (
    <CustomTable
      loading={loading}
      rows={getMappedRows() ?? []}
      rowCount={rowCount}
      paginationModel={paginationModel}
      sortModel={sortModel}
      pageSizeOptions={[10, 20, 50, 100]}
      columns={[
        ...columns,
        {
          field: 'actions',
          type: 'actions',
          headerAlign: 'left',
          align: 'right',
          headerName: t('actionsHeader'),
          getActions: (params) => [
            <GridActionsCellItem
              key="edit"
              icon={<EditIcon />}
              label={t('edit')}
              onClick={() => {
                onRowClick(params.row, 'clients');
              }}
            />,
          ],
        },
      ]}
      addButtonText={t('newClient')}
      addButtonOnClick={addButtonOnClick}
      onPaginationModelChange={(model) => onPaginationModelChange(model)}
      onSortModelChange={(model) => onSortModelChange(model)}
      onRowClick={(row) => {
        onRowClick(row, 'clients');
      }}
    />
  );
};

UserTable.propTypes = {
  loading: PropTypes.bool,
  clients: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  onSortModelChange: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default UserTable;
