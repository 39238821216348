import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import SearchAndFilterContainer from 'components/blocks/SearchAndFilterContainer';
import Filter from 'UI/molecules/Filter';
import getInsurers from 'services/insurers/getInsurers';
import dayjs from 'dayjs';
import ReceiptsFilter from '../ReceiptsFilter';

const formDefaultValues = {
  insurerId: null,
  companyId: null,
  totalAmount: [0, 1000000],
  periodicityId: '',
  expirationDateFrom: null,
  expirationDateTo: null,
  issueDateFrom: null,
  issueDateTo: null,
  statusId: '',
  branchId: null,
};

const ReceiptsSearch = ({ onSearch }) => {
  const [t] = useTranslation('receiptsComponent');
  const [searchValue, setSearchValue] = useState('');

  const [insurers, setInsurers] = useState([]);
  const [loading, setLoading] = useState(false);
  const methods = useForm({
    defaultValues: formDefaultValues,
  });

  async function retrieveInsurers(value) {
    setLoading(true);
    const data = await getInsurers({ searchBarText: value });
    setInsurers(data.resultList);
    setLoading(false);
  }

  function getRangeValue(field, index) {
    return formDefaultValues[field][index] === methods.getValues(field)[index]
      ? ''
      : methods.getValues(field)[index];
  }

  const updateFilters = () => {
    const filter = {
      searchBarText: searchValue,
      insurerId: methods.getValues('insurerId')?.id,
      statusId: methods.getValues('statusId'),
      branchId: methods.getValues('branchId')?.id,
      companyId: methods.getValues('companyId')?.id,
      periodicityId: methods.getValues('periodicityId'),
      issueDateFrom: methods.getValues('issueDateFrom')
        ? dayjs(methods.getValues('issueDateFrom')).format('YYYY-MM-DD')
        : '',
      issueDateTo: methods.getValues('issueDateTo')
        ? dayjs(methods.getValues('issueDateTo')).format('YYYY-MM-DD')
        : '',
      totalAmountStart: getRangeValue('totalAmount', 0),
      totalAmountEnd: getRangeValue('totalAmount', 1),
    };
    onSearch(filter);
  };

  const handleOnClean = () => {
    setSearchValue('');
    methods.reset(formDefaultValues);
  };

  function handleOnInputChange(field, value) {
    if (value.length === 0) {
      setInsurers([]);
      return;
    }
    if (value.length >= 2) {
      retrieveInsurers(value);
    }
  }

  return (
    <SearchAndFilterContainer
      topFilters={(openFilter, setOpenFilter) => (
        <>
          <Filter.SearchBar
            value={searchValue}
            onChange={(newValue) => setSearchValue(newValue)}
            label={t('search')}
          />
          <Filter.Switch setOpenFilter={setOpenFilter} open={openFilter} />
          <Filter.ClearButton text={t('cleanButton')} onClick={handleOnClean} />
          <Filter.SearchButton
            text={t('searchButton')}
            onClick={updateFilters}
          />
        </>
      )}
      filters={() => (
        <ReceiptsFilter
          onInputChange={(field, value) => handleOnInputChange(field, value)}
          methods={methods}
          insurers={insurers}
          loading={loading}
        />
      )}
    />
  );
};

ReceiptsSearch.propTypes = {
  onSearch: PropTypes.func,
};

export default ReceiptsSearch;
