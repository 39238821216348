import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { Switch as SwitchMUI, FormControlLabel } from '@mui/material';

import styles from './styles.module.scss';

const Switch = ({ name, label, stylesContainer }) => {
  const { control } = useFormContext();
  return (
    <>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            className={styles.switchContainer}
            style={stylesContainer}
            slotProps={{
              typography: {
                classes: {
                  root: styles.label,
                },
              },
            }}
            control={
              <SwitchMUI
                checked={value}
                onChange={onChange}
                classes={{
                  switchBase: styles.switchBase,
                  root: styles.switch,
                  track: styles.track,
                  thumb: styles.thumb,
                }}
              />
            }
            label={label}
            labelPlacement="start"
          />
        )}
      />
    </>
  );
};

Switch.propTypes = {
  label: PropTypes.string,
  stylesContainer: PropTypes.object,
  name: PropTypes.string,
};

export default Switch;
