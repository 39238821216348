class Receipt {
  constructor(params) {
    this.id = params.id;
    this.receipt = params.receipt;
    this.insurer = params.insurer;
    this.insurerId = params.insurerId;
    this.policyId = params.policyId;
    this.policy = params.policy;
    this.branch = params.branch;
    this.branchId = params.branchId;
    this.companyName = params.companyName;
    this.companyId = params.companyId;
    this.type = params.type;
    this.typeId = params.typeId;
    this.issueDate = params.issueDate;
    this.expirationDate = params.expirationDate;
    this.totalAmount = params.totalAmount;
    this.commission = params.commission;
    this.totalPremium = params.totalPremium;
    this.netPremium = params.netPremium;
    this.status = params.status;
    this.statusId = params.statusId;
    this.periodicity = params.periodicity;
    this.periodicityId = params.periodicityId;
  }
}

export default Receipt;
