import PropTypes from 'prop-types';
import { Controller, useFormContext } from 'react-hook-form';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

import styles from './styles.module.scss';

const Date = ({ name, label, ...props }) => {
  const { control } = useFormContext();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div className={`${styles.container}`}>
        <Controller
          control={control}
          name={name}
          render={({ field: { value, onChange } }) => (
            <DatePicker
              format="DD/MM/YYYY"
              value={value ? dayjs(value) : null}
              onChange={onChange}
              label={label}
              slotProps={{
                textField: {
                  classes: {
                    root: styles.dateContainer,
                  },
                  InputProps: {
                    classes: {
                      root: styles.inputContainer,
                      input: styles.input,
                      notchedOutline: styles.inputOutline,
                    },
                  },
                  InputLabelProps: {
                    classes: {
                      root: styles.label,
                      shrink: styles['label--shrink'],
                    },
                  },
                },
              }}
              {...props}
            />
          )}
        />
      </div>
    </LocalizationProvider>
  );
};

Date.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
};
export default Date;
