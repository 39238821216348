import { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { PropTypes } from 'prop-types';

import Form from 'UI/molecules/Form';

import validationSchema from './validationSchema';

const InsurerUsersAndCodeForm = ({
  supports,
  onSubmit,
  userTable,
  accessTable,
  admins,
}) => {
  const [submitting, setSubmitting] = useState(false);
  const [t] = useTranslation('insurersComponent');
  const schema = validationSchema(t, admins.length, supports.length);
  const navigate = useNavigate();

  const adminsDefaultValues = admins.reduce((acc, admin, index) => {
    acc[`adminId_${index}`] = admin.id;
    acc[`adminName_${index}`] = admin.name;
    acc[`adminSurname_${index}`] = admin.surname;
    acc[`adminRoleId_${index}`] = admin.roleId;
    acc[`adminPhone_${index}`] = admin.phone;
    acc[`adminEmail_${index}`] = admin.email;
    acc[`adminPosition_${index}`] = admin.position;
    return acc;
  }, {});

  const supportsDefaultValues = supports.reduce((acc, support, index) => {
    acc[`supportId_${index}`] = support.id;
    acc[`supportName_${index}`] = support.name;
    acc[`supportSurname_${index}`] = support.surname;
    acc[`supportRoleId_${index}`] = support.roleId;
    acc[`supportPhone_${index}`] = support.phone;
    acc[`supportEmail_${index}`] = support.email;
    acc[`supportPosition_${index}`] = support.position;
    return acc;
  }, {});

  const defaultValues = {
    ...adminsDefaultValues,
    ...supportsDefaultValues,
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    formState: { errors },
    handleSubmit,
  } = methods;

  const handleOnSubmit = async (data) => {
    setSubmitting(true);

    const adminFormData = Object.keys(data)
      .filter((key) => key.startsWith('adminId_'))
      .map((key) => ({
        id: data[key],
        name: data[`adminName_${key.split('_')[1]}`],
        surname: data[`adminSurname_${key.split('_')[1]}`],
        roleId: data[`adminRoleId_${key.split('_')[1]}`],
        phone: data[`adminPhone_${key.split('_')[1]}`],
        email: data[`adminEmail_${key.split('_')[1]}`],
        position: data[`adminPosition_${key.split('_')[1]}`],
      }));

    const supportFormData = Object.keys(data)
      .filter((key) => key.startsWith('supportId_'))
      .map((key) => ({
        id: data[key],
        name: data[`supportName_${key.split('_')[1]}`],
        surname: data[`supportSurname_${key.split('_')[1]}`],
        roleId: data[`supportRoleId_${key.split('_')[1]}`],
        phone: data[`supportPhone_${key.split('_')[1]}`],
        email: data[`supportEmail_${key.split('_')[1]}`],
        position: data[`supportPosition_${key.split('_')[1]}`],
      }));

    await onSubmit(adminFormData, supportFormData);
    setSubmitting(false);
  };

  return (
    <Form.Container>
      <Form.ErrorBox errors={errors} text={t('errorBoxText')} />
      <Form.Title size="medium">{t('user.title')}</Form.Title>
      <FormProvider {...methods}>
        <Form onSubmit={handleSubmit(handleOnSubmit)}>
          {admins.length > 0 ? (
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('user.administrator')} />
            </Form.Column>
          ) : null}
          {admins.map((admin, index) => (
            <Form.Row rowSpacing={{ xs: 2 }} key={admin.id}>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'adminName_' + index}
                  label={t('user.contactAdministrator')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'adminPosition_' + index}
                  label={t('user.position')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'adminEmail_' + index}
                  label={t('contactEmail')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'adminPhone_' + index}
                  label={t('contactPhone')}
                  inputProps={{ type: 'number' }}
                />
              </Form.Column>
            </Form.Row>
          ))}
          {supports.length > 0 ? (
            <Form.Column sm={24} md={24} lg={24} xl={24}>
              <Form.SectionTitle title={t('user.support')} />
            </Form.Column>
          ) : null}
          {supports.map((support, index) => (
            <Form.Row key={support.id}>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'supportName_' + index}
                  label={t('user.contactSupport')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'supportPosition_' + index}
                  label={t('user.position')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'supportEmail_' + index}
                  label={t('contactEmail')}
                />
              </Form.Column>
              <Form.Column sm={24} md={12} lg={6} xl={4}>
                <Form.Input
                  name={'supportPhone_' + index}
                  label={t('contactPhone')}
                  inputProps={{ type: 'number' }}
                />
              </Form.Column>
            </Form.Row>
          ))}

          <Form.Row>
            <Form.Column
              extraClassName="no-margin-bottom"
              sm={24}
              md={24}
              lg={24}
              xl={24}
              marginTop={2}
            >
              <Form.SectionTitle title={t('user.title')} />
            </Form.Column>
            <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
              {userTable}
            </Form.Column>
          </Form.Row>
          {accessTable ? (
            <Form.Row>
              <Form.Column
                extraClassName="no-margin-bottom"
                sm={24}
                md={24}
                lg={24}
                xl={24}
                marginTop={2}
              >
                <Form.SectionTitle title={t('user.accessCodes')} />
              </Form.Column>
              <Form.Column xs={24} sm={24} md={24} lg={24} xl={24}>
                {accessTable}
              </Form.Column>
            </Form.Row>
          ) : null}
          <Form.Footer
            loading={submitting}
            onClickSecondary={() => navigate('/insurers')}
          />
        </Form>
      </FormProvider>
    </Form.Container>
  );
};

InsurerUsersAndCodeForm.propTypes = {
  supports: PropTypes.array,
  onSubmit: PropTypes.func,
  admins: PropTypes.array,
  userTable: PropTypes.node,
  accessTable: PropTypes.node,
};

export default InsurerUsersAndCodeForm;
