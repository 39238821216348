class Insurer {
  constructor(params) {
    this.id = params.id;
    this.cif = params.cif;
    this.name = params.name;
    this.solvency = params.solvency;
    this.email = params.email;
    this.phone = params.phone;
    this.address = params.address;
    this.trademark = params.trademark;
    this.zipCode = params.zipCode;
    this.insurerURL = params.insurerURL;
    this.provinceId = params.provinceId;
    this.life = params.life;
    this.accidents = params.accidents;
    this.health = params.health;
    this.accessLife = params.accessLife;
    this.accessAccidents = params.accessAccidents;
    this.accessHealth = params.accessHealth;
    this.averageAge = params.averageAge;
    this.internacionalEspecialization = params.internacionalEspecialization;
    this.branches = params.branches;
    this.preferredSectors = params.preferredSectors;
    this.advanceCommission = params.advanceCommission;
    this.availableCredits = params.availableCredits;
    this.ainaPolicies = params.ainaPolicies;
    this.onboarding = params.onboarding;
    this.attachment = params.attachment;
  }
}

export default Insurer;
