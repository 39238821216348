import { useState } from 'react';
import { GridActionsCellItem } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import {
  ArrowRightIcon,
  CheckIcon,
  DownloadIcon,
  EditIcon,
} from 'assets/icons/IconsLibrary';
import CustomTable from 'components/blocks/CustomTable';
import {
  AINA_MANAGER,
  CLIENT_USER,
  INSURER_MANAGER,
  INSURER_SUPPORT,
  INSURER_USER,
  PROJECT_STATUS_CANCELLED_ID,
  PROJECT_STATUS_FINALIZED_ID,
} from 'utils/constants';

import { columns as columsConfiguration } from './tableConfiguration';
import { useUserStore } from 'context/UserProvider/hooks';
import Button from 'UI/atoms/Button';
import getDocumentationList from 'services/projects/getDocumentationList';
import AccessProjectDialog from '../AccessProjectDialog';
import downloadDocumentation from 'services/projects/downloadDocumentation';
import { showErrorNotification } from 'utils/showToastNotifications';
import ProjectDialog from '../ProjectDialog';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useDownloadFile } from 'hooks/useDownloadFile';

const DOCUMENT_QUOTE_ID = '25';

const ProjectTable = ({
  loading,
  projects,
  rowCount,
  paginationModel,
  sortModel,
  onPaginationModelChange,
  onSortModelChange,
  addButtonOnClick,
  onRowClick,
}) => {
  const { t } = useTranslation('projectsComponent');
  const [userState] = useUserStore();
  const [selectedProject, setSelectedProject] = useState(null);
  const [openDetailModal, setOpenDetailModal] = useState(false);
  const [openAccessModal, setOpenAccessModal] = useState(false);

  const columns = columsConfiguration(t, userState.role);

  const { downloadFile } = useDownloadFile(
    (...params) => downloadDocumentation(...params),
    undefined,
    t('errorDownloadFile'),
  );

  function getMappedRows() {
    return projects.map((project) => ({
      ...project,
      participantNames: getParticipants(project?.participants ?? []),
    }));
  }

  function getParticipants(participants) {
    let text = '';
    for (let index = 0; index < participants.length; index++) {
      if (index === participants.length - 1 || participants.length === 1) {
        text += participants[index].lookupValue;
      } else {
        text += participants[index].lookupValue + ', ';
      }
    }

    return text;
  }

  const downloadProjectFile = async (params) => {
    const projectFiles = await getDocumentationList(params.row.id, {
      documentTypeId: DOCUMENT_QUOTE_ID,
    });

    let projectFile = null;

    if (projectFiles?.resultList?.length > 0) {
      projectFile = projectFiles.resultList[0];
    }

    if (!projectFile) {
      showErrorNotification(t('errorDownloadFile'));
      return false;
    }

    downloadFile(params.row.companyId, projectFile.id, projectFile.fileName);
  };

  const getActions = (params) => {
    const seeAction = (
      <GridActionsCellItem
        key="see"
        icon={<VisibilityIcon color="info" />}
        label={t('see')}
        onClick={() => {
          setSelectedProject(params.row);
          setOpenDetailModal(true);
        }}
      />
    );
    if ([AINA_MANAGER].includes(userState.role)) {
      return [
        seeAction,
        <GridActionsCellItem
          key="edit"
          icon={<EditIcon />}
          label={t('edit')}
          onClick={() => {
            onRowClick(params.row, 'projects', [
              'companyName',
              'companyId',
              'code',
            ]);
          }}
        />,
      ];
    }
    if ([CLIENT_USER].includes(userState.role)) {
      return [
        <GridActionsCellItem
          key="see"
          icon={<VisibilityIcon color="info" />}
          label={t('see')}
          onClick={() => {
            onRowClick(params.row, 'projects', [
              'companyName',
              'companyId',
              'code',
            ]);
          }}
        />,
        <GridActionsCellItem
          key="download"
          icon={<DownloadIcon />}
          label={t('download')}
          onClick={() => downloadProjectFile(params)}
        />,
      ];
    }

    const insurerId = userState.insurerId;

    if (
      [INSURER_USER, INSURER_SUPPORT, INSURER_MANAGER].includes(
        userState.role,
      ) &&
      insurerId
    ) {
      if (/true/.test(params.row.accessGranted)) {
        return [
          seeAction,
          <GridActionsCellItem
            key="download"
            icon={<DownloadIcon />}
            label={t('download')}
            onClick={() => downloadProjectFile(params)}
          />,
          <Button
            key="participating"
            color="green"
            size="small"
            text={t('participating')}
            onClick={() => {
              onRowClick(params.row, 'projects', [
                'companyName',
                'companyId',
                'code',
              ]);
            }}
            icon={ArrowRightIcon}
          ></Button>,
        ];
      }

      if (
        ![PROJECT_STATUS_CANCELLED_ID, PROJECT_STATUS_FINALIZED_ID].includes(
          params.row.statusId,
        ) &&
        userState.onboarding
      )
        return [
          seeAction,
          <Button
            key="participate"
            size="small"
            text={t('participate')}
            onClick={() => {
              setSelectedProject(params.row);
              setOpenAccessModal(true);
            }}
            icon={CheckIcon}
          ></Button>,
        ];
    }
    return [seeAction];
  };

  return (
    <>
      <CustomTable
        loading={loading}
        rows={getMappedRows()}
        rowCount={rowCount}
        paginationModel={paginationModel}
        sortModel={sortModel}
        pageSizeOptions={[10, 20, 50, 100]}
        columns={[
          ...columns,
          {
            field: 'actions',
            type: 'actions',
            headerAlign: 'left',
            align: 'right',
            flex: 1,
            minWidth: 200,
            headerName: t('actionsHeader'),
            getActions,
          },
        ]}
        addButtonText={t('newProject')}
        addButtonOnClick={addButtonOnClick}
        onPaginationModelChange={onPaginationModelChange}
        onSortModelChange={onSortModelChange}
        onRowClick={(row) => {
          if ([AINA_MANAGER, CLIENT_USER].includes(userState.role)) {
            onRowClick(row, 'projects', ['companyName', 'companyId', 'code']);
          }
        }}
      />
      <AccessProjectDialog
        open={openAccessModal}
        project={selectedProject}
        handleAccessAccepted={() =>
          onRowClick(selectedProject, 'projects', [
            'companyName',
            'companyId',
            'code',
          ])
        }
        onClose={() => {
          setOpenAccessModal(false);
          setSelectedProject(null);
        }}
      />
      <ProjectDialog
        open={openDetailModal}
        selectedProject={selectedProject}
        onClose={() => {
          setOpenDetailModal(false);
        }}
      />
    </>
  );
};

ProjectTable.propTypes = {
  loading: PropTypes.bool,
  projects: PropTypes.array,
  rowCount: PropTypes.number,
  onPaginationModelChange: PropTypes.func,
  addButtonOnClick: PropTypes.func,
  onSortModelChange: PropTypes.func,
  onRowClick: PropTypes.func,
  paginationModel: PropTypes.object,
  sortModel: PropTypes.array,
};

export default ProjectTable;
