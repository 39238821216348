import { Link } from 'react-router-dom';

import CreditsCounter from 'UI/atoms/CreditsCounter';
import { useUserStore } from 'context/UserProvider/hooks';
import { useTranslation } from 'react-i18next';

import {
  INSURER_USER,
  INSURER_SUPPORT,
  INSURER_MANAGER,
} from 'utils/constants';

const InsurerCredits = () => {
  const [t] = useTranslation('insurerCredits');
  const [userState] = useUserStore();

  if (
    ![INSURER_USER, INSURER_SUPPORT, INSURER_MANAGER].includes(userState.role)
  ) {
    return <></>;
  }

  return (
    <Link to={'/profile/credits'} style={{ textDecoration: 'none' }}>
      <CreditsCounter>
        {t('credits', { creditsNumber: userState.credits })}
      </CreditsCounter>
    </Link>
  );
};

export default InsurerCredits;
