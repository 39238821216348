import { Link } from 'react-router-dom';

import Footer from 'components/home/Footer';
import Header from 'components/home/Header';
import PublicContainer from 'UI/atoms/PublicContainer';

const PrivacyPolicyPage = () => {
  return (
    <div>
      <Header />
      <PublicContainer>
        <h1>Política de Privacidad</h1>
        <h2>Introducción</h2>
        <p>
          En Aina.Market estamos totalmente comprometidos con la seguridad de
          tus datos personales. Por eso, diseñamos nuestros productos y
          servicios teniendo en cuenta tu privacidad y siempre trataremos estos
          datos con el máximo respeto.
        </p>
        <p>
          Nuestra política de privacidad te ayudará a comprender cómo
          recopilamos, usamos y protegemos tus datos personales. Al utilizar
          nuestros servicios y aceptar esta Política de Privacidad, manifiestas
          tu conformidad con la recopilación y tratamiento de los datos para las
          finalidades y de la manera que aquí se detallan.
        </p>
        <p>
          Estas condiciones aplican tanto a la web como a la plataforma, por lo
          que todas las menciones a la web se entenderán también respecto de la
          plataforma.
        </p>
        <h2>Quiénes somos</h2>
        <p>
          El servicio de correduría de seguros es proporcionado por Aina Markets
          S.L. (en adelante, “AinaMarket”) con domicilio en Calle Nanclares de
          Oca 18, 6º 2ª, 28022 Madrid, con CIF B56450315, inscrita en el
          Registro Mercantil de Madrid, tomo 45832, folio 13, hoja M-805504,
          inscripción 1.
        </p>
        <p>
          AinaMarket es un corredor de seguros conforme a la Ley 26/2006, de 17
          de julio, de mediación de seguros y reaseguros privados, inscrito en
          la Dirección General de Seguros y Fondos de Pensiones con número
          J-4285. AinaMarket es responsable del tratamiento de los datos que nos
          proporciones en nuestra web o plataforma (en adelante, el “sitio
          web”).
        </p>
        <p>
          Puedes obtener más información sobre quiénes somos aquí:{' '}
          <Link to={'/legal-notice'}>
            <a>https://aina.market/legal-notice</a>
          </Link>
          .
        </p>
        <h2>Tratamiento de tus datos según el servicio solicitado</h2>
        <p>
          AinaMarket puede guardar la dirección IP de tu dispositivo por razones
          técnicas y de seguridad de nuestra página web, en base a nuestro
          interés legítimo. AinaMarket nunca utilizará la IP para identificarte
          personalmente.
        </p>
        <h2>Mediación en la contratación de seguros</h2>
        <p>
          Cuando solicites la mediación de AinaMarket como corredor de seguros,
          se tratarán tus datos para:
        </p>
        <ol>
          <li>
            <strong>Prestarte el servicio solicitado</strong>: Los datos
            facilitados serán utilizados para proporcionarte cotizaciones
            adecuadas, ofrecer asesoramiento independiente, gestionar la
            contratación y asistirte después de la contratación de tu seguro.
          </li>
          <li>
            <strong>
              Acceso y gestión de &quot;Mi cuenta AinaMarket&quot;
            </strong>
            : Trataremos tus datos de registro y acceso, perfil, presupuestos
            personalizados y documentación de contratos.
          </li>
          <li>
            <strong>Gestionar el cobro de nuestras comisiones</strong>: Para
            este fin, intercambiaremos información anonimizada con las compañías
            cuyos productos mediamos.
          </li>
          <li>
            <strong>Marketing</strong>: Podremos contactarte para informarte
            sobre nuestras propuestas a través de diversos medios, salvo que
            indiques que no deseas recibir comunicaciones. Utilizamos
            tecnologías como píxeles, etiquetas publicitarias, cookies, e
            identificadores de teléfonos móviles.
          </li>
          <li>
            <strong>Marketing de </strong>: Compartiremos información
            anonimizada con terceros para mostrarte publicidad personalizada.
          </li>
          <li>
            <strong>Personalización y análisis</strong>: Utilizaremos tus datos
            para recordar tus preferencias y realizar análisis estadísticos y de
            negocio.
          </li>
          <li>
            <strong>Mejora de la experiencia y servicio</strong>: Recabaremos
            información sobre la calidad del servicio para mejorar nuestro
            rendimiento.
          </li>
          <li>
            <strong>Cumplimiento de obligaciones legales</strong>: Trataremos
            tus datos para cumplir con nuestras obligaciones legales.
          </li>
          <li>
            <strong>Atención al cliente</strong>: Utilizaremos tus datos para
            ayudarte con cualquier duda sobre nuestro servicio.
          </li>
          <li>
            <strong>Quejas y reclamaciones</strong>: Trataremos tus datos para
            gestionar tus quejas y reclamaciones.
          </li>
          <li>
            <strong>Servicio de asistencia para dar de baja tu seguro</strong>:
            Los datos se tratarán para prestarte este servicio.
          </li>
        </ol>
        <h2>Bases legales para el tratamiento de tus datos:</h2>
        <ul>
          <li>
            <strong>Necesidad contractual</strong>: Para prestarte el servicio
            de mediación para la contratación de un seguro.
          </li>
          <li>
            <strong>Interés legítimo</strong>: Para nuestros intereses
            comerciales que no vayan en contra de tus derechos.
          </li>
          <li>
            <strong>Consentimiento</strong>: En casos específicos como el envío
            de comunicaciones comerciales o el uso de cookies.
          </li>
        </ul>
        <h2>Cómo obtenemos tus datos</h2>
        <p>Recabamos tus datos de las siguientes formas:</p>
        <ul>
          <li>
            <strong>Directamente de ti</strong>: A través del cuestionario
            online, conversaciones de chat, o telefónicas con nuestro servicio
            de Atención al Cliente.
          </li>
          <li>
            <strong>De las compañías aseguradoras</strong>: Para gestionar el
            cobro de nuestras comisiones o proporcionarte asistencia
            post-contratación.
          </li>
          <li>
            <strong>Consultas en bases de datos</strong>: Consultamos
            información en bases de datos públicas o privadas.
          </li>
          <li>
            <strong>Redes sociales</strong>: Si decides registrarte en &quot;Mi
            cuenta AinaMarket&quot; a través de una red social.
          </li>
          <li>
            <strong>Uso del sitio web y servicios</strong>: A través de cookies
            y píxeles.
          </li>
        </ul>
        <h2>Compartimos tus datos</h2>
        <p>
          AinaMarket necesita compartir algunos datos con las compañías
          aseguradoras y terceros en las siguientes condiciones:
        </p>
        <ul>
          <li>
            Para obtener ofertas y gestionar la contratación de tu póliza.
          </li>
          <li>Para prestarte asistencia durante la vigencia de tu póliza.</li>
          <li>
            Para percibir la remuneración de las compañías cuyos productos
            mediamos.
          </li>
          <li>Para mostrarte publicidad personalizada a través de cookies.</li>
        </ul>
        <h2>Plataformas de terceros con las que compartimos datos</h2>
        <ol>
          <li>
            <strong>Google Analytics</strong>: Utilizado para analizar el uso
            del sitio web y mejorar nuestros servicios.
          </li>
          <li>
            <strong>Facebook Ads y Audience Network</strong>: Para mostrarte
            publicidad personalizada en base a tu comportamiento en nuestra web.
          </li>
          <li>
            <strong>LinkedIn Marketing Solutions</strong>: Para ofrecerte
            publicidad adaptada a tus intereses profesionales.
          </li>
          <li>
            <strong>Hubspot</strong>: Para gestionar y enviar nuestras campañas
            de email marketing.
          </li>
          <li>
            <strong>Hubspot</strong>: Para gestionar el servicio de atención al
            cliente y soporte.
          </li>
          <li>
            <strong>Apollo.io</strong>: Para obtener permisos de contacto de
            usuarios de terceras plataformas.
          </li>
        </ol>
        <h2>Tus derechos</h2>
        <p>Como titular de los datos, tienes derechos como:</p>
        <ul>
          <li>
            <strong>Acceder a tus datos personales.</strong>
          </li>
          <li>
            <strong>Ser informado sobre el tratamiento de tus datos.</strong>
          </li>
          <li>
            <strong>Rectificar tus datos personales.</strong>
          </li>
          <li>
            <strong>Oponerte al procesamiento de tus datos.</strong>
          </li>
          <li>
            <strong>Solicitar la supresión de tus datos.</strong>
          </li>
          <li>
            <strong>Portabilidad de tus datos.</strong>
          </li>
          <li>
            <strong>Decisiones automatizadas y elaboración de perfiles.</strong>
          </li>
        </ul>
        <h2>Ejercicio de tus derechos</h2>
        <p>
          Para ejercer tus derechos, puedes escribirnos a: aina@aina.market o
          por correo postal a: Aina Markets SL., Calle Nanclares de Oca 18, 6º
          2ª, 28022 Madrid.
        </p>
        <h2>Contacto con el Delegado de Protección de Datos</h2>
        <p>
          Puedes contactar con el Delegado de Protección de Datos de Aina Market
          en España a través del correo electrónico: aina@aina.market.
        </p>
        <h2>Reclamación ante la AEPD</h2>
        <p>
          Si consideras que tus derechos han sido vulnerados, puedes presentar
          una reclamación ante la Agencia Española de Protección de Datos (AEPD)
          en su sede (Calle Jorge Juan 6, 28001 Madrid) o en su sitio web:
          https://www.aepd.es.
        </p>
        <h2>Conservación de los datos</h2>
        <p>
          Conservaremos tus datos personales durante el tiempo necesario para
          proporcionarte el servicio y hasta un máximo de 4 años para análisis y
          elaboración de perfiles. Luego, los datos serán bloqueados conforme a
          la ley.
        </p>
        <h2>Seguridad de tus datos</h2>
        <p>
          Utilizamos medidas de seguridad estándar para proteger tus datos en
          tránsito a nuestros servidores. En caso de transferencias fuera del
          EEE, aseguramos la protección de tus datos conforme a las cláusulas
          contractuales tipo de la Comisión Europea.
        </p>
        <h2>Cambios en esta política</h2>
        <p>
          Esta política de privacidad fue actualizada por última vez el 15 de
          junio de 2024. De vez en cuando, es posible que tengamos que modificar
          esta Política y te informaremos adecuadamente de los cambios.
        </p>
      </PublicContainer>
      <Footer />
    </div>
  );
};

export default PrivacyPolicyPage;
